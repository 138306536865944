import { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Navbar/Sidebar';
import TopNavbar from '../components/Navbar/TopNavbar';
import AdminVisit from '../pages/properties/AdminVisit';
import Dashboard from '../pages/dashboard/Dashboard';
import AdminProperty from '../pages/properties/AdminProperty';
import CreateProperty from '../pages/properties/CreateProperty';
import Properties from '../pages/properties/Properties';
import PropertyDetail from '../pages/properties/PropertyDetail';
import PropertyPhotos from '../pages/properties/PropertyPhotos';
import PublishProperties from '../pages/properties/PublishProperties';
import PublishProperty from '../pages/properties/PublishProperty';
import Tenants from '../pages/tenant/Tenants';
import TenantDetail from '../pages/tenant/TenantDetail';
import TenantCreate from '../pages/tenant/TenantCreate';
import LandlordCreate from '../pages/landlord/LandlordCreate';
import Landlords from '../pages/landlord/Landlords';
import LandlordDetail from '../pages/landlord/LandlordDetail';
import GuestVisits from '../pages/guest/GuestVisits';
import GuestOffers from '../pages/guest/GuestOffers';
import GuestVisitDetail from '../pages/guest/GuestVisitDetail';
import GuestOfferDetail from '../pages/guest/GuestOfferDetail';
import AdminOffer from '../pages/properties/AdminOffer';
import OfferDetail from '../pages/offers/OfferDetail';
import CreateContract from '../pages/contracts/CreateContract';
import ContractDetail from '../pages/contracts/ContractDetail';
import GuestProfileEdit from '../pages/guest/GuestProfileEdit';
import GuestOfferEdit from '../pages/guest/GuestOfferEdit';
import GuestVisitEdit from '../pages/guest/GuestVisitEdit';
import Contracts from '../pages/contracts/Contracts';
import ContractPaymentsList from '../pages/contract_payments/ContractPaymentsList';
import ContractPaymentsDetail from '../pages/contract_payments/ContractPaymentsDetail';
import ContractQuotaDetail from '../pages/contract_payments/ContractQuotaDetail';
import InvestorProjectList from '../pages/investors/InvestorProjectList';
import InvestorProjectDetails from '../pages/investors/InvestorProjectDetails';
import InvestorProjectCreate from '../pages/investors/InvestorProjectCreate';
import InvestorProjectEdit from '../pages/investors/InvestorProjectEdit';
import InvestGuestVisits from '../pages/investors/InvestGuestVisits';
import InvestGuestVisitDetail from '../pages/investors/InvestGuestVisitDetail';
import InvestorProjectModelList from '../pages/investors/InvestorProjectModelList';
import InvestorProjectModelCreate from '../pages/investors/InvestorProjectModelCreate';
import InvestorProjectModelDetails from '../pages/investors/InvestorProjectModelDetails';
import ModelUnitCreate from '../pages/investors/ModelUnitCreate';
import ModelUnitList from '../pages/investors/ModelUnitList';
import InvestGuestPreBooking from '../pages/investors/InvestGuestPreBooking';
import InvestGuestPreBookingDetail from '../pages/investors/InvestGuestPreBookingDetail';
import InvestorProjectUploadResources from '../pages/investors/InvestorProjectUploadResources';
import InvestorProjectModelUploadResources from '../pages/investors/InvestorProjectModelUploadResources';
import ModelUnitUploadResources from '../pages/investors/ModelUnitUploadResources';
import ModelUnitFinances from '../pages/investors/ModelUnitFinances';
import ModelUnitPaymentContract from '../pages/investors/ModelUnitPaymentContract';
import ModelUnitUpdate from '../pages/investors/ModelUnitUpdate';
import InvestorProjectAccess from '../pages/investors/components/InvestorProjectAccess';
import InvestorPaymentList from '../pages/investors/InvestorPaymentList';
import InvestorPaymentDetail from '../pages/investors/InvestorPaymentDetail';
import InvestorQuotaDetail from '../pages/investors/InvestorQuotaDetail';
import InvestorProjectModelEdit from '../pages/investors/InvestorProjectModelEdit';
import { useDispatch } from 'react-redux';
import { clearGridFiltersAction } from '../redux/features/gridSearchBar';
import AccountingProofIncomeCreate from '../pages/accounting/AccoutingProofIncomeCreate';
import AccountingProofIncomeDetail from '../pages/accounting/AccountingProofIncomeDetail';
import AccountingProofIncomeList from '../pages/accounting/AccountingProofIncomeList';
import { PROOF_INCOME_PRODUCT_TYPES } from '../constants/accountingConstants';
import AccountingProofOutcomeCreate from '../pages/accounting/AccountingProofOutcomeCreate';
import AccountingProofOutcomeResume from '../pages/accounting/AccountingProofOutcomeResume';
import AccountingProofOutcomeList from '../pages/accounting/AccountingProofOutcomeList';
import AccountingProofOutcomeDetail from '../pages/accounting/AccountingProofOutcomeDetail';
import AdminPropertyBroker from '../pages/properties/AdminPropertyBroker';
import GuestProperties from '../pages/guest/GuestProperties';
import GuestPropertyDetail from '../pages/guest/GuestPropertyDetail';
import PropertyCounterViews from '../pages/properties/PropertyCounterViews';
import HomeSlider from '../pages/settings/HomeSlider';
import TenantEdit from '../pages/tenant/TenantEdit';
import HomeSliderPhotos from '../pages/settings/HomeSliderPhotos';
import ResidentialList from '../pages/residentials/ResidentialList';
import ResidentialCreate from '../pages/residentials/ResidentialCreate';
import ResidentialUpdate from '../pages/residentials/ResidentialUpdate';
import DashboardResumeVisits from '../pages/dashboard/DashboardResumeVisits';
import useUserCountry from '../hooks/useUserCountry';
import AccountingProofOutcomeUpdate from '../pages/accounting/AccountingProofOutcomeUpdate';
import AccountingProofIncomeUpdate from '../pages/accounting/AccountingProofIncomeUpdate';
import useAuth from '../hooks/useAuth';
import InvestorProjectVisits from '../pages/investors/components/InvestorProjectVisits';
import Subscriptions from '../pages/subscriptions/Subscriptions';
import SubscriptionDetail from '../pages/subscriptions/SubscriptionDetail';
import ModelUnitSigningPromiseDocs from '../pages/investors/ModelUnitSigningPromiseDocs';
import AdminPropertySales from '../pages/properties/AdminPropertySales';
import AdminPolygons from '../pages/admin_polygons/AdminPolygons';
import TransactionSaleList from '../pages/secondary/TransactionSaleList';
import TransactionSalePaymentDetail from '../pages/secondary/TransactionSalePaymentDetail';
import TransactionSaleQuotaDetail from '../pages/secondary/TransactionSaleQuotaDetail';
import InvestorProjectUnits from '../pages/investors/InvestorProjectUnits';
import ModelUnitBulkCreate from '../pages/investors/ModelUnitBulkCreate';
import DocumentsUser from '../pages/documents/DocumentsUser';
import AdminPropertyRent from '../pages/properties/AdminPropertyRent';
import WithdrawalList from '../pages/withdrawal/WithdrawalList';
import WithdrawalCreatePreSale from '../pages/withdrawal/WithdrawalCreatePreSale';
import WithdrawalDetail from '../pages/withdrawal/WithdrawalDetail';
import AddressListPicker from '../components/address/AddressListPicker';
import { AdminPropertyAdditionalInfo } from '../pages/properties/AdminPropertyAdditionalInfo';
import AdminPropertyAddressLocation from '../pages/properties/AdminPropertyAddressLocation';
import AdminPropertyAmenities from '../pages/properties/AdminPropertyAmenities';
import InvestorProjectTowerLayout from '../pages/investors/InvestorProjectTowerLayout';
import InvestorProjectFloorLayout from '../pages/investors/InvestorProjectFloorLayout';
import CustomerCreate from '../pages/customer/CustomerCreate';

const SiteRoutes = () => {
  const dispatch = useDispatch();
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const location = useLocation();
  const mainContent = useRef(null);
  const { getCurrentCountry } = useUserCountry();
  const navigate = useNavigate();
  const { getUserRoles, unsetAuth } = useAuth();
  const toggleSidenav = (e: any) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const roles = getUserRoles();

  useEffect(() => {
    dispatch(clearGridFiltersAction({}));
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  if (!roles.length) {
    unsetAuth();
    navigate('/auth/login', { replace: true });
    return <></>;
  }

  return (
    <>
      <Sidebar
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        country={getCurrentCountry()?.code}
        assigned_roles={roles}
        logo={{
          innerLink: '/',
          imgSrc: require('../assets/img/brand/propi-logo-full.png'),
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <TopNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
        />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {/* Property routes */}
          <Route path="/properties/view" element={<Properties />} />
          <Route path="/properties/admin/:id" element={<AdminProperty />} />
          {/* Property admin routes and crud info */}
          <Route
            path="/properties/create"
            element={<CreateProperty isUpdate={false} />}
          />
          {/* Deprecate update */}
          <Route
            path="/properties/update/:id"
            element={<CreateProperty isUpdate={true} />}
          />

          <Route
            path="/properties/admin/:id/additional-info"
            element={<AdminPropertyAdditionalInfo />}
          />
          <Route
            path="/properties/admin/:id/address-location"
            element={<AdminPropertyAddressLocation />}
          />
          <Route
            path="/properties/admin/:id/amenities"
            element={<AdminPropertyAmenities />}
          />
          <Route
            path="/properties/admin/:id/rent"
            element={<AdminPropertyRent />}
          />
          <Route
            path="/properties/admin/:id/sales"
            element={<AdminPropertySales />}
          />
          <Route
            path="/properties/admin/:id/photos"
            element={<PropertyPhotos />}
          />
          <Route
            path="/properties/admin/:id/broker"
            element={<AdminPropertyBroker />}
          />

          <Route
            path="/properties/admin/:id/detail"
            element={<PropertyDetail />}
          />
          <Route
            path="/properties/admin/:id/admin-visit"
            element={<AdminVisit />}
          />
          <Route
            path="/properties/admin/:id/admin-offer"
            element={<AdminOffer />}
          />
          <Route
            path="/properties/admin/:id/views"
            element={<PropertyCounterViews />}
          />
          <Route path="/properties/publish" element={<PublishProperties />} />
          <Route path="/properties/publish/:id" element={<PublishProperty />} />
          {/* Tenant routes */}
          <Route path="/tenant/view" element={<Tenants />} />
          <Route path="/tenant/:id/profile" element={<TenantDetail />} />
          <Route path="/tenant/:id/edit" element={<TenantEdit />} />
          {/* <Route path="/tenant/create" element={<TenantCreate />} /> */}
          <Route path="/tenant/create" element={<CustomerCreate />} />
          <Route path="/tenant/:id/documents" element={<DocumentsUser />} />
          {/* Landlord routes */}
          {/* <Route path="/landlord/create" element={<LandlordCreate />} /> */}
          <Route path="/landlord/create" element={<CustomerCreate />} />
          <Route path="/landlord/view" element={<Landlords />} />
          <Route path="/landlord/:id/profile" element={<LandlordDetail />} />
          <Route path="/landlord/:id/documents" element={<DocumentsUser />} />
          {/* Offer routes */}
          <Route path="/offer/:id/detail" element={<OfferDetail />} />
          {/* Contract routes */}
          <Route
            path="/offer/:id/contract/create"
            element={<CreateContract />}
          />
          <Route path="/contract/:id/detail" element={<ContractDetail />} />
          <Route path="/contract/view" element={<Contracts />} />
          {/* Guest routes */}
          <Route path="/guest/visits" element={<GuestVisits />} />
          <Route path="/guest/offers" element={<GuestOffers />} />
          <Route path="/guest/properties" element={<GuestProperties />} />
          <Route path="/guest-visits/:id" element={<GuestVisitDetail />} />
          <Route path="/guest-visits/:id/edit" element={<GuestVisitEdit />} />
          <Route path="/guest-offers/:id" element={<GuestOfferDetail />} />
          <Route path="/guest-offers/:id/edit" element={<GuestOfferEdit />} />
          <Route
            path="/guest/:entityType/:entityId/edit"
            element={<GuestProfileEdit />}
          />
          <Route
            path="/guest-properties/:id"
            element={<GuestPropertyDetail />}
          />
          {/* Contract Payments */}
          <Route
            path={'/contracts/payment-status'}
            element={<ContractPaymentsList />}
          />
          <Route
            path={'/contracts/:id/payment-status'}
            element={<ContractPaymentsDetail />}
          />
          <Route
            path={'/contracts/:contractId/quota/:transactionId'}
            element={<ContractQuotaDetail />}
          />
          {/* Invierte */}
          <Route
            path={'/investors/projects/create'}
            element={<InvestorProjectCreate />}
          />
          <Route
            path={'/investors/projects/:id/edit'}
            element={<InvestorProjectEdit />}
          />
          <Route
            path={'/investors/projects'}
            element={<InvestorProjectList />}
          />
          <Route
            path={'/investors/projects/:id'}
            element={<InvestorProjectDetails />}
          />
          <Route
            path={'/investors/projects/:id/access'}
            element={<InvestorProjectAccess />}
          />
          <Route
            path={'/investors/projects/:id/visits'}
            element={<InvestorProjectVisits />}
          />
          <Route
            path={'/investors/projects/:id/upload-images'}
            element={<InvestorProjectUploadResources />}
          />
          <Route
            path={'/investors/projects/:id/models'}
            element={<InvestorProjectModelList />}
          />
          <Route
            path={'/investors/projects/:id/models/create'}
            element={<InvestorProjectModelCreate />}
          />
          <Route
            path={'/investors/projects/:id/models/:modelId/edit'}
            element={<InvestorProjectModelEdit />}
          />
          <Route
            path={'/investors/projects/:id/models/:modelId'}
            element={<InvestorProjectModelDetails />}
          />
          <Route
            path={'/investors/projects/:id/models/:modelId/upload-images'}
            element={<InvestorProjectModelUploadResources />}
          />
          <Route
            path={'/investors/projects/:id/units'}
            element={<InvestorProjectUnits />}
          />
          <Route
            path={'/investors/projects/:id/tower-layout'}
            element={<InvestorProjectTowerLayout />}
          />
          <Route
            path={'/investors/projects/:id/tower-layout/floor-layout/:id_dist'}
            element={<InvestorProjectFloorLayout />}
          />
          <Route
            path={'/investors/models/:id/model-units/create'}
            element={<ModelUnitCreate />}
          />
          <Route
            path={'/investors/models/:id/model-units/:modelUnitId/edit'}
            element={<ModelUnitUpdate />}
          />
          <Route
            path={
              '/investors/models/:id/model-units/:modelUnitId/upload-images'
            }
            element={<ModelUnitUploadResources />}
          />
          <Route
            path={'/investors/models/:id/model-units/:modelUnitId/finances'}
            element={<ModelUnitFinances />}
          />
          <Route
            path={
              '/investors/models/:id/model-units/:modelUnitId/payment-contract'
            }
            element={<ModelUnitPaymentContract />}
          />
          <Route
            path={
              '/investors/models/:id/model-units/:modelUnitId/upload-signing-promise-docs'
            }
            element={<ModelUnitSigningPromiseDocs />}
          />
          <Route
            path={'/investors/models/:id/model-units'}
            element={<ModelUnitList />}
          />
          <Route
            path={'/investors/guest/visits'}
            element={<InvestGuestVisits />}
          ></Route>
          <Route
            path={'/investors/guest/visits/:id'}
            element={<InvestGuestVisitDetail />}
          ></Route>
          <Route
            path={'/investors/guest/pre-booking'}
            element={<InvestGuestPreBooking />}
          ></Route>
          <Route
            path={'/investors/guest/pre-booking/:id'}
            element={<InvestGuestPreBookingDetail />}
          ></Route>
          <Route
            path={'/investors/payments'}
            element={<InvestorPaymentList />}
          ></Route>
          <Route
            path={'/investors/payments/:id'}
            element={<InvestorPaymentDetail />}
          ></Route>
          <Route
            path={'/investors/payments/:id/quota/:transactionDetailId'}
            element={<InvestorQuotaDetail />}
          ></Route>
          <Route
            path={'/investors/models/:id/create-units-in-csv'}
            element={<ModelUnitBulkCreate />}
          ></Route>
          {/* Contabilidad */}
          <Route
            path={'/accounting/proof-income'}
            element={<AccountingProofIncomeList />}
          />
          <Route
            path={
              '/accounting/proof-income/pre-sales/:transactionDetailId/create'
            }
            element={
              <AccountingProofIncomeCreate
                incomeProduct={PROOF_INCOME_PRODUCT_TYPES.PRE_SALE}
              />
            }
          />
          <Route
            path={'/accounting/proof-income/rent/:transactionDetailId/create'}
            element={
              <AccountingProofIncomeCreate
                incomeProduct={PROOF_INCOME_PRODUCT_TYPES.RENT}
              />
            }
          />
          <Route
            path={'/accounting/proof-income/sale/:transactionDetailId/create'}
            element={
              <AccountingProofIncomeCreate
                incomeProduct={PROOF_INCOME_PRODUCT_TYPES.SALE}
              />
            }
          />
          <Route
            path={'/accounting/proof-income/:proofIncomeId'}
            element={<AccountingProofIncomeDetail />}
          />
          <Route
            path={'/accounting/proof-income/:id/edit'}
            element={<AccountingProofIncomeUpdate />}
          />
          <Route
            path={'/accounting/proof-outcome/create'}
            element={<AccountingProofOutcomeCreate />}
          />
          <Route
            path={'/accounting/proof-outcome/summary'}
            element={<AccountingProofOutcomeResume />}
          />
          <Route
            path={'/accounting/proof-outcome'}
            element={<AccountingProofOutcomeList />}
          />
          <Route
            path={'/accounting/proof-outcome/:id'}
            element={<AccountingProofOutcomeDetail />}
          />
          <Route
            path={'/accounting/proof-outcome/:id/edit'}
            element={<AccountingProofOutcomeUpdate />}
          />
          {/* Settings */}
          <Route path={'/settings/home-slider'} element={<HomeSlider />} />
          <Route
            path={'/settings/home-slider/:id/photo'}
            element={<HomeSliderPhotos />}
          />
          <Route
            path={'/settings/propi-estimate'}
            element={<AdminPolygons />}
          />
          {/* Residentials */}
          <Route path={'/residentials'} element={<ResidentialList />} />
          <Route
            path={'/residentials/create'}
            element={<ResidentialCreate />}
          />
          <Route
            path={'/residentials/:id/edit'}
            element={<ResidentialUpdate />}
          />
          {/* Dashboard */}
          <Route path="/dashboard/visits" element={<DashboardResumeVisits />} />
          {/* Subscriptions */}
          <Route
            path="/subscriptions/:id/detail"
            element={<SubscriptionDetail />}
          />
          <Route
            path="/subscriptions/view/rental-managment"
            element={<Subscriptions />}
          />
          {/* Pagos Secondary */}
          <Route
            path={'/transaction-sales'}
            element={<TransactionSaleList />}
          />
          <Route
            path={'/transaction-sales/:id'}
            element={<TransactionSalePaymentDetail />}
          />
          <Route
            path={
              '/transaction-sales/:transactionSaleId/quota/:transactionDetailId'
            }
            element={<TransactionSaleQuotaDetail />}
          />
          {/* Desestimiento */}
          <Route path={'/withdrawal/'} element={<WithdrawalList />} />
          <Route
            path={'/withdrawal/pre-sale/create'}
            element={<WithdrawalCreatePreSale />}
          />
          <Route path={'/withdrawal/:id'} element={<WithdrawalDetail />} />
        </Routes>
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default SiteRoutes;
