import React from 'react';
import { TransactionDetailItem } from '../models/contracts';
import { PaymentsDetailsTable } from '../../../components/payments/PaymentsDetailsTable';
import { getTransactionDetailStatusAdapter } from '../../../adapters/transactionAdapter';

export type ContractFuturePaymentsProps = {
  transactionItems: Array<TransactionDetailItem>;
  duesPaids?: boolean;
};

const ContractFuturePayments: React.FC<ContractFuturePaymentsProps> = ({
  transactionItems,
  duesPaids = false,
}) => {
  const itemsMapped = transactionItems.map((item) => ({
    id: item.id,
    title: item.label,
    value: item.price,
    status: getTransactionDetailStatusAdapter(item.status),
    customDot: item.status === 'paid' ? 'bg-success' : '',
    href: `/contracts/${item.contractId}/quota/${item.id}`,
    due_month: item.due_month,
  }));
  return (
    <PaymentsDetailsTable
      title="Detalle de cuotas por pagar"
      items={itemsMapped}
      dues_paids={duesPaids}
      show_due_months={true}
    />
  );
};

export default ContractFuturePayments;
