import { Badge } from 'reactstrap';
import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { WithdrawalDetailInfo } from '../models/withdrawalModel';
import { WithdrawalStatus, WithdrawalType } from '../helpers/withdrawalHelper';
import { BussinessProduct } from '../../../helpers/bussinessHelpers';
import {
  getWithdrawalByStatus,
  getWithdrawalByType,
} from '../adapters/withdrawalAdapter';
import { getBussinessBadge } from '../../../adapters/bussinessAdapter';

interface Props {
  withdrawal: WithdrawalDetailInfo;
}

const WithdrawalInfoCard = ({ withdrawal }: Props) => {
  const byType: WithdrawalType = getWithdrawalByType(withdrawal.type);
  const byStatus: WithdrawalStatus = getWithdrawalByStatus(withdrawal.status);
  const byBussiness: BussinessProduct = getBussinessBadge(withdrawal.bussiness);

  const isWithdrawalPreSale: boolean = withdrawal.bussiness === 'pre_sale';

  const fieldMapper: DisplayItem[] = [
    {
      title: 'Tipo de desestimento',
      value: (
        <>
          <Badge color={byType.color} className="badge-lg">
            {byType.product_text}
          </Badge>
          <Badge color={byBussiness.color} className="badge-lg">
            {byBussiness.product_text}
          </Badge>
        </>
      ),
    },
    {
      title: 'Estado de desestimiento',
      value: (
        <>
          {' '}
          <Badge color={byStatus.color} className="badge-lg">
            {byStatus.product_text}
          </Badge>
        </>
      ),
    },

    {
      title: 'Razón de desestimiento',
      value: withdrawal.reason,
    },
    {
      title: 'Explicación',
      value: withdrawal.explain_reason,
    },
    {
      title: 'Generado',
      value: withdrawal.generated_at,
    },
  ];

  const detailWithdrawal: DisplayItem = {
    title: isWithdrawalPreSale
      ? 'Detalle de unidad a desestimar'
      : 'Detalle de propiedad a desestimar',
    value: withdrawal.initial_resume,
  };

  if (withdrawal.bussiness_id) {
    detailWithdrawal.href = isWithdrawalPreSale
      ? `/investors/payments/${withdrawal.bussiness_id}`
      : `/contract/${withdrawal.bussiness_id}/detail`;
  }

  fieldMapper.push(detailWithdrawal);

  if (withdrawal.ref_change_resume) {
    const obj: DisplayItem = {
      title: 'Cambio de unidad',
      value: withdrawal.ref_change_resume,
    };

    if (withdrawal.ref_change_link_id) {
      obj.href = `/investors/payments/${withdrawal.ref_change_link_id}`;
    }

    fieldMapper.push(obj);
  }

  return (
    <AdminListGroup
      title={'Información de desestimiento'}
      items={fieldMapper}
    />
  );
};

export default WithdrawalInfoCard;
