import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  AdminPropertyData,
  PropertyUpdateIsVerifiedApi,
} from '../models/propertyModel';
import useAuth from '../../../hooks/useAuth';
import {
  getAdminPropertyEndpoint,
  updatePropertyIsVerifiedEndpoint,
} from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

interface Props {
  id: string;
}

export const useAdminProperty = (propertyId: string) => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [adminPropertyData, setAdminPropertyData] =
    useState<AdminPropertyData>();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  useEffect(() => {
    const loadProperties = async () => {
      const { status, data } = await callEndpoint(
        getAdminPropertyEndpoint(propertyId, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setAdminPropertyData(response.data);
      }
    };
    loadProperties().catch((e) => console.error(e));
  }, []);

  const changeIsVerified = async (
    propertyId: string,
    isVerified: boolean = false,
  ) => {
    const form: PropertyUpdateIsVerifiedApi = {
      is_validated_by_proper: isVerified,
    };
    const { status, data } = await callEndpoint(
      updatePropertyIsVerifiedEndpoint(form, propertyId, token),
    );
    const response = fetchResponseAdapter(data);
    if (status !== 200) {
      console.error(response.message);
    } else {
      if (adminPropertyData) {
        setAdminPropertyData({
          ...adminPropertyData,
          is_validated_by_proper: isVerified
        });
      }
    }
  };

  return {
    isLoading,
    adminPropertyData,
    changeIsVerified,
  };
};
