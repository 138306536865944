import React from 'react';
import useAdminAddressLocation from './hooks/useAdminAddressLocation';
import { Alert, Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import { useParams } from 'react-router-dom';
import AddressListPicker from '../../components/address/AddressListPicker';
import LoadingCard from '../../components/LoadingCard';
import { Controller } from 'react-hook-form';

const AdminPropertyAddressLocation = () => {
  const { id = '' } = useParams();
  const {
    isLoading,
    control,
    setValue,
    errors,
    currentAddress,
    handleSubmit,
    formUpdateData,
    doAction,
  } = useAdminAddressLocation({ id });

  if (!formUpdateData) {
    return (
      <LoadingCard name={'Actualizar ubicación'} parent_name={'Propiedades'} />
    );
  }

  return (
    <AdminPage
      name={`Actualizar ubicación a propiedad #${formUpdateData?.code ?? ''}`}
      parentName={'Propiedades'}
      header={<h3 className="mb-0">{'Seleccione los datos de ubicación'}</h3>}
    >
      <Row>
        <div className="col">
          <Form role="form" onSubmit={handleSubmit(doAction)}>
            <Row>
              <Col md="6">
                <AddressListPicker
                  defaultPolygonId={formUpdateData.location?.id}
                  defaultCenter={{
                    lat: formUpdateData?.lat ?? 0,
                    lng: formUpdateData?.lng ?? 0,
                  }}
                  onAddressAssigned={(addressAssignedId, centerPoint) => {
                    setValue('lat', centerPoint.lat);
                    setValue('lng', centerPoint.lng);
                    setValue('address_id', addressAssignedId);
                  }}
                />
                <h5 className="text-danger">
                  {errors.lat?.message ||
                    errors.lng?.message ||
                    errors.address_id?.message}
                </h5>
              </Col>
            </Row>
            <Row>
              {/* address */}
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="address">
                    Ingresa la dirección
                  </label>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Input
                        className={errors.address?.message && 'is-invalid'}
                        {...field}
                        id="name"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.address?.message}</h5>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {/* address */}
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="street">
                    Calle
                  </label>
                  <Controller
                    name="street"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Input
                        className={errors.street?.message && 'is-invalid'}
                        {...field}
                        id="name"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.street?.message}</h5>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mt-3">
                <Button color={'warning'} disabled={isLoading}>
                  {'Actualizar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </AdminPage>
  );
};

export default AdminPropertyAddressLocation;
