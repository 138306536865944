import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink, Table, UncontrolledDropdown } from "reactstrap";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter"
import AdminPage from "../../components/admin/AdminPage";
import LoadingCard from "../../components/LoadingCard";
import useAuth from "../../hooks/useAuth"
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import { Paginator } from "../../models/formModel";
import { setGridFiltersAction } from "../../redux/features/gridSearchBar";
import { AppStore } from "../../redux/store";
import { getContractStatusAdapter } from "./adapters/contractAdapter";
import { ContractsList } from "./models/contractModel";
import { getContractsEndpoint } from './services/contractService';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import { getLandlordByNameEndpoint, getTenantByNameEndpoint } from '../accounting/services/accountingService';

const Contracts = () => {

  const dispatch = useDispatch()
  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar)
  const { getAuthToken } = useAuth()
  const token = getAuthToken()
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [contracts, setContracts] = useState<ContractsList[]>([])
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false
  })

  const loadContracts = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(getContractsEndpoint(token, gridSearchBar.query))
    if (status === 200) {
      const response = fetchResponseAdapter(data)
      setContracts(response.data.items || [])
      setPaginator(response.data.meta)
    }
  }, [callEndpoint, token, gridSearchBar.query])

  useEffect(() => {
    loadContracts()
      .catch(e => console.error(e))
  }, [loadContracts])


  const propertyFilters = [
    {
      label: 'Nombre inquilino',
      value: 'tenant_name',
      apiParamName: 'tenant_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingresa nombre del inquilino',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getTenantByNameEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          return response.data.map((tenant: any) => ({
            value: tenant.tenant_id,
            label: (
              <>
                <span>{tenant.name}</span>
                <small className="text-gray mx-2">{tenant.phone}</small>
              </>
            ),
          }))
        } else {
          return []
        }
      },
    },
    {
      label: 'Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getLandlordByNameEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }))
        } else {
          return []
        }
      },
    }
  ]

  if (isLoading && !contracts.length) {
    return <LoadingCard name={"Contratos"} parent_name={""} />
  }

  return (
    <AdminPage
      name={"Contratos"}
      parentName={""}
      header={
      <>
        <GridSearchBar
          filtersList={propertyFilters}
        />

      </>
      }
      footer={
        <nav aria-label="...">
          <Pagination
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
          >
            <PaginationItem className={!paginator.hasPreviousPage ? 'disabled' : ''}>
              <PaginationLink
                onClick={() => {
                  dispatch(setGridFiltersAction({
                    page: paginator.page - 1,
                    take: paginator.take
                  }))
                }}
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">Anterior</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className="active">
              <PaginationLink>
                {paginator.page}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className={!paginator.hasNextPage ? 'disabled' : ''}>
              <PaginationLink
                onClick={() => {
                  dispatch(setGridFiltersAction({
                    page: paginator.page + 1,
                    take: paginator.take
                  }))
                }}
              >
                <i className="fas fa-angle-right" />
                <span className="sr-only">Siguiente</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      }
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/*<th scope="col">Propiedad</th>*/}
            <th scope="col">ID</th>
            <th scope="col">Dirección</th>
            <th scope="col">Inquilino</th>
            <th scope="col">Correo inquilino</th>
            <th scope="col">Propietario</th>
            <th scope="col">Correo propietario</th>
            {/*<th scope="col">Fecha de inicio</th>*/}
            {/*<th scope="col">Fecha de fin</th>*/}
            <th scope="col">Estado</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {
            contracts.length > 0 && contracts.map((contract) => (
              <tr key={`k-${contract.property_info.code}`}>
                {/*<th scope="row">*/}
                {/*  <img*/}
                {/*    alt="..."*/}
                {/*    width={175}*/}
                {/*    height={100}*/}
                {/*    src={*/}
                {/*      contract.property_info && contract.property_info.cover*/}
                {/*        ? contract.property_info.cover*/}
                {/*        : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'*/}
                {/*    }*/}

                {/*  />*/}
                {/*</th>*/}
                <td>{contract.property_info.code}</td>
                <td>{contract.property_info.address}</td>
                <td>{contract.tenant_info.name}</td>
                <td>{contract.tenant_info.email}</td>
                <td>{contract.landlord_info.name}</td>
                <td>{contract.landlord_info.email}</td>
                {/*<td>{contract.start_contract}</td>*/}
                {/*<td>{contract.end_contract}</td>*/}
                <td>{getContractStatusAdapter(contract.status)}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/contract/${contract.id}/detail`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </AdminPage>
  )
}

export default Contracts
