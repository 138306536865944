export const addMonthsToDate = (date: string | Date, monthsToAdd: number): Date => {  
  if(typeof date === 'string') date = new Date(date)
  return new Date(date.setMonth(date.getMonth() + monthsToAdd))
}

export const getDateVars = (date: string | Date) => {
  let dateAux;
  if(typeof date === 'string') date = new Date(date)
  dateAux = date;
  let d = dateAux.getDate();
  let day = d < 10 ? `0${d}` : `${d}`;
  let m = dateAux.getMonth() + 1; //Month from 0 to 11
  let month = m < 10 ? `0${m}` : `${m}`;
  let year = dateAux.getFullYear();
  return {
    day, month, year
  }
}

export const dateFormatted = (date: string): string => {
  const {day, month, year} = getDateVars(date)
  return `${day}/${month}/${year}`;
};

export const getAge = (date: string | null): string => {
  try {
    if (!date) {
      return ''
    }
    let birthDate = new Date(date)
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  } catch (e) {
    return ''
  }
}