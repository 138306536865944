import { useCallback, useEffect, useState } from 'react';
import { AxiosCall } from '../models/axiosModel';
import { fetchRequestAdapter } from '../adapters/fetchAdapter';
import { AxiosResponse } from 'axios';
import useUserCountry from './useUserCountry';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { REALMS } from '../constants/profileConstants';
const statusUnauthorized = 401;

interface Props {
  useGenericDomain?: boolean;
}

const useCallApiAndLoad = (useGenericDomain: boolean = false) => {
  const navigate = useNavigate();
  const { unsetAuth, getUserRealm } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const { getServiceUrlCountry } = useUserCountry();

  // used for testing
  // const customDelay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
  const callEndpoint = useCallback(async (axiosCall: AxiosCall<any>) => {
    const userRealm = getUserRealm()
    setIsLoading(true);
    let result: AxiosResponse;
    let response;
    try {
      // await customDelay(800) // used for testing
      const urlService = getServiceUrlCountry(userRealm);
      if (!urlService && useGenericDomain) {
        response = fetchRequestAdapter(
          getDefautlErrorWithMessage(
            'User does not have permission for country service',
            1,
          ),
        );
      } else {
        result = await axiosCall.call(urlService); // config data headers request status statusMessage
        response = fetchRequestAdapter(result); // status, statusText, data
      }
    } catch (e: any) {
      setIsLoading(false);
      if (e.code === 'ERR_NETWORK') {
        response = fetchRequestAdapter(
          getDefautlErrorWithMessage('Connection Error!'),
        );
      } else {
        response = fetchRequestAdapter(e.response); // status, statusText, data
      }

      const { status } = response;
      if (status === statusUnauthorized) {
        setIsAuthenticated(false);
      }
    }
    setIsLoading(false);
    return response;
  }, []);

  const getDefautlErrorWithMessage = (
    message: string = 'General Error.',
    code: number = 0,
  ) => {
    return {
      data: {
        code,
        message,
        data: {},
      },
      status: 0,
      statusText: message,
    };
  };

  useEffect(() => {
    if (!isAuthenticated) {
      // TODO show an alert or a toast for closed session
      unsetAuth();
      navigate('/auth/login', { replace: true });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let controller = new AbortController();
    return () => {
      setIsLoading(false);
      controller && controller.abort();
    };
  }, []);

  return {
    isLoading,
    callEndpoint,
  };
};

export default useCallApiAndLoad;
