import React, { useCallback, useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import AdminUploadResource from '../../../components/admin/AdminUploadResource';

export interface Props {
  handleForm: (form: FormData, errorRepository: Function) => void;
}
const TransactionSaleQuotaPaymentForm: React.FC<Props> = ({ handleForm }) => {
  const { isLoading } = useCallApiAndLoad();
  const [error, setError] = useState<string | null>(null);
  const [resourceFile, setResourceFile] = useState();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<any>({
    resolver: yupResolver(
      yup
        .object({
          identifier: yup.string().required(),
          name: yup.string().required(),
          document: yup.string().required(),
        })
        .required(),
    ),
  });

  const internalHandleForm = useCallback(
    (form: any) => {
      if (!resourceFile) {
        setError('Adjunte un documento con el comprobante.');
        return;
      }

      setError('');

      const finalForm = new FormData();
      finalForm.append('identifier', form.identifier);
      finalForm.append('name', form.name);
      finalForm.append('document', form.document);
      finalForm.append('voucher', resourceFile[0]);

      handleForm(finalForm, setError);
    },
    [handleForm, resourceFile],
  );

  const handleResourceFile = useCallback((resource: any) => {
    if (resource) setResourceFile(resource);
  }, []);

  return (
    <Form role="form" onSubmit={handleSubmit(internalHandleForm)}>
      <Row>
        <Col>
          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="identifier">
                  Referencia de transacción
                </label>
                <Controller
                  name="identifier"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.identifier?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.identifier?.message && ''}
                </h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.name?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.name?.message && ''}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="document">
                  Documento
                </label>
                <Controller
                  name="document"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.document?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.document?.message && ''}
                </h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          {resourceFile && (
            <UncontrolledAlert color="success" fade={true}>
              <span className="alert-inner--text">
                Comprobante listo para ser reportado.
              </span>
            </UncontrolledAlert>
          )}

          <AdminUploadResource
            handleUpload={handleResourceFile}
            description={'Subir comprobante'}
            maxFiles={1}
          />
        </Col>
      </Row>

      <Button color="primary" disabled={isLoading}>
        Marcar como pagada
      </Button>
    </Form>
  );
};

export default TransactionSaleQuotaPaymentForm;
