import { GuestInfoDetails } from '../models/guestModel';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from 'reactstrap';
import React from 'react';
import RegisteredTypeBadge from './RegisteredTypeBadge';
import CreditScoreBadge from './CreditScoreBadge';
import { useNavigate } from 'react-router-dom';
import ProfileCard from '../../../components/ProfileCard';

export enum Entities {
  Visit = 'visit',
  Offer = 'offer',
  Property = 'property'
}

interface Props {
  guestInfo: GuestInfoDetails;
  parentEntity: Entities;
  parentId: string;
}

export default function GuestProfileCard({
  guestInfo,
  parentEntity,
  parentId,
}: Props) {
  const navigate = useNavigate();

  return (
    <>
      <Card className="card-profile">
        <CardHeader>
          <CreditScoreBadge guestInfo={guestInfo} />
        </CardHeader>
        <CardBody>
          <div className="text-center">
            <h5 className="h3">
              {`${guestInfo.first_name} ${guestInfo.last_name}`}
            </h5>
            <div className="h5 font-weight-300">
              Teléfono: {`${guestInfo.area_code}${guestInfo.phone}`}
            </div>
            <div className="h5 font-weight-300">
              Email: {`${guestInfo.email}`}
            </div>
            <div className="h5 font-weight-300">
              Documento: {guestInfo.document_number ?? 'No disponible'}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          {!guestInfo.is_register_as_tenant && (
            <Button
              color="primary"
              onClick={() =>
                navigate(`/guest/${parentEntity}/${parentId}/edit`)
              }
            >
              Editar perfil
            </Button>
          )}
        </CardFooter>
      </Card>
      {guestInfo.is_register_as_tenant && guestInfo.registered_tenant && (
        <Card className="card-profile">
          <CardBody>
            <Alert color="warning">
              <strong>Atención</strong> Existe un perfil de inquilino con el
              mismo correo de este lead, Si decides aceptarlo sera asignado a
              dicho inquilino y este prodrá ver su visita u oferta en la sección
              de ofertas y visitas
            </Alert>
            Inquilino encontrado
            <ProfileCard
              avatar={guestInfo.registered_tenant.avatar}
              name={guestInfo.registered_tenant.name}
              phone={guestInfo.registered_tenant.phone}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
}
