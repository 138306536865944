import { GuestInfoDetails } from '../models/guestModel';
import { Badge } from 'reactstrap';
import React from 'react';

interface Props {
  guestInfo: GuestInfoDetails;
}

export default function RegisteredTypeBadge({guestInfo}: Props) {
  return (
    <Badge
      className="badge-lg"
      color="primary"
    >
      {guestInfo.is_register_as_tenant ?
        "Registrado como Tenant" :
        "Registrado como Guest"
      }
    </Badge>
  );
}