import React, { useCallback, useState } from 'react';
import { ModelUnitForm } from '../models/investors';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';

export type Props = {
  modelUnit?: ModelUnitForm;
  handleForm: (form: ModelUnitForm, errorRepository: Function) => void;
};
const ManageModelUnitForm: React.FC<Props> = ({ handleForm, modelUnit }) => {
  const [error, setError] = useState(null);
  const { isLoading } = useCallApiAndLoad();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ModelUnitForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required('nombre de la unidad es requerido.'),
          base_price: yup
            .number()
            .positive('El precio debe ser mayor que cero.')
            .required('El precio de la unidad es requerido.'),
          area: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          square_rods: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          bathrooms: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          bedrooms: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          parking: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          level: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          distribution: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value)),
        })
        .required(),
    ),
  });

  const internalHandleForm = useCallback(
    (form: ModelUnitForm) => handleForm(form, setError),
    [],
  );

  return (
    <Form role="form" onSubmit={handleSubmit(internalHandleForm)}>
      <Row>
        <Col md="6">
          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}

              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={modelUnit?.name}
                  key={`${modelUnit?.name}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.name?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.name?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="base_price">
                  Precio base
                </label>
                <Controller
                  name="base_price"
                  control={control}
                  defaultValue={modelUnit?.base_price}
                  key={`${modelUnit?.base_price}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.base_price?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.base_price?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="area">
                  Area de construcción
                </label>
                <Controller
                  name="area"
                  control={control}
                  defaultValue={modelUnit?.area}
                  key={`${modelUnit?.area}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.area?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.area?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="area">
                  Varas cuadradas
                </label>
                <Controller
                  name="square_rods"
                  control={control}
                  defaultValue={modelUnit?.square_rods}
                  key={`${modelUnit?.square_rods}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.square_rods?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.square_rods?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="bathrooms">
                  Baños
                </label>
                <Controller
                  name="bathrooms"
                  control={control}
                  defaultValue={modelUnit?.bathrooms}
                  key={`${modelUnit?.bathrooms}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.bathrooms?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.bathrooms?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="bedrooms">
                  Cuartos
                </label>
                <Controller
                  name="bedrooms"
                  control={control}
                  defaultValue={modelUnit?.bedrooms}
                  key={`${modelUnit?.bedrooms}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.bedrooms?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.bedrooms?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="parking">
                  Parqueos
                </label>
                <Controller
                  name="parking"
                  control={control}
                  defaultValue={modelUnit?.parking}
                  key={`${modelUnit?.parking}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.parking?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.parking?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="level">
                  Piso
                </label>
                <Controller
                  name="level"
                  control={control}
                  defaultValue={modelUnit?.level}
                  key={`${modelUnit?.level}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.level?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.level?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="distribution">
                  Distribución
                </label>
                <Controller
                  name="distribution"
                  control={control}
                  defaultValue={modelUnit?.distribution}
                  key={`${modelUnit?.distribution}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.level?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.level?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Button color="primary" disabled={isLoading}>
        {modelUnit ? 'Actualizar' : 'Crear'}
      </Button>
    </Form>
  );
};

export default ManageModelUnitForm;
