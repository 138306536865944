import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Row,
  CardHeader,
  Input,
  Form,
  CardFooter,
  Button,
} from 'reactstrap';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import LoadingCard from '../../components/LoadingCard';
import useAdminBroker from './hooks/useAdminBroker';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const AdminPropertyBroker = () => {
  const navigate = useNavigate();
  const pageName = 'Administrador de agente inmobiliario';
  const pageParentName = 'Detalle propiedad';
  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };
  const { id: propertyId = '' } = useParams();
  const {
    // form
    control,
    errors,
    handleSubmit,
    handleSaveBroker,
    unassignBroker,
    // states and date
    adminPropertyData,
    brokerDetailInfo,
    // loading
    isLoading,
    brokerInfoList,
    assignBrokerToProperty,
  } = useAdminBroker({ propertyId, setErrorAlert });

  const [selectedBroker, setSelectedBroker] = useState<string>();

  if (!adminPropertyData || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const brokersOptionList = brokerInfoList ?
    brokerInfoList.map(broker => ({label: `${broker.first_name} ${broker.last_name}`, value: broker.broker_id})) : [];


  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        {brokerDetailInfo?.broker_id && (
          <Col md="6">
            <Card>
              <CardHeader>
                <h6 className="heading-small text-muted mt-4 mb-4">
                  Detalle de agente inmobiliario para propiedad{' '}
                  {adminPropertyData.code}
                </h6>
              </CardHeader>
              <CardBody>
                <Card className="card-profile">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={DEFAULT_AVATAR}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between"></div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div></div>
                        </div>
                      </div>
                    </Row>
                    <div className="text-center">
                      <h5 className="h3">{`${brokerDetailInfo.first_name} ${brokerDetailInfo.last_name} `}</h5>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        Teléfono: {brokerDetailInfo.phone}
                      </div>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        Correo: {brokerDetailInfo.email}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={() => unassignBroker(propertyId)}
                  className=""
                  color="danger"
                  size="md"
                  type="button"
                  disabled={isLoading}
                >
                  Remover agente
                </Button>
              </CardFooter>
            </Card>
          </Col>
        )}
        {!brokerDetailInfo?.broker_id && (
          <Col md="6">
            <Card>
              <CardBody>
                <CardHeader>
                  <h6 className="heading-small text-muted">
                    Seleccionar agente inmmobiliario
                  </h6>
                </CardHeader>
                <Select
                  placeholder={'Selecciona un proper'}
                  options={brokersOptionList}
                  onChange={(event) => setSelectedBroker(event?.value)}
                />
                <br/>
                <Button color="primary" onClick={() => {
                  if (selectedBroker) {
                    assignBrokerToProperty(selectedBroker, propertyId);
                    // navigate(0);
                  }
                }}>
                  Asignar broker
                </Button>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col md="6">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">
                {brokerDetailInfo?.broker_id ? 'Actualizar' : 'Crear'} agente
                inmobiliario
              </h6>
            </CardHeader>
            <Form role="form" onSubmit={handleSubmit(handleSaveBroker)}>
              <CardBody>
                <Col md="12">
                  {/* first_name */}
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="first_name"
                        >
                          Nombres
                        </label>
                        <Controller
                          name="first_name"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.first_name?.message && 'is-invalid'
                              }
                              {...field}
                              id="first_name"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.first_name?.message}
                        </h5>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* last_name */}
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="last_name"
                        >
                          Apellidos
                        </label>
                        <Controller
                          name="last_name"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.last_name?.message && 'is-invalid'
                              }
                              {...field}
                              id="last_name"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.last_name?.message}
                        </h5>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* email */}
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="email">
                          Correo electrónico
                        </label>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              readOnly={!!brokerDetailInfo?.broker_id}
                              className={errors.email?.message && 'is-invalid'}
                              {...field}
                              id="email"
                            />
                          )}
                        />
                        <h5 className="text-danger">{errors.email?.message}</h5>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* phone */}
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="phone">
                          Teléfono
                        </label>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={errors.phone?.message && 'is-invalid'}
                              {...field}
                              id="phone"
                            />
                          )}
                        />
                        <h5 className="text-danger">{errors.phone?.message}</h5>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </CardBody>
              <CardFooter>
                <Button color={'primary'} disabled={isLoading}>
                  {brokerDetailInfo?.broker_id ? 'Actualizar' : 'Crear'}
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default AdminPropertyBroker;
