import AdminPage from '../../components/admin/AdminPage';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  BrokerListForContract,
  ContractOfferParams,
  CreateContractForm,
} from './models/contractModel';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import { useState, useEffect } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createContractEndpoint,
  getBrokersContractListEndpoint,
} from './services/contractService';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { getOfferDetailEndpoint } from '../../services/publicService';
import LoadingCard from '../../components/LoadingCard';
import { addMonthsToDate, getDateVars } from '../../helpers/dateHelpers';
import Select from 'react-select';
import { Option } from '../../models/formModel';
import { checkOptions } from '../../constants/formConstants';

const CreateContract = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState('');
  const { getAuthToken } = useAuth();
  const navigate = useNavigate();
  const token = getAuthToken();
  const [contractOfferParams, setContractOfferParams] =
    useState<ContractOfferParams>();
  const [isDemandByBoker, setIsDemandByBoker] = useState<boolean>(false);
  const [brokers, setBrokers] = useState<Option[]>([]);
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    resetField,
  } = useForm<CreateContractForm>({
    resolver: yupResolver(
      yup
        .object({
          offer_id: yup
            .string()
            .required('Identificador de oferta es requerido'),
          contract_months: yup
            .number()
            .required('Número de meses son requerido'),
          moving_date: yup.string().required('Fecha de mudanza es requerida'),
          start_contract: yup.string().required('Fecha de inicio es requerida'),
          end_contract: yup.string().required('Fecha de fin es requerida'),
          country: yup.string().required('País es requerido'),
        })
        .required(),
    ),
  });

  useEffect(() => {
    setValue('country', 'SV');
    setValue('offer_id', id);
    const loadOffer = async () => {
      const { status, data } = await callEndpoint(
        getOfferDetailEndpoint(id, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const endDate = addMonthsToDate(
          response.data.estimate_moving_at,
          response.data.period,
        );
        const { day, month, year } = getDateVars(endDate);
        const endDateFormatted = `${year}-${month}-${day}`;
        setValue('contract_months', response.data.period);
        setValue('moving_date', response.data.estimate_moving_at);
        setValue('start_contract', response.data.estimate_moving_at);
        setValue('end_contract', endDateFormatted);
        setValue('has_rental_management', 'false');
        setValue('demand_by_broker', 'false');
        setContractOfferParams({
          period: response.data.period,
          estimate_moving_at: response.data.estimate_moving_at,
          end_contract: endDateFormatted,
        });
      }
    };

    const loadBrokers = async () => {
      const { status, data } = await callEndpoint(
        getBrokersContractListEndpoint(token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const dataEndpoint = response.data || [];
        const brokersMapped: Option[] = dataEndpoint.map(
          (broker: BrokerListForContract) => {
            return {
              label: `${broker.first_name} ${broker.last_name} - ${broker.email}`,
              value: broker.broker_id,
            };
          },
        );
        setBrokers(brokersMapped);
      }
    };

    loadOffer().catch((e) => console.error(e));
    loadBrokers().catch((e) => console.error(e));
  }, []);

  const doCreate = async (form: CreateContractForm) => {
    setError('');
    form.has_rental_management =
      form.has_rental_management === 'true' ? true : false;
    form.demand_by_broker =
      form.demand_by_broker === 'true' ? true : false;
    const { status, data } = await callEndpoint(
      createContractEndpoint(form, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      navigate(`/contract/${response.data.id}/detail`);
      return;
    }
    setError(`(${response.code}) ${response.message}`);
  };

  if (!contractOfferParams || isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <AdminPage name={'Crear contrato'} parentName={'Ofertas'}>
      <Row>
        <div className="col">
          <Card>
            <Form role="form" onSubmit={handleSubmit(doCreate)}>
              <CardHeader className="border-0">
                <h3 className="mb-0">Configurciones del contrato</h3>
                <p>
                  Los datos precargados son obtenidos por medio de la oferta
                  asociada, estos pueden ser cambiados sin problema y solo
                  afectará al contraro.
                </p>
              </CardHeader>
              <CardBody>
                <Col md="6">
                  {/* first_name */}
                  <Row>
                    <Col md="12">
                      {error && (
                        <UncontrolledAlert color="danger" fade={false}>
                          <span className="alert-inner--text">
                            <strong> Ops! </strong>
                            {error}
                          </span>
                        </UncontrolledAlert>
                      )}
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="moving_date"
                        >
                          Fecha de mudanza
                        </label>
                        <Controller
                          name="moving_date"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.moving_date?.message && 'is-invalid'
                              }
                              {...field}
                              type="date"
                              defaultValue={
                                contractOfferParams.estimate_moving_at
                              }
                              id="moving_date"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.moving_date?.message}
                        </h5>
                      </FormGroup>
                      <Alert color="warning">
                        Cantidad de meses de contrato es usado para generar la
                        cantidad de cuotas con el precio de la oferta, El
                        deposito se agrega automáticamente, no es necesario
                        agregarlo en los meses
                      </Alert>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="contract_months"
                        >
                          Meses de contrato
                        </label>
                        <Controller
                          name="contract_months"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.contract_months?.message && 'is-invalid'
                              }
                              {...field}
                              type="number"
                              min={1}
                              // defaultValue={contractOfferParams.period}
                              id="contract_months"
                            ></Input>
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.contract_months?.message}
                        </h5>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="start_contract"
                        >
                          Fecha de inicio de contrato
                        </label>
                        <Controller
                          name="start_contract"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.start_contract?.message && 'is-invalid'
                              }
                              {...field}
                              type="date"
                              defaultValue={
                                contractOfferParams.estimate_moving_at
                              }
                              id="start_contract"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.start_contract?.message}
                        </h5>
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="end_contract"
                        >
                          Fecha de fin de contrato
                        </label>
                        <Controller
                          name="end_contract"
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.end_contract?.message && 'is-invalid'
                              }
                              {...field}
                              type="date"
                              defaultValue={contractOfferParams.end_contract}
                              id="end_contract"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.end_contract?.message}
                        </h5>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md={4}>
                            <label
                              className="form-control-label"
                              htmlFor="is_furnished"
                            >
                              Rental managment
                            </label>
                          </Col>
                          <Col md={8}>
                            {checkOptions &&
                              checkOptions.map((item: any) => (
                                <div
                                  key={`has_rental_management-${item.name}`}
                                  className="custom-control custom-radio custom-control-inline"
                                >
                                  <input
                                    {...register('has_rental_management')}
                                    className="custom-control-input"
                                    type="radio"
                                    value={item.value}
                                    defaultChecked={false}
                                    id={`has_rental_management-${item.name}`}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`has_rental_management-${item.name}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col md={4}>
                            <label
                              className="form-control-label"
                              htmlFor="is_furnished"
                            >
                              Oferta viene de un broker (corredor)?
                            </label>
                          </Col>
                          <Col md={8}>
                            {checkOptions &&
                              checkOptions.map((item: any) => (
                                <div
                                  key={`demand_by_broker-${item.name}`}
                                  className="custom-control custom-radio custom-control-inline"
                                >
                                  <input
                                    {...register('demand_by_broker')}
                                    className="custom-control-input"
                                    type="radio"
                                    value={item.value}
                                    defaultChecked={false}
                                    id={`demand_by_broker-${item.name}`}
                                    onClick={() => {
                                      setIsDemandByBoker(item.value);
                                      if (!item.value) {
                                        resetField('broker_id');
                                      }
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`demand_by_broker-${item.name}`}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              ))}
                          </Col>
                        </Row>
                      </FormGroup>
                      {isDemandByBoker && (
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="city_id"
                          >
                            Broker
                          </label>
                          {!false && (
                            <Controller
                              name="broker_id"
                              control={control}
                              render={({ field: { ref } }) => (
                                <Select
                                  placeholder="Selecciona un broker"
                                  options={brokers}
                                  onChange={(selected: any) => {
                                    const { value = '' } = selected;
                                    setValue('broker_id', value);
                                  }}
                                  ref={ref}
                                />
                              )}
                            />
                          )}
                        </FormGroup>
                      )}
                    </Col>
                  </Row>
                </Col>
              </CardBody>
              <CardFooter>
                <Button color={'primary'} disabled={isLoading}>
                  Crear
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </Row>
    </AdminPage>
  );
};

export default CreateContract;
