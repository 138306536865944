import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  UncontrolledCarousel
} from 'reactstrap'

import { fetchResponseAdapter } from '../../adapters/fetchAdapter'
import TimelineHeader from '../../components/headers/TimelineHeader'
import ProfileAvatarGroup from '../../components/ProfileAvatarGroup'
import PropertyDetailCard from '../../components/PropertyDetailCard'
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad'
import { getPhotoSessionStatus } from './adapters/photosAdapter';
import { AdminPropertyData, PublishPropertyForm } from './models/propertyModel'
import { getAdminPropertyEndpoint, getPhotosEndpoint, updatePropertyStatusEndpoint } from './services/propertyService';
import useAuth from '../../hooks/useAuth';

const PublishProperty = () => {
  const { id = '' } = useParams()
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [adminPropertyData, setAdminPropertyData] = useState<AdminPropertyData>()
  const [customGallery, setCustomGallery] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    const loadProperty = async () => {
      const {
        status, data
      } = await callEndpoint(getAdminPropertyEndpoint(id, token))
      if (status === 200) {
        const response = fetchResponseAdapter(data)
        setAdminPropertyData(response.data)
      }
    }
    const loadPropertyPhotos = async () => {
      const {
        status, data
      } = await callEndpoint(getPhotosEndpoint(id, token))
      if (status === 200) {
        const response = fetchResponseAdapter(data)
        setCustomGallery(response.data.custom_gallery)
      }
    }
    loadProperty()
      .catch(e => console.error(e))
    loadPropertyPhotos()
      .catch(e => console.error(e))
  }, [])

  const toggleModal = (image: string) => {
    setCurrentImage(image)
    setShowModal((state) => !state)
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal((state) => !state)
  };

  const doPublishProperty = async () => {
    const form: PublishPropertyForm = {
      status: 'published'
    }
    const {
      status
    } = await callEndpoint(updatePropertyStatusEndpoint(id, form, token))
    if (status === 200) {
      setConfirmationModal(false)
      return
    }
  }

  return (
    <>
      <TimelineHeader parentName="Sesiones agendadas" name={`Detalle de publicación`} />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <PropertyDetailCard
              cover={adminPropertyData?.cover}
              address={adminPropertyData?.address}
              code={adminPropertyData?.code}
              price={adminPropertyData?.monthly_price} />
          </CardHeader>
          <CardBody>
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      Propietario
                    </h4>
                  </div>
                  <Col className="col-auto">
                    <a
                      style={{ cursor: 'pointer' }}
                      className=""
                      onClick={() => navigate(`/landlord/${ adminPropertyData?.landlord_id }/profile`)}
                    >
                      <span>{ adminPropertyData?.landlordInfo.name || 'NA' }</span>
                    </a>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      Detalle de propiedad
                    </h4>
                  </div>
                  <Col className="col-auto">
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/detail`)}
                      color="" size="sm" type="button">
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      Detalle de contrato
                    </h4>
                  </div>
                  <Col className="col-auto">
                    <span className='mr-2' >{adminPropertyData?.plan}</span>
                    <Button color="" size="sm" type="button">
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

            <hr />
            <ListGroup className="list my--3" flush>
              <ListGroupItem>
                <Row className="align-items-center">
                  <div className="col ml--2">
                    <h4 className="mb-0">
                      Fotografias
                    </h4>
                  </div>
                  <Col className="col-auto">
                    <span className='mr-2' >{
                      `${adminPropertyData?.requestPhotographyInfo.dateTime} Estado: ${getPhotoSessionStatus(adminPropertyData?.requestPhotographyInfo.status || '')}`
                    }</span>
                    <Button
                      onClick={() => navigate(`/properties/admin/${id}/photos`)}
                      color="" size="sm" type="button">
                      <i className="ni ni-bold-right pt-1" />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className='col ml--2 pt-2' md="6">
                    <Card>
                      <CardHeader>
                        <h4>fotografías subidas por propietario</h4>
                      </CardHeader>
                      <CardBody>
                        {
                          customGallery &&
                          customGallery.length > 0 && customGallery.map(image => (
                            <ListGroup className="list my--3" flush key={image}>
                              <ListGroupItem className="px-0">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      onClick={() => toggleModal(image || '')}
                                      className="rounded"
                                      alt="..."
                                      width={175}
                                      height={100}
                                      src={image || ''}
                                    />
                                  </Col>
                                  <div className="col ml--2"></div>
                                  <Col className="col-auto">
                                    <Button
                                      className="btn-icon"
                                      onClick={() => toggleModal(image || '')}
                                      color="secondary" size="sm" type="button">
                                      <span className="btn-inner--icon">
                                        <i className="ni ni-image" />
                                      </span>
                                      <span className="btn-inner--text">Ver</span>
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            </ListGroup>
                          ))
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>

          </CardBody>
          <CardFooter >
            <Button color="primary" onClick={() => toggleConfirmationModal()}>
              Publicar propiedad
            </Button>
          </CardFooter>
        </Card>
        {/* Modal to visualize photos */}
        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => toggleModal('')}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal('')}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <UncontrolledCarousel
              controls={false}
              indicators={false}
              items={[
                {
                  src: currentImage,
                  altText: '',
                  caption: '',
                  header: ''
                }
              ]} />
          </div>
          <div className="modal-footer">
          </div>
        </Modal>
        {/* Modal to confirm publish property */}
        <Modal
          className="modal-dialog-centered modal-warning"
          contentClassName="bg-gradient-warning"
          isOpen={confirmationModal}
          toggle={() => toggleConfirmationModal()}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Confirmación requerida
            </h6>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">Estas a punto de publicar una propiedad</h4>
              <p>
                Esta acción hará que tu publicación sea accedida desde la marketplace propiweb,
                asi como recibir visitas y/o ofertas
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white"
              onClick={doPublishProperty}
              color="default" type="button">
              Publicar
            </Button>
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              toggle={() => toggleConfirmationModal()}
            >
              Seguir revisando...
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default PublishProperty