import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageResidentialForm from './components/ManageResidentialForm';
import { Col, Row } from 'reactstrap';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { createResidentialsEndpoint } from './services/residentialService';
import { useNavigate } from 'react-router-dom';

const ResidentialCreate: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const { callEndpoint } = useCallApiAndLoad();

  const navigate = useNavigate();

  const pageName = "Crear residencial";
  const pageParentName = "Residenciales";

  const handleForm = useCallback(async (form: any) => {
    const { status } = await callEndpoint(createResidentialsEndpoint(form, token));
    if (status === 201) {
      navigate(`/residentials`);
    }
  }, [token, callEndpoint]);

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <Row>
        <Col md="6">
          <ManageResidentialForm handleForm={handleForm} />
        </Col>
      </Row>
    </AdminPage>
  )
};

export default ResidentialCreate;