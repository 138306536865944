import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageResidentialForm from './components/ManageResidentialForm';
import { Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import LoadingCard from '../../components/LoadingCard';
import {
  createResidentialsEndpoint,
  getResidentialByIdEndpoint,
  updateResidentialByIdEndpoint,
} from './services/residentialService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';

const ResidentialUpdate: React.FC = () => {

  const { id: residentialId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const getResidentialInfo = useCallback(
    () => getResidentialByIdEndpoint(residentialId, token),
    [residentialId]
  );
  const [residentialInfo] = useAdminEntity<any>(getResidentialInfo, console.error);

  const updateResidencial = useCallback(async (form: any) => {
    const { status } = await callEndpoint(updateResidentialByIdEndpoint(form, residentialId, token));
    if (status === 200) {
      navigate(`/residentials`);
    }
  }, [token, callEndpoint, residentialId]);

  const pageName = "Editar residencial";
  const pageParentName = "Residenciales";

  if (!residentialInfo)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    )

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <Row>
        <Col md="6">
          <ManageResidentialForm handleForm={updateResidencial} defaultForm={residentialInfo} />
        </Col>
      </Row>
    </AdminPage>
  )
};

export default ResidentialUpdate;