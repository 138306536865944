import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import { VisitInfo } from '../models/guestInvestorModel'

interface Props {
  guestVisit: VisitInfo
}

const InvestorVisitDetailCard = ({ guestVisit }: Props) => {
  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">
            Asesoría
          </h6>
          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Tipo
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >
                  {guestVisit.visit_type === "presential" ?
                    "Presencial" : "Virtual"}
                </span>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Fecha y hora
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{`${guestVisit.visit_date} ${guestVisit.visit_time}`}</span>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  )
}

export default InvestorVisitDetailCard