import { useNavigate, useParams } from 'react-router-dom';
import { activateGuestEndpoint, cancelOfferEndpoint, getGuestOfferDetailEndpoint } from './services/guestService';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useEffect, useState } from 'react';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { Button, Col, Row } from 'reactstrap';
import { GuestOfferDetails } from './models/guestModel';
import PropertyDetailCard from '../../components/PropertyDetailCard';
import CancelEntityModal from './components/CancelEntityModal';
import GuestProfileCard, { Entities } from './components/GuestProfileCard';
import GuestOfferDetailCard from './components/GuestOfferDetailCard';
import ActivateGuestModal from './components/ActivateGuestModal';
import useModalState from '../../hooks/useModalState';


export default function GuestOfferDetail() {
  const { id: guestOfferId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [errorAlert, setErrorAlert] = useState(null);

  const [guestOffer, setGuestOffer] = useState<GuestOfferDetails>();
  const loadGuestOffer = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(getGuestOfferDetailEndpoint(guestOfferId, token));

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setGuestOffer(response.data);
    }
  }, [callEndpoint, guestOfferId, token]);
  useEffect(() => {
    loadGuestOffer().catch(e => setErrorAlert(e.toString()));
  }, [loadGuestOffer]);

  const [showCancelOfferModal, toggleCancelOfferModal] = useModalState();
  const cancelOffer = useCallback(async (offerForCancel: GuestOfferDetails) => {
    const {status, data} = await callEndpoint(
      cancelOfferEndpoint(
        offerForCancel.guestInfo.id,
        offerForCancel.offerInfo.id,
        token
      )
    );
    if (status === 200) {
      toggleCancelOfferModal();
      navigate('/guest/offers');
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleCancelOfferModal();
    }
  }, [callEndpoint, navigate, toggleCancelOfferModal, token]);

  const [showActivateGuestModal, toggleActivateGuestModal] = useModalState();
  const activateGuest = useCallback(async (guestId: string) => {
    const {status, data} = await callEndpoint(
      activateGuestEndpoint(guestId, '', token)
    );
    if (status === 200) {
      toggleActivateGuestModal();
      navigate('/guest/offers');
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleActivateGuestModal();
    }
  }, [callEndpoint, navigate, toggleActivateGuestModal, token]);

  const pageName = "Detalle de Oferta";
  const pageParentName = "Guests";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!guestOffer || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  const cancelOfferModal = (
    <CancelEntityModal
      entity={guestOffer}
      isOpen={showCancelOfferModal}
      onToggle={toggleCancelOfferModal}
      onConfirmation={cancelOffer}
      warningText={"¿Estás seguro de anular la oferta?"}
      confirmationText={"Si, anular oferta"}
      abortText={"No, continuar revisando..."}
    />
  );

  const activateGuestModal = (
    <ActivateGuestModal
      guestId={guestOffer.guestInfo.id}
      isOpen={showActivateGuestModal}
      onToggle={toggleActivateGuestModal}
      onConfirmation={activateGuest}
      bodyText={
        guestOffer.guestInfo.is_register_as_tenant
        ? 'Al aceptar asignaras el lead al inquilino encontrado.'
        : 'Al activar el guest aceptas todas las ofertas, visitas del mismo y vuelves al guest un inquilino, Automáticamente enviaremos al inquilino un correo que genere su contraseña'
      }
      confirmationText={"Entiendo, continuar"}
      abortText={"No, seguir revisando..."}
    />
  );

  const componentProfileCard = (
    <GuestProfileCard
      guestInfo={guestOffer.guestInfo}
      parentEntity={Entities.Offer}
      parentId={guestOfferId}
    />
  );

  const componentPropertyCard = (
    <PropertyDetailCard
      cover={guestOffer.propertyInfo.cover}
      address={guestOffer.propertyInfo.address}
      code={guestOffer.propertyInfo.code}
      price={guestOffer.propertyInfo.monthly_price}
    />
  );

  const componentOfferDetail = (
    <GuestOfferDetailCard guestOffer={guestOffer.offerInfo} />
  );

  const componentBody = (
    <Row>
      {/* Profile section */}
      <Col className="order-xl-2 pt-10" xl="4">
        {componentProfileCard}
      </Col>

      <Col>
        {/* Property info */}
        {componentPropertyCard}

        {/* Offer detail info */}
        {componentOfferDetail}
      </Col>
    </Row>
  );

  const componentFooter = (
    <>
      <Button color="warning" onClick={() => {
        toggleCancelOfferModal()
      }}>
        Anular oferta
      </Button>
      <Button color="primary" onClick={() => {
        toggleActivateGuestModal()
      }}>
        Aceptar oferta
      </Button>
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentFooter}
      globalAlertOptions={globalAlertOptions}
    >
      {componentBody}
      {cancelOfferModal}
      {activateGuestModal}
    </AdminPage>
  );
};