import { Card, CardBody, Col, Row } from 'reactstrap';

interface Props {
  property_id: string;
  landlord_name: string;
  landlord_id: string;
  plan: string;
  is_validated_by_proper?: boolean;
  validated_by_proper_event: (
    propertyId: string,
    isVerified?: boolean,
  ) => Promise<void>;
}

const LandlordVerifiedCard = (props: Props) => {
  const {
    property_id,
    landlord_name,
    landlord_id,
    plan,
    is_validated_by_proper = false,
    validated_by_proper_event,
  } = props;
  return (
    <>
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <div className="col ml--2">
              <p className="text-sm text-muted mb-0">Propiedad verificada</p>
            </div>
            <Col className="col-auto">
              <label className="custom-toggle" id="">
                <input
                  type="checkbox"
                  defaultChecked={is_validated_by_proper}
                  onChange={(event) =>
                    validated_by_proper_event(property_id, event.target.checked)
                  }
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Si"
                />
              </label>
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <div className="col ml--2">
              <p className="text-sm text-muted mb-0">Propietario</p>
            </div>
            <Col className="col-auto">
              <a href={`/landlord/${landlord_id}/profile`}>{landlord_name}</a>
            </Col>
          </Row>
          <Row className="align-items-center mt-2">
            <div className="col ml--2">
              <p className="text-sm text-muted mb-0">Plan</p>
            </div>
            <Col className="col-auto">
              <span className="text-sm text-muted mb-0">{plan}</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default LandlordVerifiedCard;
