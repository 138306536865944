import { TimelineItem } from '../../../models/timelineModel';
import {
  WithdrawalStatus,
  WithdrawalType,
  withdrawalLogAction,
  withdrawalStatus,
  withdrawalType,
} from '../helpers/withdrawalHelper';
import { Action } from '../models/withdrawalModel';

export const withdrawalActionTimelineAdapter = (
  withdrawalLog: Action[],
): TimelineItem[] => {
  return withdrawalLog.map((log) => {
    const { action, logged_at, user } = log;

    const actionInfo = withdrawalLogAction.find((w) => w.product === action);

    return {
      header: 'header',
      main_message: 'Acciones',
      message: actionInfo?.product_text ?? '',
      sub_message: user,
      time: logged_at,
      type: user,
    };
  });
};

export const getWithdrawalByStatus = (status: string): WithdrawalStatus => {
  return (
    withdrawalStatus.find((po) => po.product.toString() === status) ?? {
      color: 'primary',
      product: 'created',
      product_text: 'Default',
    }
  );
};

export const getWithdrawalByType = (type: string): WithdrawalType => {
  return (
    withdrawalType.find((po) => po.product.toString() === type) ?? {
      color: 'info',
      product: 'regular',
      product_text: 'Default',
    }
  );
};
