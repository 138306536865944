import { GuestInfoDetails } from '../models/guestModel';
import { Badge } from 'reactstrap';
import React from 'react';

interface Props {
  guestInfo: GuestInfoDetails;
}

export default function CreditScoreBadge({guestInfo}: Props) {
  return (
    <Badge
      className="badge-lg"
      color="primary"
    >
      {`Score crediticio: ${guestInfo.credit_score ?? "No disponible"}`}
    </Badge>
  );
}