import Timeline from '../../../components/Timeline';
import { withdrawalActionTimelineAdapter } from '../adapters/withdrawalAdapter';
import { Action } from '../models/withdrawalModel';

interface Props {
  actions: Action[];
}

const WithdrawalLogActionCard = ({ actions }: Props) => {
  return (
    <Timeline
      data={withdrawalActionTimelineAdapter(actions)}
      header="Operaciones de desestimiento"
    />
  );
};

export default WithdrawalLogActionCard;
