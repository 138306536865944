import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  NavLink,
  Row
} from "reactstrap"
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad"
import { uploadContractEndpoint } from "../services/contractService"
import useAuth from "../../../hooks/useAuth"

interface Props {
  contract_id: string;
  type: string;
  url?: string
}

const UploadContract = (props: Props) => {
  const { contract_id = '', type = '', url = '' } = props
  const [files, setFiles] = useState<Array<any>>([])
  const [fileAccepted, setFileAccepted] = useState<any>()
  const [error, setError] = useState<string>('')
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const navigate = useNavigate()
  const text = type === 'standard'
    ? 'Subir contrato'
    : 'Subir contrato FIRMADO'

  const { getAuthToken } = useAuth()
  const token = getAuthToken()

  const onDrop = useCallback((acceptedFiles: any) => {
    setError('')
    setFileAccepted(acceptedFiles)
    setFiles([...acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))])
  }, [files, fileAccepted])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': []
    },
    onDrop,
  })

  const removeFile = (file: any) => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const handleUpload = async () => {
    setError('')
    if (!fileAccepted || !fileAccepted.length) {
      setError('Debés seleccionar un archivo pdf a subir')
      return
    }
    let formData = new FormData()
    formData.append('type', type)
    formData.append('document', fileAccepted[0])
    // const token = getAuthToken(user)
    const { status } = await callEndpoint(uploadContractEndpoint(formData, contract_id, token))
    if (status === 200) {
      navigate(0) //make a reload
    } else {
      setError('Lo sentimos, por el momento no es posible completar la acción')
    }
  }

  return (
    <Card>
      <CardHeader>
        {error && <Alert className="alert-danger" >{error}</Alert>}
        <h3>{ text }</h3>
        <p>{
          type === 'standard'
            ? 'Espacio para subir contrato estandar el cual podrá visualizar el propietario e inquilino al momento de aceptar una oferta desde propi web'
            : 'Espacio para subir contrato firmado por propietario e inquilino para respaldo digital, este documento solo será visible desde este detalle en admin propi'
        }</p>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row>
              <Col md="12">
                <div {...getRootProps({ className: "px-4 py-4 text-center dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Arrastra tu archivo pdf o Selecciona</p>
                  <span className='text-sm'>Formatos soportados: PDF únicamente</span>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        {
          files && files.length > 0 && files.map(file => (
            <ListGroup className="list my--3" flush key={file.path}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    Archivo cargado
                  </Col>
                  <div className="col ml--2"></div>
                  <Col className="col-auto">
                    <Button
                      onClick={() => removeFile(file)}
                      color="danger" size="sm" type="button">
                      Remover
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          ))
        }
      </CardBody>
      <CardFooter>
        <Row>
          <div className="col ml--2">
            {
              url && <NavLink href={url} target='_blank'>
                <i className={"fa fa-duotone fa-file-contract"} /> Ver documento existente...
              </NavLink>
            }
          </div>
          <Col className="col-auto">
            <Button
              disabled={isLoading}
              onClick={handleUpload}
              className=""
              color="success" size="md" type="button">
              <i className={"fa fa-duotone fa-upload"} /> { text }
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  )
}

export default UploadContract
