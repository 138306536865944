import {
  VISIT_STATUS,
  VISIT_STATUS_NAMES,
} from '../../../constants/visitsConstants';
import {
  PropertyVisitList,
  CalendarVisitEvents,
} from '../models/propertyModel';

export const pickCalendarEventColor = (status: string) => {
  switch (status) {
    case VISIT_STATUS.CANCELLED:
      return 'bg-red text-white';
    case VISIT_STATUS.DONE:
      return 'bg-success text-white';
    default:
      return 'bg-secondary';
  }
};

export const buildCalendarVisitEvent = (
  propertyVisit: PropertyVisitList,
  dateTime: any,
) => {
  const { year, month, day, hours, minutes } = dateTime;
  return {
    id: propertyVisit.id,
    title: `Visita ${propertyVisit.tenantInfo.name}`,
    start: new Date(year, month - 1, day, hours, minutes),
    allDay: false,
    className: pickCalendarEventColor(propertyVisit.status),
    tenantInfo: propertyVisit.tenantInfo,
    proper_id: propertyVisit.proper_id,
    status: propertyVisit.status,
    marketplace_type: propertyVisit.marketplace_type,
  };
};

export const buildCalendarVisitData = (visits: PropertyVisitList[]) => {
  const visitsAdapted: CalendarVisitEvents[] = visits.map((el) => {
    const { year, month, day, hours, minutes } = el.visitDateTime;
    return buildCalendarVisitEvent(el, { year, month, day, hours, minutes });
  });
  return visitsAdapted;
};

export const getVisitStatusAdapter = (status: string) => {
  return VISIT_STATUS_NAMES.hasOwnProperty(status)
    ? VISIT_STATUS_NAMES[status]
    : 'NA';
};
