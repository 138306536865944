import axios from 'axios';
import {
  ProofIncomeForm,
  ProofOutcomeForm,
  UpdateProofIncomeForm,
  UpdateProofOutcomeForm,
} from '../models/proofIncomeModel';

const loadAbort = () => {
  return new AbortController();
};

export const getProofResumeEndpoint = (
  transactionDetailId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-income/transaction-detail/${transactionDetailId}/resume`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofIncomeDetailEndpoint = (
  proofIncomeId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-income/${proofIncomeId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofIncomeListEndpoint = (query: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-income/${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createProofResumeEndpoint = (
  form: ProofIncomeForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/proof-income`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofsIncomeByOwnerEndpoint = (
  queryParams: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-income/by-owner?${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofsOutcomeSummaryEndpoint = (
  queryParams: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-outcome/resume?${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createProofOutcomeEndpoint = (
  form: ProofOutcomeForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/proof-outcome`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofOutcomesEndpoint = (
  queryParams: string = '',
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-outcome${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProofOutcomeDetailEndpoint = (
  proofIncomeId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proof-outcome/${proofIncomeId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getLandlordByNameEndpoint = (name: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/landlord/search-name`,
        params: { name },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantByNameEndpoint = (name: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/tenant/search-name`,
        params: { name },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getLandlordByEmailEndpoint = (email: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v2/admin/landlord/search-mail`,
        params: { email },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getTenantByEmailEndpoint = (email: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/tenant/search-mail`,
        params: { email },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateProofOutcomeEndpoint = (
  id: string,
  form: UpdateProofOutcomeForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/proof-outcome/${id}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateProofIncomeEndpoint = (
  id: string,
  form: UpdateProofIncomeForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/proof-income/${id}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sendProofIncomeViaEmail = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/proof-income/${id}/sent-to-client`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sendProofOutcomeViaEmail = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/proof-outcome/${id}/sent-to-owner`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
