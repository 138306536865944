import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GuestVisitDetails, UpdateGuestVisitRequest } from './models/guestModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  getAvailableTimeVisitsEndpoint,
  getGuestVisitDetailEndpoint, updateGuestVisitEndpoint,
} from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { Button, Col, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';

export default function GuestVisitEdit() {
  const {id: guestVisitId = ""} = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [availableTimesForVisit, setAvailableTimesForVisit] = useState([]);
  const {
    handleSubmit, formState: { errors }, control, setValue
  } = useForm<UpdateGuestVisitRequest>({
    resolver: yupResolver(
      yup.object({
        visit_type: yup.string()
          .required("El tipo de visita es requerido."),
        date: yup.date()
          .min(new Date(), "La fecha no puede ser menor a hoy.")
          .required("La fecha de la visita es requerida."),
        start_time: yup.number()
          .integer()
          .positive()
          .required("La hora de la visita es requerida."),
      }).required()
    )
  });
  const [guestVisit, setGuestVisit] = useState<GuestVisitDetails>();
  const loadGuestVisit = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(getGuestVisitDetailEndpoint(guestVisitId, token));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestVisit(response.data);
    } else {
      setError(response.message);
    }
  }, [callEndpoint, guestVisitId, token]);
  useEffect(() => {
    loadGuestVisit()
      .catch(e => setError(e.toString()));
  }, [loadGuestVisit]);
  const doUpdate = useCallback(async (form: UpdateGuestVisitRequest) => {
    if (!guestVisit?.visitInfo?.id)
      return;

    const {
      status, data
    } = await callEndpoint(updateGuestVisitEndpoint(
      guestVisit.visitInfo.id,
      form,
      token
    ));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      navigate(`/guest-visits/${guestVisit.visitInfo.id}`)
    } else {
      setError(response.message);
    }

  }, [guestVisit]);
  const visitTypes = useMemo(() => [
    {
      "visit_type": "presential",
      "display_name": "Presencial",
    },
    {
      "visit_type": "virtual",
      "display_name": "Virtual",
    }
  ], []);
  const visitTypesOptions = useMemo(() =>
    listToSelectOptionAdapter(
      visitTypes,
      "visit_type",
      "display_name"
    ), [visitTypes]);
  const loadAvailableTimesForVisit = useCallback(async (dateForSearch: string) => {
    if (!guestVisit?.propertyInfo)
        return;

    const {
      status, data
    } = await callEndpoint(
      getAvailableTimeVisitsEndpoint(
        guestVisit.propertyInfo.id,
        dateForSearch,
        token
      )
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setAvailableTimesForVisit(response.data);
    } else {
      setError(response.message);
    }
  }, [guestVisit, callEndpoint, token])

  const pageName = "Editar visita";
  const pageParentName = "Guests";

  if (!guestVisit || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <h3 className="mb-4">Editar visita del guest</h3>
      <Form role="form" onSubmit={handleSubmit(doUpdate)}>
        <Col md="6">
          <Row>
            <Col md="12">
              {
                error && (
                  <UncontrolledAlert color="danger" fade={false}>
                    <span className="alert-inner--text">
                      <strong> Ops! </strong>{error}
                    </span>
                  </UncontrolledAlert>
                )
              }
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="period"
                >

                </label>
                <Controller
                  name="visit_type"
                  control={control}
                  defaultValue={guestVisit.visitInfo.visit_type}
                  render={(field) => (
                    <Select
                      placeholder="Selecciona un tipo de visita"
                      options={visitTypesOptions as Array<any>}
                      defaultValue={
                        visitTypesOptions.find(
                          option => option.value === field.field.value
                        )
                      }
                      onChange={optionSelected => setValue("visit_type", optionSelected?.value)}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.visit_type?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="estimate_moving_at"
                >
                  Fecha de visita (Actual: {`${guestVisit.visitInfo.visit_date} ${guestVisit.visitInfo.visit_time}`})
                </label>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.date?.message && 'is-invalid'}
                      {...field}
                      id="date"
                      type="date"
                      onChange={(e) => {
                        const currentDate = e.target.value;
                        setValue("date", currentDate);
                        loadAvailableTimesForVisit(currentDate)
                          .catch(e => setError(e.toString()));
                      }}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.date?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="period"
                >
                Hora de la visita
                </label>
                <Controller
                  name="start_time"
                  control={control}
                  render={() => (
                    <Select
                      placeholder="Selecciona el tiempo de la visita"
                      options={
                        listToSelectOptionAdapter(
                          availableTimesForVisit,
                          "minutes",
                          "time_value"
                        ) as Array<any>
                      }
                      onChange={optionSelected => setValue("start_time", optionSelected?.value)}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.start_time?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Button color={'primary'} disabled={isLoading}>
          Actualizar
        </Button>
      </Form>
    </AdminPage>
  );
}