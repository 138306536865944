import React, { useCallback, useEffect, useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import { createSearchParams } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  CardHeader,
  Card,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import TimelineHeader from "../../components/headers/TimelineHeader";
import ProfileAvatarGroup from "../../components/ProfileAvatarGroup";
import useAuth from "../../hooks/useAuth";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { ErrorAlert, SuccessAlert } from "../../models/alertModel";
import { Paginator } from "../../models/formModel";
import { requestChangePasswordEndpoint } from "../../services/publicService";
import { Tenant } from "./models/tenantModel";
import { getTenantsEndpoint } from "./services/tenantService";
import {
  getLandlordByEmailEndpoint,
  getLandlordByNameEndpoint,
  getTenantByEmailEndpoint, getTenantByNameEndpoint,
} from '../accounting/services/accountingService';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';

const Tenants = () => {

  const [sweetAlert, setSweetAlert] = useState<boolean>(false)
  const [alertProps, setAlertProps] = useState<SuccessAlert | ErrorAlert>({
    success: true,
    title: "",
    text: "",
    onConfirm: () => (setSweetAlert(false)),
    onCancel: () => (setSweetAlert(false))
  })
  const { getAuthToken } = useAuth()
  const token = getAuthToken()
  const { callEndpoint } = useCallApiAndLoad()
  const [tenants, setTenants] = useState<Tenant[]>([])
  /*const [filters, setfilters] = useState<Filters>({
    page: 0, take: 0, status: ''
  })
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false)*/

  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar)
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false
  })

  const loadTenants = useCallback(async (page: number = 0, take: number = 0) => {
    let filters: any = {}
    if (page > 0 && take > 0) {
      filters.page = page.toString()
      filters.take = take.toString()
    }

    let query = `${gridSearchBar.query}`
    if (!query || query.length <= 0)
      query += `?`
    if (query && query.length > 0 && createSearchParams(filters).toString().length > 0)
      query += `&`
    query += `${createSearchParams(filters)}`

    const {
      status, data
    } = await callEndpoint(getTenantsEndpoint(query, token))
    if (status === 200) {
      const response = fetchResponseAdapter(data)
      setTenants(response.data.items || [])
      setPaginator(response.data.meta)
    }
  }, [callEndpoint, token, gridSearchBar])

  useEffect(() => {
    loadTenants()
      .catch(e => console.error(e))
  }, [loadTenants])

  const sendChangePassword = async (email: string) => {
    const {
      status
    } = await callEndpoint(requestChangePasswordEndpoint(email))
    if (status === 200) {
      setAlertProps((state) => (
        {
          ...state,
          success: true,
          danger: false,
          title: 'OK',
          text: 'Correo enviado satisfactoriamente'
        }
      ))
    } else {
      setAlertProps((state) => (
        {
          ...state,
          success: false,
          danger: true,
          title: 'Error',
          text: 'Lo sentimos ocurrió un error al querer mandar correo de cambio de contraseña'
        }
      ))
    }
    setSweetAlert(true)
  }

  const propertyFilters = [
    {
      label: 'Nombre Inquilino',
      value: 'tenant_name',
      apiParamName: 'tenant_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del inquilino',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getTenantByNameEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          return response.data.map((tenant: any) => ({
            value: tenant.tenant_id,
            label: (
              <>
                <span>{tenant.name}</span>
                <small className="text-gray mx-2">{tenant.phone} {tenant.email}</small>
              </>
            ),
          }))
        } else {
          return []
        }
      },
    },
    {
      label: 'Email inquilino',
      value: 'tenant_email',
      apiParamName: 'tenant_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el email del inquilino',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getTenantByEmailEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          const tenant = response.data
          return [{
            value: tenant.tenant_id,
            label: (
              <>
                <span>{tenant.name}</span>
                <small className="text-gray mx-2">{tenant.phone} {tenant.email}</small>
              </>
            )
          }]
        } else {
          return []
        }
      },
    },
  ]

  const componentHeader = (
    <GridSearchBar
      filtersList={propertyFilters}
    />
  )

  return (
    <>
      {sweetAlert && (<SweetAlert {...alertProps} >{alertProps.text || ''}</SweetAlert>)}
      <TimelineHeader name="Lista de inquilinos" parentName="Inquilinos" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                {/* <Button onClick={toggleModal}
                  className="right" color="default" size="sm" >
                  Filtrar
                </Button> */}
                {componentHeader}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Avatar</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Fecha de registro</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    tenants.length > 0 && tenants.map((tenant, key) => (
                      <tr>
                        <td>
                          <ProfileAvatarGroup
                            code={key}
                            name={
                              tenant.profile.first_name + ' ' + tenant.profile.last_name
                            }
                            avatar={
                              tenant.profile.avatar
                                ? tenant.profile.avatar
                                : ''
                            }
                          />
                        </td>
                        <td>{tenant.profile.first_name}</td>
                        <td>{tenant.profile.last_name}</td>
                        <td>{tenant.profile.email}</td>
                        <td>{tenant.profile.phone}</td>
                        <td>{tenant.created_at_local}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href={`/tenant/${tenant.id}/profile`}
                              >
                                Ver perfil
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => { sendChangePassword(tenant.profile.email) }}
                              >
                                Envíar cambio de contraseña
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={!paginator.hasPreviousPage ? 'disabled' : ''}>
                      <PaginationLink
                        onClick={() => {
                          loadTenants(paginator.page - 1, paginator.take)
                        }}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Anterior</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink>
                        {paginator.page}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={!paginator.hasNextPage ? 'disabled' : ''}>
                      <PaginationLink
                        onClick={() => {
                          loadTenants(paginator.page + 1, paginator.take)
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Siguiente</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>

          </div>
        </Row>
      </Container>
    </>
  )
}

export default Tenants
