import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import AdminUploadResource from '../../components/admin/AdminUploadResource';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  getHomeSliderItemDetailEndpoint,
  uploadHomeSliderImageEndpoint,
} from './services/homeSliderService';
import useAuth from '../../hooks/useAuth';
import useAdminEntity from '../../hooks/useAdminEntity';
import { HomeSliderItem } from './models/homeSliderModel';
import AdminResourceGallery from '../../components/admin/AdminResourceGallery';
import LoadingCard from '../../components/LoadingCard';

const HomeSliderPhotos = () => {
  const { id: homeSliderId = '' } = useParams();
  const navigate = useNavigate();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const pageName = 'Subir imagen';
  const pageParentName = 'Administrador de Carrusell Home';
  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getHomeSliderItem = useCallback(
    () => getHomeSliderItemDetailEndpoint(homeSliderId, token),
    [homeSliderId, token],
  );
  const [item] = useAdminEntity<HomeSliderItem>(
    getHomeSliderItem,
    setErrorAlert,
  );

  const uploadHomeItemImage = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;
      const formData = new FormData();
      files.forEach((file) => formData.append('image', file));
      const { status } = await callEndpoint(
        uploadHomeSliderImageEndpoint(homeSliderId, formData, token),
      );
      if (status === 201) navigate(0);
    },
    [homeSliderId, token, callEndpoint, navigate],
  );

  if (isLoading || !item)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row className="pt-4">
        <Col md="8">
          <AdminUploadResource
            handleUpload={uploadHomeItemImage}
            description={'Subir banner de item'}
            maxFiles={1}
          />
        </Col>
        <Col md="4">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4>Banner actual</h4>
                </CardHeader>
                <CardBody>
                  {item.image && (
                    <AdminResourceGallery
                      gallery={[item.image]}
                      description={'Cover actual'}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default HomeSliderPhotos;
