import { AuthUser } from '../models/authUserModel';

export const getUserAdapter = (user: any) => ({
  name: user.data.name,
  gender: user.data.gender,
  status: user.data.status,
});

export const getAuthUserAdapter = (response: any): AuthUser => ({
  token: response.access_token,
  rft: response.refresh_token,
  name: response.name,
  avatar: response.avatar,
  realm: response.realm || '',
  assigned_countries: response.assigned_countries || [],
  assigned_roles: response.assigned_roles || [],
  assigned_permissions: response.assigned_permissions || [],
});
