import React, { useCallback, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  getLandlordByNameEndpoint,
  getProofOutcomesEndpoint,
} from './services/accountingService';
import useAdminPagination from '../../hooks/useAdminPagination';
import AdminPage from '../../components/admin/AdminPage';
import AdminPaginator from '../../components/admin/AdminPaginator';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  ProofOutcomeListItem,
  ProofOutcomeProduct,
} from './models/proofIncomeModel';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import { getDevelopersEndpoint } from '../investors/services/investorService';
import useAdminEntity from '../../hooks/useAdminEntity';

const AccountingProofOutcomeList: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Comprobantes de egresos';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const [proofsOutcomes, setProofsOutcomes] = useState<ProofOutcomeListItem[]>(
    [],
  );

  const getDevelopersList = useCallback(
    () => getDevelopersEndpoint(token),
    [token],
  );

  const [developerList] = useAdminEntity<any[]>(
    getDevelopersList,
    console.error,
  );

  const proofIncomeFilters = [
    {
      label: 'Código de comprobante',
      value: 'code',
      apiParamName: 'propi_code',
      inputType: 'text',
      isUnique: true,
      placeholder: 'Ingresa el código de comprobante',
    },
    {
      label: '[Renta] Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'owner_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: '[PreVenta] Desarrolladora',
      value: 'developer_name',
      apiParamName: 'owner_id',
      inputType: 'select',
      isUnique: false,
      placeholder: 'Ingrese la desarrolladora',
      inputTypeValues:
        developerList?.map((developer: any) => ({
          label: developer.name,
          value: developer.id,
        })) || [],
    },
  ];

  const proofOutcomeProducts: ProofOutcomeProduct[] = [
    {
      color: 'primary',
      product: 'rent',
      product_text: 'Renta',
    },
    {
      color: 'warning',
      product: 'pre_sale',
      product_text: 'PreVenta',
    },
    {
      color: 'success',
      product: 'sale',
      product_text: 'Vivienda usada',
    },
  ];

  const componentHeader = <GridSearchBar filtersList={proofIncomeFilters} />;

  const { paginator, loadItems } = useAdminPagination(
    getProofOutcomesEndpoint,
    setProofsOutcomes,
    console.error,
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={
        <AdminPaginator
          page={paginator.page}
          totalPages={paginator.pageCount}
          onPageChange={loadItems}
        />
      }
      globalAlertOptions={globalAlertOptions}
      header={componentHeader}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Tipo</th>
            <th scope="col">Monto</th>
            <th scope="col">Generado el</th>
            <th scope="col">Pagado a</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {proofsOutcomes.length > 0 &&
            proofsOutcomes.map((proofOutcomes) => (
              <tr key={`${proofOutcomes.proofOutcome.id}`}>
                <td>{proofOutcomes.proofOutcome.code}</td>
                <td>
                  <Badge
                    color={
                      proofOutcomeProducts.find(
                        (po) =>
                          po.product ===
                          proofOutcomes.proofOutcome.outcome_product,
                      )?.color
                    }
                    className="badge-lg"
                  >
                    {
                      proofOutcomeProducts.find(
                        (po) =>
                          po.product ===
                          proofOutcomes.proofOutcome.outcome_product,
                      )?.product_text
                    }
                  </Badge>
                </td>
                <td>{proofOutcomes.proofOutcome.amount}</td>
                <td>{proofOutcomes.proofOutcome.generated_at}</td>
                <td>{proofOutcomes.proofOutcome.ref_outcome}</td>

                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/accounting/proof-outcome/${proofOutcomes.proofOutcome.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default AccountingProofOutcomeList;
