import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  PropertyLocationAddressForm,
  PropertyUpdateDataApi,
} from '../models/propertyModel';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import {
  getPropertyToEditEndpoint,
  updatePropertyEndpoint,
} from '../services/propertyService';
import { useNavigate } from 'react-router-dom';
import { getAddressDetailById } from '../../../services/addressService';

interface Props {
  id: string;
}
const useAdminAddressLocation = ({ id }: Props) => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [formUpdateData, setFormUpdateData] = useState<PropertyUpdateDataApi>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm<PropertyLocationAddressForm>({
    resolver: yupResolver(
      yup
        .object({
          address_id: yup
            .string()
            .nullable()
            .required('Ubicación/polgígono es requerido'),
          lat: yup.number().required('Latitud es requerida'),
          lng: yup.number().required('Longitud es requerida'),
          address: yup.string().required('Dirección escrita es requerida'),
          street: yup.string().required('Calle es requerida'),
        })
        .required(),
    ),
  });

  const [currentAddress, setCurrentAddress] = useState<any>();

  useEffect(() => {
    const loadExistingProperty = async () => {
      const { status, data } = await callEndpoint(
        getPropertyToEditEndpoint(id, token),
      );
      if (status === 200) {
        const { data: formData } = fetchResponseAdapter(data);
        setFormUpdateData(formData);
      }
    };

    loadExistingProperty().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (formUpdateData) {
      reset({
        lat: formUpdateData.lat,
        lng: formUpdateData.lng,
        address_id: formUpdateData.location?.id.toString() ?? '',
        street: formUpdateData.street,
        address: formUpdateData.address,
      });
    }
  }, [formUpdateData]);

  const doAction = async (form: PropertyLocationAddressForm) => {
    console.log({ form });
    const { status } = await callEndpoint(
      updatePropertyEndpoint(form, id, token),
    );
    if (status === 200) {
      navigate(`/properties/admin/${id}`);
    }
  };

  return {
    isLoading,
    control,
    register,
    setValue,
    errors,
    handleSubmit,
    formUpdateData,
    doAction,
    currentAddress,
  };
};

export default useAdminAddressLocation;
