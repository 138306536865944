import axios from 'axios';
import { WithdrawalRentForm } from '../models/withdrawalModel';

const loadAbort = () => {
  return new AbortController();
};

export const getWithdrawalReasonRentEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/withdrawal-rent/reasons`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createWithdrawalRentEndpoint = (
  form: WithdrawalRentForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/withdrawal-rent`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
