import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  createModelUnitPaymentContractEndpoint, getModelUnitPreBookingsEndpoint, getModelUnitsEndpoint,
} from './services/investorService';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import AdminListGroup from '../../components/admin/AdminListGroup';
import useAdminEntity from '../../hooks/useAdminEntity';
import { ModelUnitDetail } from './models/investors';
import { getLandlordByMailEndpoint } from '../../services/landlordService';

const ModelUnitPaymentContract: React.FC = () => {
  const {id: investorProjectModelId = "", modelUnitId = ""} = useParams()
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(investorProjectModelId, token),
    [investorProjectModelId, token]
  );
  const [modelUnits] = useAdminEntity<ModelUnitDetail[]>(getModelUnits, setErrorAlert);

  const getModelUnitPreBookings = useCallback(
    () => getModelUnitPreBookingsEndpoint(investorProjectModelId, modelUnitId, token),
    [investorProjectModelId, token]
  );
  const [modelUnitPreBookings] = useAdminEntity<any[]>(getModelUnitPreBookings, setErrorAlert);

  const [landlordEmail, setLandlordEmail] = useState<string>("");
  const [landlord, setLandlord] = useState<any>();


  const createPaymentContract = useCallback(async (landlordId: string, modelUnitId: string) => {
    const payload = {
      unit_id: modelUnitId,
      landlord_id: landlordId,
    };
    const {status, data} = await callEndpoint(createModelUnitPaymentContractEndpoint(payload, token));
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      navigate(`/investors/models/${investorProjectModelId}/model-units/`);
    } else {
      setErrorAlert(response.message);
    }
  }, [callEndpoint, token]);


  const handleSearchLandlord = useCallback(async (landlordEmailForSearch: string) => {
    if (!landlordEmailForSearch)
      return

    const { status, data } = await callEndpoint(getLandlordByMailEndpoint(landlordEmailForSearch, token));
    if (status === 200) {
      const landlordResponse = fetchResponseAdapter(data);
      setLandlord(landlordResponse.data);
    } else {
      setErrorAlert("El inversionista no fue encontrado.");
    }

  }, [callEndpoint, createPaymentContract, modelUnitId]);

  const pageName = "Generar mandato de pago";
  const pageParentName = "Preventas";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  const currentModelUnit = modelUnits && modelUnits.length > 0
    ? modelUnits.find((modelUnit) => modelUnit.id === modelUnitId)
    : {base_price: "", preview_dues: []};

  const basePriceItem = [{"title": "Precio base", "value": currentModelUnit?.base_price}]
  const paymentContractItems = currentModelUnit?.preview_dues.map(item => ({"title": item.label, "value": item.value})) || [];

  return (
    <AdminPage name={pageName} parentName={pageParentName} globalAlertOptions={globalAlertOptions}>
      <Form role="form">
        <Row>
          <Col md="6">

            <Row>
              <Col>
                <AdminListGroup title={"Vista previa de cuotas"} items={basePriceItem.concat(paymentContractItems)} />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label"
                         htmlFor="plan_id">Asignar propietario</label>
                  <InputGroup className="mb-3">
                    <Input onChange={({ target }) => setLandlordEmail(target.value || '')}
                           placeholder="Correo de propietario" type="text" />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() => handleSearchLandlord(landlordEmail)}
                        color="primary" outline> Buscar
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                {landlord && (<h5>Asignará la unidad a {landlord.name} | Teléfono: {landlord.phone}</h5>)}

                <Button
                  size="md"
                  color="primary"
                  className="my-2"
                  disabled={!landlord}
                  onClick={async () => {
                    await createPaymentContract(landlord.landlord_id, modelUnitId);
                  }}>
                  Generar mandato de pago
                </Button>
              </Col>
            </Row>

          </Col>
        </Row>
      </Form>
    </AdminPage>
  );
};

export default ModelUnitPaymentContract;