import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';

interface Props {
  property_id: string;
  name: string;
  parentName: string;
  offer_count: number;
  visit_count: number;
  visualization_text: string;
  visualization_count: number;
  parentUri?: string;
}

const HeaderCounters = ({
  property_id,
  name,
  parentName,
  parentUri,
  offer_count = 0,
  visit_count = 0,
  visualization_text = '',
  visualization_count = 0,
}: Props) => {
  if (!parentUri) {
    parentUri = '#';
  }
  return (
    <>
      <div className="header header-dark bg-default pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href={parentUri}>{parentName}</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
              <div className="col-lg-4">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Visitas
                        </h5>
                        <span className="h2 font-weight-bold mb-0">
                          {visit_count}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i className="ni ni-calendar-grid-58"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm">
                      <a href={`/properties/admin/${property_id}/admin-visit`}>
                        Ver detalle
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Ofertas
                        </h5>
                        <span className="h2 font-weight-bold mb-0">
                          {offer_count}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                          <i className="ni ni-shop"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm">
                      <a href={`/properties/admin/${property_id}/admin-offer`}>
                        {' '}
                        Ver detalle{' '}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title text-uppercase text-muted mb-0">
                          Visualizaciones {visualization_text}
                        </h5>
                        <span className="h2 font-weight-bold mb-0">
                          {visualization_count}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35"></i>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm">
                      <a href={`/properties/admin/${property_id}/views`}>
                        {' '}
                        Ver detalle{' '}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HeaderCounters;
