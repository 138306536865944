export const VISIT_STATUS_NAMES: any = {
  sheduled: 'Programada',
  rescheduled: 'Re programada',
  done: 'Finalizada',
  cancelled: 'Cancelada',
  deleted: 'Eliminada'
};

export enum VISIT_STATUS {
  SCHEDULED = 'sheduled',
  RESCHEDULED = 'rescheduled',
  DONE = 'done',
  CANCELLED = 'cancelled',
  DELETED = 'deleted' // for pre sales
}
