import { useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { ContractSubscriptionsList } from './models/subscriptionsModel';
import { Paginator } from '../../models/formModel';
import { getContractSubscriptionsEndpoint } from './services/subscriptionServices';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setGridFiltersAction } from '../../redux/features/gridSearchBar';
import LoadingCard from '../../components/LoadingCard';
import {
  getLandlordByEmailEndpoint,
  getLandlordByNameEndpoint,
} from '../accounting/services/accountingService';
import GridSearchBar from '../../components/searchBar/GridSearchBar';

const Subscriptions = () => {
  const dispatch = useDispatch();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [contractSubscriptions, setContractSubscriptions] = useState<
    ContractSubscriptionsList[]
  >([]);
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const pageFilters = [
    // {
    //   label: 'Código de propiedad',
    //   value: 'code',
    //   apiParamName: 'code',
    //   inputType: 'number',
    //   isUnique: true,
    //   placeholder: 'Ingresa el código de propiedad',
    // },
    {
      label: 'Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];
        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );
        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: 'Email propietario',
      value: 'landlord_email',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el email del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];
        const { status, data } = await callEndpoint(
          getLandlordByEmailEndpoint(searchTerm, token),
        );
        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const loadContractSubscriptions = async () => {
    const { status, data } = await callEndpoint(
      getContractSubscriptionsEndpoint(token, gridSearchBar.query),
    ); //send filters
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setContractSubscriptions(response.data.items || []);
      setPaginator(response.data.meta);
    }
  };

  useEffect(() => {
    loadContractSubscriptions().catch((e) => console.error(e));
  }, [gridSearchBar]);

  if (isLoading && !contractSubscriptions.length) {
    return <LoadingCard name={'Contratos'} parent_name={''} />;
  }

  return (
    <AdminPage
      name={'Rental managment'}
      parentName={''}
      header={<GridSearchBar filtersList={pageFilters} />}
      footer={
        <nav aria-label="...">
          <Pagination
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
          >
            <PaginationItem
              className={!paginator.hasPreviousPage ? 'disabled' : ''}
            >
              <PaginationLink
                onClick={() => {
                  dispatch(
                    setGridFiltersAction({
                      page: paginator.page - 1,
                      take: paginator.take,
                    }),
                  );
                }}
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">Anterior</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem className="active">
              <PaginationLink>{paginator.page}</PaginationLink>
            </PaginationItem>
            <PaginationItem
              className={!paginator.hasNextPage ? 'disabled' : ''}
            >
              <PaginationLink
                onClick={() => {
                  dispatch(
                    setGridFiltersAction({
                      page: paginator.page + 1,
                      take: paginator.take,
                    }),
                  );
                }}
              >
                <i className="fas fa-angle-right" />
                <span className="sr-only">Siguiente</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      }
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {/*<th scope="col">Propiedad</th>*/}
            <th scope="col">Cod propiedad</th>
            <th scope="col">Propietario</th>
            <th scope="col">Tipo de valor</th>
            <th scope="col">Valor aplicado</th>
            <th scope="col">Fecha inicio</th>
            <th scope="col">Fecha fin</th>
            <th scope="col">Estado</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {contractSubscriptions.length > 0 &&
            contractSubscriptions.map((cSubscription) => (
              <tr key={`k-${cSubscription.id}`}>
                <td>{cSubscription.property_code}</td>
                <td>{cSubscription.landlord_name}</td>
                <td>{cSubscription.subscription_type}</td>
                <td>{cSubscription.value}</td>
                <td>{cSubscription.start_date}</td>
                <td>{cSubscription.end_date}</td>
                <td>{cSubscription.status}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/subscriptions/${cSubscription.id}/detail`}
                      >
                        Ver detalle de rental managment
                      </DropdownItem>
                      <DropdownItem
                        href={`/contract/${cSubscription.contract_id}/detail`}
                      >
                        Ver detalle de contrato
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default Subscriptions;
