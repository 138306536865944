import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const loginEndpoint = (username: string, password: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/user/v1/auth-internal-users/login`,
        data: JSON.stringify({ username, password }),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    controller,
  };
};
