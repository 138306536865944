import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { AdminPropertyData } from '../models/propertyModel';
import { BrokerDetailInfo, BrokerRegistrationForm, BrokerUpdateForm } from '../models/brokerModel';
import { getAdminPropertyEndpoint } from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import {
  assignBrokerToPropertyEndpoint,
  createBrokerInfoEndpoint,
  getBrokerInfoEndpoint,
  getBrokerListInfoEndpoint,
  unassignBrokerEndpoint,
  updateBrokerInfoEndpoint,
} from '../services/brokerService';

interface Props {
  propertyId: string;
  setErrorAlert: Function
}

const useAdminBroker = ({ propertyId, setErrorAlert }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [adminPropertyData, setAdminPropertyData] =
    useState<AdminPropertyData>();

  const [brokerDetailInfo, setBrokerDetailInfo] = useState<BrokerDetailInfo>();
  const navigate = useNavigate();
  const [brokerInfoList, setBrokerInfoList] = useState<any[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<BrokerRegistrationForm>({
    resolver: yupResolver(
      yup
        .object({
          first_name: yup.string().required('Nombre es requerido'),
          last_name: yup.string().required('Apellido es requerido'),
          email: yup
            .string()
            .email('Ingrese un formato valido para correo')
            .required('Correo electrónico es requerido'),
          phone: yup.string().required('Teléfono es requerido'),
        })
        .required(),
    ),
  });

  const loadPropertyInfo = async (propertyId: string) => {
    const { status, data } = await callEndpoint(
      getAdminPropertyEndpoint(propertyId, token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setAdminPropertyData(response.data);
      return response.data.broker_id;
    } else {
      return null;
    }
  };

  const loadBroker = async (brokerId: string | null) => {
    if (!brokerId) {
      console.log(`Broker info not found`);
      return;
    }
    const { status, data } = await callEndpoint(
      getBrokerInfoEndpoint(brokerId, token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setBrokerDetailInfo(response.data);
      const { first_name, last_name, email, phone } = response.data;
      setValue('first_name', first_name);
      setValue('last_name', last_name);
      setValue('email', email);
      setValue('phone', phone);
    }
  };

  const handleSaveBroker = async (form: BrokerRegistrationForm) => {
    if (brokerDetailInfo?.broker_id) {
      return update(brokerDetailInfo.broker_id, form);
    } else {
      return create(form);
    }
  };

  const create = async (form: BrokerRegistrationForm) => {
    form.username = form.email;
    form.area_code = '+0';
    form.country = '';
    const { status, data } = await callEndpoint(createBrokerInfoEndpoint(form, token));
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      const { tid = '' } = response.data;
      const { status: assignStatus } = await callEndpoint(
        assignBrokerToPropertyEndpoint(propertyId, tid, token),
      );
      if (assignStatus !== 200) {
        setErrorAlert(
          'Ocurrió un error al momento de asignar el broker a la propiedad',
        );
      }
    } else {
      setErrorAlert(response.message);
    }
  };

  const update = async (brokerId: string, form: BrokerRegistrationForm) => {
    const updateForm: BrokerUpdateForm = {
      first_name: form.first_name,
      last_name: form.last_name,
      phone: form.phone,
      area_code: '+0',
    };
    const { status, data } = await callEndpoint(
      updateBrokerInfoEndpoint(brokerId, updateForm, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert(response.message);
    }
  };

  const unassignBroker = async (propertyId: string) => {    
    const { status, data } = await callEndpoint(
      unassignBrokerEndpoint(propertyId, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert('Lo sentimos, no fue posible remover el broker de la propiedad');
    }
  };
  
  useEffect(() => {
    setValue('country', '');
    setValue('area_code', '+0');
    loadPropertyInfo(propertyId)
      .then((brokerId) => {
        console.log({ brokerId });
        return loadBroker(brokerId);
      })
      .catch((e) => console.error(e));
    loadBrokerList().catch(e => console.error(e))
  }, []);

  const loadBrokerList = async () => {
    const { status, data } = await callEndpoint(
      getBrokerListInfoEndpoint(token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setBrokerInfoList(response.data);
    }
  };

  const assignBrokerToProperty = async (brokerId: string, propertyId: string) => {
    const { status: assignStatus } = await callEndpoint(
      assignBrokerToPropertyEndpoint(propertyId, brokerId, token),
    );
    if (assignStatus !== 200) {
      setErrorAlert(
        'Ocurrió un error al momento de asignar el broker a la propiedad',
      );
    } else {
      loadPropertyInfo(propertyId)
        .then((brokerId) => {
          return loadBroker(brokerId);
        })
        .catch((e) => console.error(e));
    }
  };

  return {
    control,
    errors,
    handleSubmit,
    handleSaveBroker,
    adminPropertyData,
    brokerDetailInfo,
    unassignBroker,
    isLoading,
    loadBrokerList,
    brokerInfoList,
    assignBrokerToProperty,
  }

};

export default useAdminBroker
