import React, { useEffect, useRef, useState } from 'react';
import {
  CreateTowerLayoutLevel,
  FacadePolygon,
  FloorImagePolygon,
  FloorLayoutDetail,
} from '../models/towerLayoutModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { createImageFloorEndpoint, createPolygonTowerLevel, polygonDetailFloorEndpoint } from '../../withdrawal/services/projectService';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { PROJECT_DISTRIBUTION_LIST } from '../constants/towerLayoutConstants';
import { Option } from '../../../models/formModel';
import { getUnitsForModelEndpoint } from '../services/investorService';

const useCreateImageFloor = () => {
  const distributionStatusOptions = listToSelectOptionAdapter(
    PROJECT_DISTRIBUTION_LIST,
    'value',
    'display_value',
  );
  const { id = '', id_dist = '' } = useParams();
  
  const [isEditing, setIsEditing] = useState(false); // Nuevo estado
  const canvasRef = useRef(null);
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate()

  const [errorAlert, setErrorAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
  } = useForm<FloorImagePolygon>({
    resolver: yupResolver(
      yup
        .object({
            
        })
        .required(),
    ),
  });


  const addCurrentImage = async (form: any) => {
    
      const { status } = await callEndpoint(
        createImageFloorEndpoint(
          id,
          id_dist,
          form,
          token
        ),
      );

      if (status === 200) {
        setSuccessAlert(true)
        setTimeout(() => {
            navigate(0)
        }, 1000)
      } else {
        setErrorAlert(true);
      }
    
  };
  

  /* -- Load and preload info -- */

  // 1- Load tower information

  // 2-  Load canvas and catch updates when we are drawing


  /* -- Handlers -- */

  // This put a pin of a shape that is being drawn

  return {
    // Form
    setIsEditing,
    isEditing,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    isLoading,
    canvasRef,
    addCurrentImage,
    errorAlert,
    successAlert
  };
};

export default useCreateImageFloor;
