import axios from 'axios';

const loadAbort = () => {
    return new AbortController();
  };

  export const getDocumentsCatalog = (country: string) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "get",
          url: `${urlService}/user/v1/documents/config?countryCode=${country}`,
          headers: {
            "Content-Type": "application/json",
          },
        }),
      controller,
    };
  };

export const getProfileDocuments = (
    profile_id: string,
    token: string,
  ) => {
    const controller = loadAbort();
  
    return {
      call: (urlService: string) =>
        axios({
          method: 'get',
          url: `${urlService}/user/v1/admin/document/profile/${profile_id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };

  export const sendDocumentsProfile = (token: string, payload: any) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: "post",
          url: `${urlService}/user/v1/admin/document/profile`,
          data: payload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };