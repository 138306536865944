import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  getCountriesEndpoint,
  getStatesEndpoint,
} from '../../services/publicService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { UpdateTenantProfileForm } from './models/tenantModel';
import LoadingCard from '../../components/LoadingCard';
import {
  getTenantEndpoint,
  updateTenantEndpoint,
} from './services/tenantService';
import AdminPage from '../../components/admin/AdminPage';
import Select from 'react-select';
import useProperList from '../../hooks/useProperList';

interface FormControlErrorProps {
  message: string | undefined;
  additionalClass?: string | undefined;
}

const FormControlError = ({
  message,
  additionalClass = '',
}: FormControlErrorProps) => {
  if (!message) return null;
  return (
    <Row>
      <Col>{message && <Alert color="warning">{message}</Alert>}</Col>
    </Row>
  );
};

interface DetailFormControlSectionProps {
  label: string;
  children: JSX.Element;
}

const DetailFormControlSection = (props: DetailFormControlSectionProps) => {
  const { label, children } = props;
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label>{label}</Label>
            {children}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

const TenantEdit: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const { propersOptions } = useProperList();

  const { id: tenantId = '' } = useParams();

  const [countries, setCountries] = useState<any>();
  const [states, setStates] = useState<Array<any>>([]);
  const [error, setError] = useState('');
  const [havePetsState, setHavePetsState] = useState(false);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [defaultForm, setDefaultForm] = useState<any>();
  const navigate = useNavigate();

  const parseStringBooleanToBool = (value: string) => {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  };

  const petSizesOptions: any = [
    { label: 'Pequeño', value: 'P' },
    { label: 'Mediano', value: 'M' },
    { label: 'Grande', value: 'G' },
  ];

  const havePetsOptions: any = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];

  const genderOptions: any = [
    { label: 'Femenino', value: 'F' },
    { label: 'Masculino', value: 'M' },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<UpdateTenantProfileForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required('Nombres son requerido'),
          last_name: yup.string().required('Apellidos son requerido'),
          country: yup.string().required('País es requerido'),
          state: yup.string().required('Departamento es requerido'),
          phone: yup.string().required('Teléfono es requerido'),
          birth_date: yup.string().required('Fecha de nacimiento es requerida'),
          profession: yup.string().required('Profesión es requerida'),
          roommates_qty: yup
            .string()
            .required('Cantidad de personas es requerido'),
          hobbies: yup.string().required('Cantidad de personas es requerido'),
          gender: yup.string().required('Sexo es requerido'),
          social_media: yup
            .string()
            .required('Cantidad de personas es requerido'),
          company_name: yup
            .string()
            .required('Ingrese la empresa donde trabaja actualmente'),
          net_income: yup.string().required('Ingrese su salario aproximado'),
          proper_id: yup.string(),
          summary: yup
            .string()
            .required('Escriba una breve descripción de usted y su familia'),
          ...(havePetsState
            ? {
                pet_size: yup
                  .string()
                  .required('Tamaño de mascota es requerido'),
              }
            : {}),
        })
        .required(),
    ),
  });

  useEffect(() => {
    const loadTenant = async () => {
      const { status, data } = await callEndpoint(
        getTenantEndpoint(tenantId, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setDefaultForm(response.data);

        const currentDefaultForm = response.data;

        setHavePetsState(currentDefaultForm.tenant.have_pets || false);
        reset({
          name: currentDefaultForm.tenant.profile.first_name || '',
          last_name: currentDefaultForm.tenant.profile.last_name || '',
          country: currentDefaultForm.tenant.profile.country_id || '',
          state: currentDefaultForm.tenant.profile.state_id || '',
          phone: currentDefaultForm.tenant.profile.phone || '',
          birth_date:
            currentDefaultForm.tenant.profile.birth_date.split('T')[0] || '',
          gender: currentDefaultForm.tenant.profile.gender || '',
          roommates_qty: currentDefaultForm.tenant.roommates_qty || 0,
          social_media: currentDefaultForm.tenant.social_media || '',
          profession: currentDefaultForm.tenant.profession || '',
          hobbies: currentDefaultForm.tenant.hobbies || '',
          have_pets: currentDefaultForm.tenant.have_pets || false,
          pet_size: currentDefaultForm.tenant.pet_size || '',
          company_name: currentDefaultForm.tenant.company_name || '',
          net_income: currentDefaultForm.tenant.net_income || 0.0,
          summary: currentDefaultForm.tenant.summary || '',
          proper_id: currentDefaultForm.tenant.proper?.id || '',
        });
      }
    };
    loadTenant().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const callCatalogs = async () => {
      if (!defaultForm || !defaultForm.tenant) return;

      const countriesResponse = await callEndpoint(getCountriesEndpoint());
      if (countriesResponse.status !== 200) {
        setError(
          'Ocurrio un error al momento de cargar los catalogos de paises',
        );
        return;
      }
      const countriesData = fetchResponseAdapter(countriesResponse.data);
      setCountries(
        listToSelectOptionAdapter(
          countriesData.data,
          'country_id',
          'country_name',
        ),
      );
      if (defaultForm.tenant.profile.country_id) {
        const statesResponse = await callEndpoint(
          getStatesEndpoint(defaultForm.tenant.profile.country_id),
        );
        if (statesResponse.status !== 200) {
          setError(
            'Ocurrio un error al momento de cargar los catalogos de estados',
          );
          return;
        }
        const statesData = fetchResponseAdapter(statesResponse.data);
        setStates(
          listToSelectOptionAdapter(statesData.data, 'state_id', 'state_name'),
        );
      }
    };
    callCatalogs().catch((e) => console.error(e));
  }, [defaultForm]);

  const handleChangeCountry = async (country_id: any) => {
    setStates([]);
    if (country_id) {
      const { status, data } = await callEndpoint(
        getStatesEndpoint(country_id),
      );
      if (status === 200) {
        const statesResponse = fetchResponseAdapter(data);
        setStates(
          listToSelectOptionAdapter(
            statesResponse.data,
            'state_id',
            'state_name',
          ),
        );
        return;
      }
    }
  };

  const handleChangeHavePets = (e: any) => {
    const value = e.target.value;
    let formattedValue;
    if (value && typeof value === 'string') {
      formattedValue = parseStringBooleanToBool(value);
    } else {
      formattedValue = value;
    }
    setHavePetsState(formattedValue);
  };

  const doUpdate = async (form: UpdateTenantProfileForm) => {
    form.have_pets = havePetsState;
    if (!form.have_pets) {
      form.pet_size = '';
    }

    if (form.proper_id === '') {
      delete form.proper_id;
    }

    setError('');
    const { status } = await callEndpoint(
      updateTenantEndpoint(tenantId, form, token),
    );
    if (status === 200) {
      return navigate(0);
    }
  };

  const pageName = 'Editar inquilino';
  const parentPageName = 'Inquilinos';
  const parentUri = '/tenant/view'

  if (!countries || !defaultForm || !defaultForm.tenant)
    return (
      <LoadingCard name={pageName} parent_name={parentPageName}></LoadingCard>
    );

  return (
    <AdminPage name={pageName} parentName={parentPageName} parentUri={parentUri}>
      <Row>
        <Col md="6">
          <Form onSubmit={handleSubmit(doUpdate)}>
            <FormControlError message={error} />
            <DetailFormControlSection label="Nombres">
              <Controller
                key={'name'}
                name="name"
                control={control}
                defaultValue={defaultForm.tenant.profile.first_name}
                render={({ field }) => (
                  <>
                    <Input
                      type="text"
                      placeholder="Ingresa tus nombres"
                      {...field}
                    />
                    <FormControlError
                      message={`${errors.name?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Apellidos">
              <Controller
                key={'last_name'}
                name="last_name"
                control={control}
                defaultValue={defaultForm.tenant.profile.last_name}
                render={({ field }) => (
                  <>
                    <Input
                      type="text"
                      placeholder="Ingresa tus apellidos"
                      {...field}
                    />
                    <FormControlError
                      message={`${errors.last_name?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Correo electrónico">
              <Input
                defaultValue={defaultForm.tenant.profile.email}
                readOnly
                disabled
                type="text"
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="País">
              <Controller
                key={'country'}
                name="country"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      key={`country-${countries.length}`}
                      placeholder="Seleccione un país..."
                      onChange={(e) => {
                        const countryId = e?.value;
                        setValue('country', countryId);
                        handleChangeCountry(countryId);
                      }}
                      ref={field.ref}
                      defaultValue={countries.map((c: any) => {
                        const isSelected =
                          c.value === defaultForm.tenant.profile.country_id;
                        if (isSelected)
                          return { value: c.value, label: c.label };
                      })}
                      options={countries.map((c: any) => {
                        return { value: c.value, label: c.label };
                      })}
                    />
                    <FormControlError
                      message={`${errors.country?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Estado o departamento">
              <Controller
                key={'state'}
                control={control}
                name="state"
                render={({ field }) => (
                  <>
                    <Select
                      placeholder="Seleccione un estado..."
                      ref={field.ref}
                      onChange={(e) => {
                        const stateId = e?.value;
                        setValue('state', stateId);
                      }}
                      defaultValue={states.map((c) => {
                        const isSelected =
                          c.value === defaultForm.tenant.profile.state_id;
                        if (isSelected)
                          return { label: c.label, value: c.value };
                      })}
                      options={states.map((c) => {
                        return { label: c.label, value: c.value };
                      })}
                      key={`states-${states.length}`}
                    />
                    <FormControlError
                      message={`${errors.state?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Teléfono">
              <Controller
                key={'phone'}
                control={control}
                name={'phone'}
                defaultValue={defaultForm.tenant.profile.phone}
                render={({ field }) => (
                  <>
                    <Input
                      type="text"
                      placeholder="Ingrese su teléfono"
                      {...field}
                    />
                    <FormControlError
                      message={`${errors.phone?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Fecha de nacimiento">
              <Controller
                key={'birth_date'}
                control={control}
                name="birth_date"
                defaultValue={defaultForm.tenant.profile.birth_date}
                render={({ field }) => (
                  <>
                    <Input type="date" {...field} />
                    <FormControlError
                      message={`${errors.birth_date?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Profesión u oficio">
              <Controller
                key={'profession'}
                control={control}
                name="profession"
                defaultValue={defaultForm.tenant.profession}
                render={({ field }) => (
                  <>
                    <Input
                      type="text"
                      placeholder="Ingrese su profesión u oficio"
                      {...field}
                    />
                    <FormControlError
                      message={`${errors.profession?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Mascota">
              <Controller
                //key={'have_pets'}
                control={control}
                name="have_pets"
                render={({ field }) => (
                  <>
                    {havePetsOptions &&
                      havePetsOptions.map((item: any, index: number) => (
                        <>
                          <br key={`br-${index}`} />
                          <Input
                            type="radio"
                            {...field}
                            onChange={(e) => {
                              setValue('have_pets', e.target.value === 'true');
                              handleChangeHavePets(e);
                            }}
                            value={item.value}
                            key={`have_pets_${item.value}`}
                            checked={havePetsState === item.value}
                          />
                          <Label key={index}> {item.label} </Label>
                        </>
                      ))}
                  </>
                )}
              />
            </DetailFormControlSection>
            {havePetsState && (
              <DetailFormControlSection label="Tamaño de mascota">
                <Controller
                  key={'pet_size'}
                  control={control}
                  name="pet_size"
                  render={({ field }) => (
                    <>
                      <Select
                        key={`petz-${petSizesOptions.length}`}
                        placeholder={'Seleccione un tamaño...'}
                        ref={field.ref}
                        defaultValue={petSizesOptions.map((c: any) => {
                          const isSelected =
                            c.value === defaultForm.tenant.pet_size;
                          if (isSelected)
                            return { label: c.label, value: c.value };
                        })}
                        options={petSizesOptions.map((c: any) => {
                          return { label: c.label, value: c.value };
                        })}
                        onChange={(optionSelected) =>
                          setValue('pet_size', optionSelected?.value)
                        }
                      />
                      <FormControlError
                        message={`${errors.pet_size?.message || ''}`}
                      />
                    </>
                  )}
                />
              </DetailFormControlSection>
            )}
            <DetailFormControlSection label="Sexo">
              <Controller
                key={'gender'}
                control={control}
                name="gender"
                render={({ field }) => (
                  <>
                    <Select
                      key={`gender-${genderOptions.length}`}
                      ref={field.ref}
                      placeholder={'Seleccione un genero...'}
                      defaultValue={genderOptions.map((c: any) => {
                        const isSelected =
                          c.value === defaultForm.tenant.profile.gender;
                        if (isSelected)
                          return { label: c.label, value: c.value };
                      })}
                      options={genderOptions.map((c: any) => {
                        return { label: c.label, value: c.value };
                      })}
                      onChange={(optionSelected) =>
                        setValue('gender', optionSelected?.value)
                      }
                    />
                    <FormControlError
                      message={`${errors.gender?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Cantidad de personas con las que vives">
              <Controller
                key={'roomates_qty'}
                control={control}
                name="roommates_qty"
                defaultValue={defaultForm.tenant.roommates_qty}
                render={({ field }) => (
                  <>
                    <Input type="number" {...field} />
                    <FormControlError
                      message={`${errors.roommates_qty?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Pasatiempos">
              <Controller
                key={'hobbies'}
                control={control}
                name="hobbies"
                defaultValue={defaultForm.tenant.hobbies}
                render={({ field }) => (
                  <>
                    <Input type="text" {...field} />
                    <FormControlError
                      message={`${errors.hobbies?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Red social">
              <Controller
                key={'social_media'}
                control={control}
                name="social_media"
                defaultValue={defaultForm.tenant.social_media}
                render={({ field }) => (
                  <>
                    <Input type="text" {...field} />
                    <FormControlError
                      message={`${errors.social_media?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Compañia donde trabaja">
              <Controller
                key={'company_name'}
                control={control}
                name={'company_name'}
                defaultValue={defaultForm.tenant.company_name}
                render={({ field }) => (
                  <>
                    <Input type="text" {...field} />
                    <FormControlError
                      message={`${errors.hobbies?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Salario neto aproximado">
              <Controller
                key={'net_income'}
                control={control}
                name="net_income"
                defaultValue={defaultForm.tenant.net_income}
                render={({ field }) => (
                  <>
                    <Input type="number" {...field} />
                    <FormControlError
                      message={`${errors.net_income?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Resumen de mi">
              <Controller
                key={'summary'}
                control={control}
                name="summary"
                defaultValue={defaultForm.tenant.summary}
                render={({ field }) => (
                  <>
                    <Input type="text" {...field} />
                    <FormControlError
                      message={`${errors.summary?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>
            <DetailFormControlSection label="Asignar proper">
              <Controller
                key={'proper_id'}
                control={control}
                name="proper_id"
                render={({ field }) => (
                  <>
                    <Select
                      key={`proper-${propersOptions.length}`}
                      placeholder={'Selecciona un proper'}
                      options={propersOptions}
                      defaultValue={propersOptions.find(
                        (po) => po.value === defaultForm.tenant.proper?.id,
                      )}
                      onChange={(selected) => {
                        setValue('proper_id', selected?.value);
                      }}
                      ref={field.ref}
                    />
                    <FormControlError
                      message={`${errors.proper_id?.message || ''}`}
                    />
                  </>
                )}
              />
            </DetailFormControlSection>

            <Button disabled={isLoading} color="primary">
              Guardar
            </Button>
          </Form>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default TenantEdit;
