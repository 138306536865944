import React, { useState, useEffect } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';

interface Props {
  onChange: (amenities: string[]) => void;
  defaultValues?: string[];
};

const AmenitiesList = ({ onChange, defaultValues = [] }: Props) => {
  const [currentAmenity, setCurrentAmenity] = useState<string>("");
  const [amenities, setAmenities] = useState<string[]>([]);
  const handleAdd = () => {
    if (!currentAmenity) {
      return
    }
    setAmenities([...amenities, currentAmenity]);
    setCurrentAmenity("");
    onChange([...amenities, currentAmenity]);
  }
  const handleRemove = (amenity: string) => {
    if (amenities.length > 0) { // just check if value exists
      const index = amenities.indexOf(amenity);
      if (index !== -1) {
        const newAmenities = amenities.filter((value) => ( value !== amenity ))
        setAmenities([ ...newAmenities ])
        onChange([ ...newAmenities ])
      }
    } else {
      setAmenities([])
      onChange([])
    }
  }
  useEffect(() => {
    if (defaultValues && defaultValues.length > 0) {
      console.log('setting defaultValues', defaultValues);
      // this is to et default values to setAmmenities and onChange
      // set values to react hook forms
      setAmenities([...defaultValues]);
      onChange([...defaultValues]);
    }
  }, [])
  return (
    <>
      <Row>
        <Col>
          <div>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="plan_id">
                Amenidades
              </label>

              <InputGroup className="mb-3">
                <Input
                  value={currentAmenity}
                  onInput={({ target }) => setCurrentAmenity((target as HTMLInputElement).value || "")}
                  placeholder="Ingrese una amenidad"
                  type="text"
                />

                <InputGroupAddon addonType="append">
                  <Button
                    onClick={handleAdd}
                    color="primary" outline
                  >
                    Agregar
                  </Button>
                </InputGroupAddon>

              </InputGroup>
            </FormGroup>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <ul>
            {amenities.length <= 0 && (
              <li>Agrega al menos una amenidad.</li>
            )}
            {amenities.length > 0 && amenities.map((amenity, idx) => (
              <li key={idx}>
                <Row>
                  <Col md="4">
                    {amenity}
                  </Col>
                  <Col md="8">
                    <strong onClick={() => handleRemove(amenity)} >Remover</strong>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default AmenitiesList;