import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageModelUnitFinancesForm from './components/ManageModelUnitFinancesForm';
import { ModelUnitFinancesForm } from './models/investors';
import { updateModelUnitFinancesEndpoint } from './services/investorService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ModelUnitFinances: React.FC = () => {
  const {id: investorProjectModelId = "", modelUnitId = ""} = useParams();
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const updateFinanceInfo = useCallback(async (form: ModelUnitFinancesForm, errorRepository: Function) => {
    // console.log(form);
    const {
      status, data
    } = await callEndpoint(updateModelUnitFinancesEndpoint(investorProjectModelId, modelUnitId, form, token));

    const response = fetchResponseAdapter(data);

    if (status === 200)
      navigate(`/investors/models/${investorProjectModelId}/model-units`)
    else
      errorRepository(response.message);
  }, [callEndpoint, investorProjectModelId, modelUnitId, navigate, token]);

  const pageName = "Datos financieros de la unidad"
  const pageParentName = "Preventa"

  return (
    <AdminPage name={pageName} parentName={pageParentName}>
      <ManageModelUnitFinancesForm projectModelId={investorProjectModelId} modelUnitId={modelUnitId} handleForm={updateFinanceInfo} />
    </AdminPage>
  );
};

export default ModelUnitFinances;