import React, { useCallback, useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import {
  cancelVisitEndpoint,
  getGuestVisitsEndpoint,
} from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { GuestVisit } from './models/guestModel';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import CancelEntityModal from './components/CancelEntityModal';
import useModalState from '../../hooks/useModalState';
import AdminPaginator from '../../components/admin/AdminPaginator';
import VisitMarketplaceTypeBadge from '../../components/VisitMarketplaceTypeBadge';

const GuestVisits = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();

  const [errorAlert, setErrorAlert] = useState(null);

  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const [guestVisits, setGuestVisits] = useState<GuestVisit[]>([]);
  const loadGuestVisits = useCallback(
    async (page: number = 0, take: number = 0) => {
      let filterBuilder: any = {};
      if (page > 0) filterBuilder.page = page.toString();
      if (take > 0) filterBuilder.take = take.toString();
      const queryString = `?${createSearchParams(filterBuilder)}`;
      const { status, data } = await callEndpoint(
        getGuestVisitsEndpoint(queryString, token),
      );

      const response = fetchResponseAdapter(data);
      if (status === 200) {
        setGuestVisits(response.data.items || []);
        const { page, pageCount } = response.data.meta;
        setPaginator({ page, pageCount });
      } else {
        setErrorAlert(response.message);
      }
    },
    [callEndpoint, token],
  );
  useEffect(() => {
    loadGuestVisits().catch((e) => setErrorAlert(e.toString()));
  }, [loadGuestVisits]);

  const [visitForCancel, setVisitForCancel] = useState({});
  const [showCancelVisitModal, toggleCancelVisitModal] = useModalState();
  const cancelVisit = useCallback(
    async (visitForCancel: GuestVisit) => {
      await callEndpoint(
        cancelVisitEndpoint(
          visitForCancel.guestInfo.id,
          visitForCancel.visitInfo.id,
          token,
        ),
      );
      toggleCancelVisitModal();
      await loadGuestVisits();
    },
    [callEndpoint, loadGuestVisits, toggleCancelVisitModal, token],
  );

  const pageName = 'Visitas de Guests';
  const pageParentName = 'Guests';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const cancelGuestModal = (
    <CancelEntityModal
      entity={visitForCancel}
      isOpen={showCancelVisitModal}
      onToggle={toggleCancelVisitModal}
      onConfirmation={cancelVisit}
      warningText={'¿Estás seguro de anular la visita?'}
      confirmationText={'Si, anular visita'}
      abortText={'No, continuar revisando...'}
    />
  );

  const guestVisitActions = (visit: GuestVisit) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem href={`/guest-visits/${visit.visitInfo.id}`}>
          Ver visita detallada
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setVisitForCancel(visit);
            toggleCancelVisitModal();
          }}
        >
          Anular visita
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Email</th>
          <th scope="col">Tipo de visita</th>
          <th scope="col">Fecha de visita</th>
          <th scope="col">Tipo de lead</th>
          <th scope="col">ID Propiedad</th>
          <th scope="col">Propiedad</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {guestVisits.length > 0 &&
          guestVisits.map((guestVisit) => (
            <tr
              key={`${guestVisit.guestInfo.id}-${guestVisit.propertyInfo.id}`}
            >
              <td>{guestVisit.guestInfo.fullname}</td>
              <td>{guestVisit.guestInfo.phone}</td>
              <td>{guestVisit.guestInfo.email}</td>
              <td>
                {guestVisit.visitInfo.visit_type === 'presential'
                  ? 'Presencial'
                  : 'Virtual'}
              </td>
              <td>{`${guestVisit.visitInfo.visit_date} ${guestVisit.visitInfo.visit_time}`}</td>
              <td>
                <VisitMarketplaceTypeBadge
                  marketplace_type={guestVisit.visitInfo.marketplace_type}
                />
              </td>
              <td>{guestVisit.propertyInfo.code}</td>
              <td>
                <img
                  alt="..."
                  width={175}
                  height={100}
                  src={
                    guestVisit.propertyInfo.cover
                      ? guestVisit.propertyInfo.cover
                      : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                  }
                />
              </td>
              <td className="text-right">{guestVisitActions(guestVisit)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadGuestVisits}
    />
  );

  if (isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentPagination}
      globalAlertOptions={globalAlertOptions}
    >
      {componentTable}
      {cancelGuestModal}
    </AdminPage>
  );
};

export default GuestVisits;
