import axios from 'axios';
import { GenerateCodes } from '../models/investors';
import {
  CreateVisitInvestor,
  UpdateVisitInvestor,
} from '../models/visitsInvestorModel';

const loadAbort = () => {
  return new AbortController();
};

export const getProjectVisitsEndpoint = (
  projectId: string,
  start: string,
  end: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/visits/project/${projectId}?start=${start}&end=${end}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAvailableTimeProjectVisitsEndpoint = (
  projectId: string,
  day: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/visits/project/${projectId}/available-time-visits/${day}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createProjectVisitsEndpoint = (
  form: CreateVisitInvestor,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/visits`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateProjectVisitsEndpoint = (
  visit_id: string,
  form: UpdateVisitInvestor,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/visits/${visit_id}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteProjectVisitsEndpoint = (
    visit_id: string,
    token: string,
  ) => {
    const controller = loadAbort();
    return {
      call: (urlService: string) =>
        axios({
          method: 'delete',
          url: `${urlService}/pre-sales/v1/admin/visits/${visit_id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      controller,
    };
  };
