import React, { useCallback, useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import {
  getProofOutcomeDetailEndpoint,
  sendProofOutcomeViaEmail,
} from './services/accountingService';
import useAdminEntity from '../../hooks/useAdminEntity';
import { Col, Row } from 'reactstrap';
import AccountingProofOutcomeOwnerCard from './components/AccountingProofOutcomeOwnerCard';
import AccountProofOutcomeCard from './components/AccountProofOutcomeCard';
import { ProofOutcomeListItem } from './models/proofIncomeModel';
import AccountingProofIncomeListByProofOutcome from './components/AccountingProofIncomeListByProofOutcome';
import useModalState from '../../hooks/useModalState';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AccountingSendProofEmailModal from './components/AccountingSendProofEmailModal';

const AccountingProofOutcomeDetail: React.FC = () => {
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(null);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const pageName = 'Detalle comprobante de egreso';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const { id: proofOutcomeId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getProofIncomeDetail = useCallback(
    () => getProofOutcomeDetailEndpoint(proofOutcomeId, token),
    [proofOutcomeId, token],
  );
  const [proofOutcomeDetail] = useAdminEntity<ProofOutcomeListItem>(
    getProofIncomeDetail,
    setErrorAlert,
  );

  const [showSendProofModal, toggleSendProofModal] = useModalState();

  const sendProofEmail = useCallback(
    async (id: string) => {
      const { status, data } = await callEndpoint(
        sendProofOutcomeViaEmail(id, token),
      );
      if (status === 201) {
        toggleSendProofModal();
      } else {
        const response = fetchResponseAdapter(data);
        setErrorAlert(response.message);
        toggleSendProofModal();
      }
    },
    [callEndpoint, navigate, toggleSendProofModal, token],
  );

  if (!proofOutcomeDetail || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  const sendProofModal = (
    <AccountingSendProofEmailModal
      proofId={proofOutcomeId}
      isOpen={showSendProofModal}
      onToggle={toggleSendProofModal}
      onConfirmation={sendProofEmail}
      bodyText={`Enviaras el siguiente comprobante a ${proofOutcomeDetail.ownerInfo.email}`}
      confirmationText={'Entiendo, continuar'}
      abortText={'No, seguir revisando...'}
    />
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <AccountProofOutcomeCard
            proofOutcome={proofOutcomeDetail.proofOutcome}
          />
        </Col>
        <Col>
          <AccountingProofOutcomeOwnerCard
            owner={proofOutcomeDetail.ownerInfo}
            toggleSendProofModal={toggleSendProofModal}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" sm="12">
          <AccountingProofIncomeListByProofOutcome
            proofIncomes={proofOutcomeDetail.proofIncomes}
          />
        </Col>
      </Row>
      {sendProofModal}
    </AdminPage>
  );
};

export default AccountingProofOutcomeDetail;
