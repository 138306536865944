import React, { useState, useEffect, useCallback } from 'react';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, Row, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setGridFiltersAction, clearGridFiltersAction, setUniqueGridFiltersAction, setCurrentSearchAction } from '../../redux/features/gridSearchBar';
import { AppStore } from '../../redux/store';
import { FilterItemList } from '../../models/gridSearchBarModel';
import { InputType } from 'reactstrap/es/Input';

interface Props {
  filtersList: FilterItemList[]
}

const GridSearchBar = ({ filtersList = [] }: Props) => {

  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar)
  const [control, setControl] = useState<FilterItemList | null>(null)
  const [input, setInput] = useState<any>()
  const [error, setError] = useState<string>('')
  const [searchResults, setSearchResults] = useState<any[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    if (gridSearchBar.currentSearch.control && !control) {
      setControl(gridSearchBar.currentSearch.control)
    }
  }, [])

  const changeFilterHandler = (filterCode: string) => {
    if (filterCode === "empty-grid")
      setControl(null)

    const filter = filtersList.find((el) => el.value === filterCode)
    if (filter) {
      setControl(filter)
    }
  }

  const searchHandler = (value: any) => {
    if (!value || !control) {
      setError('Debes ingresar un valor a filtrar')
      return
    }
    const filters = { [control.apiParamName]: value };
    const {inputTypeChoices, ...serializableControl} = control
    dispatch(setCurrentSearchAction({
      serializableControl,
      value: value
    }))
    if (control.isUnique) {
      dispatch(setUniqueGridFiltersAction(filters))
    } else {
      dispatch(setGridFiltersAction(filters))
    }
    setSearchResults([])
  }

  const clearHandler = () => {
    dispatch(clearGridFiltersAction({}))
    setInput(undefined)
    setError('')
  }

  const updateSearchResults = async (currentControl: FilterItemList, searchTerm: string) => {
    if (currentControl.inputTypeChoices)
      setSearchResults(await currentControl.inputTypeChoices(searchTerm))
  }

  const selectForFilterTypes = (
    <>
      <Input
        onChange={(e) => (changeFilterHandler(e.target.value))}
        type="select">
        <option key={'empty-grid'}>Selecciona el campo a filtrar</option>
        {filtersList.map((filterItem) => (
          <option
            selected={
              gridSearchBar.currentSearch.control
              && gridSearchBar.currentSearch.control.value
              && gridSearchBar.currentSearch.control.value === filterItem.value
            }
            key={`${filterItem.value}-grid`}
            value={filterItem.value}>
            {filterItem.label}
          </option>
        ))}
      </Input>
    </>
  )

  const buildFilterInputComponent = (currentControl: FilterItemList) => {

    if (currentControl.inputType === "select")
      return (
        <Input
          onChange={(e) => (setInput(e.target.value))}
          type="select">
          <option key={'empty-grid-value'} >{currentControl.placeholder}</option>
          {currentControl.inputTypeValues?.map((filterItem) => (
            <option
              selected={
                gridSearchBar.currentSearch
                && gridSearchBar.currentSearch.value === filterItem.value
              }
              key={`${filterItem.value}-grid-value`} value={filterItem.value}>
              {filterItem.label}
            </option>
          ))}
        </Input>
      )
    else if (["text", "number"].includes(currentControl.inputType))
      return (
        <Input
          value={
            gridSearchBar.currentSearch.value ?
              gridSearchBar.currentSearch.value : undefined
          }
          placeholder={currentControl.placeholder}
          onChange={(e) => (setInput(e.target.value))}
          type={currentControl.inputType as InputType}>
        </Input>
      )
    else if (currentControl.inputType === "search")
      return (
        <Input
          placeholder={currentControl.placeholder}
          onChange={(e) => (setInput(e.target.value))}
          type={currentControl.inputType as InputType}>
        </Input>
      )
    else
      return (
        <></>
      )
  }

  const buildSearchResults = () => {

    return (
      <ListGroup>
        {searchResults.map((el: any) => (
          <ListGroupItem
            className="shadow--hover"
            key={el.value}
            onClick={() => searchHandler(el.value)}
          >
            {el.label}
          </ListGroupItem>
        ))}


      </ListGroup>
    )
  }

  return (
    <>
      <Row>
        <Col lg="6" md="8" sm="12">
          <FormGroup>
            <label className="form-control-label"
                   htmlFor="plan_id">Búsqueda</label>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="append">
                {selectForFilterTypes}
              </InputGroupAddon>
              {control && buildFilterInputComponent(control)}
              {
                !control && <Input
                  placeholder="Selecciona un filtro"
                  type="text"
                  disabled
                />
              }
              {(control && control.inputType !== "search") && (
                <InputGroupAddon addonType="append">
                  <Button outline color='primary' onClick={() => searchHandler(input)}>
                    Buscar
                  </Button>
                  <Button outline color='default' onClick={clearHandler}>
                    Limpiar
                  </Button>
                </InputGroupAddon>
              )}
              {(control && control.inputType === "search") && (
                <InputGroupAddon addonType="append">
                  <Button
                    outline
                    color="primary"
                    onClick={() => (updateSearchResults(control, input))}
                  >
                    Buscar
                  </Button>
                </InputGroupAddon>
              )}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      {(control && control.inputType === "search") && (
        <Row>
          <Col lg="6" md="8" sm="12">
            {buildSearchResults()}
          </Col>
        </Row>
      )}
    </>
  )
}

export default GridSearchBar
