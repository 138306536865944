import { CONTRACT_STATUS } from "../constants/contractConstants"
import { ContractDetailData } from '../models/contractModel';

export const getContractStatusAdapter = (status: string) => {
    return CONTRACT_STATUS.hasOwnProperty(status) ? CONTRACT_STATUS[status] : 'NA'
}

export const isContractSigned = (contract: ContractDetailData) => {
    const alreadySignedStatuses = [
      CONTRACT_STATUS.deposit_and_first_due,
      CONTRACT_STATUS.monthly_payment,
      CONTRACT_STATUS.recurrent_payment,
      CONTRACT_STATUS.finish_contract,
    ]
    return !alreadySignedStatuses.includes(contract.status)
      && contract.document_signed_url
}