import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useModalState from '../../../hooks/useModalState';
import { isProjectVisibleInMarketplace } from '../helpers/investorHelpers';
import { InvestorProjectDetail } from '../models/investors';
import { updateInvestorProjectEndpoint } from '../services/investorService';
import MarketplaceVisibleModal from './MarketplaceVisibleModal';

export type Props = {
  project: InvestorProjectDetail;
};
const InvestorProjectSummaryCard: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [showMarketplaceVisibleModal, toggleMarketplaceVisibleModal] =
    useModalState();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const isProjectVisible = isProjectVisibleInMarketplace(project.status);

  const changeMarketplaceVisible = async (
    projectId: string,
    projectStatus: string,
    parentId: string = '',
  ) => {
    const form = {
      status: isProjectVisibleInMarketplace(projectStatus)
        ? 'disabled'
        : 'active',
    };
    const { status, data } = await callEndpoint(
      updateInvestorProjectEndpoint(projectId, form, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
      toggleMarketplaceVisibleModal();
    }
  };
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              className="rounded"
              alt="..."
              width={175}
              height={100}
              src={
                project.cover &&
                !project.cover.includes('null') &&
                !project.cover.includes('undefined')
                  ? project.cover
                  : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
              }
            />
          </Col>
          <div className="col ml--2">
            <h4 className="mb-0">#{project.code}</h4>
            <p className="text-sm text-muted mb-0">{project.address}</p>
            <small className="mb-2">{project.developer.name}</small>
          </div>
          <Col className="col-auto">
            <Button
              onClick={() => toggleMarketplaceVisibleModal()}
              color={isProjectVisible ? 'warning' : 'success'}
              size="sm"
              type="button"
            >
              {isProjectVisible
                ? 'Ocultar de marketplace'
                : 'Publicar en marketplace'}
            </Button>
          </Col>
          <Col className="col-auto">
            <Button
              onClick={() =>
                navigate(`/investors/projects/${project.id}/edit/`)
              }
              color="primary"
              size="sm"
              type="button"
            >
              Editar proyecto
            </Button>
          </Col>
        </Row>
        <MarketplaceVisibleModal
          parentEntityId=""
          entityId={project.id}
          entityStatus={project.status}
          entityType="project"
          isOpen={showMarketplaceVisibleModal}
          onToggle={toggleMarketplaceVisibleModal}
          onConfirmation={changeMarketplaceVisible}
          bodyText={
            isProjectVisible
              ? 'Con esta acción ocultarás el proyecto, modelos y unidades de la marketplace'
              : 'Con esta acción publicarás el proyecto, modelos activos y unidades disponibles en la marketplace'
          }
          confirmationText={'Entiendo, continuar'}
          abortText={'Cancelar'}
        />
      </CardBody>
    </Card>
  );
};

export default InvestorProjectSummaryCard;
