export enum PROJECT_WORK_PROGRESS_ENUM {
  IN_PLANS = 'in_plans',
  IN_CONSTRUCTION = 'in_construction',
  FINALIZED = 'finalized',
}

export const PROJECT_WORK_PROGRESS = [
  {
    value: PROJECT_WORK_PROGRESS_ENUM.IN_PLANS,
    display_value: 'Preventa',
  },
  {
    value: PROJECT_WORK_PROGRESS_ENUM.IN_CONSTRUCTION,
    display_value: 'Construcción',
  },
  {
    value: PROJECT_WORK_PROGRESS_ENUM.FINALIZED,
    display_value: 'Terminado',
  },
];
