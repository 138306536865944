import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface Props {
  children: JSX.Element
}

const PrivateRoute = ({ children }: Props) => {
  const { isLoggedIn } = useAuth()
  const isLogged = isLoggedIn()
  return (
    isLogged
      ? children
      : <Navigate to={'/auth/login'} />
  )
}
export default PrivateRoute

