import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const getDashboardMetricsEndpoint = (
  token: string,
  role: string = 'proper',
) => {
  let path: string;
  if (role === 'proper') path = `/ts/v1/admin/proper/dashboard`;
  else path = `/ts/v1/admin/dashboard`;
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}${path}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const getDashboardVisitsProperEndpoint = (
  queryParams: string = '',
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/dashboard/visits-by-proper${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
export const getDashboardVisitsEndpoint = (
  queryParams: string = '',
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proper/visits${queryParams}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDashboardOffersEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/proper/offers?take=50`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateVisitStateEndpoint = (
  visitId: string,
  visitState: boolean,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/proper/visits/${visitId}`,
        data: JSON.stringify({
          toggle_on: visitState,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDashboardResumeVisitsByProperEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/dashboard/resume-visits`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
