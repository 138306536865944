import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { UpdatePropertySalesStateForm } from '../models/propertyModel';
import { PROPERTY_SALE_STATUS_VALUES } from '../constants/propertyConstants';

export interface Props {
  property_id: string;
  isOpen: boolean;
  marketplace_type: 'rent' | 'sale';
  onToggle: () => void;
  onConfirmation: (
    property_id: string,
    form: UpdatePropertySalesStateForm,
  ) => void;
  warningText: string;
  confirmationText: string;
  abortText: string;
}
const ActivatePropertyModal: React.FC<Props> = ({
  marketplace_type,
  property_id,
  isOpen,
  onToggle,
  onConfirmation,
  warningText,
  confirmationText,
  abortText,
}) => {
  const confirmationModal = (
    <Modal
      className="modal-dialog-centered modal-success"
      contentClassName="bg-gradient-success"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Tu atención es requerida
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">
            Activar propiedad en marketplace{' '}
            {marketplace_type === 'rent' ? 'Rentas' : 'Ventas'}
          </h4>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          onClick={() =>
            onConfirmation(
              property_id,
              marketplace_type === 'sale'
                ? { status: PROPERTY_SALE_STATUS_VALUES.PUBLISHED }
                : { status: 'published' },
            )
          }
        >
          {confirmationText}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );

  return <>{confirmationModal}</>;
};

export default ActivatePropertyModal;
