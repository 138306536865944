import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageModelUnitForm from './components/ManageModelUnitForm';
import { ModelUnitForm } from './models/investors';
import { createModelUnitEndpoint } from './services/investorService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const ModelUnitCreate: React.FC = () => {
  const {id: projectModelId = ""} = useParams()
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const createModelUnit = useCallback(async (form: ModelUnitForm, errorRepository: Function) => {
    const {
      status, data
    } = await callEndpoint(createModelUnitEndpoint(form, projectModelId, token));

    const response = fetchResponseAdapter(data);

    if (status === 201)
      navigate(`/investors/models/${projectModelId}/model-units`)
    else
      errorRepository(response.message);
  }, []);

  const pageName = "Crear una unidad";
  const parentPageName = "Preventa";

  return (
    <AdminPage
      name={pageName}
      parentName={parentPageName}
    >
      <ManageModelUnitForm handleForm={createModelUnit} />
    </AdminPage>
  );
};

export default ModelUnitCreate;