import React from 'react';
import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { Badge, Button } from 'reactstrap';
import { ProofOutcomeListItemOwnerInfo } from '../models/proofIncomeModel';

export interface Props {
  owner: ProofOutcomeListItemOwnerInfo;
  toggleSendProofModal: () => void;
}
const AccountingProofOutcomeOwnerCard: React.FC<Props> = ({
  owner,
  toggleSendProofModal,
}) => {
  const fieldMapper: DisplayItem[] = [
    {
      title: 'Nombre',
      value: `${owner.name} ${owner.lastname}`.trim(),
    },
    {
      title: 'Identificación',
      value: owner.document_number,
    },
    {
      title: 'Email',
      value: owner.email ?? 'No ingresado',
    },
    {
      title: 'Último comprobante enviado',
      value:
        owner.last_email_proof_log_at !== ''
          ? owner.last_email_proof_log_at
          : 'Sin registro',
    },
  ];

  const footer = owner.email ? (
    <Button color="primary" onClick={() => toggleSendProofModal()}>
      Enviar comprobante
    </Button>
  ) : (
    <Badge className="badge-lg" color="warning">
      Es necesario configurar el email para enviar el comprobante
    </Badge>
  );

  return (
    <AdminListGroup
      title={'Información del receptor del egreso'}
      items={fieldMapper}
      footer={footer}
    />
  );
};

export default AccountingProofOutcomeOwnerCard;
