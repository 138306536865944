import React from 'react';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { PropersAssignedVisits } from '../models/dashboardModels';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_AVATAR } from '../../../constants/profileConstants';
import VisitMarketplaceTypeBadge from '../../../components/VisitMarketplaceTypeBadge';

export interface Props {
  visits: PropersAssignedVisits[];
  footer?: any;
}

const DashboardVisitsManagerTable: React.FC<Props> = ({ visits, footer }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card style={{ overflowY: 'scroll', maxHeight: '450px' }}>
        <CardBody>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Propiedad</th>
                <th scope="col">Código</th>
                <th scope="col">Fecha de visita</th>
                <th scope="col">Tipo de lead</th>
                <th scope="col">Inquilino</th>
                <th scope="col">Proper</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {visits.map((visit) => (
                <tr key={visit.id}>
                  <th scope="row">
                    <img
                      alt="..."
                      width={175}
                      height={100}
                      src={
                        !visit.propertyInfo.cover ||
                        visit.propertyInfo.cover.endsWith('null')
                          ? 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                          : visit.propertyInfo.cover
                      }
                    />
                  </th>
                  <td>{visit.propertyInfo.code}</td>
                  <td>{visit.visitInfo.visit_date}</td>
                  <td>
                    <VisitMarketplaceTypeBadge
                      marketplace_type={visit.visitInfo.marketplace_type}
                    />
                  </td>
                  <td>{visit.visitInfo.tenant_name}</td>
                  <td>
                    <img
                      className="avatar avatar-sm rounded-circle"
                      src={
                        visit.properInfo && visit.properInfo.avatar
                          ? visit.properInfo.avatar
                          : DEFAULT_AVATAR
                      }
                    />
                    &nbsp;
                    {visit.properInfo.name}
                  </td>
                  <td>
                    <>
                      <Button
                        onClick={() =>
                          navigate(
                            `/properties/admin/${visit.propertyInfo.id}/admin-visit`,
                          )
                        }
                        className="btn btn-icon btn-default"
                        size="sm"
                        type="button"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-calendar-grid-58"></i>
                        </span>
                      </Button>
                    </>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {footer && <div>{footer}</div>}
    </>
  );
};

export default DashboardVisitsManagerTable;
