export interface ImageSize {
    width: number;
    height: number;
  }
  
  
export const getImageSize = (url: string): Promise<ImageSize> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
  
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
  
      img.onerror = (error) => {
        reject(error);
      };
    });
  };