import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import usePropertySalesPrices from '../../hooks/usePropertySalesPrices';
import TakeDownPropertyModal from '../TakeDownPropertyModal';
import useModalState from '../../../../hooks/useModalState';
import { PropertySalesProps } from '../../models/propertyModel';
import { getPropertySaleStatus } from '../../adapters/propertyAdapter';
import ActivatePropertyModal from '../ActivatePropertyModal';
import { PROPERTY_SALE_STATUS_VALUES } from '../../constants/propertyConstants';

export const PropertySalesPrices = (props: PropertySalesProps) => {
  const [takeDownPropertyModal, toggleTakeDownPropertyModal] = useModalState();
  const [activatePropertyModal, toggleActivatePropertyModal] = useModalState();
  const {
    // form
    handleSubmit,
    control,
    register,
    errors,
    // loading
    isLoading,
    updateMarketplaceStatus,
    updateMarketplacePrices,
    markPropertyAsSold,
  } = usePropertySalesPrices(props);

  return (
    <Card>
      <CardHeader>
        <h6 className="heading-small text-muted">
          Configurar precios y visibilidad
        </h6>
      </CardHeader>

      <Form role="form" onSubmit={handleSubmit(updateMarketplacePrices)}>
        <CardBody>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="has_scriptures">
                  Estado en venta
                </label>
              </Col>
              <Col md={6}>
                <Badge color="default">
                  {getPropertySaleStatus(props.property_sale_state)}
                </Badge>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="has_scriptures">
                  Precio de venta
                </label>
              </Col>
              <Col md={6}>
                <Controller
                  name="sale_price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.sale_price?.message && 'is-invalid'}
                      {...field}
                      id="sale_price"
                      type="number"
                      min={0}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.sale_price?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="has_scriptures">
                  Precio de mínimo de venta
                </label>
              </Col>
              <Col md={6}>
                <Controller
                  name="sale_min_price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.sale_min_price?.message && 'is-invalid'}
                      {...field}
                      id="sale_min_price"
                      type="number"
                      min={0}
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.sale_min_price?.message}
                </h5>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button color={'primary'} disabled={isLoading}>
            Actualizar precios
          </Button>
          {props.property_sale_state ===
          PROPERTY_SALE_STATUS_VALUES.PUBLISHED ? (
            <Button onClick={toggleTakeDownPropertyModal} color={'danger'}>
              Ocultar en marketplace
            </Button>
          ) : (
            <Button onClick={toggleActivatePropertyModal} color={'success'}>
              Publicar en marketplace
            </Button>
          )}
          {(props.property_sale_state === PROPERTY_SALE_STATUS_VALUES.ON_TRANSFER_OWNERSHIP) && (
            <Button onClick={() => {markPropertyAsSold(props.property_id)}} color={'danger'}>
              Marcar como vendida
            </Button>
          )}
        </CardFooter>
      </Form>
      <TakeDownPropertyModal
        marketplace_type="sale"
        property_id={props.property_id}
        isOpen={takeDownPropertyModal}
        onToggle={toggleTakeDownPropertyModal}
        onConfirmation={updateMarketplaceStatus}
        warningText={
          '¿Estás seguro de quitar la propiedad del marketplace de ventas?'
        }
        confirmationText={'Si, quitar del marketplace ventas'}
        abortText={'No, continuar revisando...'}
      />
      <ActivatePropertyModal
        marketplace_type="sale"
        property_id={props.property_id}
        isOpen={activatePropertyModal}
        onToggle={toggleActivatePropertyModal}
        onConfirmation={updateMarketplaceStatus}
        warningText={
          '¿Estás seguro de activar la propiedad en marketplace de ventas?'
        }
        confirmationText={'Si, activar del marketplace ventas'}
        abortText={'No, continuar revisando...'}
      />
    </Card>
  );
};
