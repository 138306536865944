import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { fetchResponseAdapter } from '../../adapters/fetchAdapter'
import TimelineHeader from '../../components/headers/TimelineHeader'
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad'
import DetailLabelText from './components/DetailLabelText'
import { getAdminPropertyDetailEndpoint } from './services/propertyService'
import useAuth from '../../hooks/useAuth';

const PropertyDetail = () => {

  const { id = '' } = useParams()
  const navigate = useNavigate()
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [detailData, setDetailData] = useState<any>()
  const { getAuthToken } = useAuth()
  const token = getAuthToken()

  useEffect(() => {
    const loadProperties = async () => {
      const {
        status, data
      } = await callEndpoint(getAdminPropertyDetailEndpoint(id, token))
      if (status === 200) {
        const response = fetchResponseAdapter(data)
        setDetailData(response.data)
      }
    }
    loadProperties()
      .catch(e => console.error(e))
  }, [])

  return (
    <>
      <TimelineHeader name="Lista de propiedades" parentName="Propiedades" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col className="col-auto">
                <h3 className="mb-0">Detalle de propiedad {detailData?.code}</h3>
              </Col>
              <div className="col ml--2"></div>
              <Col className="col-auto">
                <a
                  style={{ cursor: 'pointer' }}
                  className="text-green"
                  onClick={() => navigate(`/properties/update/${id}`)}
                >
                  <span className="btn-inner--icon pr-2">
                    <i className="ni ni-ruler-pencil" />
                  </span>
                  <small>Editar propiedad</small>
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <DetailLabelText
              label='Tipo de inmueble' text={detailData?.type} />
            <DetailLabelText
              label='Mensualidad de alquiler' text={detailData?.monthly_price} />
            <DetailLabelText
              label='Cantidad de habitaciones' text={detailData?.rooms} />
            <DetailLabelText
              label='Cantidad de baños' text={detailData?.bathrooms} />
            <DetailLabelText
              label='Estacionamientos' text={detailData?.parking} />
            <DetailLabelText
              label='Desde cuando puede ser alquilado' text={detailData?.available_at} />
            <DetailLabelText
              label='País' text={detailData?.country} />
            <DetailLabelText
              label='Estado o provincia' text={detailData?.state} />
            <DetailLabelText
              label='Ciudad' text={detailData?.city} />
            <DetailLabelText
              label='Calle' text={detailData?.street} />
            <DetailLabelText
              label='Número de casa/apto' text={detailData?.property_number} />
            <DetailLabelText
              label='Descipción' text={detailData?.description} />
            <DetailLabelText
              label='Amenidades'
              text={
                detailData && detailData.amenities && detailData.amenities.length > 0
                  ? detailData.amenities.join(', ')
                  : ''
              } />
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default PropertyDetail
