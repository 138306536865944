import {
  VISIT_STATUS,
  VISIT_STATUS_NAMES,
} from '../../../constants/visitsConstants';
import {
  CalendarVisitInvestorEvents,
  ProjectVisitList,
} from '../models/visitsInvestorModel';

export const pickCalendarEventColor = (status: string) => {
  switch (status) {
    case VISIT_STATUS.DELETED:
      return 'bg-red text-white';
    case VISIT_STATUS.DONE:
      return 'bg-success text-white';
    default:
      return 'bg-secondary';
  }
};

export const buildCalendarVisitInvestorEvent = (
  projectVisit: ProjectVisitList,
  dateTime: any,
) => {
  const { year, month, day, hours, minutes } = dateTime;
  return {
    id: projectVisit.id,
    title: `Asesoría ${projectVisit.landlordInfo.name}`,
    start: new Date(year, month - 1, day, hours, minutes),
    allDay: false,
    className: pickCalendarEventColor(projectVisit.status),
    landlordInfo: projectVisit.landlordInfo,
    status: projectVisit.status,
  };
};

export const buildCalendarVisitInvestorData = (visits: ProjectVisitList[]) => {
  const visitsAdapted: CalendarVisitInvestorEvents[] = visits.map((el) => {
    const { year, month, day, hours, minutes } = el.visitDateTime;
    return buildCalendarVisitInvestorEvent(el, {
      year,
      month,
      day,
      hours,
      minutes,
    });
  });
  return visitsAdapted;
};

export const getVisitStatusAdapter = (status: string) => {
  return VISIT_STATUS_NAMES.hasOwnProperty(status)
    ? VISIT_STATUS_NAMES[status]
    : 'NA';
};
