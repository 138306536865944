import { Button } from 'reactstrap';
import TableModal from '../modals/TableModal';
import useModalState from '../../hooks/useModalState';

interface CheckoutQuotaPreviewProps {
  title: string;
  cardHeader: string[];
  rows: rowItem[];
}

interface rowItem {
  specialRow?: boolean;
  coloredRow?: string;
  rows: string[];
}

const CheckoutQuotaPreview = ({
  cardHeader,
  rows,
  title,
}: CheckoutQuotaPreviewProps) => {
  const [showModal, toggleShowModal] = useModalState();

  return (
    <>
      <Button color="info" onClick={() => toggleShowModal()}>
        <i className="ni ni-money-coins" />
        <span>Preview de pago</span>
      </Button>
      <TableModal
        title={title}
        cardHeader={cardHeader}
        rows={rows}
        isOpen={showModal}
        onToggle={toggleShowModal}
        hover={true}
        striped={true}
      />
    </>
  );
};

export default CheckoutQuotaPreview;
