import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { LatePaymentPenalty } from '../models/contractModel';

interface Props {
  paymentPenalty: LatePaymentPenalty[];
}

const ContractPaymentPenalty: React.FC<Props> = ({ paymentPenalty }) => {
  const itemList: DisplayItem[] = [];

  if (paymentPenalty.length > 0) {
    paymentPenalty.forEach((children) => {
      itemList.push({
        title: `Penalidad del ${children.from_day_of_month} al ${children.to_day_of_month} de cada mes`,
        value: `${children.percentage_penalty}%`,
      });
    });
  }

  return <AdminListGroup title={'Penalidades de pago'} items={itemList} />;
};

export default ContractPaymentPenalty;
