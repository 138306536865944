import AdminPage from '../../components/admin/AdminPage';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { createProofResumeEndpoint, getProofResumeEndpoint } from './services/accountingService';
import useAuth from '../../hooks/useAuth';
import { ProofIncomeResume, ProofIncomeForm } from './models/proofIncomeModel';
import useAdminEntity from '../../hooks/useAdminEntity';
import AccountingProofIncomeForm from './components/AccountingProofIncomeForm';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

interface Props {
  incomeProduct: string;
}

const AccountingProofIncomeCreate = ({ incomeProduct }: Props) => {
  const navigate = useNavigate()
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [ errorAlert, setErrorAlert ] = useState<string | null>(null);
  const pageName = 'Crear comprobante de ingreso';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  const {
    transactionDetailId = ""
  } = useParams()
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getProofResumeDetail = useCallback(
    () => getProofResumeEndpoint(transactionDetailId, token),
    [transactionDetailId, token]
  );
  const [
    proofResume
  ] = useAdminEntity<ProofIncomeResume>(getProofResumeDetail, setErrorAlert);

  const handleCreateProofIncome = useCallback(async (form: ProofIncomeForm) => {
    const {
      status, data
    } = await callEndpoint(createProofResumeEndpoint(form, token))
    const response = fetchResponseAdapter(data)
    if (status === 201) {
      navigate(`/accounting/proof-income/${response.data.id}`);
    } else if(status === 409) {
      setErrorAlert('Comprobante de ingreso generado previamente');
    } else {
      setErrorAlert('No fue posible generar comprobante de ingreso');
    }
  }, [])

  if (!proofResume || isLoading) {
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );
  }

  return <AdminPage
    name={pageName}
    parentName={pageParentName}
    globalAlertOptions={globalAlertOptions}
  >
    <AccountingProofIncomeForm
      incomeProduct={incomeProduct}
      proofResume={proofResume}
      handleForm={handleCreateProofIncome}
    />
  </AdminPage>;
};

export default AccountingProofIncomeCreate;
