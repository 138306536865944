import axios from 'axios';
import {
  BrokerRegistrationForm,
  BrokerUpdateForm,
} from '../models/brokerModel';

const loadAbort = () => {
  return new AbortController();
};

export const createBrokerInfoEndpoint = (
  form: BrokerRegistrationForm,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/user/v1/admin/broker`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateBrokerInfoEndpoint = (
  brokerId: string,
  form: BrokerUpdateForm,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/broker/${brokerId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const assignBrokerToPropertyEndpoint = (
  propertyId: string,
  brokerId: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/broker/assign`,
        data: JSON.stringify({ broker_id: brokerId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getBrokerInfoEndpoint = (brokerId: string, token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/broker/${brokerId}/detail`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const unassignBrokerEndpoint = (
  propertyId: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/broker/unassign`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getBrokerListInfoEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/broker/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
