import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InvestorProjectDetail, InvestorProjectVideoLinksFormPayload } from '../models/investors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Button, Col, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';


export type Props = {
  handleForm: (form: InvestorProjectVideoLinksFormPayload, errorRepository: Function) => void;
  project: InvestorProjectDetail
};
const InvestorProjectVideoLinksForm: React.FC<Props> = ({handleForm, project}) => {
  const { isLoading } = useCallApiAndLoad();
  const [error, setError] = useState(null);

  const {
    handleSubmit, formState: { errors }, control, setValue
  } = useForm<InvestorProjectVideoLinksFormPayload>({
    resolver: yupResolver(
      yup.object({
        link_tour360: yup.string(),
        link_video: yup.string(),
      }).required()
    )
  });

  const internalHandleForm = useCallback(
    (form: InvestorProjectVideoLinksFormPayload) => handleForm(form, setError),
    [handleForm]
  );

  return (
    <Form role="form" onSubmit={handleSubmit(internalHandleForm)}>
      <Row>
        <Col md="6">

          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}

              <FormGroup>
                <label className="form-control-label" htmlFor="link_tour360">Link tour 360</label>
                <Controller
                  name="link_tour360"
                  control={control}
                  defaultValue={project?.link_tour360 || ""}
                  key={`${project?.link_tour360}-link_tour360-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.link_tour360?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.link_tour360?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="link_video">Link video</label>
                <Controller
                  name="link_video"
                  control={control}
                  defaultValue={project?.link_video || ""}
                  key={`${project?.link_video}-link_video-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.link_video?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.link_video?.message}</h5>
              </FormGroup>
            </Col>
          </Row>


        </Col>
      </Row>

      <Button color="primary" disabled={isLoading}>
        Actualizar
      </Button>
    </Form>
  );
};

export default InvestorProjectVideoLinksForm;