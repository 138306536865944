import { createSlice } from '@reduxjs/toolkit';
import { AuthUser } from '../../models/authUserModel';

const initialState: AuthUser = {
  token: '',
  rft: '',
  name: '',
  avatar: '',
  realm: '',
  assigned_countries: [],
  assigned_roles: [],
  assigned_permissions: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, action) => {
      return {
        ...action.payload,
      };
    },
    logoutAction: (state, action) => initialState,
    changeCountryAction: (state, action) => {
      const { current_country } = action.payload;
      return {
        ...state,
        ...current_country,
      };
    },
  },
});

// provide actions
export const { loginAction, logoutAction, changeCountryAction } =
  authSlice.actions;
// provide reducer
export default authSlice.reducer;
