import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageInvestorProjectForm from './components/ManageInvestorProjectForm';
import { InvestorProjectForm } from './models/investors';
import { createInvestorProjectEndpoint } from './services/investorService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const InvestorProjectCreate: React.FC = () => {
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const createInvestorProject = useCallback(async (form: InvestorProjectForm, errorRepository: Function) => {
    const {
      status, data
    } = await callEndpoint(createInvestorProjectEndpoint(form, token));

    const response = fetchResponseAdapter(data);

    if (status === 201)
      navigate("/investors/projects")
    else
      errorRepository(response.message);
  }, [callEndpoint, navigate, token]);

  const pageName = "Crear proyecto preventa"
  const pageParentName = "Preventa"
  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <ManageInvestorProjectForm handleForm={createInvestorProject} />
    </AdminPage>
  );
};

export default InvestorProjectCreate;