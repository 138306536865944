import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { HomeSliderForm, HomeSliderItem } from '../models/homeSliderModel';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  createHomeSliderItemsEndpoint,
  getHomeSliderItemsEndpoint,
  updateHomeSliderItemsEndpoint,
} from '../services/homeSliderService';
import { useNavigate } from 'react-router-dom';

interface Props {
  setErrorAlert: Function;
}
const useHomeSlider = ({ setErrorAlert }: Props) => {
  const [isCheckedHasAction, setIsCheckedHasAction] = useState<boolean>(false);
  // const [isUpdateSelected, setIsUpdateSelected] = useState<boolean>(false);
  const [homeSliderItemSelected, setHomeSliderItemSelected] =
    useState<HomeSliderItem | null>(null);
  const [homeSliderItems, setHomeSliderItems] = useState<HomeSliderItem[]>([]);
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint, isLoading } = useCallApiAndLoad();

  const {
    reset,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
  } = useForm<HomeSliderForm>({
    resolver: yupResolver(
      yup
        .object({
          text: yup.string().required('Texto principal es requerido'),
          sub_text: yup.string().required('Texto secundario es requerido'),
          has_action: yup.boolean(),
          ...(isCheckedHasAction
            ? {
                action_url: yup
                  .string()
                  .required('Acción es requerida en formato de url'),
              }
            : {
                action_url: yup.string(),
              }),
        })
        .required(),
    ),
  });

  const selectHomeSliderItem = (item: HomeSliderItem) => {
    setValue('text', item.text);
    setValue('sub_text', item.sub_text);
    setValue('has_action', item.has_action);
    setValue('action_url', item.action_url);
    setHomeSliderItemSelected(item);
  };

  const clearHomeSliderItem = () => {
    reset();
    setHomeSliderItemSelected(null);
  };

  const loadHomeSliderItems = async () => {
    const { status, data } = await callEndpoint(
      getHomeSliderItemsEndpoint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setHomeSliderItems(response.data);
    }
  };

  const handleAction = async (form: HomeSliderForm) => {
    if (homeSliderItemSelected?.id) {
      handleUpdateSlideItem(homeSliderItemSelected?.id, form);
      reset()
    } else {
      return handleSaveSlideItem(form);
    }
  };

  const handleSaveSlideItem = async (form: HomeSliderForm) => {
    const { status, data } = await callEndpoint(
      createHomeSliderItemsEndpoint(form, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      // navigate(0);
      loadHomeSliderItems().catch((e) => console.error(e));
      clearHomeSliderItem()
    } else {
      setErrorAlert(response.message);
    }
  };

  const handleUpdateSlideItem = async (id: string, form: HomeSliderForm) => {
    const { status, data } = await callEndpoint(
      updateHomeSliderItemsEndpoint(id, form, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      // navigate(0);
      loadHomeSliderItems().catch((e) => console.error(e));
    } else {
      setErrorAlert(response.message);
    }
  };

  useEffect(() => {
    loadHomeSliderItems().catch((e) => console.error(e));
  }, []);

  return {
    register,
    control,
    errors,
    homeSliderItems,
    isLoading,
    handleSubmit,
    handleAction,
    isCheckedHasAction,
    setIsCheckedHasAction,
    homeSliderItemSelected,
    selectHomeSliderItem,
    clearHomeSliderItem,
  };
};

export default useHomeSlider;
