import React from 'react'
import { Button, Modal } from 'reactstrap';

interface Props {
  parentEntityId: string;
  entityId: string;
  entityStatus: string;
  entityType: string;
  isOpen: boolean;
  onToggle: () => void;
  onConfirmation: (
    entityId: string, newEntityStatus: string, parentId: string
  ) => void;
  bodyText: string;
  confirmationText: string;
  abortText: string;
}

const MarketplaceVisibleModal = ({
  parentEntityId, entityId, entityStatus, isOpen, onToggle, onConfirmation, bodyText, confirmationText, abortText
}: Props) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Modificar visibilidad en Marketplace
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{bodyText}</p>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          onClick={() => onConfirmation(entityId, entityStatus, parentEntityId)}
        >
          {confirmationText}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );
}

export default MarketplaceVisibleModal