import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap"
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad"
import { uploadPhotosEndpoint } from "../services/propertyService"
import loading from "../../../assets/gif/loading.gif";
import useAuth from "../../../hooks/useAuth"

interface Props {
  property_id: string;
}

// "username": "edilson@internal.com",
// "password": "4nmL1dr9LHJV",

const UploadGallery = ({ property_id }: Props) => {
  const [filesForPreview, setFilesForPreview] = useState<Array<any>>([]);
  const [error, setError] = useState<string>('');
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const onDrop = useCallback((acceptedFiles: any) => {
    const acceptedFilesForPreview = acceptedFiles.map(
      (file: any) => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }
    ));
    setFilesForPreview((currentFiles) => [...currentFiles, ...acceptedFilesForPreview]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop,
  })

  const removeFile = useCallback((file: any) => {
    setFilesForPreview(
      (currentFilesForPreview) => {
        const newFilesForPreview = [...currentFilesForPreview];
        newFilesForPreview.splice(newFilesForPreview.indexOf(file), 1);
        return newFilesForPreview;
      }
    )
  }, []);

  const removeAll = useCallback(() => {
    setFilesForPreview([])
  }, []);

  const handleUploadGallery = useCallback(async () => {
    if (!filesForPreview || !filesForPreview.length) {
      setError('Debes seleccionar una imagen para subir')
      return;
    }

    setError('');
    const requestsForUpload = filesForPreview.map((imgFile) => {
      const formData = new FormData();
      formData.append('gallery', imgFile);
      return callEndpoint(uploadPhotosEndpoint(formData, property_id, token));
    });

    // formData.append('cover', imageAccepted[0])
    // const token = getAuthToken(user)
    const responses = await Promise.all(requestsForUpload);
    const responseStatuses = responses.map(response => response.status === 200);
    if (responseStatuses.indexOf(false) !== -1) {
      setError('Lo sentimos, por el momento no es posible completar la acción');
    } 
    navigate(0); //make a reload
  }, [callEndpoint, navigate, property_id, filesForPreview]);

  return (
    <Card>
      <CardHeader>
        <h4>Subir Galería</h4>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row>
              <Col md="12">
                <div {...getRootProps({ className: "px-4 py-4 text-center dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Arrastra tus fotos o Selecciona</p>
                  <span className='text-sm'>Formatos soportados: JPEG y PNG, máximo de 1MB por archivo.</span>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        {
          filesForPreview && filesForPreview.length > 0 && filesForPreview.map(file => (
            <ListGroup className="list my--3" flush key={file.path}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      className="rounded"
                      alt="..."
                      width={175}
                      height={100}
                      src={file.preview}
                      onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                  </Col>
                  <div className="col ml--2"></div>
                  <Col className="col-auto">
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        removeFile(file)
                      }}
                      color="danger" size="sm" type="button">
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          ))
        }
      </CardBody>
      <CardFooter>
        <Row>
          {filesForPreview.length > 0 && <>
            <Col className="col-auto" >
              <Button
                color="danger"
                size="md"
                type="button" onClick={removeAll}>Remover preview
              </Button>
            </Col>
          </>
          }
          <div className="col ml--2"></div>
          {isLoading && <img src={loading} alt="" width={45} />}
          <Col className="col-auto">
            <Button
              onClick={handleUploadGallery}
              className=""
              color="success"
              size="md"
              type="button"
              disabled={isLoading}
            >
              Subir a galería
            </Button>
          </Col>
        </Row>
      </CardFooter>
      <CardFooter>
      </CardFooter>
    </Card>


  )
}

export default UploadGallery
