import React, { useCallback, useState } from 'react';
import { ModelUnitChangeStatus, ModelUnitDetail } from '../models/investors';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import MarketplaceVisibleModal from './MarketplaceVisibleModal';
import { isUnitValidForChangeMarketplace, isUnitVisibleInMarketplace } from '../helpers/investorHelpers';
import useModalState from '../../../hooks/useModalState';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { moveModelUnitOnDeed, updateModelUnitStatusEndpoint } from '../services/investorService';
import useAuth from '../../../hooks/useAuth';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

export type Props = {
  modelUnit: ModelUnitDetail;
  projectModelId: string;
};
const ModelUnitSummaryCard: React.FC<Props> = ({ modelUnit, projectModelId }) => {
  const navigate = useNavigate();
  const isUnitVisible = isUnitVisibleInMarketplace(modelUnit.status)
  const [showMarketplaceVisibleModal, toggleMarketplaceVisibleModal] = useModalState();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const getStatusPermissions = (statusToFind: string) => {
    const statusMapper = [
      {
        status: "created",
        icon: "fa-check",
        displayText: "Disponible",
        color: "success",
        canCreatePaymentContract: true,
        canUpdateFinanceDetails: true,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
      {
        status: "available",
        icon: "fa-check",
        displayText: "Disponible",
        color: "success",
        canCreatePaymentContract: true,
        canUpdateFinanceDetails: true,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
      {
        status: "disabled",
        icon: "fa-xmark",
        displayText: "Desactivada",
        color: "danger",
        canCreatePaymentContract: false,
        canUpdateFinanceDetails: false,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
      {
        status: "taken",
        icon: "fa-house-flag",
        displayText: "Tomada",
        color: "primary",
        canCreatePaymentContract: false,
        canUpdateFinanceDetails: false,
        canUploadSigningPromiseDocs: true,
        canMoveToOnDeed: false,
      },
      {
        status: "pre_booked",
        icon: "fa-house-flag",
        displayText: "Pre asignada",
        color: "info",
        canCreatePaymentContract: true,
        canUpdateFinanceDetails: true,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
      {
        status: "assigned",
        icon: "fa-house",
        displayText: "Asignada",
        color: "warning",
        canCreatePaymentContract: false,
        canUpdateFinanceDetails: false,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
      {
        status: "signing_promise",
        icon: "fa-house-flag",
        displayText: "Firma de promesa",
        color: "warning",
        canCreatePaymentContract: false,
        canUpdateFinanceDetails: false,
        canUploadSigningPromiseDocs: true,
        canMoveToOnDeed: true,
      },
      {
        status: "on_deed",
        icon: "fa-house-flag",
        displayText: "En escrituración",
        color: "primary",
        canCreatePaymentContract: false,
        canUpdateFinanceDetails: false,
        canUploadSigningPromiseDocs: false,
        canMoveToOnDeed: false,
      },
    ];

    return statusMapper.find((modelUnitStatus) => modelUnitStatus.status === statusToFind);
  };

  const statusMapped = getStatusPermissions(modelUnit.status)

  const changeMarketplaceVisible = async(unitId: string, unitStatus: string, modelId: string = '') => {
    if(!isUnitValidForChangeMarketplace(unitStatus)){
      return
    }
    const form: ModelUnitChangeStatus = { 
      status: isUnitVisibleInMarketplace(unitStatus)
        ? 'disabled'
        : 'available'
    }
    const {status, data} = await callEndpoint(
      updateModelUnitStatusEndpoint(modelId, unitId, form, token)
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleMarketplaceVisibleModal();
    }
  }

  const changeModelUnitToOnDeed = useCallback(async (modelId: string, unitId: string) => {
    const {status} = await callEndpoint(
      moveModelUnitOnDeed(modelId, unitId, token)
    );
    if (status === 200) {
      navigate(0);
    }
  }, [token, callEndpoint, navigate]);

  return (
    <Card>
      <CardHeader>
        <Badge className="badge-lg my-2" color={statusMapped?.color} pill>
          <i className={`fa fa-duotone ${statusMapped?.icon}`}></i> {statusMapped?.displayText}
        </Badge>
      </CardHeader>
      <CardBody>
        <CardImg src={modelUnit.gallery_blueprints[0] || ""} top></CardImg>
        <CardTitle>{modelUnit.name}</CardTitle>
        <CardTitle className="text-muted">
          Propietario {modelUnit.investor_name ? modelUnit.investor_name : "No asignado"}
        </CardTitle>
        <CardText>
          <small className="text-muted">{modelUnit.base_price}</small>
        </CardText>

        <CardText>
          <Badge className="badge-lg my-2" color="primary" pill>
            <i className="fa fa-duotone fa-square"></i> {modelUnit.area} mts2
          </Badge>
          <Badge className="badge-lg my-2" color="primary" pill>
            <i className="fa fa-duotone fa-square"></i> {modelUnit.square_rods} v2
          </Badge>
          <Badge className="badge-lg my-2" color="primary" pill>
            <i className="fa fa-duotone fa-toilet"></i> {modelUnit.bathrooms} baños
          </Badge>
          <Badge className="badge-lg my-2" color="primary" pill>
            <i className="fa fa-duotone fa-bed"></i> {modelUnit.bedrooms} cuartos
          </Badge>
          <Badge className="badge-lg my-2" color="primary" pill>
            <i className="fa fa-duotone fa-building"></i> {modelUnit.level} piso
          </Badge>
        </CardText>
      </CardBody>
      <CardFooter>
        <Row>
          <Col lg="12" className='pb-2'>
            <Button block color='secondary' size="sm" onClick={() => navigate(`/investors/models/${projectModelId}/model-units/${modelUnit.id}/upload-images`)}>
              <i className={"fa fa-duotone fa-image"} /> Galería de imágenes
            </Button>
          </Col>
          <Col lg="12" className='pb-2'>
            <Button block color='secondary' size="sm" onClick={
              () => navigate(`/investors/models/${projectModelId}/model-units/${modelUnit.id}/edit`)}>
              <i className={"ni ni-ruler-pencil"} /> Editar unidad
            </Button>
          </Col>
          {statusMapped?.canUpdateFinanceDetails && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => navigate(`/investors/models/${projectModelId}/model-units/${modelUnit.id}/finances`)}>
                <i className={"fa fa-duotone fa-coins"} /> Datos financieros
              </Button>
            </Col>
          )}
          {statusMapped?.canCreatePaymentContract && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => navigate(`/investors/models/${projectModelId}/model-units/${modelUnit.id}/payment-contract`)}>
                <i className={"fa fa-duotone fa-file-contract"} /> Generar mandato de pago
              </Button>
            </Col>
          )}
          {statusMapped?.canUploadSigningPromiseDocs && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => navigate(`/investors/models/${projectModelId}/model-units/${modelUnit.id}/upload-signing-promise-docs`)}>
                <i className={"fa fa-duotone fa-file-contract"} /> Subir promesa de pago
              </Button>
            </Col>
          )}
          {(modelUnit.signing_promise_doc_url) && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" href={modelUnit.signing_promise_doc_url} target={'_blank'}>
                <i className={"fa fa-duotone fa-file-contract"} /> { `Ver promesa de pago` + (modelUnit.signing_promise_doc_at ? ` (Fimada el ${modelUnit.signing_promise_doc_at})` : ``)}
              </Button>
            </Col>
          )}
          {statusMapped?.canMoveToOnDeed && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => changeModelUnitToOnDeed(projectModelId, modelUnit.id)}>
                <i className={"fa fa-duotone fa-file-contract"} /> Pasar a escrituración
              </Button>
            </Col>
          )}

          {modelUnit.transaction_id && (
            <Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => navigate(`investors/payments/${modelUnit.transaction_id}`)}>
                Ver pagos
              </Button>
            </Col>
          )}


          {
            isUnitValidForChangeMarketplace(modelUnit.status)
            && (<Col lg="12" className='pb-2'>
              <Button block color='secondary' size="sm" onClick={() => toggleMarketplaceVisibleModal()}>
                {
                  isUnitVisible
                    ? 'Ocultar de marketplace' : 'Publicar en marketplace'
                }
              </Button>
            </Col>)
          }

        </Row>
      </CardFooter>
      <MarketplaceVisibleModal
        parentEntityId={projectModelId}
        entityId={modelUnit.id}
        entityStatus={modelUnit.status}
        entityType='model'
        isOpen={showMarketplaceVisibleModal}
        onToggle={toggleMarketplaceVisibleModal}
        onConfirmation={changeMarketplaceVisible}
        bodyText={
          isUnitVisible
            ? 'Con esta acción ocultarás la unidad de la marketplace'
            : 'Con esta acción publicarás la unidad en la marketplace'
        }
        confirmationText={"Entiendo, continuar"}
        abortText={"Cancelar"}
      />
    </Card>
  );
};

export default ModelUnitSummaryCard;