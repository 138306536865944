import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import AdminUploadResource from '../../components/admin/AdminUploadResource';
import AdminResourceGallery from '../../components/admin/AdminResourceGallery';
import {
  deleteGalleryProjectModelEndpoint,
  deleteItemFromProjectModelGalleryEndpoint,
  getProjectModelDetailsEndpoint,
  sortGalleryProjectModelEndpoint,
  syncProjectModelGalleryEndpoint,
  uploadInvestorProjectModelGalleryResourcesEndpoint,
  uploadInvestorProjectModelResourcesEndpoint,
} from './services/investorService';
import useAdminEntity from '../../hooks/useAdminEntity';
import { InvestorProjectModelDetail } from './models/investors';
import { wait } from '../../helpers/commonHelpers';

const InvestorProjectModelUploadResources: React.FC = () => {
  const { id: investorProjectId = '', modelId: investorProjectModelId = '' } =
    useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getInvestorProjectModel = useCallback(
    () =>
      getProjectModelDetailsEndpoint(
        investorProjectId,
        investorProjectModelId,
        token,
      ),
    [investorProjectId, investorProjectModelId, token],
  );
  const [investorProjectModel] = useAdminEntity<InvestorProjectModelDetail>(
    getInvestorProjectModel,
    setErrorAlert,
  );

  const uploadInvestorProjectModelCover = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;

      const formData = new FormData();
      files.forEach((file) => formData.append('cover', file));
      const { status } = await callEndpoint(
        uploadInvestorProjectModelResourcesEndpoint(
          investorProjectId,
          investorProjectModelId,
          formData,
          token,
        ),
      );

      if (status === 200) navigate(0);
    },
    [investorProjectId, investorProjectModelId, callEndpoint, navigate, token],
  );

  const updateInvestorProjectModelGallery = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;

      //make n calls
      await Promise.allSettled(
        files.map(async (f) => {
          const formData = new FormData();
          formData.append('gallery', f);

          return callEndpoint(
            uploadInvestorProjectModelGalleryResourcesEndpoint(
              investorProjectId,
              investorProjectModelId,
              formData,
              token,
            ),
          );
        }),
      );

      wait(2000)
        .then(async (r) => {
          //sync
          await syncProjectModelGallery();
        })
        .catch();
    },
    [investorProjectId, investorProjectModelId, callEndpoint, navigate, token],
  );

  const deleteImageFromGallery = useCallback(
    async (galleryItemPath: string) => {
      const { status } = await callEndpoint(
        deleteItemFromProjectModelGalleryEndpoint(
          investorProjectId,
          investorProjectModelId,
          galleryItemPath,
          token,
        ),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, investorProjectModelId, callEndpoint, navigate, token],
  );

  const deleteAllImagesFromGallery = useCallback(
    async (gallery: string[]) => {
      const { status } = await callEndpoint(
        deleteGalleryProjectModelEndpoint(
          investorProjectId,
          investorProjectModelId,
          token,
        ),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, investorProjectModelId, callEndpoint, navigate, token],
  );

  const sortGallery = useCallback(
    async (gallery: string[]) => {
      const { status } = await callEndpoint(
        sortGalleryProjectModelEndpoint(
          investorProjectId,
          investorProjectModelId,
          gallery,
          token,
        ),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, investorProjectModelId, callEndpoint, navigate, token],
  );

  const syncProjectModelGallery = useCallback(async () => {
    await callEndpoint(
      syncProjectModelGalleryEndpoint(
        investorProjectId,
        investorProjectModelId,
        token,
      ),
    );
    // reload
    navigate(0);
  }, [investorProjectId, callEndpoint, navigate, token]);

  const pageName = `Recursos del modelo`;
  const pageParentName = `Preventa`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  if (!investorProjectModel || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const coverGallery = [investorProjectModel.cover].filter(
    (img) => img !== null,
  );

  const modelGallery = [...investorProjectModel.gallery].filter(
    (img) => img !== null,
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadInvestorProjectModelCover}
            description={'Subir cover'}
            maxFiles={1}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={coverGallery}
            description={'Cover actual'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={updateInvestorProjectModelGallery}
            description={'Subir galería'}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={modelGallery}
            description={'Galería actual'}
            onDelete={deleteImageFromGallery}
            onDeleteAll={deleteAllImagesFromGallery}
            onDrag={sortGallery}
            onSync={syncProjectModelGallery}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectModelUploadResources;
