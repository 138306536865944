import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  getContractSubscriptionDetailEndpoint,
  getContractSubscriptionsEndpoint,
} from './services/subscriptionServices';
import useAdminEntity from '../../hooks/useAdminEntity';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { ContractSubscriptionDetail } from './models/subscriptionsModel';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import DetailLabelText from '../properties/components/DetailLabelText';
import TransactionSubscriptionList from './components/TransactionSubscriptionList';

const SubscriptionDetail = () => {
  const { id = '' } = useParams();
  const pageName = 'Detalle de subscripción';
  const pageParentName = 'Subscripciones';
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [errorAlert, setErrorAlert] = useState(null);

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const getContractSubscription = useCallback(
    () => getContractSubscriptionDetailEndpoint(id, token),
    [],
  );
  const [contractSubscriptionDetail] =
    useAdminEntity<ContractSubscriptionDetail>(
      getContractSubscription,
      setErrorAlert,
    );

  if (!contractSubscriptionDetail || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  const { contract_subscription_info, transaction_subscription_detail } =
    contractSubscriptionDetail;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={<></>}
    >
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Información de subscripción de contrato</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Badge className="badge-lg" color="default"></Badge>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="">
                <DetailLabelText
                  label="Código de propiedad"
                  text={contract_subscription_info.property_code}
                />
                <DetailLabelText
                  label="Propietario"
                  text={contract_subscription_info.landlord_name}
                />
                <DetailLabelText
                  label="Fecha de inicio"
                  text={contract_subscription_info.start_date}
                />
                <DetailLabelText
                  label="Fecha de fin"
                  text={contract_subscription_info.end_date}
                />
                <DetailLabelText
                  label="Fecha de inicio de pago"
                  text={contract_subscription_info.start_payment_date}
                />
                <DetailLabelText
                  label="Tipo subscripción"
                  text={contract_subscription_info.subscription_type}
                />
                <DetailLabelText
                  label="Valor subscripción"
                  text={contract_subscription_info.value.toString()}
                />
              </div>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      </Row>
      <TransactionSubscriptionList
        applicableTransactions={transaction_subscription_detail}
      />
    </AdminPage>
  );
};

export default SubscriptionDetail;
