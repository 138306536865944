import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import PropertyDetailCard from '../../components/PropertyDetailCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useModalState from '../../hooks/useModalState';
import ActivateEntityInvestorModal from '../../components/landlordInvestors/ActivateEntityInvestorModal';
import InvestorProfileCard from '../../components/landlordInvestors/InvestorProfileCard';
import InvestorVisitDetailCard from './components/InvestorVisitDetailCard';
import { ExistingInvestorProfileFlags, GuestVisitInvestor } from './models/guestInvestorModel';
import { activateGuestInvestorEndpoint, getGuestVisitInvestorDetailEndpoint } from './services/guestInvestorService';

const InvestGuestVisitDetail = () => {
  const pageName = "Detalle de Asesoría";
  const pageParentName = "Asesorías";
  const { id: guestVisitInvestorId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const [guestVisitInvestor, setGuestVisitInvestor] = useState<GuestVisitInvestor>()
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const [showActivateGuestModal, toggleActivateGuestModal] = useModalState();
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  const activateGuest = async (guestId: string) => {
    const { status, data } = await callEndpoint(
      activateGuestInvestorEndpoint(guestId, token)
    );
    if (status === 200) {
      toggleActivateGuestModal();
      navigate('/investors/guest/visits');
    } else {
      const response = fetchResponseAdapter(data)
      const flags: ExistingInvestorProfileFlags = response.data;
      const hasExistingByMail = flags.hasOwnProperty('existsProfile')
      const hasExistingByPhone = flags.hasOwnProperty('existsProfileByPhone')
      const message = `${response.message} 
        ${hasExistingByMail && flags.existsProfile && '(Correo existente en otros perfiles)'}
        ${hasExistingByPhone && flags.existsProfileByPhone && '(Número existente en otros perfiles) Verifica con administrador ya que no pueden haber teléfonos repetidos en un mismo tipo de usuarios'}`
      setErrorAlert(message);
      toggleActivateGuestModal();
    }
  }

  const cancelGuest = async () => { }

  const loadGuestVisitInvestors = async () => {
    const { status, data } = await callEndpoint(
      getGuestVisitInvestorDetailEndpoint(guestVisitInvestorId, token)
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestVisitInvestor(response.data);
    } else {
      setErrorAlert(response.message);
    }
  };
  useEffect(() => {
    loadGuestVisitInvestors()
      .catch(e => setErrorAlert(e.toString()));
  }, []);

  if (!guestVisitInvestor || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={<>
        {/* <Button color="warning" onClick={() => toggleCancelVisitModal()}>
          Anular Guest y asesoría
        </Button> */}
        <Button color="primary" onClick={() => toggleActivateGuestModal()}>
          Aceptar asesoría
        </Button>
      </>}
    >
      <Row>
        {/* Profile section */}
        <Col className="order-xl-2 pt-10" xl="4">
          <InvestorProfileCard guestInfo={guestVisitInvestor.guestInfo} />
        </Col>
        <Col>
          {/* Property info */}
          <PropertyDetailCard
            cover={guestVisitInvestor.projectInfo.cover || ''}
            address={guestVisitInvestor.projectInfo.address}
            code={guestVisitInvestor.projectInfo.code}
            price={''}
          />
          {/* Visit detail info */}
          <InvestorVisitDetailCard guestVisit={guestVisitInvestor.visitInfo} />
        </Col>
      </Row>
      <ActivateEntityInvestorModal
        guestId={guestVisitInvestor.guestInfo.id}
        isOpen={showActivateGuestModal}
        onToggle={toggleActivateGuestModal}
        onConfirmation={activateGuest}
        bodyText={
          guestVisitInvestor.guestInfo.is_registered
            ? 'Al aceptar asignaras el lead al inversionista/propietario encontrado.'
            : "Al activar el guest aceptas todas las asesorías del mismo. Además vuelves al guest un Inversonista."
        }
        confirmationText={"Entiendo, continuar"}
        abortText={"No, seguir revisando..."}
      />
    </AdminPage>
  )
}

export default InvestGuestVisitDetail
