import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap"
import useCallApiAndLoad from "../../../hooks/useCallApiAndLoad"
import { uploadPhotosEndpoint } from "../services/propertyService"
import useAuth from "../../../hooks/useAuth"

interface Props {
  property_id: string;
}

const UploadCover = ({ property_id }: Props) => {

  const [files, setFiles] = useState<Array<any>>([])
  const [imageAccepted, setImageAccepted] = useState<any>()
  const [error, setError] = useState<string>('')
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const navigate = useNavigate()
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const onDrop = useCallback((acceptedFiles: any) => {
    setImageAccepted(acceptedFiles)
    setFiles([...acceptedFiles.map((file: any) => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))])
  }, [files, imageAccepted])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': []
    },
    onDrop,
  })

  const removeFile = (file: any) => {
    const newFiles = [...files]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiles(newFiles)
  }

  const handleUpload = async () => {
    setError('')
    if (!imageAccepted || !imageAccepted.length) {
      setError('Debés seleccionar una imágen para subir')
      return
    }
    let formData = new FormData()
    formData.append('cover', imageAccepted[0])
    // const token = getAuthToken(user)
    const { status, data } = await callEndpoint(uploadPhotosEndpoint(formData, property_id, token))
    if (status === 200) {
      navigate(0) //make a reload
    } else {
      setError('Lo sentimos, por el momento no es posible completar la acción')
    }
  }

  return (
    <Card>
      <CardHeader>
        {error}
        <h4>Subir Cover</h4>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row>
              <Col md="12">
                <div {...getRootProps({ className: "px-4 py-4 text-center dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Arrastra tu foto o Selecciona</p>
                  <span className='text-sm'>Formatos soportados: JPEG y PNG, máximo de 1MB de archivo.</span>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        {
          files && files.length > 0 && files.map(file => (
            <ListGroup className="list my--3" flush key={file.path}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      className="rounded"
                      alt="..."
                      width={175}
                      height={100}
                      src={file.preview}
                      onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                  </Col>
                  <div className="col ml--2"></div>
                  <Col className="col-auto">
                    <Button
                      onClick={() => removeFile(file)}
                      color="danger" size="sm" type="button">
                      Remover
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          ))
        }
      </CardBody>
      <CardFooter>
        <Row>
          <div className="col ml--2"></div>
          <Col className="col-auto">
            <Button
              onClick={handleUpload}
              className=""
              color="success" size="md" type="button">
              Subir cover
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  )
}

export default UploadCover
