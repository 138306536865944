import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useModalState from '../../../hooks/useModalState';
import { isProjectVisibleInMarketplace } from '../helpers/investorHelpers';
import { InvestorProjectDetail } from '../models/investors';
import { updateInvestorProjectEndpoint } from '../services/investorService';
import MarketplaceVisibleModal from './MarketplaceVisibleModal';

export type Props = {
  project: InvestorProjectDetail;
};
const InvestorProjectUnitCard: React.FC<Props> = ({ project }) => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [showMarketplaceVisibleModal, toggleMarketplaceVisibleModal] =
    useModalState();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const isProjectVisible = isProjectVisibleInMarketplace(project.status);

  const changeMarketplaceVisible = async (
    projectId: string,
    projectStatus: string,
    parentId: string = '',
  ) => {
    const form = {
      status: isProjectVisibleInMarketplace(projectStatus)
        ? 'disabled'
        : 'active',
    };
    const { status, data } = await callEndpoint(
      updateInvestorProjectEndpoint(projectId, form, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
      toggleMarketplaceVisibleModal();
    }
  };
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              className="object-fit-cover border rounded"
              alt="..."
              width={250}
              height={175}
              src={
                project.cover &&
                !project.cover.includes('null') &&
                !project.cover.includes('undefined')
                  ? project.cover
                  : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
              }
            />
          </Col>
          <div className="col ml--2">
            <h1 className="mb-0">{project.name}</h1>
            <p className="mb-0">#{project.code}</p>
            <p className="text-base text-muted mb-0"><span style={{color: '#212121', fontWeight: 600}}>Dirección: </span>{project.address}</p>
            <p className="text-base mb-2"><span style={{color: '#212121', fontWeight: 600}}>Desarrolladora: </span> {project.developer.name}</p>
          </div>


        </Row>

      </CardBody>
    </Card>
  );
};

export default InvestorProjectUnitCard;
