import React, { useCallback, useReducer, useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { ModelUnitDetail, ModelUnitFinancesForm } from '../models/investors';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import { getModelUnitsEndpoint } from '../services/investorService';
import useAuth from '../../../hooks/useAuth';
import useAdminEntity from '../../../hooks/useAdminEntity';

export type Props = {
  handleForm: (form: ModelUnitFinancesForm, errorRepository: Function) => void;
  projectModelId: string;
  modelUnitId: string;
};
const ManageModelUnitFinancesForm: React.FC<Props> = ({
  handleForm,
  projectModelId,
  modelUnitId,
}) => {
  const [error, setError] = useState(null);
  const { isLoading } = useCallApiAndLoad();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [symbolData, setSymbolData] = useState('');

  const internalHandleForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleForm(
      {
        percentage_reserve_payment: financeState.pctReservePayment,
        percentage_down_payment: financeState.pctDownPayment,
      },
      setError,
    );
  };

  const financeInitState = {
    pctToCollect: 0.0,
    pctReservePayment: 0.0,
    pctDownPayment: 0.0,
    amountToCollect: 0.0,
    amountReservePayment: 0.0,
    amountDownPayment: 0.0,
    modelUnitBasePrice: 1.0,
  };
  const financeReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'setModelUnitBasePrice': {
        return {
          ...state,
          modelUnitBasePrice: action.modelUnitBasePrice,
        };
      }

      case 'updateReservePayment': {
        // debugger;
        const unitBasePrice = state.modelUnitBasePrice || 1;
        const pctReservePayment = action.amountReservePayment / unitBasePrice;
        let pctDownPayment = state.pctDownPayment / 100.0;
        let pctToCollect = pctReservePayment + pctDownPayment;
        if (!action.pctReservePayment) {
          //this is when change ammount reserve payment only
          pctToCollect = state.pctToCollect / 100;
          pctDownPayment = pctToCollect - pctReservePayment;
        }

        return {
          ...state,
          pctToCollect: pctToCollect * 100.0,
          pctReservePayment: pctReservePayment * 100.0,
          pctDownPayment: pctDownPayment * 100.0,
          amountToCollect: (pctToCollect * unitBasePrice).toFixed(0),
          amountReservePayment: action.amountReservePayment.toFixed(0),
          amountDownPayment: (pctDownPayment * unitBasePrice).toFixed(0),
        };
      }

      case 'updateDownPayment': {
        return {
          ...state,
          pctDownPayment: action.pctDownPayment,
          amountDownPayment: action.amountDownPayment,
          pctToCollect: state.pctReservePayment + action.pctDownPayment,
          amountToCollect:
            +state.amountReservePayment + +action.amountDownPayment,
        };
      }

      case 'updateAmountToCollect': {
        // debugger;
        const unitBasePrice = state.modelUnitBasePrice || 1;
        const pctToCollect = action.amountToCollect / unitBasePrice;
        const pctReservePayment = state.amountReservePayment / unitBasePrice;
        const pctDownPayment = pctToCollect - pctReservePayment;

        return {
          ...state,
          pctToCollect: pctToCollect * 100.0,
          pctReservePayment: pctReservePayment * 100.0,
          pctDownPayment: pctDownPayment * 100.0,
          amountToCollect: action.amountToCollect.toFixed(0),
          amountReservePayment: (pctReservePayment * unitBasePrice).toFixed(0),
          amountDownPayment: (pctDownPayment * unitBasePrice).toFixed(0),
        };
      }

      case 'updatePctToCollect': {
        const unitBasePrice = state.modelUnitBasePrice;
        const amountReservePayment = state.amountReservePayment;
        const ptcToCollect = action.ptcToCollect / 100;
        const ptcToReservePayment = amountReservePayment / unitBasePrice; //decimal

        const pctDownPayment = ptcToCollect - ptcToReservePayment; //decimal
        const amountDownPayment = unitBasePrice * pctDownPayment;
        const amountToCollect = unitBasePrice * ptcToCollect;

        return {
          ...state,
          pctToCollect: ptcToCollect * 100,
          pctReservePayment: ptcToReservePayment * 100,
          pctDownPayment: pctDownPayment * 100,
          amountDownPayment: amountDownPayment.toFixed(0),
          amountReservePayment: amountReservePayment,
          amountToCollect: amountToCollect.toFixed(0),
        };
      }
    }
  };

  const [financeState, dispatch] = useReducer(financeReducer, financeInitState);

  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(projectModelId, token),
    [projectModelId, token],
  );
  const setModelUnitFinanceInfo = useCallback(
    (modelUnits: ModelUnitDetail[]) => {
      const currentModelUnit = modelUnits.find(
        (modelUnit) => modelUnit.id === modelUnitId,
      );

      if (!currentModelUnit) return;

      setSymbolData(currentModelUnit.base_price[0]);

      const modelUnitBasePriceStr = currentModelUnit.base_price.replace(
        /[^.0-9]/g,
        '',
      );

      const modelUnitBasePrice = parseFloat(modelUnitBasePriceStr);
      dispatch({
        type: 'setModelUnitBasePrice',
        modelUnitBasePrice: modelUnitBasePrice,
      });

      if (currentModelUnit.preview_dues.length < 2) return;

      const paymentItemAmounts = currentModelUnit.preview_dues.map((e) => {
        return {
          paymentType: e.label.toLowerCase().includes('reserva')
            ? 'paymentReserve'
            : 'paymentDown',
          amount: parseFloat(e.value.replace(/[^.0-9]/g, '')),
        };
      });

      paymentItemAmounts.forEach((payment) => {
        if (payment.paymentType === 'paymentReserve') {
          dispatch({
            type: 'updateReservePayment',
            pctReservePayment: (payment.amount / modelUnitBasePrice) * 100.0,
            amountReservePayment: payment.amount,
          });
        } else {
          dispatch({
            type: 'updateDownPayment',
            pctDownPayment: (payment.amount / modelUnitBasePrice) * 100.0,
            amountDownPayment: payment.amount,
          });
        }
      });
    },
    [modelUnitId],
  );

  useAdminEntity<ModelUnitDetail[]>(
    getModelUnits,
    setError,
    setModelUnitFinanceInfo,
  );

  return (
    <Form role="form" onSubmit={internalHandleForm}>
      <Row>
        <Col md="8">
          {error && (
            <UncontrolledAlert color="danger" fade={false}>
              <span className="alert-inner--text">{error}</span>
            </UncontrolledAlert>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={{ offset: 1 }}>
          <Row>
            <Col>
              <label style={{ height: '60px' }} className="form-control-label">
                Monto a colectar <br /> (Prima + Reserva)
              </label>
            </Col>
          </Row>

          <Row>
            <Col>
              <label style={{ height: '60px' }} className="form-control-label">
                Prima
              </label>
            </Col>
          </Row>

          <Row>
            <Col>
              <label style={{ height: '60px' }} className="form-control-label">
                Reserva
              </label>
            </Col>
          </Row>

          <Row>
            <Col>
              <label style={{ height: '60px' }} className="form-control-label">
                Precio base
              </label>
            </Col>
          </Row>
        </Col>
        <Col md="3">
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <Input
                    value={financeState.pctToCollect}
                    type="number"
                    step="any"
                    //readOnly={true}
                    onChange={(e) => {
                      const ptcToCollect = parseFloat(e.target.value || '0');

                      dispatch({
                        type: 'updatePctToCollect',
                        ptcToCollect: ptcToCollect,
                      });
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <Input
                    // className={errors.percentage_down_payment?.message && 'is-invalid'}
                    value={financeState.pctDownPayment}
                    type="number"
                    step="any"
                    readOnly={true}
                    // onChange={(e) => {
                    //   const pctDownPayment = parseFloat(e.target.value || "0");
                    //
                    //   dispatch({
                    //     type: "updateDownPayment",
                    //     pctDownPayment: pctDownPayment,
                    //     amountDownPayment: financeState.modelUnitBasePrice * (pctDownPayment / 100.0)
                    //   });
                    // }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {/*<h5 className="text-danger">{errors.percentage_down_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <Input
                    // className={errors.percentage_reserve_payment?.message && 'is-invalid'}
                    value={financeState.pctReservePayment}
                    type="number"
                    step="any"
                    readOnly={true}
                    // onChange={(e) => {
                    //   const pctReservePayment = parseFloat(e.target.value || "0");
                    //
                    //   dispatch({
                    //     type: "updateReservePayment",
                    //     pctReservePayment: pctReservePayment,
                    //     amountReservePayment: financeState.modelUnitBasePrice * (pctReservePayment / 100.0)
                    //   });
                    // }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {/*<h5 className="text-danger">{errors.percentage_reserve_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <Input readOnly={true} value={100} type="number" step="any" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="3">
          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{symbolData}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={financeState.amountToCollect}
                    type="number"
                    step="any"
                    readOnly={true}
                    // onChange={(e) => {
                    //   const amountToCollect = parseFloat(e.target.value || '0');

                    //   dispatch({
                    //     type: 'updateAmountToCollect',
                    //     amountToCollect: amountToCollect,
                    //   });
                    // }}
                  />
                </InputGroup>
                {/*<h5 className="text-danger">{errors.amount_down_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{symbolData}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    // className={errors.amount_down_payment?.message && 'is-invalid'}
                    value={financeState.amountDownPayment}
                    type="number"
                    step="any"
                    readOnly={true}
                    // onChange={(e) => {
                    //   const amountDownPayment = parseFloat(e.target.value || "1");
                    //
                    //   dispatch({
                    //     type: "updateDownPayment",
                    //     pctDownPayment: (amountDownPayment / financeState.modelUnitBasePrice) * 100.0,
                    //     amountDownPayment: amountDownPayment
                    //   });
                    // }}
                  />
                </InputGroup>
                {/*<h5 className="text-danger">{errors.amount_down_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{symbolData}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    // className={errors.amount_reserve_payment?.message && 'is-invalid'}
                    value={financeState.amountReservePayment}
                    type="number"
                    step="any"
                    onChange={(e) => {
                      const amountReservePayment = parseFloat(
                        e.target.value || '0',
                      );

                      dispatch({
                        type: 'updateReservePayment',
                        amountReservePayment: amountReservePayment,
                      });
                    }}
                  />
                </InputGroup>
                {/*<h5 className="text-danger">{errors.amount_reserve_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{symbolData}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    readOnly={true}
                    value={financeState.modelUnitBasePrice}
                    type="number"
                    step="any"
                  />
                </InputGroup>
                {/*<h5 className="text-danger">{errors.amount_reserve_payment?.message}</h5>*/}
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Button color="primary" disabled={isLoading}>
        Actualizar datos financieros
      </Button>
    </Form>
  );
};

export default ManageModelUnitFinancesForm;
