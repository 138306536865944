const BANK_ACCOUNT_TYPES = [
    {
        type_id: "A",
        type: "Ahorro"
    },
    {
        type_id: "C",
        type: "Corriente"
    }
]

export const getBankType = (typeId: string) => {
    const bankAccountType = BANK_ACCOUNT_TYPES.find(el => el.type_id === typeId);
    return bankAccountType && bankAccountType.type
        ? bankAccountType.type
        : 'No disponible'
}