import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  ListGroup,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { FINANCE_TYPES_LIST } from '../../../constants/transactionConstants';
import useSubscriptions, {
  SubscriptionEnumState,
} from '../hooks/useSubscriptions';
import { useNavigate } from 'react-router-dom';
import TransactionSubscriptionList from './TransactionSubscriptionList';

interface Props {
  headerText: string;
  contractId: string;
  isOpen: boolean;
  onToggle: () => void;
}

const CreateSubscriptionModal = ({
  headerText,
  isOpen,
  onToggle,
  contractId,
}: Props) => {
  const {
    handleSubmit,
    errors,
    apiErrors,
    control,
    register,
    setValue,
    resetField,
    doCreateSubscription,
    doCheckAvailability,
    isLoading,
    subscriptions,
    applicableTransactions,
    formState,
  } = useSubscriptions({ contractId, defaultSubscriptionType: '' });
  const navigate = useNavigate();

  if (formState === SubscriptionEnumState.DONE) {
    onToggle(); // close modal and refresh
    navigate(0);
  }

  return (
    <Modal
      size="xl"
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {headerText}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {apiErrors && (
          <Alert color="danger">
            <strong>Error!</strong> {apiErrors}
          </Alert>
        )}
        <Form id="subscription" role="form">
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="subscription_id">
                  Subscripción
                </label>
                <Controller
                  name="subscription_id"
                  control={control}
                  defaultValue={''}
                  render={({ field: { ref } }) => (
                    <Select
                      placeholder="Selecciona subscripción"
                      options={subscriptions}
                      onChange={(selected: any) => {
                        const { value = '' } = selected;
                        setValue('subscription_id', value);
                      }}
                      ref={ref}
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.subscription_id?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="subscription_id">
                  Tipo de valor a aplicar
                </label>
                <Controller
                  name="subscription_type"
                  control={control}
                  defaultValue={''}
                  render={({ field: { ref } }) => (
                    <Select
                      placeholder="Selecciona subscripción"
                      options={FINANCE_TYPES_LIST}
                      onChange={(selected: any) => {
                        const { value = '' } = selected;
                        setValue('subscription_type', value);
                      }}
                      ref={ref}
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.subscription_type?.message}
                </h5>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="value">
                  Valor a aplicar
                </label>
                <Controller
                  name="value"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Input
                      className={errors.value?.message && 'is-invalid'}
                      {...field}
                      id="last_name"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.value?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="start_contract">
                  Fecha de inicio
                </label>
                <Controller
                  name="start_date"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={errors.start_date?.message && 'is-invalid'}
                      {...field}
                      type="month"
                      defaultValue={''}
                      id="start_date"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.start_date?.message}</h5>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="start_contract">
                  Fecha de fin
                </label>
                <Controller
                  name="end_date"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={errors.end_date?.message && 'is-invalid'}
                      {...field}
                      type="month"
                      defaultValue={''}
                      id="end_date"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.end_date?.message}</h5>
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="start_payment_date"
                >
                  Fecha de inicio de pago
                </label>
                <Controller
                  name="start_payment_date"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={
                        errors.start_payment_date?.message && 'is-invalid'
                      }
                      {...field}
                      type="month"
                      defaultValue={''}
                      id="start_payment_date"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.start_payment_date?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
          {/* availability response */}
          <TransactionSubscriptionList
            applicableTransactions={applicableTransactions}
          />
        </Form>
      </div>
      <div className="modal-footer">
        {formState === SubscriptionEnumState.CREATING ? (
          <Button
            form="subscription"
            color={'success'}
            onClick={handleSubmit(doCreateSubscription)}
            disabled={isLoading}
          >
            {headerText}
          </Button>
        ) : (
          <Button
            form="subscription"
            color={'warning'}
            onClick={handleSubmit(doCheckAvailability)}
            disabled={isLoading}
          >
            Verificar subscripción
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CreateSubscriptionModal;
