import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import TimelineHeader from './headers/TimelineHeader'

import loading from "../assets/gif/loading.gif";

interface Props {
  name: string;
  parent_name: string;
}

const LoadingCard = ({ name, parent_name }: Props) => {
  return (
    <>
      <TimelineHeader name={ name } parentName={ parent_name } />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="12" >
            <Card>
              <CardBody className="text-center">
                <img src={loading} alt="" width={60} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LoadingCard
