import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import AdminUploadFile from '../../components/admin/AdminUploadFile';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import {
  updateSigningPromiseDateAtEndpoint,
  uploadSigningPromiseDocsToModelUnit,
} from './services/investorService';

const ModelUnitSigningPromiseDocs: React.FC = () => {
  const {id: modelId = "", modelUnitId = ""} = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [errorAlert, setErrorAlert] = useState<any>(null);
  const [signingPromiseDateAt, setSigningPromiseDateAt] = useState<string>("");

  const uploadSigningPromiseFile = useCallback(async (files: Array<any>) => {
    if (files.length < 0)
      return

    const formData = new FormData();
    files.forEach(file => formData.append("signingPromiseDoc", file));

    const { status } = await  callEndpoint(uploadSigningPromiseDocsToModelUnit(modelId, modelUnitId, formData, token))

    if (status === 200)
      // debugger;
      navigate(0);

  }, [token, callEndpoint, modelId, modelUnitId, navigate]);

  const updateSigningPromiseDateAt = useCallback(async () => {
    const payload = {
      signingPromiseDocAt: signingPromiseDateAt,
    }

    const { status } = await callEndpoint(updateSigningPromiseDateAtEndpoint(modelId, modelUnitId, payload, token))


    if (status === 200)
      navigate(`/investors/models/${modelId}/model-units`);
  }, [callEndpoint, modelId, modelUnitId, navigate, token, signingPromiseDateAt]);

  const pageName = "Promesa de pago";
  const pageParentName = "Preventas";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  return (
    <AdminPage name={pageName} parentName={pageParentName} globalAlertOptions={globalAlertOptions}>
      <Row>
        <Col>
          <AdminUploadFile
            handleUpload={uploadSigningPromiseFile}
            description={"Subir promersa de pago"}
            maxFiles={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <label className="form-control-label" htmlFor="description">
              Fecha de firma
            </label>
            <Input
              type={'date'}
              onChange={event => setSigningPromiseDateAt(event.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="primary" disabled={isLoading} onClick={updateSigningPromiseDateAt}>
        {'Actualizar'}
      </Button>
    </AdminPage>
  )
};


export default ModelUnitSigningPromiseDocs;