import { Col, Row } from 'reactstrap'

interface Props {
  label: string;
  text: string;
}

const DetailLabelText = ({ label, text }: Props) => {
  return (
    <Row className="py-3 align-items-center">
      <Col sm="4">
        <small className="text-uppercase text-muted font-weight-bold">
          { label }
        </small>
      </Col>
      <Col sm="8">
        <p className="text-muted mb-0">
          { text }
        </p>
      </Col>
    </Row>
  )
}

export default DetailLabelText
