import {
  BussinessProduct,
  bussinessProducts,
} from '../helpers/bussinessHelpers';

export const getBussinessBadge = (bussiness: string): BussinessProduct => {
  return (
    bussinessProducts.find((po) => po.product.toString() === bussiness) ?? {
      color: 'primary',
      product: '',
      product_text: 'NA',
    }
  );
};
