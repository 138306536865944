import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};
export const getLandlordByMailEndpoint = (email: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/landlord/search-mail`,
        params: { email },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
