/**
 *
 * @param currentPage
 * @param totalPages
 * @param pagesToShow
 * @returns an array with the list of pages to show
 */

export const generatePagination = (
  currentPage: number,
  totalPages: number,
  pagesToShow: number,
): number[] => {
  const halfPagesToShow = Math.floor(pagesToShow / 2);
  let paginationStart = Math.max(currentPage - halfPagesToShow, 1);
  let paginationEnd = paginationStart + pagesToShow - 1;

  if (paginationEnd > totalPages) {
    paginationEnd = totalPages;
    paginationStart = Math.max(totalPages - pagesToShow + 1, 1);
  }

  const pagination = [];

  for (let i = paginationStart; i <= paginationEnd; i++) {
    pagination.push(i);
  }

  return pagination;
};
