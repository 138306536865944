import { Card, CardBody, Col, ListGroup, Row, Table } from 'reactstrap';
import { PreviewDownPaymentQuotas } from '../models/downPaymentModel';

interface Props {
  previewDues: PreviewDownPaymentQuotas[];
}

const InvestorDownPaymentQuotsPreview = ({ previewDues }: Props) => {
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <ListGroup className="list my--3" flush>
              <h6 className="heading-small text-muted mt-4 mb-4">
                Visualización de prima dividida en cuotas
              </h6>
            </ListGroup>
            {previewDues.length > 0 && (
              <div
                style={{
                  height: '200px',
                  overflow: 'scroll',
                }}
              >
                <Table
                  className="align-items-center table-flush hei"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Cuota</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Correlativo Fecha pago</th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewDues.map((due) => (
                      <tr key={due.due}>
                        <td>{due.due}</td>
                        <td>{due.price}</td>
                        <td>{due.due_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default InvestorDownPaymentQuotsPreview;
