import React, { useReducer } from 'react';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';

export type Props = {
  onChange: Function
};
const ModelUnitListFilters: React.FC<Props> = ({onChange}) => {

  const modelUnitFilterReducer = (state: any, action: any) => {
    switch (action.type) {
      case "setNameFilter": {
        const neoState = {
          ...state,
          modelUnitName: action.filteredName,
        };
        onChange(neoState);
        return neoState;
      }

      case "setStatusFilter": {
        const neoState = {
          ...state,
          modelUnitStatus: action.filteredStatus,
        };
        onChange(neoState);
        return neoState
      }
    }
  };

  const [modelUnitFilterState, dispatch] = useReducer(modelUnitFilterReducer, { modelUnitName: "",  modelUnitStatus: ""});

  const modelUnitStates = listToSelectOptionAdapter([
    // {
    //   status: "created",
    //   displayText: "Disponible",
    // },
    {
      status: "",
      displayText: "Todos",
    },
    {
      status: "available",
      displayText: "Disponible",
    },
    {
      status: "disabled",
      displayText: "Desactivada",
    },
    {
      status: "taken",
      displayText: "Tomada",
    },
    {
      status: "pre_booked",
      displayText: "Pre asignada",
    },
    {
      status: "assigned",
      displayText: "Asignada",
    },
  ], "status", "displayText")

  return (
    <Form role="form">
      <Row>
        <Col md="3">
          <FormGroup>
            <label className="form-control-label" >Por nombre</label>
            <Input
              type="text"
              placeholder="Ingresa un nombre para buscar"
              value={modelUnitFilterState.modelUnitName}
              onChange={(e) => {
                dispatch({
                  type: "setNameFilter",
                  filteredName: e.target.value || "",
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <label className="form-control-label" >Por estado</label>
            <Select
              options={modelUnitStates}
              placeholder="Selecciona un estado"
              onChange={(e) => {
                dispatch({
                  type: "setStatusFilter",
                  filteredStatus: e?.value || "",
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ModelUnitListFilters;