import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  InvestorProjectModelDetail,
  InvestorProjectModelForm,
} from '../models/investors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import AmenitiesList from './AmenitiesList';

export type Props = {
  projectModel?: InvestorProjectModelDetail;
  handleForm: (
    form: InvestorProjectModelForm,
    errorRepository: Function,
  ) => void;
};
const ManageInvestorProjectModelForm: React.FC<Props> = ({
  projectModel,
  handleForm,
}) => {
  const { isLoading } = useCallApiAndLoad();
  const [error, setError] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<InvestorProjectModelForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required('El nombre del proyecto es requerido.'),
          max_units: yup
            .number()
            .positive('La cantidad máxima de unidades debe ser mayor a cero.')
            .required('La cantidad máxima de unidades es requerida.'),
          amenities: yup
            .array()
            .of(yup.string())
            .required('Selecciona al menos una amenidad.'),
          quota_base_price: yup.number(),
          area: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(),
          bathrooms: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(),
          bedrooms: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(),
          parking: yup
            .number()
            .transform((value) => (isNaN(value) ? null : value))
            .nullable(),
        })
        .required(),
    ),
  });

  const internalHandleForm = useCallback(
    (form: InvestorProjectModelForm) => handleForm(form, setError),
    [],
  );

  return (
    <Form role="form" onSubmit={handleSubmit(internalHandleForm)}>
      <Row>
        <Col md="6">
          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}

              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={projectModel?.name}
                  key={`${projectModel?.name}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.name?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.name?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="max_units">
                  Número de unidades
                </label>
                <Controller
                  name="max_units"
                  control={control}
                  defaultValue={projectModel?.max_units}
                  key={`${projectModel?.max_units}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.max_units?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.max_units?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="area">
                  Area de construcción
                </label>
                <Controller
                  name="area"
                  control={control}
                  defaultValue={projectModel?.area}
                  key={`${projectModel?.area}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.area?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.area?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="bathrooms">
                  Baños
                </label>
                <Controller
                  name="bathrooms"
                  control={control}
                  defaultValue={projectModel?.bathrooms}
                  key={`${projectModel?.bathrooms}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.bathrooms?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.bathrooms?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="bedrooms">
                  Cuartos
                </label>
                <Controller
                  name="bedrooms"
                  control={control}
                  defaultValue={projectModel?.bedrooms}
                  key={`${projectModel?.bedrooms}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.bedrooms?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.bedrooms?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="parking">
                  Parqueos
                </label>
                <Controller
                  name="parking"
                  control={control}
                  defaultValue={projectModel?.parking}
                  key={`${projectModel?.parking}-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.parking?.message && 'is-invalid'}
                      {...field}
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.parking?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          {projectModel?.has_quota_base_price && (
            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="quota_base_price"
                  >
                    Precio por modelo (valor en cuota)
                  </label>
                  <Controller
                    name="quota_base_price"
                    control={control}
                    defaultValue={projectModel?.quota_base_price}
                    key={`${projectModel?.quota_base_price}-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.quota_base_price?.message && 'is-invalid'
                        }
                        {...field}
                        type="number"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.quota_base_price?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <AmenitiesList
                defaultValues={projectModel?.amenities || []}
                onChange={(amenities) => {
                  setValue('amenities', [...[], ...amenities]);
                }}
              />
            </Col>
          </Row>
          <h5 className="text-danger">{errors.amenities?.message}</h5>
        </Col>
      </Row>

      <Button color="primary" disabled={isLoading}>
        {projectModel ? 'Actualizar' : 'Crear'}
      </Button>
    </Form>
  );
};

export default ManageInvestorProjectModelForm;
