import React, { useState, useCallback, memo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { MAP_STYLE } from '../../constants/MapConstants';
import useUserCountry from '../../hooks/useUserCountry';
import { Coordinate } from '../../models/mapViewModel';

const zoom = 15;
const containerStyle = {
  width: '100%',
  height: '400px'
};

const svCenter = {
  lat: 13.701402266923608,
  lng: -89.22444462777489,
};

const gtCenter = {
  lat: 14.602933,
  lng: -90.5107544,
}

export type Props = {
  onChange: Function,
  defaultCenter?: Coordinate
}

const MapPicker: React.FC<Props> = ({onChange, defaultCenter}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB0c1nq-isvWJWWam5CICmPt0oYgY1A_eg"
  })
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode = getCurrentCountry()?.code || "SV"

  if (!defaultCenter) {
    if (currentUserCountryCode.toLowerCase() === "gt") {
      defaultCenter = gtCenter;
    } else { // SV
      defaultCenter = svCenter;
    }
  }

  const [mapCenter, setMapCenter] = useState<Coordinate>(defaultCenter);

  const onLoad = useCallback((map: any) => {
    map.setZoom(zoom)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={13}
      onLoad={onLoad}
      options={{
        minZoom: 13,
        styles: MAP_STYLE
      }}
      onClick={e => {
        if (e && e.latLng) {
          const newMapCenter = {lat: e.latLng.lat(), lng: e.latLng.lng()}
          setMapCenter(newMapCenter)
          onChange(newMapCenter)
        }
      }}

    >
      <Marker
        position={mapCenter}
      />
    </GoogleMap>
  ) : <></>
}

export default memo(MapPicker)
