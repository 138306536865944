import React from 'react';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { QuotaDetails } from '../models/contracts';
import { getTransactionDetailStatusAdapter, getPaymentMethodStringAdapter } from '../../../adapters/transactionAdapter';

export interface Props {
  quotaDetails: QuotaDetails;
}
const QuotaDetailsCard: React.FC<Props> = ({quotaDetails}: Props) => {

  const stateMapper = (state: string) => {
    return getTransactionDetailStatusAdapter(state)
  };

  const paymentMethodMapper = (paymentMethod: string) => {
    return getPaymentMethodStringAdapter(paymentMethod)
  };

  const fieldMapper = [
    {
      "title": "Cuota",
      "value": quotaDetails.due,
    },
    {
      "title": "Descripción",
      "value": quotaDetails.description,
    },
    {
      "title": "Estado",
      "value": stateMapper(quotaDetails.status),
    },
    {
      "title": "Valor",
      "value": quotaDetails.price,
    },
    {
      "title": "Fecha",
      "value": `${quotaDetails.month}-${quotaDetails.year}`,
    },
    {
      "title": "Método de pago",
      "value": paymentMethodMapper(quotaDetails.payment_method),
    },
  ];


  return (
    <AdminListGroup title={"Detalle de la couta"} items={fieldMapper} />
  );
};


export default QuotaDetailsCard;