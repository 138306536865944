import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';

interface Props {
  score: number | null;
  internalInfo: {
    birthdate: string;
    document: string;
    full_name: string;
  };
}

export default function BuroOfferDetailCard({ score, internalInfo }: Props) {
  const fieldMapper: DisplayItem[] = [
    {
      title: 'Score',
      value: score,
    },
    {
      title: 'Nombre',
      value: internalInfo.full_name,
    },
    {
      title: 'Documento',
      value: internalInfo.document,
    },
    {
      title: 'Fecha de nacimiento',
      value: internalInfo.birthdate,
    },
  ];

  return (
    <AdminListGroup title={'Información según buró'} items={fieldMapper} />
  );
}
