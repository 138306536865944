import { LeadMarketplaceType } from '../../../components/VisitMarketplaceTypeBadge';
import { Option } from '../../../models/formModel';
import { City } from '../../../models/publicModel';
import {
  PROPERTY_MONTHS_LIST,
  PROPERTY_SALE_STATUS,
  PROPERTY_STATUS,
} from '../constants/propertyConstants';
import {
  CharacteristicApi,
  PropertyToCharacteristics,
  PropertyToAmenities,
  CreatePropertyForm,
  CreatePropertyFormApi,
  CreatePropertyFormForApi,
  IPropertyViewCounter,
  lastCounterDetail,
} from '../models/propertyModel';

export const getPropertyStatus = (status: string) => {
  if (!status) return 'NA';
  return PROPERTY_STATUS[status] ? PROPERTY_STATUS[status] : '';
};

export const getPropertySaleStatus = (status: string) => {
  if (!status) return 'No definido';
  return PROPERTY_SALE_STATUS[status] ? PROPERTY_SALE_STATUS[status] : '';
};

export const getOptionPropertyStatus = (): Option[] => {
  let response: any[] = [];
  for (const [key, value] of Object.entries(PROPERTY_STATUS)) {
    response.push({ label: value, value: key });
  }
  return response;
};

export const createPropertyApiV2Adapter = (
  data: CreatePropertyForm,
  characteristics: CharacteristicApi[],
): CreatePropertyFormForApi => {
  const characteristicsData = buildCharacteristics(
    characteristics,
    data.c_rooms,
    data.c_bathrooms,
  );
  const apiData: CreatePropertyFormForApi = {
    type_id: data.type_id,
    currency: data.currency,
    characteristics_info: characteristicsData,
  };
  if (data.landlord_id) {
    apiData.landlord_id = data.landlord_id;
  }
  if (data.marketplace_type === LeadMarketplaceType.ON_RENT) {
    apiData.price = data.price;
    apiData.min_price = data.min_price;
  } else if (data.marketplace_type === LeadMarketplaceType.ON_SALE) {
    apiData.sale_price = data.price;
    apiData.sale_min_price = data.min_price;
  }
  return apiData;
};

/**
 * @deprecated
 * @param data
 * @param characteristics
 * @returns
 */
export const createPropertyApiAdapter = (
  data: CreatePropertyForm | any, // TODO fix this
  characteristics: CharacteristicApi[],
): CreatePropertyFormApi => {
  const characteristicsData = buildCharacteristics(
    characteristics,
    data.c_rooms,
    data.c_bathrooms,
    data.c_half_bathrooms,
    data.c_parkings,
    data.c_square_mts,
  );
  const months = buildDataMonths(data.months);
  const apiData: CreatePropertyFormApi = {
    type_id: data.type_id,
    price: data.price || 0,
    min_price: data.min_price || 0,
    // sale_price: data.sale_price || 0,
    // sale_min_price: data.sale_min_price || 0,
    months,
    name: data.name,
    characteristics_info: characteristicsData,
    // this is because react hook form, set radio input as string :/
    has_white_goods: data.has_white_goods === 'true',
    is_furnished: data.is_furnished === 'true',
    is_new: false,
    pets_allowed: data.pets_allowed === 'true',
    available_at: data.available_at,
    address: data.address,
    city_id: null, // deprecated for address id tool
    street: data.street,
    property_number: data.property_number,
    extra_info: data.extra_info || '',
    description: data.description,
    amenities_info: data.amenities_info,
    lat: data.lat,
    lng: data.lng,
    landlord_id: data.landlord_id,
    plan_id: data.plan_id,
    has_propi_property_keys: data.has_propi_property_keys || false,
    location_property_keys: data.location_property_keys || '',
    address_id: data.address_id,
  };

  if (data.residential_id) apiData.residential_id = data.residential_id;

  if (data.proper_id) apiData.proper_id = data.proper_id;

  if (data.currency) apiData.currency = data.currency;

  return apiData;
};

const buildDataMonths = (months: string[]) => {
  return months.filter((el) => !isNaN(parseInt(el))).map((el) => parseInt(el));
};

/**
 * This function transforms separated params into
 * single object array with all characteristic
 * @param rooms
 * @param bathrooms
 * @param half_bathrooms
 * @param parking
 * @param mts
 * @param characteristics
 * @returns
 */
export const buildCharacteristics = (
  characteristics: CharacteristicApi[] = [],
  rooms?: string,
  bathrooms?: string,
  half_bathrooms?: string,
  parking?: string,
  mts?: number,
) => {
  const response = [];
  for (const element of characteristics) {
    switch (element.special_code) {
      case 'RMS':
        if (rooms) {
          response.push({
            characteristic_id: element.id,
            measure: parseInt(rooms),
          });
        }
        break;
      case 'PRKN':
        if (parking) {
          response.push({
            characteristic_id: element.id,
            measure: parseInt(parking),
          });
        }
        break;
      case 'FBTH':
        if (bathrooms) {
          response.push({
            characteristic_id: element.id,
            measure: parseInt(bathrooms),
          });
        }
        break;
      case 'HBTH':
        if (half_bathrooms) {
          response.push({
            characteristic_id: element.id,
            measure: parseInt(half_bathrooms),
          });
        }
        break;
      case 'MT2':
        if (mts) {
          response.push({
            characteristic_id: element.id,
            measure: mts,
          });
        }
        break;
      default:
        break;
    }
  }
  return response;
};

/**
 * Function to transform Property to characteristic info into separated
 * vars to create property form
 * @param characteristics
 */
export const buildFormCharacteristics = (
  characteristics: PropertyToCharacteristics[],
) => {
  let rooms = '',
    bathrooms = '',
    half_bathrooms = '',
    parking = '',
    mts = 0;
  for (const element of characteristics) {
    const characteristic = element.characteristic;
    switch (characteristic.special_code) {
      case 'RMS':
        rooms = parseInt(element.measure).toString(); // this is because api returns values as "1.00"
        break;
      case 'PRKN':
        parking = parseInt(element.measure).toString();
        break;
      case 'FBTH':
        bathrooms = parseInt(element.measure).toString();
        break;
      case 'HBTH':
        half_bathrooms = parseInt(element.measure).toString();
        break;
      case 'MT2':
        mts = parseInt(element.measure);
        break;
      default:
        break;
    }
  }
  return {
    rooms,
    bathrooms,
    half_bathrooms,
    parking,
    mts,
  };
};

export const buildCountryStateCityIds = (cityStateCountryData: City) => {
  const defaultOption: any = { label: '', value: 'NA' };
  let country =
      cityStateCountryData.state && cityStateCountryData.state.country
        ? {
            label: cityStateCountryData.state.country.name,
            value: cityStateCountryData.state.country.id,
          }
        : defaultOption,
    state = cityStateCountryData.state
      ? {
          label: cityStateCountryData.state.name,
          value: cityStateCountryData.state.id,
        }
      : defaultOption,
    city = {
      label: cityStateCountryData.name,
      value: cityStateCountryData.id,
    };
  return { country, state, city };
};

export const getCurrentAmenities = (
  amenities: Option[],
  assignedAmenities: PropertyToAmenities[],
) => {
  const mapCurrentAmenities = assignedAmenities.map((item) => ({
    value: item.amenity.id,
  }));
  return amenities.filter((element) =>
    mapCurrentAmenities?.some((item: any) => element.value === item.value),
  );
};

export const getCurrentAmenitiesValues = (
  assignedAmenities: PropertyToAmenities[],
): string[] => {
  return assignedAmenities.map((el) => el.amenity.id);
};

export const getOptionsBySelectedMonths = (currentMonths: string[]) => {
  return PROPERTY_MONTHS_LIST.filter((option) =>
    currentMonths.some((month) => option.value === month),
  );
};

export const getLastPropertyViewsCounterAdapter = (
  counters: IPropertyViewCounter[],
): lastCounterDetail => {
  const response: lastCounterDetail = {
    text: '',
    counter: 0,
  };
  if (!counters.length) {
    return response;
  }
  const lastCounter = counters[counters.length - 1];
  response.text = `${lastCounter.month}/${lastCounter.year}`;
  response.counter = lastCounter.counter;
  return response;
};
