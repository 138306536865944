import React, { useCallback } from 'react';
import useAuth from '../../hooks/useAuth';
import ProperDashboard from './components/ProperDashboard';
import InternalUserDashboard from './components/InternalUserDashboard';

const Dashboard = () => {
  const { getUserRealm } = useAuth();
  const userRealm = getUserRealm();

  const buildUserDashboard = useCallback(() => {
    if (userRealm.toLowerCase() === "proper")
      return (
        <ProperDashboard />
      )
    else
      return (
        <InternalUserDashboard />
      )
  }, [userRealm, getUserRealm]);

  return (
    <>
      {buildUserDashboard()}
    </>
  )
};

export default Dashboard;

