import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import { useCallback, useState } from 'react';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  ProofIncomeDetail,
  ProofIncomeForm,
  ProofIncomeResume,
  UpdateProofIncomeForm,
} from './models/proofIncomeModel';
import {
  getProofIncomeDetailEndpoint,
  updateProofIncomeEndpoint,
} from './services/accountingService';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import AccountingProofIncomeForm from './components/AccountingProofIncomeForm';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const AccountingProofIncomeUpdate: React.FC = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const pageName = 'Editar comprobante de ingreso';
  const pageParentName = 'Comprobante de ingreso';
  const parentUri = `/accounting/proof-income/${id}`;
  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getProofIncomeDetail = useCallback(
    () => getProofIncomeDetailEndpoint(id, token),
    [id, token],
  );
  const [proofIncomeDetail] = useAdminEntity<ProofIncomeDetail>(
    getProofIncomeDetail,
    setErrorAlert,
  );

  let proofIncomeSummary: ProofIncomeResume = {
    amount: '0',
    client_document: '',
    client_lastname: '',
    client_name: '',
    client_email: '',
    concept_income: '',
    generated_at: '',
    income_product: '',
    payment_income: '',
    ref_income: '',
  };

  const handleUpdateProofIncome = useCallback(
    async (form: ProofIncomeForm) => {
      const { proofIncome } = proofIncomeDetail;

      const payload: UpdateProofIncomeForm = {
        ...form,
      };

      delete payload.transaction_detail_id;
      delete payload.income_product;

      const { status, data } = await callEndpoint(
        updateProofIncomeEndpoint(proofIncome.id, payload, token),
      );

      const response = fetchResponseAdapter(data);
      if (status === 200) {
        navigate(`/accounting/proof-income/${response.data.id}`);
      } else {
        setErrorAlert('No fue posible actualizar el comprobante de ingreso');
      }
    },
    [callEndpoint, navigate, token, proofIncomeDetail],
  );

  if (!proofIncomeDetail || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  if (!proofIncomeDetail !== undefined) {
    const { clientInfo, proofIncome } = proofIncomeDetail;
    const generated_at = proofIncome.generated_at.trim().split('/');
    const formatted_date = `${generated_at[2]}-${generated_at[1]}-${generated_at[0]}`;
    proofIncomeSummary = {
      amount: proofIncome.amount_aux.toString() ?? '0',
      client_document: clientInfo.document_number,
      client_lastname: clientInfo.lastname,
      client_name: clientInfo.name,
      concept_income: proofIncome.concept_income,
      generated_at: formatted_date,
      income_product: proofIncome.income_product,
      payment_income: proofIncome.payment_income,
      ref_income: proofIncome.ref_income,
      client_email: clientInfo.email,
    };
  }

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      parentUri={parentUri}
      globalAlertOptions={globalAlertOptions}
    >
      <h2>Editar comprobante [{proofIncomeDetail.proofIncome.code}]</h2>
      <AccountingProofIncomeForm
        handleForm={handleUpdateProofIncome}
        incomeProduct={proofIncomeSummary.income_product}
        proofResume={proofIncomeSummary}
        isUpdate={true}
      />
    </AdminPage>
  );
};

export default AccountingProofIncomeUpdate;
