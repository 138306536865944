import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import AdminUploadResource from '../../components/admin/AdminUploadResource';
import AdminResourceGallery from '../../components/admin/AdminResourceGallery';
import {
  deleteModelUnitBlueprintImg,
  deleteModelUnitGalleryImg,
  getModelUnitsEndpoint, sortModelUnitBlueprintImgs, sortModelUnitGalleryImgs,
  uploadModelUnitBlueprintEndpoint,
  uploadModelUnitGalleryEndpoint,
} from './services/investorService';
import useAdminEntity from '../../hooks/useAdminEntity';
import { ModelUnitDetail } from './models/investors';

const ModelUnitUploadResources: React.FC = () => {
  const {id: investorProjectModelId = "", modelUnitId = ""} = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(investorProjectModelId, token),
    [investorProjectModelId, token]
  );
  const [modelUnits] = useAdminEntity<ModelUnitDetail[]>(getModelUnits, setErrorAlert);

  const uploadBlueprint = useCallback(async (files: Array<any>) => {
    if (files.length < 0)
      return

    const imageBlueprintRequests = files.map(file => {
      const formData = new FormData();
      formData.append("blueprint", file);
      return callEndpoint(uploadModelUnitBlueprintEndpoint(investorProjectModelId, modelUnitId, formData, token));
    })

    const responses = await Promise.all(imageBlueprintRequests);
    const responseStatuses = responses.map(response => response.status === 200);
    if (responseStatuses.indexOf(false) !== -1) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    }
    else {
      navigate(0); //make a reload
    }
  }, [callEndpoint, investorProjectModelId, modelUnitId, navigate, token]);

  const uploadModelUnitGallery = useCallback(async (files: Array<any>) => {
    if (files.length < 0)
      return

    const imageGalleryRequests = files.map(file => {
      const formData = new FormData();
      formData.append("image", file);
      return callEndpoint(uploadModelUnitGalleryEndpoint(investorProjectModelId, modelUnitId, formData, token));
    });

    const responses = await Promise.all(imageGalleryRequests);
    const responseStatuses = responses.map(response => response.status === 200);
    if (responseStatuses.indexOf(false) !== -1) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    } else {
      navigate(0); //make a reload
    }
  }, [callEndpoint, investorProjectModelId, modelUnitId, navigate, token]);

  const deleteGalleryImage = useCallback(async (galleryImgPath: string) => {
    const { status } = await callEndpoint(deleteModelUnitGalleryImg(investorProjectModelId, modelUnitId, galleryImgPath, token));
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    }
  }, [investorProjectModelId, modelUnitId, token, navigate, callEndpoint]);

  const deleteBlueprintImage = useCallback(async (blueprintImgPath: string) => {
    const { status } = await callEndpoint(deleteModelUnitBlueprintImg(investorProjectModelId, modelUnitId, blueprintImgPath, token));
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    }
  }, [investorProjectModelId, modelUnitId, token, navigate, callEndpoint]);

  const deleteAllGalleryImages = useCallback(async (galleryImgPaths: string[]) => {
    const requests = galleryImgPaths.map(
      galleryImgPath => callEndpoint(deleteModelUnitGalleryImg(investorProjectModelId, modelUnitId, galleryImgPath, token))
    );

    const responses = await Promise.all(requests);
    const responseStatuses = responses.map(response => response.status === 200);
    if (responseStatuses.indexOf(false) !== -1) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    } else {
      navigate(0); //make a reload
    }

  }, [investorProjectModelId, modelUnitId, token, callEndpoint, navigate]);

  const deleteAllBlueprintImages = useCallback(async (blueprintImgPaths: string[]) => {
    const requests = blueprintImgPaths.map(
      blueprintImgPath => callEndpoint(deleteModelUnitBlueprintImg(investorProjectModelId, modelUnitId, blueprintImgPath, token))
    );

    const responses = await Promise.all(requests);
    const responseStatuses = responses.map(response => response.status === 200);
    if (responseStatuses.indexOf(false) !== -1) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    } else {
      navigate(0); //make a reload
    }
  }, [investorProjectModelId, modelUnitId, token, callEndpoint, navigate]);

  const rearrangeBlueprintImages = useCallback(async (images: string[]) => {
    const { status } = await callEndpoint(sortModelUnitBlueprintImgs(investorProjectModelId, modelUnitId, images, token))

    if (status !== 200) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    }
  }, [callEndpoint, modelUnitId, token, investorProjectModelId]);

  const rearrangeGalleryImages = useCallback(async (images: string[]) => {
    const { status } = await callEndpoint(sortModelUnitGalleryImgs(investorProjectModelId, modelUnitId, images, token))

    if (status !== 200) {
      setErrorAlert('Lo sentimos, por el momento no es posible completar la acción');
    }
  }, [callEndpoint, investorProjectModelId, modelUnitId, token]);

  const pageName = `Recursos de proyecto`;
  const pageParentName = `Preventa`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!modelUnits || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  const currentModelUnit = modelUnits.find(modelUnit => modelUnit.id === modelUnitId);
  const blueprintGallery: Array<any> = currentModelUnit?.gallery_blueprints || [];
  const modelUnitGallery: Array<any> = currentModelUnit?.gallery || [];

  return (
    <AdminPage name={pageName} parentName={pageParentName} globalAlertOptions={globalAlertOptions}>
      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadBlueprint}
            description={"Subir plano"}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={blueprintGallery}
            description={"Plano actual"}
            onDelete={deleteBlueprintImage}
            onDeleteAll={deleteAllBlueprintImages}
            onDrag={rearrangeBlueprintImages}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadModelUnitGallery}
            description={"Subir galería"}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={modelUnitGallery}
            description={"Galería actual"}
            onDelete={deleteGalleryImage}
            onDeleteAll={deleteAllGalleryImages}
            onDrag={rearrangeGalleryImages}
          />
        </Col>
      </Row>


    </AdminPage>
  );
};

export default ModelUnitUploadResources;