import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useModalState from '../../hooks/useModalState';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  activateGuestPropertyEndpoint,
  cancelGuestPropertyEndpoint,
  getGuestPropertyDetailEndpoint,
} from './services/guestService';
import LoadingCard from '../../components/LoadingCard';
import InvestorProfileCard from '../../components/landlordInvestors/InvestorProfileCard';
import ActivateEntityInvestorModal from '../../components/landlordInvestors/ActivateEntityInvestorModal';
import CancelEntityInvestorModal from '../../components/landlordInvestors/CancelEntityInvestorModal';
import { GuestPropertyInfoDetail } from './models/guestModel';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const GuestPropertyDetail = () => {
  const { id = '' } = useParams();
  const pageName = 'Detalle de Pre reserva';
  const pageParentName = 'Pre reservas de invitados';
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [errorAlert, setErrorAlert] = useState(null);
  const [showDisableGuestPropertyModal, toggleDisableGuestPropertyModal] =
    useModalState();
  const [showActivateGuestModal, toggleActivateGuestModal] = useModalState();

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const getGuestProperty = useCallback(
    () => getGuestPropertyDetailEndpoint(id, token),
    [],
  );
  const [guestProperty] = useAdminEntity<GuestPropertyInfoDetail>(
    getGuestProperty,
    setErrorAlert,
  );

  const cancelGuestProperty = async (guestPropertyId: string) => {
    const { status, data } = await callEndpoint(
      cancelGuestPropertyEndpoint(guestPropertyId, token),
    );
    if (status === 200) {
      toggleDisableGuestPropertyModal();
      navigate('/guest/properties', { replace: true });
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
      toggleDisableGuestPropertyModal();
    }
  };

  const activateGuestProperty = async (
    guestId: string,
    guestPropertyId: string = '',
  ) => {
    const { status, data } = await callEndpoint(
      activateGuestPropertyEndpoint(guestId, guestPropertyId, token),
    );
    if (status === 200) {
      toggleActivateGuestModal();
      navigate('/guest/properties', { replace: true });
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
      toggleActivateGuestModal();
    }
  };

  if (!guestProperty || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={
        <>
          <Button
            color="warning"
            onClick={() => toggleDisableGuestPropertyModal()}
          >
            Anular solicitud
          </Button>
          <Button color="primary" onClick={() => toggleActivateGuestModal()}>
            Aceptar solicitud
          </Button>
        </>
      }
    >
      <Row>
        {/* Profile section */}
        <Col className="order-xl-2 pt-10" xl="4">
          <InvestorProfileCard guestInfo={guestProperty.guestInfo} />
        </Col>
        <Col>
          {/* Visit detail info */}
          <Card>
            <CardBody>
              <ListGroup className="list my--3" flush>
                <h6 className="heading-small text-muted mt-4 mb-4">
                  Información de propiedad
                </h6>
                <ListGroupItem>
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">Tipo</h4>
                    </div>
                    <Col className="col-auto">
                      <span className="mr-2">
                        {guestProperty.guestPropertyInfo.property_type}
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">Ciudad</h4>
                    </div>
                    <Col className="col-auto">
                      <span className="mr-2">
                        {guestProperty.guestPropertyInfo.property_city}
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ActivateEntityInvestorModal
        guestId={guestProperty.guestInfo.id}
        guestPropertyId={id}
        isOpen={showActivateGuestModal}
        onToggle={toggleActivateGuestModal}
        onConfirmation={activateGuestProperty}
        bodyText={
          guestProperty.guestInfo.is_registered
            ? 'Al aceptar asignaras el lead al propietario encontrado.'
            : 'Al activar el guest aceptas todas las solicitudes de creación de propiedades, estas serán creadas a partir del tipo de ciudad y propiedad, los demás campos serán llenados con placeholders para que puedas modificarlos y como último paso publicar la propiedad. Además vuelves al guest un Landlord.'
        }
        confirmationText={'Entiendo, continuar'}
        abortText={'No, seguir revisando...'}
      />
      <CancelEntityInvestorModal
        guestId={id}
        isOpen={showDisableGuestPropertyModal}
        onToggle={toggleDisableGuestPropertyModal}
        onConfirmation={cancelGuestProperty}
        bodyText={
          'Al anular la solicitud la propiedad no será creada. El guest no tiene cambios, este puede enviar solicitudes en el futuro.'
        }
        confirmationText={'Entiendo, anular'}
        abortText={'No, seguir revisando...'}
        titleText={'Anular solicitud'}
      />
    </AdminPage>
  );
};

export default GuestPropertyDetail;
