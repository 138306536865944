import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useAdminEntity from '../../hooks/useAdminEntity';
import { WithdrawalDetailInfo } from './models/withdrawalModel';
import {
  approveWithdrawalEndpoint,
  deniedWithdrawalEndpoint,
  getWithdrawalDetailEndpoint,
} from './services/withdrawalService';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import { Button, Col, Row } from 'reactstrap';
import WithdrawalInfoCard from './components/WithdrawalInfoCard';
import WithdrawalLogActionCard from './components/WithdrawalLogActionCard';
import useModalState from '../../hooks/useModalState';
import BasicModal from '../../components/modals/BasicModal';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const WithdrawalDetail: React.FC = () => {
  const { id: withdrawalId = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const navigate = useNavigate();

  const [showDeniedWithdrawal, toogleDeniedWithdrawalModal] = useModalState();
  const [showAproveWithdrawal, toogleAproveWithdrawalModal] = useModalState();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getWithdrawalDetail = useCallback(
    () => getWithdrawalDetailEndpoint(withdrawalId, token),
    [],
  );

  const [withdrawal] = useAdminEntity<WithdrawalDetailInfo>(
    getWithdrawalDetail,
    setErrorAlert,
  );

  const pageName = 'Detalle de desestimiento';
  const pageParentName = 'Lista de desestimientos';
  const parentUri = '/withdrawal';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const deniedWithdrawal = useCallback(async (withdrawalId: string) => {
    const { status, data } = await callEndpoint(
      deniedWithdrawalEndpoint(withdrawalId, token),
    );
    const response = fetchResponseAdapter(data);

    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert(response.message);
    }
  }, []);

  const aproveWithdrawal = useCallback(async (withdrawalId: string) => {
    const { status, data } = await callEndpoint(
      approveWithdrawalEndpoint(withdrawalId, token),
    );
    const response = fetchResponseAdapter(data);

    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert(response.message);
    }
  }, []);

  if (!withdrawal || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const pageFooter = (
    <>
      {withdrawal.status === 'created' && (
        <>
          <Button color="success" onClick={() => toogleAproveWithdrawalModal()}>
            Aprobar
          </Button>
          <Button color="warning" onClick={() => toogleDeniedWithdrawalModal()}>
            Denegar
          </Button>
        </>
      )}
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      parentUri={parentUri}
      footer={pageFooter}
    >
      <Row>
        <Col sm={6}>
          <WithdrawalInfoCard withdrawal={withdrawal} />
        </Col>
      </Row>
      <Col sm={6}>
        <WithdrawalLogActionCard actions={withdrawal.actions} />
      </Col>
      <BasicModal
        abortText={'No, seguir revisando...'}
        bodyText={`Estas seguro de anular el desestimiento de ${withdrawal.initial_resume}?, al anularlo tienes que crear otro`}
        confirmationText={'Anular desestimiento'}
        titleText={'Anular desestimiento'}
        isOpen={showDeniedWithdrawal}
        onConfirmation={deniedWithdrawal}
        onToggle={toogleDeniedWithdrawalModal}
        payload={withdrawalId}
      />
      <BasicModal
        abortText={'No, seguir revisando...'}
        bodyText={`Estas seguro de aprobar el desestimiento?, al aceptarlo se activará la unidad ${
          withdrawal.initial_resume
        } ${
          withdrawal.ref_change_resume
            ? `Adicionalmente se asignará la unidad ${withdrawal.ref_change_resume}`
            : ''
        }`}
        confirmationText={'Aprobar desestimiento'}
        titleText={'Aprobar desestimiento'}
        isOpen={showAproveWithdrawal}
        onConfirmation={aproveWithdrawal}
        onToggle={toogleAproveWithdrawalModal}
        payload={withdrawalId}
      />
    </AdminPage>
  );
};

export default WithdrawalDetail;
