import { useState, useEffect } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import useProjectTowerLayout from './hooks/useProjectTowerLayout';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useProjectFloorLayout from './hooks/useProjectFloorLayout';
import useCreateImageFloor from './hooks/useCreateImageFloor';

const InvestorProjectFloorLayout = () => {
  const { id = '', id_dist } = useParams();
  const {
    currentBack,
    handleSubmit,
    isEditing,
    errors,
    control,
    setValue,
    isLoading,
    resetForm,
    canvasRef,
    handleClick,
    setIsEditing,
    selectPolygonStatus,
    floorInfo,
    loadUnitsModelsData,
    sendLevelToCreate,
    sendLevelToUpdate,
    units,
    loadPolygonTowerData,
    imageSize
  } = useProjectFloorLayout();

  const {
    handleSubmit: submitImage,
    register: registerImage,
    addCurrentImage,
    errorAlert,
    successAlert,
    setValue: addValueImage
  } = useCreateImageFloor();

  const [searchValue, setSearchValue] = useState('');
  const [filteredUnits, setFilteredUnits] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    loadPolygonTowerData(id);
    loadUnitsModelsData(id);
  }, [id]);

  useEffect(() => {
    if (searchValue) {
      setFilteredUnits(
        units?.filter((item: any) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setFilteredUnits(units);
    }
  }, [searchValue, units]);

  const handleFileChange = (event: any) => {
    event.stopPropagation();
    const file = event.target.files?.[0] || null;
    addValueImage('cover', file);
  };

  const handleImageSubmit = (form: any) => {
    const formData = new FormData();
    if (form.cover) {
      formData.append('cover', form.cover);
    }

    const useDistributionId = floorInfo?.recommended_distribution_id;
    if (useDistributionId && !form.cover) {
      formData.append('use_distribution_id', useDistributionId);
    }

    addCurrentImage(formData);
  };

  const handleSelectSuggestion = (unit: any) => {
    setSearchValue(`${unit.model_name} - ${unit.name}`);
    setValue('unit_id', unit.id);
    setShowSuggestions(false); // Oculta las sugerencias después de seleccionar
  };


  return (
    <AdminPage name={'Configuración de Piso'} parentName={`Proyecto`}>
      <Row>
        <Form className='w-100' encType={'multipart/form-data'} onSubmit={submitImage(handleImageSubmit)}>
          <Col md="6" className='mx-auto'>
            {currentBack && (
              <div className='w-full'>
                <Alert color="warning">
                  Al parecer una imagen de piso ya fue subida, si quieres usar esa foto puede crearlos en la que se te muestra a continuación, si no puedes subir una nueva imagen para este piso.
                </Alert>
              </div>
            )}
            <p className='fw-bolder'>Asignar imagen de Piso seleccionado</p>
            <div className="custom-file">
              <Input
                id="exampleFile"
                name="file"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
            <hr />
            {errorAlert && <Alert color="danger">Ocurrió un error, intente más tarde!</Alert>}
            {successAlert && <Alert color="success">Imagen asignada con éxito!</Alert>}
            <div className='col-md-12 d-flex justify-content-center'>
              <Button color="primary" type='submit'>
                Asignar imagen de piso existente
              </Button>
            </div>
          </Col>
          <hr />
        </Form>

        {floorInfo?.unit_distribution && floorInfo?.unit_distribution.length > 0 && (
          <Col sm="8" className={`mb-4 d-flex ${selectPolygonStatus ? 'justify-content-around' : 'justify-content-center'} mx-auto`}>
            <div className='d-flex justify-content-center'>
              <Button color="primary" onClick={() => setIsEditing(true)}>
                Actualizar polígonos
              </Button>
              {isEditing && (
                <Button color='' onClick={() => setIsEditing(false)}>
                  Quitar edición
                </Button>
              )}
            </div>
            {selectPolygonStatus && (
              <div>
                <Button color='success'>
                  Asignar polígonos a nivel
                </Button>
              </div>
            )}
          </Col>
        )}

        <Col md="12" className="text-center">
          <canvas
            ref={canvasRef}
            width={imageSize?.width || 1440}
            height={imageSize?.height || 854}
            onClick={floorInfo?.level_facade_background === null ? () => {} : handleClick}
            style={{ cursor: 'crosshair' }}
          />
        </Col>

        <Col md="12">
          <Form role="form" onSubmit={handleSubmit(isEditing ? sendLevelToUpdate : sendLevelToCreate)}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="quotas">
                    Selecciona el modelo de la unidad
                  </label>

                  <Input
                    type="text"
                    placeholder="Buscar modelo de unidad..."
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      setShowSuggestions(true);
                    }}
                    onFocus={() => setShowSuggestions(true)} // Muestra las sugerencias al hacer foco
                  />

                  {/* Sugerencias de autocompletado */}
                  {showSuggestions && searchValue && (
                    <div className="suggestions-box" style={{ border: '1px solid #ccc', borderRadius: '4px', maxHeight: '200px', overflowY: 'auto' }}>
                      {filteredUnits?.map((item: any) => (
                        <div
                          key={item.id}
                          className="suggestion-item"
                          style={{ padding: '10px', cursor: 'pointer' }}
                          onClick={() => handleSelectSuggestion(item)}
                        >
                          {item.name} - {item.model_name}
                        </div>
                      ))}
                      {filteredUnits?.length === 0 && (
                        <div className="no-results" style={{ padding: '10px', color: 'gray' }}>
                          No se encontraron resultados
                        </div>
                      )}
                    </div>
                  )}

                  <Controller
                    name="unit_id"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="hidden"
                        {...field}
                        value={field.value || ''}
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.unit_id?.message}</h5>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button color="primary" disabled={isLoading}>
                  {isEditing ? 'Actualizar' : 'Crear polígono del piso'}
                </Button>
                <Button onClick={resetForm} color="warning">
                  Limpiar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectFloorLayout;
