import React, { useState } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import { UpdatePropertySalesStateForm } from '../models/propertyModel';
import {
  PROPERTY_SALE_STATUS_VALUES,
  PROPERTY_STATUS_VALUES,
} from '../constants/propertyConstants';

export interface Props {
  // entity: any;
  property_id: string;
  isOpen: boolean;
  marketplace_type?: 'rent' | 'sale';
  onToggle: () => void;
  // onConfirmation: (entity: any, reason: string, comments: string) => void;
  onConfirmation: (
    property_id: string,
    form: UpdatePropertySalesStateForm,
  ) => void;
  warningText: string;
  confirmationText: string;
  abortText: string;
}
const TakeDownPropertyModal: React.FC<Props> = ({
  // entity,
  property_id,
  isOpen,
  marketplace_type = 'rent',
  onToggle,
  onConfirmation,
  warningText,
  confirmationText,
  abortText,
}) => {
  const [takeDownReason, setTakeDownReason] = useState<string>('');
  const [takeDownAdditionalComments, setTakeDownAdditionalComments] =
    useState<string>('');
  const isValid = () =>
    takeDownReason !== '' && takeDownAdditionalComments !== '';
  const takeDownOptions = [
    {
      value: PROPERTY_STATUS_VALUES.DISABLED,
      label: 'Deshabilitar',
    },
    {
      value: PROPERTY_STATUS_VALUES.RENTED_EXTERNAL,
      label: 'Rentada fuera de la plataforma',
    },
  ];

  const takeDownSalesOptions = [
    {
      value: PROPERTY_SALE_STATUS_VALUES.DISABLED,
      label: 'Deshabilitar',
    },
    {
      value: PROPERTY_SALE_STATUS_VALUES.SOLD_EXTERNAL,
      label: 'Vendida fuera de la plataforma',
    },
  ];

  const displayOptions = () =>
    marketplace_type === 'sale' ? takeDownSalesOptions : takeDownOptions;

  const confirmationModal = (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Tu atención es requerida
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">Quitar del marketplace</h4>
          <p>
            Completa la razón por la cual estás quitando la propiedad del
            marketplace
          </p>
          <Input
            type="select"
            onChange={(event) => setTakeDownReason(event.target.value)}
          >
            <option value="">Selecciona una razón</option>
            {displayOptions().map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          <p className="mt-3">Comentarios adicionales</p>
          <Input
            type="textarea"
            value={takeDownAdditionalComments}
            onChange={(event) =>
              setTakeDownAdditionalComments(event.target.value)
            }
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          disabled={!isValid()}
          onClick={() =>
            onConfirmation(property_id, {
              status: takeDownReason,
              comment: takeDownAdditionalComments,
            })
          }
        >
          {confirmationText}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );

  return <>{confirmationModal}</>;
};

export default TakeDownPropertyModal;
