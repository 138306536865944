import React, { useCallback, useMemo, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import {
  createProofOutcomeEndpoint,
  getProofsOutcomeSummaryEndpoint,
} from './services/accountingService';
import AccountingProofOutcomeForm from './components/AccountingProofOutcomeForm';
import {
  ProofOutcomeForm,
  ProofOutcomeSummary,
} from './models/proofIncomeModel';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const AccountingProofOutcomeResume: React.FC = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const payload = useMemo(
    () => JSON.parse(searchParams.get('state') || '{}'),
    [searchParams],
  );

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getProofOutcomeSummary = useCallback(
    () =>
      getProofsOutcomeSummaryEndpoint(
        createSearchParams({
          proof_incomes: payload.selectedProofIncomes,
          income_product: payload.incomeProduct,
          income_owner_id: payload.incomeOwnerId,
        }).toString(),
        token,
      ),
    [token],
  );
  const [proofOutcomeSummary] = useAdminEntity<ProofOutcomeSummary>(
    getProofOutcomeSummary,
    setErrorAlert,
  );

  if (proofOutcomeSummary) {
    payload.incomeOwnerId = proofOutcomeSummary.owner_id;
  }

  const handleCreateProofOutcome = useCallback(
    async (form: ProofOutcomeForm) => {
      const { status, data } = await callEndpoint(
        createProofOutcomeEndpoint(form, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 201) {
        navigate(`/accounting/proof-outcome/${response.data.id}`);
      } else {
        setErrorAlert('No fue posible generar comprobante de egreso');
      }
    },
    [callEndpoint, navigate, token],
  );

  const pageName = 'Resumen comprobante de egresos';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  if (isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <h1>Resumen</h1>
      <AccountingProofOutcomeForm
        handleForm={handleCreateProofOutcome}
        proofResume={proofOutcomeSummary}
        outcomeProofPayload={payload}
      />
    </AdminPage>
  );
};

export default AccountingProofOutcomeResume;
