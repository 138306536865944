import { useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import useAdminSales from './hooks/useAdminSales';
import { useParams } from 'react-router-dom';
import { PropertySalesPrices } from './components/Sales/PropertySalesPrices';
import PropertySalesInfo from './components/Sales/PropertySalesInfo';

const AdminPropertySales = () => {
  const { id: propertyId = '' } = useParams();
  const pageName = 'Administrar información de venta';
  const pageParentName = 'Propiedades';
  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };

  const { propertyPricesSaleStatus } = useAdminSales({ propertyId });

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col md="6">
          <Row>
            {/* form prices and state for sales */}
            <Col md="12">
              {propertyPricesSaleStatus && (
                <PropertySalesPrices
                  property_id={propertyId}
                  property_sale_min_price_value={
                    propertyPricesSaleStatus.sale_min_price || 0
                  }
                  property_sale_price_value={
                    propertyPricesSaleStatus.sale_price || 0
                  }
                  property_sale_state={
                    propertyPricesSaleStatus.sale_state || ''
                  }
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <PropertySalesInfo
        propertyId={propertyId}
        setErrorAlert={setErrorAlert}
        baseSalePrice={propertyPricesSaleStatus?.sale_price || 0}
      />
      {/* Upload files */}
    </AdminPage>
  );
};

export default AdminPropertySales;
