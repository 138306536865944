import { useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useEffect, useState } from 'react';
import { IPropertyViewCounter } from './models/propertyModel';
import { getAdminPropertyViewCounterEndpoint } from './services/propertyService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import AdminListGroup from '../../components/admin/AdminListGroup';
import { Col, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import usePropertyCounterViews from './hooks/usePropertyCounterViews';

const PropertyCounterViews = () => {
  const pageName = 'Vistas por propiedad';
  const pageParentName = 'Administrar propiedad';
  const { id = '' } = useParams();
  const { isLoading, propertyCounterView } = usePropertyCounterViews(id);

  if (isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  const data = propertyCounterView?.map((property) => {
    return {
      title: `${property.month}/${property.year}`,
      value: property.counter,
    };
  });

  return (
    <AdminPage name={pageName} parentName={pageParentName}>
      <Row>
        <Col lg="4">
          <AdminListGroup
            title={'Cantidad de vistas de la propiedad'}
            items={data ?? []}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default PropertyCounterViews;
