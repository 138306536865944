import React, { useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import AdminPaginator from '../../components/admin/AdminPaginator';
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { createSearchParams } from 'react-router-dom';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { getGuestPropertiesEndpoint } from './services/guestService';
import { GuestPropertyList } from './models/guestModel';

const GuestProperties = () => {

  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = "Propiedades de invitados";
  const pageParentName = "Propiedades";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const [guestProperty, setGuestProperty] = useState<GuestPropertyList[]>([])

  const loadGuestProperties = async (page: number = 0, take: number = 0) => {
    let filterBuilder: any = {};
    if (page > 0) {
      filterBuilder.page = page.toString();
    }
    if (take > 0) {
      filterBuilder.take = take.toString();
    }
    const queryString = `?${createSearchParams(filterBuilder)}`;
    const {
      status, data
    } = await callEndpoint(getGuestPropertiesEndpoint(queryString, token));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestProperty(response.data.items || []);
      const { page, pageCount } = response.data.meta;
      setPaginator({ page, pageCount });
    } else {
      setErrorAlert(response.message);
    }
  };

  useEffect(() => {
    loadGuestProperties()
      .catch(e => console.error(e))
  }, [])

  if (isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={
        <AdminPaginator
          page={paginator.page}
          totalPages={paginator.pageCount}
          onPageChange={loadGuestProperties}
        />
      }
      globalAlertOptions={globalAlertOptions}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Email</th>
            <th scope="col">Tipo de inmueble</th>
            <th scope="col">Ciudad</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {guestProperty.length > 0 &&
            guestProperty.map((guestProperty: GuestPropertyList) => (
              <tr
                key={`${guestProperty.id}`}
              >
                <td>{`${guestProperty.fullname}`}</td>
                <td>{guestProperty.phone}</td>
                <td>{guestProperty.email}</td>
                <td>{guestProperty.property_type}</td>
                <td>{guestProperty.property_city}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/guest-properties/${guestProperty.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default GuestProperties;
