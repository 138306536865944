const monthNames = [
    'Enero', 
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
]

export const getStringDate = (date: Date | null) => {
    if(!date) return 'N/A'
    const day = date.getDate()
    const month = date.getMonth() + 1 // because getMonth returns month index
    const year = date.getFullYear()
    const monthString = month < 10 ? `0${month}` : month.toString()
    const dayString = day < 10 ? `0${day}` : day.toString()
    return `${year}-${monthString}-${dayString}`
}

export const formatStandarCalendarDate = (date: Date | null, includeTime: boolean = true) => {
    if(!date) return 'N/A'
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    const hours = date.getHours() < 10 
        ? `0${date.getHours()}` : date.getHours()
    const minutes = date.getMinutes() < 10
        ? `0${date.getMinutes()}` : date.getMinutes()
    
    return `${day} de ${getMonthName(month)} de ${year} ${ includeTime ? `a las: ${hours}:${minutes}` : '' }`
}

/**
 * 
 * @param monthIndex - [0-11] - (January gives 0)
 */
const getMonthName = (monthIndex: number) => {
    return monthNames[monthIndex]
}

export const getHoursMinutesFromMinutes = (sumMinutes: number) => {
    const hours = (sumMinutes / 60);
    const hoursCalculated = Math.floor(hours);
    const minutes = (hours - hoursCalculated) * 60;
    const minutesCalculated = Math.round(minutes);
    return {
        hours: hoursCalculated,
        minutes: minutesCalculated
    }
}