import { useEffect, useState } from 'react';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { getPropertyToEditEndpoint } from '../services/propertyService';
import { PropertyUpdateDataApi } from '../models/propertyModel';

interface Props {
  propertyId: string;
}
const useAdminRent = ({ propertyId }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [formUpdateData, setFormUpdateData] = useState<PropertyUpdateDataApi>();

  const loadPropertyToUpdate = async (propertyId: string) => {
    const { status, data } = await callEndpoint(
      getPropertyToEditEndpoint(propertyId, token),
    );
    if (status === 200) {
      const { data: formData } = fetchResponseAdapter(data);
      setFormUpdateData(formData);
    }
  };

  useEffect(() => {
    loadPropertyToUpdate(propertyId).catch((e) => console.error(e));
  }, []);

  return { formUpdateData };
};

export default useAdminRent;
