import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { Option } from '../../../models/formModel';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { WithdrawalPreSaleForm } from '../models/withdrawalModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  getFinanceByUnitEndpoint,
  getProjectListEndpoint,
  getUnitsAvailable,
  getUnitsTaken,
} from '../services/projectService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getWithdrawalReasonPreSaleEndpoint } from '../services/withdrawalPreSaleService';
import SimpleCardTable from '../../../components/SimpleCardTable';

interface Props {
  handleForm: (form: WithdrawalPreSaleForm) => void;
}

interface FinanceByUnit {
  type: string;
  value: string;
}

interface rowItem {
  specialRow: boolean;
  coloredRow: string;
  rows: string[];
}

type unitTypes = 'available' | 'taken';

const WithdrawalFormPreSale: React.FC<Props> = ({ handleForm }) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();

  //form

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<WithdrawalPreSaleForm>({
    resolver: yupResolver(
      yup
        .object({
          unit_id: yup.string().required('Requerido'),
          type: yup.string().default('regular'),
          reason: yup.string().required('Requerido'),
          explain_reason: yup.string().required('Requerido'),
          generated_at: yup.string().required('Requerido'),
          initial_resume: yup.string().default(''),
          ref_change_resume: yup.string().default(''),
          change_unit_id: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value)),
        })
        .required(),
    ),
  });

  const [navPills, setNavPills] = useState(1);

  const toggleNavs = (e: any, index: number) => {
    e.preventDefault();
    if (index === 1) {
      setValue('type', 'regular');
      setValue('change_unit_id', '');
    }
    if (index === 2) {
      setValue('type', 'change_unit');
    }
    setNavPills(index);
  };

  const [withdrawalPreSaleReasonOption, setWithdrawalPreSaleReasonOption] =
    useState<Option[]>([]);

  const [projectOption, setProjectOption] = useState<Option[]>([]);
  const [projectOptionSelected, setProjectOptionSelected] = useState('');
  const [modelUnitTakenOption, setModelUnitTakenOption] = useState<Option[]>(
    [],
  );

  const [projectOptionAvailable, setProjectOptionAvailable] = useState<
    Option[]
  >([]);
  const [projectOptionAvailableSelected, setProjectOptionAvailableSelected] =
    useState(null);
  const [modelUnitAvailableOption, setModelUnitAvailableOption] = useState<
    Option[]
  >([]);

  const [changeUnit, setChangeUnit] = useState('');
  const [previewFinance, setPreviewFinance] = useState<string[][]>([]);
  const [rowItem, setRowItem] = useState<rowItem[]>([]);

  useEffect(() => {
    loadProjects().then().catch(console.error);
    loadWithdrawalReasons().then().catch(console.error);
  }, []);

  useEffect(() => {
    if (projectOptionSelected) {
      loadModelUnits(projectOptionSelected, 'taken')
        .then((r) => {
          setModelUnitTakenOption(r);
        })
        .catch();
    }
  }, [projectOptionSelected]);

  useEffect(() => {
    if (projectOptionAvailableSelected) {
      loadModelUnits(projectOptionAvailableSelected, 'available')
        .then((r) => {
          setModelUnitAvailableOption(r);
        })
        .catch();
    }
  }, [projectOptionAvailableSelected]);

  useEffect(() => {
    if (changeUnit) {
      //load finances
      loadUnitFinance(changeUnit)
        .then((r) => {
          // const finance: string[][] = [];
          const items: rowItem[] = [];

          r.forEach((element) => {
            const row: string[] = [element.type, element.value];
            items.push({
              specialRow: false,
              coloredRow: '',
              rows: row,
            });
            // finance.push(row);
          });

          //setPreviewFinance(finance);

          setRowItem(items);
        })
        .catch(console.error);
    }
  }, [changeUnit]);

  const loadWithdrawalReasons = async () => {
    const { status, data } = await callEndpoint(
      getWithdrawalReasonPreSaleEndpoint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setWithdrawalPreSaleReasonOption(
        listToSelectOptionAdapter(response.data, 'key', 'value', false),
      );
    }
  };

  const loadProjects = async () => {
    const { status, data } = await callEndpoint(getProjectListEndpoint(token));

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setProjectOption(
        listToSelectOptionAdapter(response.data, 'id', 'name', false),
      );
      setProjectOptionAvailable(
        listToSelectOptionAdapter(response.data, 'id', 'name', false),
      );
    }
  };

  const loadModelUnits = async (
    project_id: string | null,
    units: unitTypes,
  ) => {
    if (units === 'available') {
      const { status, data } = await callEndpoint(
        getUnitsAvailable(project_id, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const processed = [...response.data];
        const units = processed.map((unit) => {
          return {
            id: unit.unit_id,
            name: `${unit.project_name} - ${unit.model_name} - [${unit.unit_name}]`,
          };
        });
        return listToSelectOptionAdapter(units, 'id', 'name', false);
      }
    } else if (units === 'taken') {
      const { status, data } = await callEndpoint(
        getUnitsTaken(project_id, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const processed = [...response.data];
        const units = processed.map((unit) => {
          return {
            id: `${unit.unit_id} * ${unit.landlord_id}`,
            name: `${unit.project_name} - ${unit.model_name} - [${unit.unit_name}] - ${unit.landlord_name}`,
          };
        });
        return listToSelectOptionAdapter(units, 'id', 'name', false);
      }
    }

    return listToSelectOptionAdapter([], 'id', 'name', false);
  };

  const loadUnitFinance = async (unit_id: string): Promise<FinanceByUnit[]> => {
    const { status, data } = await callEndpoint(
      getFinanceByUnitEndpoint(unit_id, token),
    );

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      const result: FinanceByUnit[] = [...response.data];

      return result;
    } else {
      return [];
    }
  };

  //handle submit
  const createWithdrawal = async (form: WithdrawalPreSaleForm) => {
    const auxForm = { ...form };
    const ids = auxForm.unit_id.replaceAll(' ', '').split('*');
    auxForm.unit_id = ids[0];
    auxForm.landlord_id = ids[1] ?? null;

    handleForm(auxForm);
  };

  return (
    <Form role="form">
      <Col md={6}>
        <Row>
          {/* buscar unidad a desestimar */}
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="project_taken">
                Selecciona proyecto
              </label>
              <Select
                placeholder={'Selecciona el proyecto'}
                options={projectOption}
                onChange={(selected: any) => {
                  setProjectOptionSelected(selected.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="unit_id">
                Selecciona unidad a desestimar
              </label>
              <Controller
                name="unit_id"
                control={control}
                key={'unit_id-key'}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder={'Selecciona unidad a desestimar'}
                    options={modelUnitTakenOption}
                    onChange={(selected: any) => {
                      setValue('initial_resume', selected.label);
                      setValue('unit_id', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
              <h5 className="text-danger">{errors.unit_id?.message}</h5>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={navPills === 1}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: navPills === 1,
                  })}
                  onClick={(e) => toggleNavs(e, 1)}
                  role="tab"
                >
                  <i className="ni ni-archive-2 mr-2" />
                  Desestimiento
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={navPills === 2}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: navPills === 2,
                  })}
                  onClick={(e) => toggleNavs(e, 2)}
                  role="tab"
                >
                  <i className="ni ni-archive-2 mr-2" />
                  Cambio de unidad
                </NavLink>
              </NavItem>
            </Nav>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={'tabs' + navPills}>
                  <TabPane tabId="tabs1">
                    <p className="description">
                      Al ingresar una solicitud de desestimiento, se procederá a
                      revisar que la información ingresada sea correcta
                      validando los datos con la desarrolladora correspondiente.
                      Al aceptarse se procederá a liberar la unidad anterior.
                    </p>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <p className="description">
                      Para este caso se debe de seleccionar la nueva unidad de
                      las que se encuentren disponibles, seleccionando el
                      proyecto correspondiente y luego el modelo / unidad libre,
                      al aceptarse se procederá a liberar la unidad anterior
                    </p>
                    <br />
                    <FormGroup>
                      <label className="form-control-label" htmlFor="unit_id">
                        Selecciona proyecto
                      </label>
                      <Select
                        placeholder={'Selecciona el proyecto'}
                        options={projectOptionAvailable}
                        onChange={(selected: any) => {
                          setProjectOptionAvailableSelected(selected.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="unit_id">
                        Selecciona nueva unidad de las disponibles
                      </label>
                      <Controller
                        name="change_unit_id"
                        control={control}
                        key={'change_unit_id-key'}
                        render={({ field: { ref } }) => (
                          <Select
                            placeholder={'Selecciona nueva unidad'}
                            options={modelUnitAvailableOption}
                            onChange={(selected: any) => {
                              setValue('ref_change_resume', selected.label);
                              setValue('change_unit_id', selected.value);
                              setChangeUnit(selected.value);
                            }}
                            ref={ref}
                          />
                        )}
                      />
                    </FormGroup>
                    {rowItem.length > 0 && (
                      <SimpleCardTable
                        title="Visualización de datos financieros"
                        cardHeader={['Detalle', 'Valor']}
                        rows={rowItem}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="reason">
                Selecciona motivo de desestimiento
              </label>
              <Controller
                name="reason"
                control={control}
                key={'reason-key'}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder={'Selecciona motivo de desestimiento'}
                    options={withdrawalPreSaleReasonOption}
                    onChange={(selected: any) => {
                      setValue('reason', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
              <h5 className="text-danger">{errors.reason?.message}</h5>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="form-control-label" htmlFor="explain_reason">
              Explica motivo de desestimiento
            </label>
            <Controller
              name="explain_reason"
              control={control}
              //defaultValue={proofResume?.explain_reason ?? ''}
              key={`explain_reason-key`}
              render={({ field }) => (
                <Input
                  className={errors.explain_reason?.message && 'is-invalid'}
                  {...field}
                  type="textarea"
                  placeholder="Explica la razón del desestimiento"
                />
              )}
            />
            <h5 className="text-danger">{errors.explain_reason?.message}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="generated_at">
                Generado en
              </label>
              <Controller
                name="generated_at"
                control={control}
                //defaultValue={proofResume?.generated_at ?? ''}
                defaultValue=""
                key={`generated_at-key`}
                render={({ field }) => (
                  <Input
                    className={errors.generated_at?.message && 'is-invalid'}
                    {...field}
                    type="date"
                  />
                )}
              />
              <h5 className="text-danger">{errors.generated_at?.message}</h5>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Button color="primary" onClick={handleSubmit(createWithdrawal)}>
            Crear desestimiento
          </Button>
        </Row>
      </Col>
    </Form>
  );
};

export default WithdrawalFormPreSale;
