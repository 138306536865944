import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import { clearMoneyStringToNumber } from '../../helpers/stringHelper';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import ManageModelUnitForm from './components/ManageModelUnitForm';
import { ModelUnitDetail, ModelUnitForm } from './models/investors';
import {
  getModelUnitsEndpoint,
  updateModelUnitEndpoint,
} from './services/investorService';

const ModelUnitUpdate = () => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { id: projectModelId = '', modelUnitId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const navigate = useNavigate();
  const getModelUnits = useCallback(
    () => getModelUnitsEndpoint(projectModelId, token),
    [projectModelId],
  );
  const [modelUnits] = useAdminEntity<ModelUnitDetail[]>(
    getModelUnits,
    setErrorAlert,
  );

  const handleModeulUnitUpdate = async (form: ModelUnitForm) => {
    const { status, data } = await callEndpoint(
      updateModelUnitEndpoint(form, projectModelId, modelUnitId, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200)
      navigate(`/investors/models/${projectModelId}/model-units`);
    else setErrorAlert(response.message);
  };

  const pageName = 'Actualizar una unidad';
  const parentPageName = 'Preventa';
  const parentUri = `/investors/models/${projectModelId}/model-units`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  if (!modelUnits || isLoading) {
    return <LoadingCard name={pageName} parent_name={parentPageName} />;
  }

  const currentModelUnit = modelUnits.find(
    (modelUnit) => modelUnit.id === modelUnitId,
  );
  const modelUnit: ModelUnitForm = {
    name: currentModelUnit?.name || '',
    base_price: currentModelUnit?.base_price
      ? clearMoneyStringToNumber(currentModelUnit?.base_price)
      : 0,
    area: currentModelUnit?.area || 0,
    square_rods: currentModelUnit?.square_rods || 0,
    bathrooms: currentModelUnit?.bathrooms || 0,
    bedrooms: currentModelUnit?.bedrooms || 0,
    parking: currentModelUnit?.parking || 0,
    level: currentModelUnit?.level || 0,
    distribution: currentModelUnit?.distribution,
  };

  return (
    <AdminPage
      name={pageName}
      parentName={parentPageName}
      parentUri={parentUri}
      globalAlertOptions={globalAlertOptions}
    >
      <ManageModelUnitForm
        handleForm={handleModeulUnitUpdate}
        modelUnit={modelUnit}
      />
    </AdminPage>
  );
};

export default ModelUnitUpdate;
