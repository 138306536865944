import React, { useState } from 'react';
import useHomeSlider from './hooks/useHomeSlider';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { Controller } from 'react-hook-form';
import { checkOptions } from '../../constants/formConstants';

const HomeSlider = () => {
  const pageName = 'Administrador de Carrusell Home';
  const pageParentName = 'Configuraciones';
  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };

  const {
    // form
    control,
    register,
    errors,
    handleSubmit,
    handleAction,
    // loading and states
    isLoading,
    setIsCheckedHasAction,
    isCheckedHasAction,
    // for update
    homeSliderItemSelected,
    selectHomeSliderItem,
    clearHomeSliderItem,
    // init items
    homeSliderItems,
  } = useHomeSlider({ setErrorAlert });

  if (isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">
                Items de carrusell home
              </h6>
            </CardHeader>
            <CardBody>
              <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Texto principal</th>
                    <th scope="col">Sub texto</th>
                    <th scope="col">Es clickeable?</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {homeSliderItems &&
                    homeSliderItems.length > 0 &&
                    homeSliderItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.text}</td>
                        <td>{item.sub_text}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i
                              className={`${
                                item.has_action ? 'bg-success' : 'bg-warning'
                              }`}
                            />
                            {item.has_action ? 'Si' : 'No'}
                          </Badge>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => selectHomeSliderItem(item)}
                              >
                                Modificar
                              </DropdownItem>
                              <DropdownItem
                                href={`/settings/home-slider/${item.id}/photo`}
                              >
                                Administrar Banner
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">
                {homeSliderItemSelected?.id ? 'Actualizar' : 'Crear'} item de
                carrusell
              </h6>
            </CardHeader>
            <Form role="form" onSubmit={handleSubmit(handleAction)}>
              <CardBody>
                <FormGroup>
                  <label className="form-control-label" htmlFor="first_name">
                    Texto principal
                  </label>
                  <Controller
                    name="text"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Input
                        className={errors.text?.message && 'is-invalid'}
                        {...field}
                        id="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.text?.message}</h5>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="first_name">
                    Texto secundario
                  </label>
                  <Controller
                    name="sub_text"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Input
                        className={errors.sub_text?.message && 'is-invalid'}
                        {...field}
                        id="sub_text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.sub_text?.message}</h5>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <label
                        className="form-control-label"
                        htmlFor="has_white_goods"
                      >
                        Es clickeable?
                      </label>
                    </Col>
                    <Col md={6}>
                      {checkOptions.map((item: any) => (
                        <div
                          key={`has_action-${item.name}`}
                          className="custom-control custom-radio custom-control-inline"
                        >
                          <input
                            {...register('has_action')}
                            className="custom-control-input"
                            type="radio"
                            value={item.value}
                            defaultChecked={
                              item.value === homeSliderItemSelected?.has_action
                            }
                            id={`has_action-${item.name}`}
                            onChange={(e) => {
                              setIsCheckedHasAction(e.target.value === 'true');
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`has_action-${item.name}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="first_name">
                    Acción
                  </label>
                  <Controller
                    name="action_url"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <Input
                        className={errors.action_url?.message && 'is-invalid'}
                        disabled={!isCheckedHasAction}
                        {...field}
                        id="action_url"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.action_url?.message}</h5>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Button
                  color={homeSliderItemSelected?.id ? 'warning' : 'primary'}
                  disabled={isLoading}
                >
                  {homeSliderItemSelected?.id ? 'Actualizar' : 'Crear'}
                </Button>
                {homeSliderItemSelected?.id && (
                  <Button onClick={clearHomeSliderItem} color="default">
                    Limpiar
                  </Button>
                )}
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default HomeSlider;
