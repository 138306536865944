import React, { useState } from 'react';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  ProofIncomeDetail,
  ProofIncomeProduct,
} from './models/proofIncomeModel';
import {
  getLandlordByNameEndpoint,
  getProofIncomeListEndpoint,
  getTenantByNameEndpoint,
} from './services/accountingService';
import useAdminPagination from '../../hooks/useAdminPagination';
import AdminPaginator from '../../components/admin/AdminPaginator';
import GridSearchBar from '../../components/searchBar/GridSearchBar';

const AccountingProofIncomeList = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Comprobante de ingresos';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };
  const [proofIncomes, setProofIncomes] = useState<ProofIncomeDetail[]>([]);

  const { paginator, loadItems } = useAdminPagination(
    getProofIncomeListEndpoint,
    setProofIncomes,
    console.error,
  );

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  const proofIncomeFilters = [
    {
      label: 'Código de comprobante',
      value: 'code',
      apiParamName: 'propi_code',
      inputType: 'text',
      isUnique: true,
      placeholder: 'Ingresa el código de comprobante',
    },
    {
      label: 'Concepto',
      value: 'income_concept',
      apiParamName: 'income_concept',
      inputType: 'text',
      isUnique: true,
      placeholder: 'Ingresa el concepto',
    },
    {
      label: '[PreVenta] Nombre inversionista',
      value: 'landlord_name',
      apiParamName: 'client_name',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del inversionista',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.name,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: '[Renta] Nombre Inquilino',
      value: 'tenant_name',
      apiParamName: 'client_name',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del inquilino',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getTenantByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((tenant: any) => ({
            value: tenant.name,
            label: (
              <>
                <span>{tenant.name}</span>
                <small className="text-gray mx-2">
                  {tenant.phone} {tenant.email}
                </small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const proofIncomeProducts: ProofIncomeProduct[] = [
    {
      color: 'primary',
      product: 'rent',
      product_text: 'Renta',
    },
    {
      color: 'warning',
      product: 'pre_sale',
      product_text: 'PreVenta',
    },
    {
      color: 'success',
      product: 'sale',
      product_text: 'Vivienda usada',
    },
  ];

  const componentHeader = <GridSearchBar filtersList={proofIncomeFilters} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={componentPagination}
      header={componentHeader}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Tipo</th>
            <th scope="col">Monto</th>
            <th scope="col">Concepto</th>
            <th scope="col">Generado</th>
            <th scope="col">Cliente</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {proofIncomes.length > 0 &&
            proofIncomes.map((item) => (
              <tr key={`${item.proofIncome.id}`}>
                <td>{item.proofIncome.code}</td>
                <td>
                  <Badge
                    color={
                      proofIncomeProducts.find(
                        (po) => po.product === item.proofIncome.income_product,
                      )?.color
                    }
                    className="badge-lg"
                  >
                    {
                      proofIncomeProducts.find(
                        (po) => po.product === item.proofIncome.income_product,
                      )?.product_text
                    }
                  </Badge>
                </td>
                <td>{item.proofIncome.amount}</td>
                <td>{item.proofIncome.concept_income}</td>
                <td>{item.proofIncome.generated_at}</td>
                <td>{`${item.clientInfo.name} ${item.clientInfo.lastname}`}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/accounting/proof-income/${item.proofIncome.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default AccountingProofIncomeList;
