// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import { CountryStorage, useUserCountry } from '../../hooks/useUserCountry';

const TopNavBar = ({ theme, sidenavOpen, toggleSidenav }: any) => {
  const { unsetAuth } = useAuth();
  const { setCountry, getCurrentCountry, getCountries } = useUserCountry();
  const navigate = useNavigate();
  const handleLogout = () => {
    unsetAuth();
    navigate('/auth/login', { replace: true });
  };
  const currentCountry = getCurrentCountry();
  const availableCountries = getCountries();

  if(!currentCountry) {
    handleLogout()
    return <></>;
  }

  return (
    <div>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark bg-default': theme === 'dark' },
          { 'navbar-light bg-default': theme === 'light' },
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: true },
                    { 'sidenav-toggler-dark': theme === 'dark' },
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <i className="ni ni-bell-55" />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">13</strong>{" "}
                      notifications.
                    </h6>
                  </div>

                  <ListGroup flush>
                    <ListGroupItem
                      className="list-group-item-action"
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      tag="a"
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            alt="..."
                            className="avatar rounded-circle"
                            src={require("../../assets/img/theme/team-1.jpg")}
                          />
                        </Col>
                        <div className="col ml--2">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="mb-0 text-sm">John Snow</h4>
                            </div>
                            <div className="text-right text-muted">
                              <small>2 hrs ago</small>
                            </div>
                          </div>
                          <p className="text-sm mb-0">
                            Let's meet at Starbucks at 11:30. Wdyt?
                          </p>
                        </div>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>

                  <DropdownItem
                    className="text-center text-info font-weight-bold py-3"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link" color="" tag="a">
                  <span className="shortcut-media avatar bg-gradient-transparent h-auto">
                    <img src={currentCountry?.flag} />
                  </span>
                </DropdownToggle>
                {currentCountry && availableCountries.length > 1 && (
                  <DropdownMenu
                    className="dropdown-menu-lg dropdown-menu"
                    right
                  >
                    <Row className="shortcuts px-3">
                      {availableCountries.map((country: CountryStorage) => (
                        <Col
                          key={`cCode-${country.code}`}
                          id={`cCode-${country.code}`}
                          className="shortcut-item"
                          href="#"
                          onClick={() => setCountry(country.code)}
                          xs="6"
                          tag="a"
                        >
                          <span className="shortcut-media avatar bg-gradient-transparent h-auto">
                            <img src={country.flag} />
                          </span>
                          <small className="">{country.code}</small>
                        </Col>
                      ))}
                    </Row>
                  </DropdownMenu>
                )}
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={DEFAULT_AVATAR} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        Sesión iniciada
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>
                    <div></div>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default TopNavBar;
