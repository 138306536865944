import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { DEFAULT_AVATAR } from '../../../constants/profileConstants';
import { ContractDetailData } from '../models/contractModel';
import { Option } from '../../../models/formModel';
import Select from 'react-select';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  unsetBrokerContractEndpoint,
  updatetBrokerContractEndpoint,
} from '../services/contractService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { useNavigate } from 'react-router-dom';
interface IProps {
  contract_id: string;
  contract: ContractDetailData;
  brokers: Option[];
  onError: (errorText: string) => void;
}

const ContractPartiesCard: React.FC<IProps> = ({
  contract_id,
  contract,
  brokers,
  onError,
}) => {
  const navigate = useNavigate();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [broker, setBroker] = useState<Option>({
    label: 'Seleccionar broker a asignar',
    value: '',
  });
  const [loadingBroker, setLoadingBroker] = useState<boolean>(true);

  useEffect(() => {
    const currentBroker = brokers.find((el) => el.value === contract.broker_id);
    if (currentBroker) {
      setBroker(currentBroker);
    }
    setLoadingBroker(false);
  }, []);

  const updateBroker = async () => {
    const { value: broker_id } = broker;
    if (!broker_id) {
      onError('Debes seleccionar un broker para asignar al contrato');
      return;
    }
    const { status, data } = await callEndpoint(
      updatetBrokerContractEndpoint(contract_id, broker_id, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data);
      onError(
        `Ocurrió un error al momento de asignar broker a contrato, ${response.message}`,
      );
    }
  };

  const removeBroker = async () => {
    const { status, data } = await callEndpoint(
      unsetBrokerContractEndpoint(contract_id, token),
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data);
      onError(
        `Ocurrió un error al momento de asignar broker a contrato, ${response.message}`,
      );
    }
  };

  return (
    <Card className="card-profile">
      <CardHeader>
        <h5 className="h3 mb-0">Partes involucradas</h5>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  className="avatar rounded-circle"
                  href={`/landlord/${contract.landlord_info.id}/profile`}
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={
                      contract.landlord_info.avatar
                        ? contract.landlord_info.avatar
                        : DEFAULT_AVATAR
                    }
                  />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <a
                    href={`/landlord/${contract.landlord_info.id}/profile`}
                    target="_blank"
                  >
                    {contract.landlord_info.name}
                  </a>
                </h4>
                <small>Propietario</small>
              </div>
              <Col className="col-auto text-right">
                {contract.signed_by_landlord ? (
                  <>
                    <Badge className="badge-lg" color="success">
                      ACEPTADO
                    </Badge>{' '}
                    <h6>Aceptado el: {contract.signed_by_landlord_at}</h6>
                  </>
                ) : (
                  <Badge className="badge-lg" color="danger">
                    PENDIENTE POR ACEPTAR
                  </Badge>
                )}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  className="avatar rounded-circle"
                  href={`/tenant/${contract.tenant_info.id}/profile`}
                  target="_blank"
                >
                  <img
                    alt="..."
                    src={
                      contract.tenant_info.avatar
                        ? contract.tenant_info.avatar
                        : DEFAULT_AVATAR
                    }
                  />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">
                  <a
                    href={`/tenant/${contract.tenant_info.id}/profile`}
                    target="_blank"
                  >
                    {contract.tenant_info.name}
                  </a>
                </h4>
                <small>Inquilino</small>
              </div>
              <Col className="col-auto text-right">
                {contract.signed_by_tenant ? (
                  <>
                    <Badge className="badge-lg" color="success">
                      ACEPTADO
                    </Badge>{' '}
                    <h6>Aceptado el: {contract.signed_by_tenant_at}</h6>
                  </>
                ) : (
                  <Badge className="badge-lg" color="danger">
                    PENDIENTE POR ACEPTAR
                  </Badge>
                )}
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <CardFooter />
      <CardHeader>
        <h5 className="h3 mb-0">Información de Broker asociado</h5>
      </CardHeader>
      <CardBody>
        {!loadingBroker && (
          <Select
            placeholder="Seleccionar broker a asignar"
            defaultValue={broker}
            options={brokers}
            onChange={(selected: any) => {
              setBroker(selected);
            }}
          />
        )}
      </CardBody>
      <CardFooter>
        <Button color={'primary'} onClick={updateBroker}>
          {!contract.broker_id ? 'Asignar' : 'Actualizar'} broker
        </Button>
        {contract.broker_id && (
          <Button color={'danger'} onClick={removeBroker}>
            Remover broker
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default ContractPartiesCard;
