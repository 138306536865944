import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage'
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import ManageInvestorProjectModelForm from './components/ManageInvestorProjectModelForm';
import { InvestorProjectModelDetail, InvestorProjectModelForm } from './models/investors';
import { getProjectModelDetailsEndpoint, updateInvestorProjectModelEndpoint } from './services/investorService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const InvestorProjectModelEdit = () => {
  const pageName = `Actualizar modelo`;
  const pageParentName = `Preventa`;
  const navigate = useNavigate()
  const {
    id: investorProjectId = "",
    modelId: investorProjectModelId = ""
  } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad()
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const getInvestorProjectModel = useCallback(
    () => getProjectModelDetailsEndpoint(investorProjectId, investorProjectModelId, token),
    []
  );
  const [investorProjectModel] = useAdminEntity<InvestorProjectModelDetail>(getInvestorProjectModel, setErrorAlert);

  const handleUpdateProject = async (form: InvestorProjectModelForm) => {
    const {
      status, data
    } = await callEndpoint(updateInvestorProjectModelEndpoint(
      investorProjectId, investorProjectModelId, form, token));
    const response = fetchResponseAdapter(data)
    if (status === 200)
      navigate(`/investors/projects/${investorProjectId}/models/${investorProjectModelId}`)
    else
      setErrorAlert(response.message);
  }

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!investorProjectModel || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      parentName={pageParentName}
      name={pageName}
      globalAlertOptions={globalAlertOptions}
    >
      <ManageInvestorProjectModelForm
        projectModel={investorProjectModel}
        handleForm={handleUpdateProject}
      />
    </AdminPage>
  )
}

export default InvestorProjectModelEdit