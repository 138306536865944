import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import UploadContract from './components/UploadContract';
import {
  BrokerListForContract,
  ContractDetailData,
  ContractQuotaPreview,
} from './models/contractModel';
import {
  createContractTransactionsEndpoint,
  getBrokersContractListEndpoint,
  getContractEndpoint,
  getContractQuotasPreview,
} from './services/contractService';
import ContractInfoCard from './components/ContractInfoCard';
import ContractPartiesCard from './components/ContractPartiesCard';
import GenerateContractPaymentMandateCard from './components/GenerateContractPaymentMandateCard';
import { isContractSigned } from './adapters/contractAdapter';
import ContractPaymentsPreviewCard from './components/ContractPaymentsPreviewCard';
import { Option } from '../../models/formModel';
import UpdateProperToContrect from './components/UpdateProperToContract';
import ContractPaymentPenalty from './components/ContractPaymentPenalty';
import { getActiveContractSubscriptionsEndpoint } from '../subscriptions/services/subscriptionServices';
import WithdrawalContractModal from '../withdrawal/WithdrawalContractModal';
import useModalState from '../../hooks/useModalState';

const ContractDetail = () => {
  const { id = '' } = useParams();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const [contract, setContract] = useState<ContractDetailData>();
  const [activeSubscription, setActiveSubscription] = useState<string | null>(
    null,
  );
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState<string>('');
  const [brokers, setBrokers] = useState<Option[]>([]);
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };

  const [isOpenModal, toggleModal] = useModalState();

  useEffect(() => {
    const loadContractSubscription = async () => {
      const { status, data } = await callEndpoint(
        getActiveContractSubscriptionsEndpoint(id, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 200) {
        setActiveSubscription(response.data.contract_subscription_id || null);
        return;
      }
    };
    const loadContract = async () => {
      const { status, data } = await callEndpoint(
        getContractEndpoint(id, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 200) {
        response.data.contract_id = id;
        setContract(response.data);
        return;
      }
    };
    const loadBrokers = async () => {
      const { status, data } = await callEndpoint(
        getBrokersContractListEndpoint(token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        const dataEndpoint = response.data || [];
        const brokersMapped: Option[] = dataEndpoint.map(
          (broker: BrokerListForContract) => {
            return {
              label: `${broker.first_name} ${broker.last_name} - ${broker.email}`,
              value: broker.broker_id,
            };
          },
        );
        setBrokers(brokersMapped);
      }
    };
    loadContract().catch((e) => setErrorAlert(e.toString()));
    loadBrokers().catch((e) => setErrorAlert(e.toString()));
    loadContractSubscription().catch((e) => setErrorAlert(e.toString()));
  }, []);

  const generateTransactions = async () => {
    const { status, data } = await callEndpoint(
      createContractTransactionsEndpoint(id, token),
    );
    if (status === 201) {
      return navigate('/contracts/payment-status');
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(`(${response.code}) ${response.message}`);
    }
  };

  const pageName = 'Detalle de contrato';
  const parentPageName = 'Contratos';

  if (!contract || isLoading) {
    return <LoadingCard name={pageName} parent_name={parentPageName} />;
  }

  const pageFooter = (
    <>
      {contract.transaction_id && (
        <>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/contracts/${contract.transaction_id}/payment-status`)
            }
          >
            Mandato de pago generado
          </Button>
          <Button
            color="warning"
            onClick={() => {
              toggleModal();
            }}
          >
            Desestimar contrato
          </Button>
        </>
      )}
    </>
  );

  // const isSigned = true;
  const isSigned = isContractSigned(contract);

  return (
    <AdminPage
      name={pageName}
      parentName={parentPageName}
      globalAlertOptions={globalAlertOptions}
      footer={pageFooter}
    >
      <Row>
        <Col>
          <ContractInfoCard
            contract={contract}
            activeSubscription={activeSubscription}
          />
        </Col>
        <Col>
          <ContractPartiesCard
            onError={setErrorAlert}
            contract_id={id}
            contract={contract}
            brokers={brokers}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ContractPaymentPenalty
            paymentPenalty={contract.late_payment_penalty}
          />
        </Col>
        <Col md={6}>
          <UpdateProperToContrect
            contract_id={id}
            proper_id={contract.proper_id}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <UploadContract
            url={contract.document_url ? contract.document_url : ''}
            type="standard"
            contract_id={id}
          />
        </Col>
        <Col>
          <UploadContract
            url={contract.document_url ? contract.document_signed_url : ''}
            type="signed"
            contract_id={id}
          />
        </Col>
      </Row>

      {isSigned && !contract.transaction_id && (
        <Row>
          <Col>
            <GenerateContractPaymentMandateCard
              onAction={generateTransactions}
              onError={setErrorAlert}
              contractId={id}
            />
          </Col>
        </Row>
      )}
      <WithdrawalContractModal
        contractId={id}
        headerText="Desestimar contrato"
        isOpen={isOpenModal}
        onToggle={toggleModal}
        resumeContract={`Propiedad [${contract.property_info.code}] - Inquilino [${contract.tenant_info.name}] - Propietario [${contract.landlord_info.name}]`}
      />
    </AdminPage>
  );
};

export default ContractDetail;
