import axios from 'axios';
import { CustomerRegistrationForm } from '../models/customerModel';

const loadAbort = () => {
  return new AbortController();
};

export const createCustomerEndpoint = (
  form: CustomerRegistrationForm,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/user/v1/admin/customer`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
