import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { useParams } from 'react-router-dom';
import useAdminEntity from '../../hooks/useAdminEntity';
import { InvestorProjectModelDetail } from './models/investors';
import { getProjectModelDetailsEndpoint } from './services/investorService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import { Col, Row } from 'reactstrap';
import InvestorProjectModelSummaryCard from './components/InvestorProjectModelSummaryCard';
import InvestorProjectModelActions from './components/InvestorProjectModelActions';
import LoadingCard from '../../components/LoadingCard';

const InvestorProjectModelDetails: React.FC = () => {
  const {id: investorProjectId = "", modelId: investorProjectModelId = ""} = useParams();
  const { isLoading } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getInvestorProjectModel = useCallback(
    () => getProjectModelDetailsEndpoint(investorProjectId, investorProjectModelId, token),
    []
  );
  const [investorProjectModel] = useAdminEntity<InvestorProjectModelDetail>(getInvestorProjectModel, setErrorAlert);
  const pageName = `${investorProjectModel?.name}`;
  const parentPageName = `Preventa`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!investorProjectModel || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={parentPageName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={parentPageName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <InvestorProjectModelSummaryCard model={investorProjectModel} projectId={investorProjectId} />
        </Col>
      </Row>
      <Row>
        <Col>
          <InvestorProjectModelActions projectId={investorProjectId} model={investorProjectModel} />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectModelDetails;