import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import React, { useCallback, useReducer, useState } from 'react';
import LoadingCard from '../../components/LoadingCard';
import { getDevelopersEndpoint } from '../investors/services/investorService';
import useAdminEntity from '../../hooks/useAdminEntity';
import { InvestorProjectDeveloper } from '../investors/models/investors';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import {
  getLandlordByNameEndpoint,
  getProofsIncomeByOwnerEndpoint,
} from './services/accountingService';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  ProofIncomeDisplaySummary,
  ProofIncomeProduct,
} from './models/proofIncomeModel';
import AdminListGroup from '../../components/admin/AdminListGroup';

const AccountingProofOutcomeCreate: React.FC = () => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const { isLoading } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);

  const reducer = useCallback((state: any, action: any) => {
    const actionType = action.type;

    switch (actionType) {
      case 'setIncomeProduct': {
        return {
          ...state,
          incomeProduct: action.incomeProduct,
          showLandlordSearchName: action.showLandlordSearchName,
          showPropertyCodeSearch: action.showPropertyCodeSearch,
        };
      }

      case 'setIncomeOwnerId': {
        return {
          ...state,
          incomeOwnerId: action.incomeOwnerId,
        };
      }

      case 'addProofIncome': {
        const neoSelectedProofIncomes = [...state.selectedProofIncomes];
        neoSelectedProofIncomes.push(action.proofIncomeId);
        return {
          ...state,
          selectedProofIncomes: neoSelectedProofIncomes,
          incomeProduct: action.incomeProduct,
        };
      }

      case 'removeProofIncome': {
        return {
          ...state,
          selectedProofIncomes: state.selectedProofIncomes.filter(
            (el: string) => el !== action.proofIncomeId,
          ),
        };
      }

      case 'setLandlordName': {
        return {
          ...state,
          landlordName: action.landlordName,
        };
      }

      case 'setLandlordSearchTerm': {
        return {
          ...state,
          searchTerm: action.searchTerm,
        };
      }

      case 'setPropertyCodeSearchTerm': {
        return {
          ...state,
          propertyCodeSearch: action.propertyCodeSearch,
        };
      }

      case 'setPropertyCode': {
        return {
          ...state,
          propertyCode: action.propertyCode,
        };
      }
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, {
    incomeProduct: '',
    incomeOwnerId: '',
    selectedProofIncomes: [],
    landlordName: '',
    searchTerm: '',
    propertyCodeSearch: '',
    showLandlordSearchName: false,
    showPropertyCodeSearch: false,
  });

  const getDevelopers = useCallback(
    () => getDevelopersEndpoint(token),
    [token],
  );
  const [developers] = useAdminEntity<InvestorProjectDeveloper[]>(
    getDevelopers,
    setErrorAlert,
  );
  const developerOptions = listToSelectOptionAdapter(
    (developers as InvestorProjectDeveloper[]) || [],
    'id',
    'name',
  );

  const getIncomeProofs = useCallback(
    () =>
      getProofsIncomeByOwnerEndpoint(
        createSearchParams({
          income_product:
            state.incomeProduct === 'pre_sale' ? 'pre_sale' : 'rent',
          income_owner_id: state.incomeOwnerId,
        }).toString(),
        token,
      ),
    [token, state.incomeProduct, state.incomeOwnerId],
  );
  const [incomeProofs] = useAdminEntity<ProofIncomeDisplaySummary[]>(
    getIncomeProofs,
    setErrorAlert,
  );

  const getLandlordByName = useCallback(
    () => getLandlordByNameEndpoint(state.landlordName, token),
    [state.landlordName, token],
  );
  const [landlords] = useAdminEntity<any[]>(getLandlordByName, setErrorAlert);

  const proofIncomeProducts: ProofIncomeProduct[] = [
    {
      color: 'primary',
      product: 'rent',
      product_text: 'Renta',
    },
    {
      color: 'warning',
      product: 'pre_sale',
      product_text: 'PreVenta',
    },
    {
      color: 'success',
      product: 'sale',
      product_text: 'Vivienda usada',
    },
  ];

  const pageFooter = (
    <>
      {state.selectedProofIncomes.length > 0 && (
        <Button
          color="primary"
          onClick={() =>
            navigate(
              `/accounting/proof-outcome/summary?${createSearchParams({
                state: JSON.stringify(state),
              })}`,
            )
          }
        >
          Completar datos del comprobante
        </Button>
      )}
    </>
  );

  const pageName = 'Crear comprobantes de egreso';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  if (isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={pageFooter}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h4>Selecciona a quién va dirigido el comprobante de egresos</h4>
              <div className="custom-control custom-radio mb-3">
                <input
                  className="custom-control-input"
                  id="presales"
                  name="incomeProduct"
                  type="radio"
                  value="pre_sale"
                  onChange={(event) =>
                    dispatch({
                      type: 'setIncomeProduct',
                      incomeProduct: event.target.value,
                      showLandlordSearchName: false,
                      showPropertyCodeSearch: false,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="presales">
                  Preventa
                </label>
              </div>
              <div className="custom-control custom-radio mb-3">
                <input
                  className="custom-control-input"
                  id="landlord"
                  name="incomeProduct"
                  type="radio"
                  value="rent"
                  onChange={(event) =>
                    dispatch({
                      type: 'setIncomeProduct',
                      incomeProduct: event.target.value,
                      showLandlordSearchName: true,
                      showPropertyCodeSearch: false,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="landlord">
                  Propietario
                </label>
              </div>

              <div className="custom-control custom-radio mb-3">
                <input
                  className="custom-control-input"
                  id="property_code"
                  name="incomeProduct"
                  type="radio"
                  value="property_code"
                  onChange={(event) =>
                    dispatch({
                      type: 'setIncomeProduct',
                      incomeProduct: event.target.value,
                      showLandlordSearchName: false,
                      showPropertyCodeSearch: true,
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="property_code">
                  Código de propiedad
                </label>
              </div>

              {state.incomeProduct === 'pre_sale' && (
                <>
                  <Select
                    placeholder="Selecciona una desarrolladora"
                    options={developerOptions}
                    onChange={(optionSelected) =>
                      dispatch({
                        type: 'setIncomeOwnerId',
                        incomeOwnerId: optionSelected?.value,
                      })
                    }
                  />
                </>
              )}

              {state.incomeProduct === 'rent' &&
                state.showLandlordSearchName && (
                  <div>
                    <Row className="my-3">
                      <Col>
                        <InputGroup className="mb-3">
                          <Input
                            id="landlordSearch"
                            type="text"
                            placeholder="Ingrese el nombre del propietario"
                            onChange={(event) =>
                              dispatch({
                                type: 'setLandlordSearchTerm',
                                searchTerm: event.target.value,
                              })
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              color={'primary'}
                              onClick={(event) =>
                                dispatch({
                                  type: 'setLandlordName',
                                  landlordName: state.searchTerm,
                                })
                              }
                            >
                              Buscar
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    {landlords && landlords.length > 0 && (
                      <AdminListGroup
                        items={landlords.map((landlordInfo) => ({
                          title: (
                            <>
                              <h4>{landlordInfo.name}</h4>
                              <small className="text-black-50">
                                {landlordInfo.phone}
                              </small>
                            </>
                          ),
                          value: (
                            <Button
                              color={'info'}
                              size={'sm'}
                              onClick={() =>
                                dispatch({
                                  type: 'setIncomeOwnerId',
                                  incomeOwnerId: landlordInfo.landlord_id,
                                })
                              }
                            >
                              Ver comprobantes de ingreso
                            </Button>
                          ),
                        }))}
                        title={'Posibles propietarios'}
                      />
                    )}
                  </div>
                )}

              {state.incomeProduct === 'property_code' &&
                state.showPropertyCodeSearch && (
                  <div>
                    <Row className="my-3">
                      <Col>
                        <InputGroup className="mb-3">
                          <Input
                            id="propertyCodeSearch"
                            type="text"
                            placeholder="Ingrese el código de propiedad"
                            onChange={(event) =>
                              dispatch({
                                type: 'setPropertyCodeSearchTerm',
                                propertyCodeSearch: event.target.value,
                              })
                            }
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              color={'primary'}
                              onClick={(event) =>
                                dispatch({
                                  type: 'setIncomeOwnerId',
                                  incomeOwnerId: state.propertyCodeSearch,
                                })
                              }
                            >
                              Buscar
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                )}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle>
                <h4>Comprobantes de ingresos</h4>
              </CardTitle>
              <ListGroup>
                {incomeProofs &&
                  incomeProofs.length > 0 &&
                  incomeProofs.map((incomeProof: ProofIncomeDisplaySummary) => (
                    <ListGroupItem key={incomeProof.id}>
                      <Row className="align-items-center m-1">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            {incomeProof.propi_code}{' '}
                            <Badge
                              color={
                                proofIncomeProducts.find(
                                  (po) =>
                                    po.product === incomeProof.income_product,
                                )?.color
                              }
                              className="badge-sm"
                            >
                              {
                                proofIncomeProducts.find(
                                  (po) =>
                                    po.product === incomeProof.income_product,
                                )?.product_text
                              }
                            </Badge>
                          </h4>
                          <span>{incomeProof.concept_income}</span>
                          <br />
                          <small>{incomeProof.amount}</small>
                        </div>

                        {!incomeProof.has_proof_outcome && (
                          <Col className="col-auto">
                            {!state.selectedProofIncomes.includes(
                              incomeProof.id,
                            ) ? (
                              <Button
                                color="primary"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  dispatch({
                                    type: 'addProofIncome',
                                    proofIncomeId: incomeProof.id,
                                    incomeProduct: incomeProof.income_product,
                                  })
                                }
                              >
                                Agregar
                              </Button>
                            ) : (
                              <Button
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() =>
                                  dispatch({
                                    type: 'removeProofIncome',
                                    proofIncomeId: incomeProof.id,
                                  })
                                }
                              >
                                Eliminar
                              </Button>
                            )}
                          </Col>
                        )}

                        {incomeProof.has_proof_outcome && (
                          <Button
                            color="warning"
                            size="sm"
                            type="button"
                            onClick={() =>
                              navigate(
                                `/accounting/proof-income/${incomeProof.id}`,
                              )
                            }
                          >
                            Ver comprobante
                          </Button>
                        )}
                      </Row>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default AccountingProofOutcomeCreate;
