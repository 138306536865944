export interface WithdrawalStatus {
  color: 'primary' | 'success' | 'warning';
  product: 'created' | 'approved' | 'denied';
  product_text: string;
}
export interface WithdrawalType {
  color: 'info' | 'primary';
  product: 'regular' | 'change_unit';
  product_text: string;
}
export interface WithdrawalLogAction {
  product: 'CREATE' | 'APPROVED' | 'DENIED';
  product_text: string;
}

export const withdrawalStatus: WithdrawalStatus[] = [
  {
    color: 'primary',
    product: 'created',
    product_text: 'Creado',
  },
  {
    color: 'warning',
    product: 'denied',
    product_text: 'Denegado',
  },
  {
    color: 'success',
    product: 'approved',
    product_text: 'Aprobado',
  },
];

export const withdrawalType: WithdrawalType[] = [
  {
    color: 'info',
    product: 'regular',
    product_text: 'Desestimiento',
  },
  {
    color: 'primary',
    product: 'change_unit',
    product_text: 'Cambio de unidad',
  },
];

export const withdrawalLogAction: WithdrawalLogAction[] = [
  {
    product: 'CREATE',
    product_text: 'Creación de desestimiento',
  },
  {
    product: 'APPROVED',
    product_text: 'Aprobación de desestimiento',
  },
  {
    product: 'DENIED',
    product_text: 'Desestimiento denegado',
  },
];
