import axios from 'axios';
import {
  InvestorProjectForm,
  InvestorProjectModelForm,
  ModelUnitChangeStatus,
  ModelUnitFinancesForm,
  ModelUnitForm,
  UpdateProjectPrivacy,
} from '../models/investors';
import { GenerateCodes } from '../models/investors';

const loadAbort = () => {
  return new AbortController();
};

export const getInvestorProjectsEndpoint = (
  query: string = '',
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorProjectDetailEndpoint = (
  investorProjectId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDevelopersEndpoint = (token: string) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/developers`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getNearbyPlacesEndpoint = (token: string) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/near-by-places`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createInvestorProjectEndpoint = (
  investorProject: InvestorProjectForm,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/projects`,
        data: JSON.stringify(investorProject),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProjectModelsEndpoint = (
  investorProjectId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProjectModelDetailsEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createInvestorProjectModelEndpoint = (
  investorProjectId: string,
  investorProjectModel: InvestorProjectModelForm,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models`,
        data: JSON.stringify(investorProjectModel),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createModelUnitEndpoint = (
  modelUnit: ModelUnitForm,
  modelUnitId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/project-model/${modelUnitId}/model-units`,
        data: JSON.stringify(modelUnit),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateModelUnitEndpoint = (
  modelUnit: ModelUnitForm,
  projectModelId: string,
  modelUnitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${projectModelId}/model-units/${modelUnitId}`,
        data: JSON.stringify(modelUnit),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getModelUnitsEndpoint = (
  investorProjectModelId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const takeDownUnitsEndpoint = (
  model_id: string,
  unit_id: string,
  payload: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${model_id}/model-units/${unit_id}/change-status`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getUnitsForModelEndpoint = (
  investorProjectModelId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project/${investorProjectModelId}/model-units/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadInvestorProjectResourcesEndpoint = (
  investorProjectId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/upload-images`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadInvestorProjectGalleryResourcesEndpoint = (
  investorProjectId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/gallery`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const syncProjectGalleryEndpoint = (
  investorProjectId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/sync-gallery`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteItemFromProjectGalleryEndpoint = (
  investorProjectId: string,
  imagePath: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/gallery-item`,
        data: JSON.stringify({ path: imagePath }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteGalleryProjectEndpoint = (
  investorProjectId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/gallery-items`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sortGalleryProjectEndpoint = (
  investorProjectId: string,
  imagePaths: string[],
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/sort-gallery`,
        data: JSON.stringify({ paths: imagePaths }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadInvestorProjectModelResourcesEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project/${investorProjectId}/project-models/${investorProjectModelId}/cover`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadInvestorProjectModelGalleryResourcesEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}/gallery`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const syncProjectModelGalleryEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}/sync-gallery`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteItemFromProjectModelGalleryEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  imagePath: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}/gallery-item`,
        data: JSON.stringify({ path: imagePath }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteGalleryProjectModelEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}/gallery-items`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sortGalleryProjectModelEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  imagePaths: string[],
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}/project-models/${investorProjectModelId}/sort-gallery`,
        data: JSON.stringify({ paths: imagePaths }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateInvestorProjectModelStatusEndpoint = (
  investorProjectId: string,
  investorProjectModelId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project/${investorProjectId}/project-models/${investorProjectModelId}/change-status`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadModelUnitBlueprintEndpoint = (
  projectModelId: string,
  modelUnitId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${projectModelId}/model-units/${modelUnitId}/blueprints-gallery-item`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadModelUnitGalleryEndpoint = (
  projectModelId: string,
  modelUnitId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${projectModelId}/model-units/${modelUnitId}/gallery-item`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateInvestorProjectEndpoint = (
  investorProjectId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects/${investorProjectId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateInvestorProjectModelEndpoint = (
  projectId: string,
  modelId: string,
  form: InvestorProjectModelForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project/${projectId}/project-models/${modelId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateModelUnitFinancesEndpoint = (
  investorProjectModelId: string,
  modelUnitId: string,
  form: ModelUnitFinancesForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/finances`,
        data: JSON.stringify({
          percentage_down_payment: form.percentage_down_payment,
          percentage_reserve_payment: form.percentage_reserve_payment,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateModelUnitStatusEndpoint = (
  investorProjectModelId: string,
  modelUnitId: string,
  form: ModelUnitChangeStatus,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/change-status`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createModelUnitPaymentContractEndpoint = (
  payload: any,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getModelUnitPreBookingsEndpoint = (
  investorProjectModelId: string,
  modelUnitId: string,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/prebookings`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getModelUnitFinanceDefaultConfigEndpoint = (token: string) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/finance/default-config`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateProjectAccessEndpoint = (
  projectId: string,
  token: string,
  form: UpdateProjectPrivacy,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/projects-access/${projectId}/privacy`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const generateAccessCodesEndpoint = (
  token: string,
  form: GenerateCodes,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/projects-access/generate-codes`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAccessCodesEndpoint = (projectId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project-access/${projectId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorPaymentsListEndpoint = (
  query: string = '',
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorPaymentsDetailEndpoint = (
  id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorQuotaDetailEndpoint = (
  transactionId: string,
  transactionDetailId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionId}/transaction-detail/${transactionDetailId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteModelUnitGalleryImg = (
  investorProjectModelId: string,
  modelUnitId: string,
  imagePath: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/gallery-item`,
        data: JSON.stringify({ path: imagePath }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const deleteModelUnitBlueprintImg = (
  investorProjectModelId: string,
  modelUnitId: string,
  imagePath: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/blueprints-gallery-item`,
        data: JSON.stringify({ path: imagePath }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sortModelUnitBlueprintImgs = (
  investorProjectModelId: string,
  modelUnitId: string,
  imagePaths: string[],
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/sort-blueprints`,
        data: JSON.stringify({ paths: imagePaths }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const sortModelUnitGalleryImgs = (
  investorProjectModelId: string,
  modelUnitId: string,
  imagePaths: string[],
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/sort-gallery`,
        data: JSON.stringify({ paths: imagePaths }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const unselectTransactionPreSalesEndpoint = (
  transactionId: any,
  token: string,
) => {
  const controller = loadAbort();

  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionId}/unselect`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const attachVoucherForPaidTransaction = (
  transactionId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/transaction-detail/${transactionId}/transfer`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const markTransactionForPaid = (
  transactionId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/transaction-detail/${transactionId}/`,
        data: JSON.stringify({ status: 'paid' }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadSigningPromiseDocsToModelUnit = (
  investorProjectModelId: string,
  modelUnitId: string,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/signing-promise-docs`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateSigningPromiseDateAtEndpoint = (
  investorProjectModelId: string,
  modelUnitId: string,
  payload: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/update-to-signing-promise`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const moveModelUnitOnDeed = (
  investorProjectModelId: string,
  modelUnitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-model/${investorProjectModelId}/model-units/${modelUnitId}/update-to-on-deed`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createModelUnitsInBulk = (
  form: any,
  modelId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/project-model/${modelId}/model-units-in-bulk`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getQuotaPreviewCheckoutEndpoint = (
  transactionId: string,
  transactionDetailId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionId}/transaction-detail/${transactionDetailId}/checkout-preview`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
