import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import useAdminRent from './hooks/useAdminRent';
import { PropertyRentPrices } from './components/Rent/PropertyRentPrices';
import LoadingCard from '../../components/LoadingCard';

const AdminPropertyRent = () => {
  const { id: propertyId = '' } = useParams();
  const pageName = 'Administrar información de renta';
  const pageParentName = 'Propiedades';

  const { formUpdateData } = useAdminRent({ propertyId });

  if (!formUpdateData) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <Row>
        <Col md="6">
          <Row>
            {/* form prices and state for sales */}
            <Col md="12">
              <PropertyRentPrices
                property_id={propertyId}
                property_rent_min_price_value={formUpdateData.min_price || 0}
                property_rent_price_value={formUpdateData.price || 0}
                property_rent_state={formUpdateData.state || ''}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </AdminPage>
  );
};

export default AdminPropertyRent;
