import { Option } from '../models/formModel';

export const TRANSACTION_DETAIL_STATUS: any = {
  created: 'Sin pago',
  current: 'Cuota en curso',
  paid: 'Pagada',
  pending: 'Pendiente',
  disabled: 'Desactivada',
  review: 'En revisión',
};

export const PAYMENT_METHODS: any = {
  card: 'Tarjeta',
  card_subscription: 'Pago suscrito',
  wire_transfer: 'Transferencia bancaria',
};

export const FINANCE_TYPES_LIST: Option[] = [
  {
    label: 'Porcentaje a aplicar a cuota',
    value: 'percentage',
  },
  {
    label: 'Valor fijo',
    value: 'fixed',
  },
];
