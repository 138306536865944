import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { ProofIncomesListByProofOutcome } from '../models/proofIncomeModel';

export interface Props {
  proofIncomes: ProofIncomesListByProofOutcome[];
}

const AccountingProofIncomeListByProofOutcome: React.FC<Props> = ({
  proofIncomes,
}) => {
  const itemList: DisplayItem[] = [];

  if (proofIncomes.length > 0) {
    proofIncomes.forEach((proof_income) => {
      itemList.push({
        title: `${proof_income.code}`,
        value: `${proof_income.amount}`,
        key: proof_income.id,
        href: `/accounting/proof-income/${proof_income.id}`
      });
    });
  }

  return (
    <AdminListGroup
      title={'Comprobantes de ingresos relacionados'}
      items={itemList}
    />
  );
};
export default AccountingProofIncomeListByProofOutcome;
