import { Col, Row } from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import AdminListGroup from '../../components/admin/AdminListGroup';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useEffect, useState } from 'react';
import { IResumeVisitsByProper } from './models/dashboardModels';
import { getDashboardResumeVisitsByProperEndpoint } from './services/dashboardService';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { LeadMarketplaceType } from '../../components/VisitMarketplaceTypeBadge';

const DashboardResumeVisits = () => {
  const pageName = 'Cantidad de visitas por proper';
  const pageParentName = 'Dashboard';
  const pageParentUri = '/';
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [dashboardResumeVisits, setDashboardResumeVisits] = useState<
    IResumeVisitsByProper[]
  >([]);

  const [resumeVisitForRent, setResumeVisitForRent] = useState<
    IResumeVisitsByProper[]
  >([]);

  const [resumeVisitForSale, setResumeVisitForSale] = useState<
    IResumeVisitsByProper[]
  >([]);

  useEffect(() => {
    const loadDashboadResumeVisits = async () => {
      const { status, data } = await callEndpoint(
        getDashboardResumeVisitsByProperEndpoint(token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setDashboardResumeVisits(response.data);
      }
    };
    loadDashboadResumeVisits().catch((e) => console.error);
  }, []);

  useEffect(() => {
    setResumeVisitForRent(
      dashboardResumeVisits.filter(
        (resume) => resume.marketplace_type === LeadMarketplaceType.ON_RENT,
      ),
    );

    setResumeVisitForSale(
      dashboardResumeVisits.filter(
        (resume) => resume.marketplace_type === LeadMarketplaceType.ON_SALE,
      ),
    );
  }, [dashboardResumeVisits]);

  if (isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  const visitForRent = resumeVisitForRent?.map((resume) => {
    return {
      title: `${resume.proper_name}`,
      value: resume.visits,
    };
  });

  const visitForSale = resumeVisitForSale?.map((resume) => {
    return {
      title: `${resume.proper_name}`,
      value: resume.visits,
    };
  });

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      parentUri={pageParentUri}
    >
      <Row>
        <Col lg="4">
          <AdminListGroup
            title={'Visitas de proper para renta'}
            items={visitForRent ?? []}
          />
        </Col>
        <Col lg="4">
          <AdminListGroup
            title={'Visitas de proper para venta'}
            items={visitForSale ?? []}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default DashboardResumeVisits;
