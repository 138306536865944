import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { checkOptions } from '../../../../constants/formConstants';
import useAdminAmenities from '../../hooks/useAdminAmenities';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { PropertyToAmenities } from '../../models/propertyModel';
import { Option } from '../../../../models/formModel';
import { getCurrentAmenitiesValues } from '../../adapters/propertyAdapter';

export interface Props {
  property_id: string;
  has_white_goods: boolean;
  is_furnished: boolean;
  pets_allowed: boolean;
  amenities_info : PropertyToAmenities[];
  bathrooms: string;
  parkings: string;
  rooms: string;
  half_bathrooms: string;
  square_mts: number;
}

const numberList: Option[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '+5' },
];

const PropertyAmenities: React.FC<Props> = (
  {
    has_white_goods,
    is_furnished,
    pets_allowed,
    property_id,
    amenities_info,
    bathrooms,
    parkings,
    rooms,
    half_bathrooms,
    square_mts,
  }) => {
  const {
    register,
    control,
    setValue,
    reset,
    handleSubmit,
    isLoading,
    amenitiesCatalog,
    currentAmenities,
    updatePropertyAmenities,
  } = useAdminAmenities(property_id, amenities_info);

  useEffect(() => {
    reset({
      c_bathrooms: bathrooms,
      c_parkings: parkings,
      c_rooms: rooms,
      c_half_bathrooms: half_bathrooms,
      c_square_mts: square_mts || 0,
      amenities_info: getCurrentAmenitiesValues(amenities_info),
    });
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h4>Amenidades y Características</h4>
        </CardHeader>
        <CardBody>
          <Form role="form" onSubmit={handleSubmit(updatePropertyAmenities)}>
            {/* has_white_goods */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="has_white_goods"
                    >
                      Linea blanca
                    </label>
                  </Col>
                  <Col md={6}>
                    {checkOptions &&
                      checkOptions.map((item: any) => (
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            {...register('has_white_goods')}
                            className="custom-control-input"
                            type="radio"
                            value={item.value}
                            defaultChecked={
                              item.value ===
                              (has_white_goods || false)
                            }
                            id={`has_white_goods-${item.name}`}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`has_white_goods-${item.name}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* is_furnished */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="is_furnished"
                    >
                      Amueblado
                    </label>
                  </Col>
                  <Col md={6}>
                    {checkOptions &&
                      checkOptions.map((item: any) => (
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            {...register('is_furnished')}
                            className="custom-control-input"
                            type="radio"
                            value={item.value}
                            defaultChecked={
                              item.value ===
                              (is_furnished || false)
                            }
                            id={`is_furnished-${item.name}`}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`is_furnished-${item.name}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* pets_allowed */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="pets_allowed"
                    >
                      Mascotas
                    </label>
                  </Col>
                  <Col md={6}>
                    {checkOptions &&
                      checkOptions.map((item: any) => (
                        <div className="custom-control custom-radio custom-control-inline">
                          <input
                            {...register('pets_allowed')}
                            className="custom-control-input"
                            type="radio"
                            value={item.value}
                            defaultChecked={
                              item.value ===
                              (pets_allowed || false)
                            }
                            id={`pets_allowed-${item.name}`}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`pets_allowed-${item.name}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* characteristics.rooms */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="c_rooms"
                    >
                      Cantidad de habitaciones
                    </label>
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="c_rooms"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="c_rooms"
                          type="select"
                        >
                          <option value="">No posee...</option>
                          {numberList.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </Input>
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* characteristics.bathrooms */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="c_bathrooms"
                    >
                      Cantidad de baños completos
                    </label>
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="c_bathrooms"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="c_bathrooms"
                          type="select"
                        >
                          <option value="">No posee...</option>
                          {numberList.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </Input>
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* characteristics.half_bathrooms */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="c_half_bathrooms"
                    >
                      Cantidad de baños sociables
                    </label>
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="c_half_bathrooms"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="c_half_bathrooms"
                          type="select"
                        >
                          <option value="">No posee...</option>
                          {numberList.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </Input>
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* characteristics.parkings */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="c_parkings"
                    >
                      Estacionamientos
                    </label>
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="c_parkings"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="c_parkings"
                          type="select"
                        >
                          <option value="">No posee...</option>
                          {numberList.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                          ))}
                        </Input>
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* characteristics.mt2 */}
            <Col md="12">
              <FormGroup>
                <Row>
                  <Col md={6}>
                    <label
                      className="form-control-label"
                      htmlFor="c_square_mts"
                    >
                      Metros cuadrados
                    </label>
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="c_square_mts"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="c_square_mts"
                          type="number"
                          min={0}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>

            {/* ammenities */}
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="amenities_info"
                >
                  Amenidades
                </label>
                {!isLoading && (
                  <Controller
                    name="amenities_info"
                    control={control}
                    render={({ field: { ref } }) => (
                      <Select
                        placeholder="Selecciona las amenidades"
                        isMulti
                        options={amenitiesCatalog}
                        defaultValue={currentAmenities}
                        onChange={(selected) => {
                          const ids = [];
                          for (const element of selected) {
                            ids.push(element.value);
                          }
                          setValue('amenities_info', ids);
                        }}
                        ref={ref}
                      />
                    )}
                  />
                )}
              </FormGroup>

              <Button
                color="primary"
                disabled={isLoading}
              >
                Actualizar
              </Button>
            </Col>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default PropertyAmenities;