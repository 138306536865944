import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { WithdrawalsList } from './models/withdrawalModel';
import useAdminPagination from '../../hooks/useAdminPagination';
import { getWithdrawalListEndpoint } from './services/withdrawalService';
import AdminPaginator from '../../components/admin/AdminPaginator';
import AdminPage from '../../components/admin/AdminPage';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { bussinessProducts } from '../../helpers/bussinessHelpers';
import { withdrawalStatus, withdrawalType } from './helpers/withdrawalHelper';
const WithdrawalList: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Lista de desestimientos';
  const pageParentName = 'Desestimientos';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const [withdrawals, setWithdrawals] = useState<WithdrawalsList[]>([]);

  const { paginator, loadItems } = useAdminPagination(
    getWithdrawalListEndpoint,
    setWithdrawals,
    console.error,
  );

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={componentPagination}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Tipo</th>
            <th scope="col">Detalle desestimiento</th>
            <th scope="col">Estatus</th>
            <th scope="col">Generado</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {withdrawals.length > 0 &&
            withdrawals.map((item) => (
              <tr key={`${item.id}`}>
                <td>
                  <Badge
                    color={
                      withdrawalType.find(
                        (po) => po.product.toString() === item.type,
                      )?.color
                    }
                    className="badge-lg"
                  >
                    {
                      withdrawalType.find(
                        (po) => po.product.toString() === item.type,
                      )?.product_text
                    }
                  </Badge>
                  <Badge
                    color={
                      bussinessProducts.find(
                        (po) => po.product.toString() === item.bussiness,
                      )?.color
                    }
                    className="badge-lg"
                  >
                    {
                      bussinessProducts.find(
                        (po) => po.product.toString() === item.bussiness,
                      )?.product_text
                    }
                  </Badge>
                </td>

                <td>{item.initial_resume}</td>
                <td>
                  {' '}
                  <Badge
                    color={
                      withdrawalStatus.find(
                        (po) => po.product.toString() === item.status,
                      )?.color
                    }
                    className="badge-lg"
                  >
                    {
                      withdrawalStatus.find(
                        (po) => po.product.toString() === item.status,
                      )?.product_text
                    }
                  </Badge>
                </td>
                <td>{item.generated_at}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem href={`/withdrawal/${item.id}`}>
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default WithdrawalList;
