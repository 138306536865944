import { Card, CardBody, Col, Row } from 'reactstrap';

interface Props {
  cover: string | undefined;
  address: string | undefined;
  code: number | undefined;
  price: string | undefined;
  sale_price?: string | undefined;
}

const PropertyDetailCard = (props: Props) => {
  const {
    cover = '',
    address = '',
    code = 0,
    price = '',
    sale_price = '',
  } = props;
  return (
    <>
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <Col className="col-auto">
              <img
                className="rounded"
                alt="..."
                width={175}
                height={100}
                src={
                  cover
                    ? cover
                    : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                }
              />
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">#{code}</h4>
              <p className="text-sm text-muted mb-0">{address}</p>
              {price && (
                <>
                  <small className="text-sm text-muted mb-0">
                    Precio de alquiler: {price}
                  </small>
                </>
              )}
              <br />
              {sale_price && (
                <>
                  <small className="text-sm text-muted mb-0">
                    Precio de venta: {sale_price}
                  </small>
                </>
              )}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PropertyDetailCard;
