import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import { VisitInfo, GuestPreBookingInvestor, ProjectInfo, ModelInfo, UnitInfo } from '../models/guestInvestorModel';

interface Props {
  projectInfo: ProjectInfo;
  modelInfo: ModelInfo;
  unitInfo: UnitInfo;
}

const InvestorPreBookingDetailCard = ({
  projectInfo,
  modelInfo,
  unitInfo
}: Props) => {
  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">
            Información de unidad pre reservada
          </h6>
          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Modelo
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >
                  {modelInfo.name}
                </span>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Unidad
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{unitInfo.name}</span>
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Precio base
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{unitInfo.base_price}</span>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  )
}

export default InvestorPreBookingDetailCard
