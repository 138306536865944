import axios from 'axios';
import { CreateTransactionSale } from '../models/transactionSaleModel';

const loadAbort = () => {
  return new AbortController();
};

export const createTransactionSaleEndpoint = (
  payload: CreateTransactionSale,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/transaction-sales`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
      }),
    controller,
  };
};
