import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { DEFAULT_AVATAR } from '../constants/profileConstants';

interface Props {
  avatar: string;
  name: string;
  phone?: string;
  extraInfo?: string;
  showAvatar?: boolean;
}

const ProfileCard = (props: Props) => {
  const { avatar, name, phone, extraInfo = '', showAvatar = true } = props
  return (
    <>
      <Card>
        <CardBody>
          {
            showAvatar && <img
              alt="..."
              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
              src={
                avatar
                  ? avatar
                  : DEFAULT_AVATAR
              }
              style={{ width: "140px" }}
            />
          }
          <div className="pt-4 text-center">
            <h5 className="h3 title">
              <span className="d-block mb-1">{name}</span>
              {
                phone && (
                  <>
                    <small className="h4 font-weight-light text-muted">
                      Teléfono: {phone}
                    </small><br />
                  </>
                )
              }
              <small className="h4 font-weight-light text-muted">
                {extraInfo}
              </small>
            </h5>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ProfileCard
