import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { PropertyPricesSaleStatus } from '../models/propertyModel';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getPropertyPricesStatusSalesInfoEndpoint } from '../services/propertySalesService';

interface Props {
  propertyId: string;
}

const useAdminSales = ({ propertyId }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [propertyPricesSaleStatus, setPropertyPricesSaleStatus] =
    useState<PropertyPricesSaleStatus>();

  const loadPropertyPricesStatusSalesInfo = async (
    propertyId: string,
  ): Promise<PropertyPricesSaleStatus | null> => {
    const { status, data } = await callEndpoint(
      getPropertyPricesStatusSalesInfoEndpoint(propertyId, token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setPropertyPricesSaleStatus(response.data);
      return response.data;
    } else {
      return null;
    }
  };

  useEffect(() => {
    loadPropertyPricesStatusSalesInfo(propertyId).catch((e) =>
      console.error(e),
    );
  }, []);

  return {
    propertyPricesSaleStatus,
  };
};

export default useAdminSales;
