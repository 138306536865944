import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { OfferCard } from './components/OfferCard';
import { LandlordOffer } from './models/propertyModel';
import { getLandlordPropertyOffers } from './services/propertyService';
import useAuth from '../../hooks/useAuth';
import CopyToClipboard from 'react-copy-to-clipboard';
import useUserCountry from '../../hooks/useUserCountry';
import { Button, UncontrolledTooltip } from 'reactstrap';

const AdminOffer = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [offers, setOffers] = useState<LandlordOffer[]>([]);
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { getCurrentCountry } = useUserCountry();
  const currenUserCountryCode = getCurrentCountry()?.code.toLowerCase() || '';
  const urlOffersTenant = `${process.env.REACT_APP_PROPI_WEB}/app/${currenUserCountryCode}/l/tn/offers`;
  const urlOffersLandlord = `${process.env.REACT_APP_PROPI_WEB}/app/${currenUserCountryCode}/l/property/offers/${id}`;
  const [clipboardUrls, setClipboardUrls] = useState<{
    landlord: string;
    tenant: string;
  }>({
    landlord: '',
    tenant: '',
  });

  useEffect(() => {
    const loadOffers = async () => {
      const { status, data } = await callEndpoint(
        getLandlordPropertyOffers(id, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setOffers(response.data.offers || []);
      }
    };
    loadOffers().catch((e) => console.error(e));
  }, []);

  if (isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <AdminPage
      name={'Administrar ofertas'}
      parentName={'Propiedades'}
      footer={
        <>
          <CopyToClipboard
            text={urlOffersTenant}
            onCopy={() =>
              setClipboardUrls((state) => ({
                landlord: '',
                tenant: urlOffersTenant,
              }))
            }
          >
            <Button color="primary" id="copy-button-tenant">
              <i className="ni ni-single-copy-04" />
              <span>Copiar link ofertas inquilino</span>
            </Button>
          </CopyToClipboard>
          <UncontrolledTooltip
            key={'ttip-tenant'}
            delay={0}
            target="copy-button-tenant"
          >
            {clipboardUrls.tenant === urlOffersTenant
              ? 'Copiado'
              : 'Hacer clic para copiar'}
          </UncontrolledTooltip>
          <CopyToClipboard
            text={urlOffersLandlord}
            onCopy={() =>
              setClipboardUrls((state) => ({
                landlord: urlOffersLandlord,
                tenant: '',
              }))
            }
          >
            <Button color="default" id="copy-button-landlord">
              <i className="ni ni-single-copy-04" />
              <span>Copiar link ofertas propietario</span>
            </Button>
          </CopyToClipboard>
          <UncontrolledTooltip
            key={'ttip-landlord'}
            delay={0}
            target="copy-button-landlord"
          >
            {clipboardUrls.landlord === urlOffersLandlord
              ? 'Copiado'
              : 'Hacer clic para copiar'}
          </UncontrolledTooltip>
        </>
      }
    >
      <>
        <div className="card-columns">
          {offers && offers.length > 0
            ? offers.map((offer) => (
                <OfferCard
                  key={offer.tenant_id}
                  offerData={offer}
                  success={offer.status === 'accepted'}
                />
              ))
            : 'Por el momento la propiedad no posee ofertas'}
        </div>
      </>
    </AdminPage>
  );
};

export default AdminOffer;
