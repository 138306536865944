import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { ProofIncomeForm, ProofIncomeResume } from '../models/proofIncomeModel';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

interface Props {
  incomeProduct: string;
  proofResume?: ProofIncomeResume;
  handleForm: (form: ProofIncomeForm) => void;
  isUpdate?: boolean;
}

const AccountingProofIncomeForm: React.FC<Props> = ({
  handleForm,
  proofResume,
  incomeProduct,
  isUpdate = false,
}) => {
  const { transactionDetailId = '' } = useParams();
  const { isLoading } = useCallApiAndLoad();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ProofIncomeForm>({
    resolver: yupResolver(
      yup
        .object({
          transaction_detail_id: yup.string(),
          amount: yup.number().required('Requerido'),
          concept_income: yup.string().required('Concepto es requerido'),
          payment_income: yup
            .string()
            .required('Forma de pago recibido es requerido'),
          ref_income: yup.string().required('Referencia es requerido'),
          income_product: yup.string().required('Producto es requerido'),
          client_name: yup.string().required('Nombre de cliente es requerido'),
          client_lastname: yup
            .string()
            .required('Apellido de cliente es requerido'),
          client_document: yup
            .string()
            .required('Documento de cliente es requerido'),
          generated_at: yup.string(),
          client_email: yup.string(),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (proofResume) {
      reset({
        transaction_detail_id: transactionDetailId,
        concept_income: proofResume.concept_income,
        payment_income: proofResume.payment_income,
        ref_income: proofResume.ref_income,
        income_product: incomeProduct,
        client_name: proofResume.client_name,
        client_lastname: proofResume.client_lastname,
        client_document: proofResume.client_document,
        generated_at: proofResume.generated_at,
        amount: +proofResume.amount,
        client_email: proofResume.client_email,
      });
    }
  }, [proofResume]);

  useEffect(() => {
    console.log(errors);
  });

  return (
    <Form role="form" onSubmit={handleSubmit(handleForm)}>
      <Row>
        <Col md="6">
          {/* amount readonly */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="amount">
                  Monto
                </label>
                {/* <Input
                    value={proofResume?.amount}
                    key={`amount-key`}
                    type="text"
                  /> */}
                <Controller
                  name="amount"
                  control={control}
                  defaultValue={
                    proofResume?.amount && !isNaN(+proofResume?.amount)
                      ? +proofResume?.amount
                      : 0
                  }
                  key={`amount-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.amount?.message && 'is-invalid'}
                      {...field}
                      type="number"
                      step="any"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.amount?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          {/* concept_income */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="concept_income">
                  Concepto
                </label>
                <Controller
                  name="concept_income"
                  control={control}
                  defaultValue={proofResume?.concept_income ?? ''}
                  key={`concept_income-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.concept_income?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.concept_income?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
          {/* payment_income */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="payment_income">
                  Forma de pago recibido
                </label>
                <Controller
                  name="payment_income"
                  control={control}
                  key={`payment_income-key`}
                  defaultValue={proofResume?.payment_income ?? ''}
                  render={({ field }) => (
                    <Input
                      className={errors.payment_income?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.payment_income?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
          {/* ref_income */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="ref_income">
                  Referencia
                </label>
                <Controller
                  name="ref_income"
                  control={control}
                  key={`ref_income-key`}
                  defaultValue={proofResume?.ref_income ?? ''}
                  render={({ field }) => (
                    <Input
                      className={errors.ref_income?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.ref_income?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          {/* client_name */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="client_name">
                  Nombres
                </label>
                <Controller
                  name="client_name"
                  control={control}
                  key={`client_name-key`}
                  defaultValue={proofResume?.client_name ?? ''}
                  render={({ field }) => (
                    <Input
                      className={errors.client_name?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.client_name?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          {/* client_lastname */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="client_lastname">
                  Apellidos
                </label>
                <Controller
                  name="client_lastname"
                  control={control}
                  key={`client_lastname-key`}
                  defaultValue={proofResume?.client_lastname ?? ''}
                  render={({ field }) => (
                    <Input
                      className={
                        errors.client_lastname?.message && 'is-invalid'
                      }
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.client_lastname?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>

          {/* client_email */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="client_email">
                  Email
                </label>
                <Controller
                  name="client_email"
                  control={control}
                  key={`client_email-key`}
                  defaultValue={proofResume?.client_email ?? ''}
                  render={({ field }) => (
                    <Input
                      className={errors.client_email?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.client_email?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          {/* client_document */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="client_document">
                  Documento
                </label>
                <Controller
                  name="client_document"
                  control={control}
                  key={`client_document-key`}
                  defaultValue={proofResume?.client_document ?? ''}
                  render={({ field }) => (
                    <Input
                      className={
                        errors.client_document?.message && 'is-invalid'
                      }
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.client_document?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
          {/* generated_at */}
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="generated_at">
                  Fecha de generación de comprobante de ingreso
                </label>
                <Controller
                  name="generated_at"
                  control={control}
                  key={`generated_at-key`}
                  defaultValue={proofResume?.generated_at ?? ''}
                  render={({ field }) => (
                    <Input
                      className={errors.generated_at?.message && 'is-invalid'}
                      {...field}
                      type="date"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.generated_at?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Button color="primary" disabled={isLoading}>
        {isUpdate ? 'Actualizar' : 'Crear'}
      </Button>
    </Form>
  );
};

export default AccountingProofIncomeForm;
