import { Alert, Badge, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { GuestInfo } from '../../pages/investors/models/guestInvestorModel';
import ProfileCard from '../ProfileCard';

interface Props {
  guestInfo: GuestInfo;
}

const InvestorProfileCard = ({ guestInfo }: Props) => {
  return (
    <>
      <Card className="card-profile">
        <CardHeader>
          <Badge className="badge-lg" color="primary">
            Registrado como Guest
          </Badge>
        </CardHeader>
        <CardBody>
          <div className="text-center">
            <h5 className="h3">{`${guestInfo.fullname}`}</h5>
            <div className="h5 font-weight-300">
              Teléfono: {`${guestInfo.phone}`}
            </div>
            <div className="h5 font-weight-300">
              Email: {`${guestInfo.email}`}
            </div>
          </div>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
      {guestInfo.is_registered && guestInfo.registered_user && (
        <Card className="card-profile">
          <CardBody>
            <Alert color="warning">
              <strong>Atención</strong> Existe un perfil de inversionista/propietario con el
              mismo correo de este lead, Si decides aceptarlo sera asignado a
              dicho inversionista y este prodrá ver sus pre reservas en la sección
              de Mis pre-reservas
            </Alert>
            Inversionista/Propietario encontrado
            <ProfileCard
              avatar={guestInfo.registered_user.avatar}
              name={guestInfo.registered_user.name}
              phone={guestInfo.registered_user.phone}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default InvestorProfileCard;
