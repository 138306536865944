import React from 'react';
import { PropertiesList } from '../models/propertyModel';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  NavLink,
  Row, UncontrolledDropdown,
} from 'reactstrap';
import { DEFAULT_AVATAR } from '../../../constants/profileConstants';
import { getPropertyStatus } from '../adapters/propertyAdapter';


export interface Props {
  property: PropertiesList
}
const PropertyMobileListing: React.FC<Props> = ({property}) => {

  const optionsMenu = (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={e => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem
          href={`/properties/admin/${property.id}`}
        >
          Ver detalle
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={e => e.preventDefault()}
        >
          Eliminar
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )


  return (
    <Card>
      <CardHeader>
        <Row className="my-2">
          <Col>
            <span className="text-gray">#{property.code}</span>
          </Col>
          <Col>
            <img
              className="avatar avatar-sm rounded-circle mr-2"
              src={
                property.landlordInfo && property.landlordInfo.avatar
                  ? property.landlordInfo.avatar
                  : DEFAULT_AVATAR
              }
            />
            <span className="">{property?.landlordInfo?.name}</span>
          </Col>
        </Row>


      </CardHeader>
      <CardImg
        alt="..."
        src={
          property.cover && !property.cover.endsWith("null")
            ? property.cover
            : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
        }
        top
      />
      <CardBody>
        <Badge color="secondary" pill>
          {getPropertyStatus(property.status)}
        </Badge>
        <Row className="my-2">
          <Col>
            <span>{property.monthly_price}</span>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <CardText>{property.address}</CardText>
          </Col>
        </Row>

      </CardBody>
      <CardFooter>
        <Row>
          <Col>
            <NavLink href={`${process.env.REACT_APP_PROPI_WEB}/alquiler/${property.url_detail}/${property.code}`} target="_blank">
              <Button color="primary" size="sm">Ver en marketplace</Button>
            </NavLink>
          </Col>
          <Col className="d-flex justify-content-end">
            {optionsMenu}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  )
};

export default PropertyMobileListing