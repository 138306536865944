import React, { useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { InvestorTransaction } from '../models/investors';

export type Props = {
  transactionDetails: InvestorTransaction
  footer: any
}

const InvestorTransactionDetailCard: React.FC<Props> = ({transactionDetails, footer}) => {

  const fieldMapper = [
    {
      title: "Código de proyecto",
      value: transactionDetails.code,
    },
    {
      title: "Proyecto",
      value: transactionDetails.name,
    },
    {
      title: "Modelo",
      value: transactionDetails.model_name,
    },
    {
      title: "Unidad",
      value: transactionDetails.unit_name,
    },
    {
      title: "Precio",
      value: transactionDetails.unit_price,
    },
  ]

  return (
    <AdminListGroup title="Detalles del transacción" items={fieldMapper} footer={footer} />
  );
}

export default InvestorTransactionDetailCard;