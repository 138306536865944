import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useAdminEntity from '../../../hooks/useAdminEntity';
import { getTypePropertiesEndpoint } from '../../properties/services/propertyService';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import Select from 'react-select';
import { getCitiesEndpoint, getCountriesEndpoint, getStatesEndpoint } from '../../../services/publicService';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import AdminMapPicker from '../../../components/admin/AdminMapPicker';
import { Coordinate } from '../../../models/mapViewModel';

export interface FormControlProps {
  label: string;
  children: JSX.Element;
  error?: any;
}

const FormControl: React.FC<FormControlProps> = ({label, children, error}) => {
  return (
    <Row>
      <Col>
        <FormGroup>
          <Label>{label}</Label>
          { children }
          <h5 className="text-danger">
            {error}
          </h5>
        </FormGroup>
      </Col>
    </Row>
  );
};

export interface FormErrors {
  message: string;
}

const FormErrors: React.FC<FormErrors> = ({message}) => {
  return (
   <>
     {(message) && (
       <Row>
         <Col>
           <Alert color="warning">{message}</Alert>
         </Col>
       </Row>
     )}
   </>
  );
};

export interface Props {
  handleForm: (form: any) => void;
  defaultForm?: any;
}

const ManageResidentialForm: React.FC<Props> = ({handleForm, defaultForm}) => {
  const {
    handleSubmit, formState: { errors }, reset, control, setValue
  } = useForm({
    resolver: yupResolver(yup.object({
      name: yup.string()
        .required("Nombre es requerido."),
      description: yup.string()
        .required("Descripción es requerido."),
      street: yup.string()
        .required("Calle es requerido."),
      address: yup.string()
        .required("Dirección es requerido."),
      lat: yup.number()
        .required("Ubicación es requerido."),
      lng: yup.number()
        .required("Ubicación es requerido."),
      type_id: yup.string()
        .required("Tipo de residencia es requerido."),
      city_id: yup.string()
        .required("Ciudad es requerido."),
    }).required())
  });

  const [errorAlert, setErrorAlert] = useState<string>("");

  const getPropertyTypes = useCallback(() => getTypePropertiesEndpoint(), []);
  const [propertyTypes] = useAdminEntity<any>(getPropertyTypes, setErrorAlert);
  const { isLoading } = useCallApiAndLoad();

  const [countryId, setCountryId] = useState<string>('');
  const [stateId, setStateId] = useState<string>('');

  const getCountries = useCallback(() => getCountriesEndpoint(), []);
  const [countries, countriesFn, loadingCountries] = useAdminEntity(
    getCountries,
    console.error,
  );
  const countriesOptions = listToSelectOptionAdapter(
    (countries as Array<any>) || [],
    'country_id',
    'country_name',
  );

  const getStates = useCallback(
    () => getStatesEndpoint(countryId),
    [countryId],
  );
  const [states] = useAdminEntity(getStates, console.error);
  const statesOptions = listToSelectOptionAdapter(
    (states as Array<any>) || [],
    'state_id',
    'state_name',
  );

  const getCities = useCallback(
    () => getCitiesEndpoint(countryId, stateId),
    [countryId, stateId],
  );
  const [cities] = useAdminEntity(getCities, console.error);
  const citiesOptions = listToSelectOptionAdapter(
    (cities as Array<any>) || [],
    'city_id',
    'city_name',
  );

  const internalHandleForm = useCallback((form: any) => {
    handleForm(form);
  }, [])

  useEffect(() => {
    if (defaultForm) {
      setCountryId(defaultForm.city.state.country.id);
      setStateId(defaultForm.city.state.id);
      setValue("city_id", defaultForm.city.id);
      setValue("type_id", defaultForm.type.id);
      setValue('lat', defaultForm.lat);
      setValue('lng', defaultForm.lng);
    }
  }, [defaultForm]);


  if (isLoading || !propertyTypes)
    return (
      <></>
    )

  return (
    <Form onSubmit={handleSubmit(internalHandleForm)}>
      <FormErrors message={errorAlert} />
      <FormControl label="Nombre" error={errors?.name?.message}>
        <Controller
          name="name"
          control={control}
          defaultValue={defaultForm?.name}
          render={({field}) => (
            <Input
              type="text"
              placeholder="Ingrese el nombre"
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl label="Descripción" error={errors?.description?.message}>
          <Controller
            name="description"
            control={control}
            defaultValue={defaultForm?.description}
            render={({field}) => (
              <Input
                type="text"
                placeholder="Ingrese la descripción"
                {...field}
              />
            )}
          />
      </FormControl >
      <FormControl label="Dirección" error={errors?.address?.message}>
        <Controller
          name="address"
          control={control}
          defaultValue={defaultForm?.address}
          render={({field}) => (
            <Input
              type="text"
              placeholder="Ingrese la dirección"
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl label="Calle" error={errors?.street?.message}>
        <Controller
          name="street"
          control={control}
          defaultValue={defaultForm?.street}
          render={({field}) => (
            <Input
              type="text"
              placeholder="Ingrese la calle"
              {...field}
            />
          )}
        />
      </FormControl>
      <FormControl label="Tipo de propiedad" error={errors?.type_id?.message}>
        <Controller
          name="type_id"
          control={control}
          render={({}) => (
            <Select
              key={`type-id-${defaultForm?.type?.id}`}
              placeholder="Seleccione el tipo de residencia"
              defaultValue={propertyTypes.map((pt: any) => {
                if (pt.id === defaultForm?.type?.id || "")
                  return {label: pt.type, value: pt.id};
              })}
              onChange={(e: any) => {
                const residentialTypeId = e?.value;
                setValue("type_id", residentialTypeId);
              }}
              options={propertyTypes.map((pt: any) => ({
                label: pt.type,
                value: pt.id,
              }))}
            />
          )}
        />
      </FormControl>
      <FormControl label="País" error={errors?.city_id?.message}>
        <Controller
          name="city_id"
          control={control}
          render={() => (
            <Select
              key={`country-id-${Math.random()}`}
              placeholder="Selecciona un país"
              defaultValue={countriesOptions.map((c: any) => {
                if (c.value === countryId)
                  return c;
              })}
              options={countriesOptions}
              onChange={(optionSelected) => {
                setCountryId(optionSelected?.value);
                setValue('city_id', '');
              }}
            />
          )}
        />
      </FormControl>
      <FormControl label="Estado" error={errors?.city_id?.message}>
        <Controller
          name="city_id"
          control={control}
          render={() => (
            <Select
              key={`state-id-${Math.random()}`}
              placeholder="Selecciona un estado"
              defaultValue={statesOptions.map((c: any) => {
                if (c.value === stateId)
                  return c;
              })}
              options={statesOptions}
              onChange={(optionSelected) => {
                setStateId((prev) => {
                  return optionSelected?.value;
                });
                setValue('city_id', '');
              }}
            />
          )}
        />
      </FormControl>
      <FormControl label="Ciudad" error={errors?.city_id?.message}>
        <Controller
          name="city_id"
          control={control}
          render={() => (
            <Select
              key={`city-id-${Math.random()}`}
              defaultValue={citiesOptions.map((c: any) => {
                if (c.value === defaultForm?.city.id)
                  return c;
              })}
              placeholder="Selecciona una ciudad"
              options={citiesOptions}
              onChange={(optionSelected) =>
                setValue('city_id', optionSelected?.value)
              }
            />
          )}
        />
      </FormControl>
      <FormControl label="Ubica la residencia">
        <AdminMapPicker
          defaultCenter={
            (defaultForm?.lat && defaultForm?.lng)
              ? {
                lat: defaultForm?.lat,
                lng: defaultForm?.lng,
              }
              : undefined
          }
          onChange={(geoPoint: Coordinate) => {
            setValue('lat', geoPoint.lat);
            setValue('lng', geoPoint.lng);
          }}
        />
      </FormControl>
      <Button color="primary">Guardar</Button>
    </Form>
  )
};

export default ManageResidentialForm;