import React from 'react';
import { ContractDetailData } from '../models/contractModel';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import { getContractStatusAdapter } from '../adapters/contractAdapter';
import DetailLabelText from '../../properties/components/DetailLabelText';
import { Option } from '../../../models/formModel';
import CreateSubscriptionModal from '../../subscriptions/components/CreateSubscriptionModal';
import useModalState from '../../../hooks/useModalState';
import { useNavigate } from 'react-router-dom';

export interface IProps {
  contract: ContractDetailData;
  activeSubscription: string | null;
}

const ContractInfoCard: React.FC<IProps> = ({
  contract,
  activeSubscription = null,
}) => {
  const [isOpenModal, toggleModal] = useModalState();
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="6">
            <h3 className="mb-0">Información de contrato</h3>
          </Col>
          <Col className="text-right" xs="6">
            <Badge className="badge-lg" color="default">
              {getContractStatusAdapter(contract.status)}
            </Badge>
            {contract.transaction_id && (
              <Badge className="badge-lg" color="success">
                Mandato de pago generado
              </Badge>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="">
          <DetailLabelText
            label="Precio de oferta"
            text={contract.offer_price}
          />
          <DetailLabelText
            label="Fecha de mudanza"
            text={contract.moving_date}
          />
          <DetailLabelText label="Periodo" text={contract.period} />
          <DetailLabelText label="Fecha de inicio" text={contract.start_date} />
          <DetailLabelText label="Fecha de fin" text={contract.end_date} />
          <DetailLabelText
            label="Rental managment"
            text={contract.has_rental_managment ? 'Si' : 'No'}
          />
          <DetailLabelText
            label="Demanda por broker"
            text={contract.demand_by_broker ? 'Si' : 'No'}
          />
        </div>
      </CardBody>
      <CardFooter>
        {!activeSubscription ? (
          <Button color={'primary'} onClick={toggleModal}>
            Crear contrato de rental managment
          </Button>
        ) : (
          <Button
            color={'default'}
            onClick={() =>
              navigate(`/subscriptions/${activeSubscription}/detail`)
            }
          >
            Ver detalle de rental managment
          </Button>
        )}
      </CardFooter>
      <CreateSubscriptionModal
        contractId={contract.contract_id}
        isOpen={isOpenModal}
        onToggle={toggleModal}
        headerText={'Crear contrato de rental managment'}
      />
    </Card>
  );
};

export default ContractInfoCard;
