import { TimelineItem } from '../../../models/timelineModel';
import { OfferLog } from '../models/offerModel';
import { OFFER_STATUS } from '../../../constants/offerConstants';
import { TimeLineType } from '../../../types/timeline.types';

export const getOfferStatusName = (status: string) => {
  return OFFER_STATUS[status] || 'NA';
};

export const offerLogTimelineAdapter = (
  offerLog: OfferLog[],
): TimelineItem[] => {
  return offerLog.map((offerLogItem): TimelineItem => {
    let action = offerLogItem.action ?? null;
    let message: string = 'Cambio en oferta';
    let type: TimeLineType = 'DEFAULT';
    if (action === 'accepted_offer_compliance') {
      message = 'Se acepta por reglas de cumplimiento';
    } else if (action === 'rejected_offer_compliance') {
      message = 'Se deniega la oferta por reglas de cumplimiento';
      type = 'WARNING';
    } else if (action === 'negociate_offer') {
      message = 'Oferta negociada';
      type = 'WARNING';
    }
    const {
      comment = 'Comentario del inquilino',
      created_at,
      trigger_by,
      current_status,
      previous_status,
      current_offer_price,
      previous_offer_price,
      trigger_user_by,
    } = offerLogItem;
    const hasChangeStatus = current_status !== previous_status;
    const hasChangePrice = current_offer_price !== previous_offer_price;
    let subMessage = '';
    if (hasChangeStatus) {
      subMessage = `Estado anterior: ${getOfferStatusName(
        previous_status,
      )}, Nuevo estado: ${getOfferStatusName(current_status)}`;
    }
    if (hasChangePrice) {
      subMessage += ` | Precio anterior: ${previous_offer_price}, Nuevo precio: ${current_offer_price}`;
    }
    if (trigger_user_by) {
      subMessage += ` [${trigger_by} - ${trigger_user_by}]`;
    }
    return {
      header: comment,
      main_message: message,
      message: comment,
      sub_message: subMessage,
      type: trigger_by,
      time: created_at,
      action: type,
    };
  });
};
