import React from 'react';
import { ContainedPolygons } from '../../pages/admin_polygons/models/polygonsModel';
import { Alert, Col, Row } from 'reactstrap';

interface Props {
  assigned_polygon?: number | null;
  contained_polygons?: ContainedPolygons[];
}

const AssignedAddressCard = ({
  assigned_polygon = null,
  contained_polygons = [],
}: Props) => {
  if (!contained_polygons || !contained_polygons.length) {
    return <></>;
  }

  return (
    <Row>
      <Col>
        <Alert color="secondary">
          Ubicación/Polígono asignado:&nbsp;
          <strong>{contained_polygons[0].name}</strong>
          <br />
          Relacionado a:
          <ul>
            {contained_polygons.map((p, i) => {
              if (i !== 0) {
                return <li key={p.name}>{p.name}</li>;
              }
            })}
          </ul>
        </Alert>
      </Col>
    </Row>
  );
};

export default AssignedAddressCard;
