export const randomNumber = (max: number): number => {
  return Math.floor(Math.random() * max);
};

const stringCharacters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const stringNumbers = '0123456789';

export const generateRandomString = (
  length: number = 10,
  only_numers: boolean = false,
  only_capitalize = false,
) => {
  let result = '';
  let validCharacters = only_capitalize
    ? stringCharacters.toUpperCase()
    : stringCharacters;
  if (only_numers) validCharacters = stringNumbers;
  const charactersLength = validCharacters.length;
  for (let i = 0; i < length; i++) {
    result += validCharacters.charAt(
      Math.floor(Math.random() * charactersLength),
    );
  }
  return result;
};
