import { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { generatePagination } from '../../adapters/paginateListAdapter';

interface Props {
  page?: number;
  totalPages: number;
  take?: number;
  onPageChange: (page: number, take: number) => void,
}

const AdminPaginator = (props: Props) => {
  const [page, setPage] = useState(props.page || 1);
  const [hasPreviousPage, setHasPreviousPage] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [paginationList, setPaginationList] = useState(Array<number>)

  useEffect(() => {
      setHasPreviousPage(page - 1 > 0);
      setHasNextPage(page + 1 <= props.totalPages)
      setPaginationList(generatePagination(page, props.totalPages, 5))
  }, [page, props.totalPages]);

  return (
    <nav aria-label="...">
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={!hasPreviousPage ? 'disabled' : ''}>
          <PaginationLink
            onClick={() => {
              const newPage = page - 1
              props.onPageChange(newPage, props.take ?? 0);
              setPage(newPage);
            }}
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Anterior</span>
          </PaginationLink>
        </PaginationItem>
        { paginationList.map((pageItem) => (
            <PaginationItem key={pageItem} className={pageItem === page ? 'active' : ''}>
              <PaginationLink onClick={() => {  
                props.onPageChange(pageItem, props.take ?? 0);
                setPage(pageItem);
              }}>
                {pageItem}
              </PaginationLink>
            </PaginationItem>
          )) }
        {/* <PaginationItem className="active">
          <PaginationLink>
            {page}
          </PaginationLink>
        </PaginationItem> */}
        <PaginationItem className={!hasNextPage ? 'disabled' : ''}>
          <PaginationLink
            onClick={() => {
              const newPage = page + 1
              props.onPageChange(newPage, props.take ?? 0);
              setPage(newPage);
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Siguiente</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
};

export default AdminPaginator