import { Alert, Button, Col, FormGroup, Row } from 'reactstrap';
import Select from 'react-select';
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MAP_STYLE } from '../../constants/MapConstants';
import useAddressLisPicker from '../../hooks/useAddressLisPicker';
import React, { useState } from 'react';
import { Coordinate } from '../../models/mapViewModel';
import { POLYGONS_TYPES_OPTIONS } from '../../pages/properties/constants/propertyConstants';
import AssignedAddressCard from './AssignedAddressCard';

export interface Props {
  onAddressAssigned: (addressAssignedId: any, centerPoint: any) => void;
  defaultCenter?: Coordinate;
  defaultPolygonId?: number;
}
const libraries: any = ['geometry'];

const AddressListPicker: React.FC<Props> = ({
  onAddressAssigned,
  defaultCenter,
  defaultPolygonId,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB0c1nq-isvWJWWam5CICmPt0oYgY1A_eg',
    libraries: libraries,
  });

  const [currentAddress, setCurrentAddress] = useState<any>();

  const {
    addressConfiguration,
    addressLevelListsForView,
    setOptionSelected,
    assignablePolygon,
    mapCenter,
    mapZoom,
    setMapCenter,
    onLoadMap,
    pickedAddressApi,
    handlePickAddress,
    existingAssignedPolygon,
  } = useAddressLisPicker({ defaultCenter, defaultPolygonId });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps</div>;
  }

  const handleClick = (e: any) => {
    if (window.google && window.google.maps && window.google.maps.geometry) {
      const clickedEventLatLng = e.latLng;
      const { lat, lng } = clickedEventLatLng.toJSON();
      if (assignablePolygon) {
        const googlePolygon = new window.google.maps.Polygon({
          paths: assignablePolygon.polygon,
        });
        const isInside = window.google.maps.geometry.poly.containsLocation(
          clickedEventLatLng,
          googlePolygon,
        );
        if (!isInside) {
          alert(
            `Clicked coordinates (${lat}, ${lng}) are outside the polygon.`,
          );
          return;
        }
        setMapCenter({ lat, lng });
      }
    } else {
      console.error('Google Maps Geometry library is not loaded.');
    }
  };

  return addressConfiguration ? (
    <>
      <AssignedAddressCard
        assigned_polygon={existingAssignedPolygon?.id}
        contained_polygons={existingAssignedPolygon?.contained_polygons}
      />
      <Row>
        <Col md={12}>
          <span>Selección de ubicación por listas</span>
          {/*<pre>{JSON.stringify(addressLevelListsForView)}</pre>*/}
          {addressLevelListsForView.length > 0 &&
            addressLevelListsForView.map((currentAddressLevel, index) => {
              if (currentAddressLevel.level_config) {
                const { admin_label, description, level, assignable } =
                  currentAddressLevel.level_config;
                const optionsToAssign = currentAddressLevel.list;
                const selectedValue = currentAddressLevel.selected;
                return (
                  <>
                    <FormGroup key={`level-config-fg-${level}`}>
                      <label
                        key={`level-config-lbl-${level}`}
                        className="form-control-label"
                      >
                        {admin_label}
                      </label>
                      <Select
                        key={`level-config-sel-${level}-${index}`}
                        placeholder={`Seleccione ${admin_label}`}
                        value={selectedValue}
                        onChange={(item) => {
                          if (item) {
                            setOptionSelected({
                              selected_option: item,
                              search_level: level + 1,
                              assignable,
                            });
                            //
                          }
                        }}
                        options={optionsToAssign}
                      />
                      {assignable && (
                        <h5 className="text-success">Asignable</h5>
                      )}
                    </FormGroup>
                  </>
                );
              }
            })}
          <>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={mapCenter}
              zoom={mapZoom}
              onLoad={onLoadMap}
              options={{
                minZoom: 15,
                styles: MAP_STYLE,
              }}
              onClick={(e) => handleClick(e)}
            >
              {assignablePolygon && (
                <Polygon
                  key={assignablePolygon.id}
                  editable={false}
                  draggable={false}
                  options={{
                    fillColor: 'green',
                    strokeColor: '#2FB672',
                    clickable: true,
                  }}
                  onClick={(e: any) => {
                    if (
                      assignablePolygon.polygon_type !==
                      POLYGONS_TYPES_OPTIONS.TOWER
                    ) {
                      setMapCenter({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      });
                    }
                  }}
                  path={assignablePolygon.polygon}
                />
              )}
              <Marker position={mapCenter} />
            </GoogleMap>
          </>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {assignablePolygon && (
            <Button
              onClick={() => {
                const pickedAddress = handlePickAddress();
                setCurrentAddress(pickedAddress);
                onAddressAssigned(
                  pickedAddress.polygon_selected,
                  pickedAddress.center,
                );
              }}
              block
              color="success"
              size="md"
              type="button"
            >
              Asignar ubicación seleccionada
            </Button>
          )}
          {currentAddress && (
            <Alert color="secondary">
              Asignaras ubicación en:&nbsp;
              <strong>{currentAddress.polygon_selected_name}</strong>
              <br />
              Con coordenada: <pre>{JSON.stringify(currentAddress.center)}</pre>
              <br />
            </Alert>
          )}
        </Col>
      </Row>
      {/* TODO: when location is updated or assigned for the first time, show this modal with de information to assign */}
    </>
  ) : (
    <></>
  );
};

export default AddressListPicker;
