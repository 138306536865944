
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

interface Props {
  name: string,
  parentName: string
  parentUri?: string;
  onHistoryBack?: () => void;
}

const TimelineHeader = ({ name, parentName, parentUri, onHistoryBack }: Props) => {
  // const navigate = useNavigate();
  if(!parentUri) {
    parentUri = '#'
  }
  return (
    <>
      <div className="header header-dark bg-default pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {(onHistoryBack) && (
                  <Button
                    // size="sm"
                    color="secondary"
                    onClick={() => {
                    onHistoryBack();
                  }}>
                    <i className='fas fa-angle-left' />
                  </Button>
                )}
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {name}
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href={parentUri}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default TimelineHeader;
