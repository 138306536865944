import React, { useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { InvestorProjectListElement } from './models/investors';
import AdminPaginator from '../../components/admin/AdminPaginator';
import LoadingCard from '../../components/LoadingCard';
import AdminPage from '../../components/admin/AdminPage';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import useAdminPagination from '../../hooks/useAdminPagination';
import { getInvestorProjectsEndpoint } from './services/investorService';

const InvestorProjectList: React.FC = () => {
  const { isLoading } = useCallApiAndLoad();

  const [errorAlert, setErrorAlert] = useState(null);

  const [projects, setProjects] = useState<InvestorProjectListElement[]>([]);

  const { paginator, loadItems } = useAdminPagination(
    getInvestorProjectsEndpoint,
    setProjects,
    console.error,
  );

  const pageName = 'Proyectos de preventa';
  const pageParentName = 'Preventa';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  const actions = (project: InvestorProjectListElement) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem href={`/investors/projects/${project.id}`}>
          Ver proyecto
        </DropdownItem>
        <DropdownItem href={`/investors/projects/${project.id}/edit/`}>
          Editar proyecto
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nombre</th>
          <th scope="col">Desarrolladora</th>
          <th scope="col">Modelos</th>
          <th scope="col">Unidades disponibles</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {projects.length > 0 &&
          projects.map((project) => (
            <tr key={`${project.id}`}>
              <td>{project.code}</td>
              <td>{project.name}</td>
              <td>
                <img
                  alt="..."
                  width={100}
                  height={80}
                  src={
                    project.developer.avatar
                      ? project.developer.avatar
                      : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                  }
                />
                {`   ${project.developer.name}`}
              </td>
              <td>{project.total_models}</td>
              <td>{project.total_available_units}</td>
              <td className="text-right">{actions(project)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  if (isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentPagination}
      globalAlertOptions={globalAlertOptions}
    >
      {componentTable}
    </AdminPage>
  );
};

export default InvestorProjectList;
