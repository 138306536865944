import { Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  Button,
} from 'reactstrap';
import TimelineHeader from '../../components/headers/TimelineHeader';
import { Option } from '../../models/formModel';
import Select from 'react-select';
import useCreateProperty from './hooks/useCreateProperty';
import LandlordSearchInput from '../../components/landlordInvestors/LandlordSearchInput';

interface Props {
  isUpdate: boolean;
}

const numberList: Option[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '+5' },
];

const CreateProperty = ({ isUpdate = false }: Props) => {
  const headLabel = isUpdate ? 'Actualizar propiedad' : 'Crear propiedad';

  const {
    isLoading,
    control,
    register,
    setValue,
    // searchLandlordError,
    handleSubmit,
    doAction,
    catalogs,
    errors,
    loadingCurrency,
    currentCurrency,
    marketplaceTypesList,
    marketplaceTypeSelected,
    setMarketplaceTypeSelected,
    // setLandlordEmail,
    // handleSearchLandlord,
    // landlordFound,
  } = useCreateProperty();

  return (
    <>
      <TimelineHeader name={headLabel} parentName="Propiedades" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <Form role="form" onSubmit={handleSubmit(doAction)}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">{headLabel}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Row>
                        {/* type */}
                        <Col md="12">
                          <FormGroup>
                            {catalogs.propertyTypes &&
                              catalogs.propertyTypes.map((item) => (
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input
                                    {...register('type_id')}
                                    className="custom-control-input"
                                    type="radio"
                                    value={item.id}
                                    id={item.id}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={item.id}
                                  >
                                    {item.type}
                                  </label>
                                </div>
                              ))}
                            <h5 className="text-danger">
                              {errors.type_id?.message}
                            </h5>
                          </FormGroup>
                        </Col>
                        {/* Currency */}
                        <Col md={12}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="currency"
                            >
                              Moneda
                            </label>
                            {!loadingCurrency && (
                              <Controller
                                name="currency"
                                control={control}
                                defaultValue={currentCurrency.value || ''}
                                render={({ field: { ref } }) => (
                                  <Select
                                    placeholder="Selecciona una moneda"
                                    options={catalogs.currencies}
                                    defaultValue={currentCurrency}
                                    onChange={(selected: any) => {
                                      const { value = '' } = selected;
                                      setValue('currency', value);
                                    }}
                                    ref={ref}
                                  />
                                )}
                              />
                            )}
                            <h5 className="text-danger">
                              {errors.currency?.message}
                            </h5>
                          </FormGroup>
                        </Col>
                        {/* marketplace type just to switch prices */}
                        <Col md={12}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="marketplace_type"
                            >
                              Tipo de marketplace
                            </label>
                            <Controller
                              name="marketplace_type"
                              control={control}
                              render={({ field: { ref } }) => (
                                <Select
                                  placeholder="Selecciona el tipo de marketplace"
                                  options={marketplaceTypesList}
                                  onChange={(selected: any) => {
                                    const { value = '' } = selected;
                                    setValue('marketplace_type', value);
                                    setMarketplaceTypeSelected(selected);
                                  }}
                                  ref={ref}
                                />
                              )}
                            />
                            <h5 className="text-danger">
                              {errors.marketplace_type?.message}
                            </h5>
                          </FormGroup>
                        </Col>
                        {/* rental price */}
                        <Col md="12">
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="price"
                                >
                                  Precio{' '}
                                  {marketplaceTypeSelected &&
                                    `de ${marketplaceTypeSelected.label.toLowerCase()}`}
                                </label>
                                <Controller
                                  name="price"
                                  control={control}
                                  defaultValue={0}
                                  render={({ field }) => (
                                    <Input
                                      className={
                                        errors.price?.message && 'is-invalid'
                                      }
                                      {...field}
                                      id="price"
                                    />
                                  )}
                                />
                                <h5 className="text-danger">
                                  {errors.price?.message}
                                </h5>
                              </FormGroup>
                            </Col>
                            {/* min price */}
                            <Col md="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="min_price"
                                >
                                  Precio mínimo aceptable{' '}
                                  {marketplaceTypeSelected &&
                                    `de ${marketplaceTypeSelected.label.toLowerCase()}`}
                                </label>
                                <Controller
                                  name="min_price"
                                  control={control}
                                  defaultValue={0}
                                  render={({ field }) => (
                                    <Input
                                      className={
                                        errors.min_price?.message &&
                                        'is-invalid'
                                      }
                                      {...field}
                                      id="min_price"
                                    />
                                  )}
                                />
                                <h5 className="text-danger">
                                  {errors.price?.message}
                                </h5>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        {/* characteristics.rooms */}
                        <Col md="12">
                          <FormGroup>
                            <Row>
                              <Col md={6}>
                                <label
                                  className="form-control-label"
                                  htmlFor="c_rooms"
                                >
                                  Cantidad de habitaciones
                                </label>
                              </Col>
                              <Col md={6}>
                                <Controller
                                  name="c_rooms"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      id="c_rooms"
                                      type="select"
                                    >
                                      <option value="">No posee...</option>
                                      {numberList.map(({ label, value }) => (
                                        <option value={value}>{label}</option>
                                      ))}
                                    </Input>
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {/* characteristics.bathrooms */}
                        <Col md="12">
                          <FormGroup>
                            <Row>
                              <Col md={6}>
                                <label
                                  className="form-control-label"
                                  htmlFor="c_bathrooms"
                                >
                                  Cantidad de baños completos
                                </label>
                              </Col>
                              <Col md={6}>
                                <Controller
                                  name="c_bathrooms"
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      {...field}
                                      id="c_bathrooms"
                                      type="select"
                                    >
                                      <option value="">No posee...</option>
                                      {numberList.map(({ label, value }) => (
                                        <option value={value}>{label}</option>
                                      ))}
                                    </Input>
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {/* Landlord */}
                        <LandlordSearchInput
                          header={'Asignar propietario (opcional)'}
                          error_form_message={errors.landlord_id?.message}
                          onLandlordfound={(landlordId, landlord) => {
                            console.log(`
                            landlordId: ${landlordId}, landlord: ${JSON.stringify(
                              landlord,
                            )}
                          `);
                            setValue('landlord_id', landlordId);
                          }}
                        />
                        {/* <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="plan_id"
                            >
                              Asignar propietario
                            </label>
                            <InputGroup className="mb-2">
                              <Input
                                onChange={({ target }) =>
                                  setLandlordEmail(target.value || '')
                                }
                                placeholder="Correo de propietario"
                                type="text"
                              />
                              <InputGroupAddon addonType="append">
                                <Button
                                  onClick={handleSearchLandlord}
                                  color="primary"
                                  outline
                                >
                                  Buscar
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                            {searchLandlordError && (
                              <h5 className="text-danger">
                                {searchLandlordError}
                              </h5>
                            )}
                          </FormGroup>
                        </Col>
                        {landlordFound && landlordFound !== null && (
                          <Col md="12">
                            <ProfileCard
                              key="landlord_card"
                              avatar={landlordFound.avatar}
                              name={landlordFound?.name}
                              phone={landlordFound?.phone}
                              extraInfo="San Salvador, El Salvador"
                            />
                          </Col>
                        )} */}
                      </Row>
                    </Col>
                    <Col md="6">
                      <Row></Row>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    color={isUpdate ? 'warning' : 'primary'}
                    disabled={isLoading}
                  >
                    {headLabel}
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CreateProperty;
