import { useEffect, useState } from 'react';
import {
  ApplicableTransaction,
  CheckAvailabilityForm,
  CreateSubscriptionForm,
} from '../models/subscriptionsModel';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Option } from '../../../models/formModel';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';
import {
  checkSubscriptionAvailabilityEndpoint,
  createSubscriptionEndpoint,
  getSubscriptionsEndpoint,
} from '../services/subscriptionServices';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';

interface Props {
  contractId: string;
  defaultSubscriptionType?: string;
}

export enum SubscriptionEnumState {
  CHECKING = 'checking',
  CREATING = 'creating',
  DONE = 'done',
}

const useSubscriptions = ({
  contractId,
  defaultSubscriptionType = '',
}: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [subscriptions, setSubscriptions] = useState<Option[]>([]);
  const [apiErrors, setApiErrors] = useState('');
  const [formState, setFormState] = useState<SubscriptionEnumState>(
    SubscriptionEnumState.CHECKING,
  );
  const [applicableTransactions, setApplicableTransactions] = useState<
    ApplicableTransaction[]
  >([]);
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    resetField,
  } = useForm<CreateSubscriptionForm>({
    resolver: yupResolver(
      yup
        .object({
          subscription_id: yup.string().required('Subscripción es requerida'),
          subscription_type: yup
            .string()
            .required('Tipo de valor de subscripción es requerido'),
          value: yup
            .number()
            .required('Valor de subscripción a aplicar es requerido'),
          start_date: yup.string().required('Fecha de inicio es requerida'),
          end_date: yup.string().required('Fecha de fin es requerida'),
          start_payment_date: yup
            .string()
            .required('Fecha de inicio de pago es requerida'),
        })
        .required(),
    ),
  });

  // set default form contract id and subscription type
  useEffect(() => {
    loadSubscriptions().catch((e) => console.error(e));
  }, []);

  const loadSubscriptions = async () => {
    const { status, data } = await callEndpoint(
      getSubscriptionsEndpoint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setSubscriptions(listToSelectOptionAdapter(response.data, 'id', 'name'));
    }
  };

  const doCheckAvailability = async (form: CreateSubscriptionForm) => {
    setApiErrors('');
    const availabilityForm: CheckAvailabilityForm = {
      contract_id: contractId,
      subscription_id: form.subscription_id,
      start_date: form.start_date + '-01',
      end_date: form.end_date + '-01',
      start_payment_date: form.start_payment_date + '-01',
      subscription_type: form.subscription_type,
      value: form.value,
    };
    const { status, data } = await callEndpoint(
      checkSubscriptionAvailabilityEndpoint(availabilityForm, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setApplicableTransactions(response.data.applicable_transactions);
      setFormState(SubscriptionEnumState.CREATING);
    } else {
      setApiErrors(response.message);
      setFormState(SubscriptionEnumState.CHECKING);
    }
  };

  const doCreateSubscription = async (form: CreateSubscriptionForm) => {
    setApiErrors('');
    form.contract_id = contractId;
    form.start_payment_date = form.start_payment_date + '-01';
    form.start_date = form.start_date + '-01';
    form.end_date = form.end_date + '-01';
    const { status, data } = await callEndpoint(
      createSubscriptionEndpoint(form, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      setApplicableTransactions(response.data.applicable_transactions);
      setFormState(SubscriptionEnumState.DONE);
    } else {
      setApiErrors(response.message);
      setFormState(SubscriptionEnumState.CREATING);
    }
  };

  return {
    handleSubmit,
    errors,
    apiErrors,
    control,
    register,
    setValue,
    resetField,
    doCreateSubscription,
    doCheckAvailability,
    isLoading,
    subscriptions,
    applicableTransactions,
    formState,
  };
};

export default useSubscriptions;
