import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useEffect, useState } from 'react';
import { GuestVisitDetails } from './models/guestModel';
import LoadingCard from '../../components/LoadingCard';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import PropertyDetailCard from '../../components/PropertyDetailCard';
import AdminPage from '../../components/admin/AdminPage';
import {
  activateGuestEndpoint,
  cancelVisitEndpoint,
  getGuestVisitDetailEndpoint,
} from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import CancelEntityModal from './components/CancelEntityModal';
import GuestProfileCard, { Entities } from './components/GuestProfileCard';
import GuestVisitDetailCard from './components/GuestVisitDetailCard';
import ActivateGuestModal from './components/ActivateGuestModal';
import useModalState from '../../hooks/useModalState';
import Select from 'react-select';
import useProperList from '../../hooks/useProperList';
import { LeadMarketplaceType } from '../../components/VisitMarketplaceTypeBadge';

export default function GuestVisitDetail() {
  const { id: guestVisitId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const { propersOptions, loadPropers } = useProperList();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const [properId, setProperId] = useState<string>('');

  const [errorAlert, setErrorAlert] = useState(null);

  const [guestVisit, setGuestVisit] = useState<GuestVisitDetails>();
  const loadGuestVisit = useCallback(async () => {
    const { status, data } = await callEndpoint(
      getGuestVisitDetailEndpoint(guestVisitId, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestVisit(response.data);
    } else {
      setErrorAlert(response.message);
    }
  }, [callEndpoint, guestVisitId, token]);

  useEffect(() => {
    loadGuestVisit().catch((e) => setErrorAlert(e.toString()));
  }, [loadGuestVisit]);

  useEffect(() => {
    if (guestVisit) {
      loadPropers(
        guestVisit?.visitInfo.marketplace_type === LeadMarketplaceType.ON_RENT
          ? 'for_rent'
          : 'for_sale',
      );
    }
  }, [guestVisit]);

  const [showCancelVisitModal, toggleCancelVisitModal] = useModalState();
  const cancelVisit = useCallback(
    async (visit: GuestVisitDetails) => {
      const { status, data } = await callEndpoint(
        cancelVisitEndpoint(visit.guestInfo.id, visit.visitInfo.id, token),
      );
      if (status === 200) {
        toggleCancelVisitModal();
        navigate('/guest/visits');
      } else {
        const response = fetchResponseAdapter(data);
        setErrorAlert(response.message);
        toggleCancelVisitModal();
      }
    },
    [callEndpoint, navigate, toggleCancelVisitModal, token],
  );

  const [showActivateGuestModal, toggleActivateGuestModal] = useModalState();
  const activateGuest = useCallback(
    async (guestId: string) => {
      const { status, data } = await callEndpoint(
        activateGuestEndpoint(guestId, properId, token),
      );
      if (status === 200) {
        toggleActivateGuestModal();
        navigate('/guest/visits');
      } else {
        const response = fetchResponseAdapter(data);
        setErrorAlert(response.message);
        toggleActivateGuestModal();
      }
    },
    [callEndpoint, navigate, toggleActivateGuestModal, token],
  );

  const pageName = 'Detalle de Visita';
  const pageParentName = 'Guests';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => {
      setErrorAlert(null);
    },
    title: 'Error',
    text: errorAlert || '',
  };

  if (!guestVisit || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const cancelVisitModal = (
    <CancelEntityModal
      entity={guestVisit}
      isOpen={showCancelVisitModal}
      onToggle={toggleCancelVisitModal}
      onConfirmation={cancelVisit}
      warningText={'¿Estás seguro de anular la visita?'}
      confirmationText={'Si, anular visita'}
      abortText={'No, continuar revisando...'}
    />
  );

  const activateGuestModal = (
    <ActivateGuestModal
      guestId={guestVisit.guestInfo.id}
      isOpen={showActivateGuestModal}
      onToggle={toggleActivateGuestModal}
      onConfirmation={activateGuest}
      bodyText={
        guestVisit.guestInfo.is_register_as_tenant
          ? 'Al aceptar asignaras el lead al inquilino encontrado.'
          : 'Al activar el guest aceptas todas las ofertas, visitas del mismo y vuelves al guest un inquilino, Automáticamente enviaremos al inquilino un correo que genere su contraseña'
      }
      confirmationText={'Entiendo, continuar'}
      abortText={'No, seguir revisando...'}
    />
  );

  const componentProfileCard = (
    <GuestProfileCard
      guestInfo={guestVisit.guestInfo}
      parentEntity={Entities.Visit}
      parentId={guestVisitId}
    />
  );

  const componentPropertyCard = (
    <PropertyDetailCard
      cover={guestVisit.propertyInfo.cover}
      address={guestVisit.propertyInfo.address}
      code={guestVisit.propertyInfo.code}
      price={guestVisit.propertyInfo.monthly_price}
    />
  );

  const componentAsignProper = (
    <Card>
      <CardBody>
        <h6 className="heading-small text-muted mt-4 mb-4">
          Seleccionar proper
        </h6>
        <Select
          key={`proper-${propersOptions.length}`}
          placeholder={'Selecciona un proper'}
          options={propersOptions}
          onChange={(selected) => {
            if (selected?.value) {
              setProperId(selected.value);
            }
          }}
          defaultValue={propersOptions.find((po) => po.value === properId)}
        />
      </CardBody>
    </Card>
  );

  const componentVisitDetail = (
    <GuestVisitDetailCard guestVisit={guestVisit.visitInfo} />
  );

  const componentBody = (
    <Row>
      {/* Profile section */}
      <Col className="order-xl-2 pt-10" xl="4">
        {componentProfileCard}
      </Col>

      <Col>
        {/* Property info */}
        {componentPropertyCard}

        {/* Visit detail info */}
        {componentVisitDetail}
        {/* Asign proper info */}
        {componentAsignProper}
      </Col>
    </Row>
  );

  const componentFooter = (
    <>
      <Button color="warning" onClick={() => toggleCancelVisitModal()}>
        Anular visita
      </Button>
      <Button color="primary" onClick={() => toggleActivateGuestModal()}>
        Aceptar visita
      </Button>
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentFooter}
      globalAlertOptions={globalAlertOptions}
    >
      {componentBody}
      {cancelVisitModal}
      {activateGuestModal}
    </AdminPage>
  );
}
