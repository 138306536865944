import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Auth from '../pages/auth/Auth'

const AuthRoutes = () => (
  <>
    <Routes>
      <Route path="/login" element={<Auth />} />
    </Routes>
  </>
)

export default AuthRoutes

