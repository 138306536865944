import React, { useState } from 'react';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import Select from 'react-select';

export type Option = {
  id: string
  label: string
}

export type Props = {
  displayComponent: any
  ogValue?: string
  ogPlaceholder?: string
  options?: Array<Option>,
  onEdit: Function
}

const AdminShowEdit: React.FC<Props> = ({displayComponent, onEdit, ogPlaceholder, ogValue, options}) => {
  const [onEditMode, setOnEditMode] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>(ogValue || '');

  let inputSelector = null;

  if (options && options.length) {
    inputSelector = (
      <Select
        options={options}
        onChange={(newValue) => {
          if (newValue)
            setCurrentValue(newValue.id || "");
        }}
      />
    );
  } else {
    inputSelector = (
      <Input defaultValue={ogValue} placeholder={ogPlaceholder} onChange={(e) => {
        setCurrentValue(e.target.value)
      }} />
    )
  }

  if (!onEditMode) {
    return (
      <Row className="mt-2">
        <Col sm={6}>
          {displayComponent}
        </Col>
        <Col sm={4}>
          <i
            className="fa fa-pen"
            onClick={() => {
              setOnEditMode(true);
            }}
          />
        </Col>
      </Row>
    )
  } else {
    return (
      <Row className="mt-2">
        <Col>
          <InputGroup>
            {inputSelector}
            <InputGroupAddon addonType="append">
              <Button color="primary" outline type="button" onClick={() => {
                setOnEditMode(false);
                onEdit(currentValue);
              }}>
                Guardar
              </Button>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
              <Button color="danger" outline type="button" onClick={() => {
                setOnEditMode(false);
              }}>
                Cancelar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>

      </Row>
    )
  }
}


export default AdminShowEdit;