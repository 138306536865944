export const isProjectVisibleInMarketplace = (projectStatus: string): boolean => {
    return projectStatus === 'active'
}

export const isModelVisibleInMarketplace = (modelStatus: string) => {
    return modelStatus === 'published'
}

export const isUnitVisibleInMarketplace = (unitStatus: string) => {
    return unitStatus === 'available'
}

export const isUnitValidForChangeMarketplace = (unitStatus: string) => {
    return !(
        unitStatus === 'taken' ||
        unitStatus === 'pre_booked' ||
        unitStatus === 'assigned'
    )
}