import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./assets/vendor/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-pro-react.scss";

// plugins styles from node_modules
// import "react-notification-alert/dist/animate.css";
// plugins styles downloaded
// import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
// import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// import "./assets/vendor/select2/dist/css/select2.min.css";
// import "./assets/vendor/quill/dist/quill.core.css";
// import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
// import "layouts/Documentation/assets-for-demo/docs.scss";
// import "layouts/Documentation/assets-for-demo/react-docs.scss";
import "./assets/scss/argon-dashboard-pro-react.scss";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
