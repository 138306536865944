import { PhoneCountryConfig } from '../models/countryModel';

export const SV_FLAG =
  'https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/el-salvador-flag.png';
export const GT_FLAG =
  'https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/guatemala-flag.png';

export const SV = 'SV';
export const GT = 'GT';

export const COUNTRY_PHONES_CONFIG: PhoneCountryConfig[] = [
  {
    code: SV,
    area_code: '+503',
    phone_length: 8,
  },
  {
    code: GT,
    area_code: '+502',
    phone_length: 8,
  },
];
