import AdminListGroup from '../../../components/admin/AdminListGroup';
import { TransactionSaleDetailDto } from '../models/transactionSaleModel';

interface Props {
  transactionSaleDetail: TransactionSaleDetailDto;
  footer: any;
}

const TransactionSaleInfo: React.FC<Props> = ({
  transactionSaleDetail,
  footer,
}) => {
  const fieldMapper = [
    {
      title: 'Código de propiedad',
      value: transactionSaleDetail.property_code,
      href: `/properties/admin/${transactionSaleDetail.property_id}`,
    },
    {
      title: 'Precio de venta',
      value: transactionSaleDetail.property_sale_price,
    },
    {
      title: 'Nombre de propiedad',
      value: transactionSaleDetail.property_name,
    },
    {
      title: 'Dirección',
      value: transactionSaleDetail.address,
    },
    {
      title: 'Nombre comprador',
      value: transactionSaleDetail.tenant_name,
    },
  ];

  return (
    <AdminListGroup
      title={'Detalle de pago'}
      items={fieldMapper}
      footer={footer}
    ></AdminListGroup>
  );
};

export default TransactionSaleInfo;
