import { useCallback, useEffect, useState } from 'react';
import { Option } from '../models/formModel';
import useCallApiAndLoad from './useCallApiAndLoad';
import { getPropersSelectList } from '../services/publicService';
import { fetchResponseAdapter } from '../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../adapters/listAdapter';
import useUserCountry from './useUserCountry';
import useAuth from './useAuth';

type marketplace_type = 'all' | 'for_rent' | 'for_sale';

const useProperList = (marketplace_type: marketplace_type = 'all') => {
  const [propers, setPropers] = useState([]);
  const [isLoadingPropers, setIsLoadingPropers] = useState<boolean>(true)
  const [propersOptions, setProperOptions] = useState<Option[]>([]);
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  useEffect(() => {
    //load propers
    loadPropers(marketplace_type).catch((e) => console.error(e));
  }, []);

  const { getCurrentCountry } = useUserCountry();
  const currenUserCountryCode = getCurrentCountry()?.code || '';

  const loadPropers = useCallback(
    async (marketplace_type: marketplace_type) => {
      const { status, data } = await callEndpoint(
        getPropersSelectList(currenUserCountryCode, marketplace_type, token),
      );
      if (status === 200) {
        const propersResponse = fetchResponseAdapter(data);
        setPropers(propersResponse.data);
        setProperOptions(
          listToSelectOptionAdapter(propersResponse.data, 'id', 'name', false),
        );
        setIsLoadingPropers(false)
      }
    },
    [],
  );

  useEffect(() => {
    if(propersOptions.length){
      setIsLoadingPropers(false)
    }
  }, [propersOptions])

  return {
    propers,
    propersOptions,
    loadPropers,
    isLoadingPropers
  };
};

export default useProperList;
