import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  buildFormCharacteristics,
  createPropertyApiAdapter,
  createPropertyApiV2Adapter,
} from '../adapters/propertyAdapter';
import {
  createPropertyEndpoint,
  getCharacteristicsEndpoint,
  getPropertyToEditEndpoint,
  getTypePropertiesEndpoint,
  updatePropertyEndpoint,
} from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { CreatePropertyForm, PublishCatalogs } from '../models/propertyModel';
import { Option } from '../../../models/formModel';
import { useNavigate } from 'react-router-dom';
import useUserCountry from '../../../hooks/useUserCountry';
import {
  listToSelectOptionAdapter,
  plainListToSelectOptionAdapter,
} from '../../../adapters/listAdapter';
import { LeadMarketplaceType } from '../../../components/VisitMarketplaceTypeBadge';
import useAuth from '../../../hooks/useAuth';
import { getCurrenciesPerCountry } from '../../../services/publicService';
import { getLandlordByMailEndpoint } from '../../../services/landlordService';

const useCreateProperty = () => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState('');
  const [loadingCurrency, setLoadingCurrency] = useState<boolean>(true);
  const [currentCurrency, setCurrentCurrency] = useState<Option>({
    label: 'Selecciona una moneda',
    value: '',
  });

  // const [landlordEmail, setLandlordEmail] = useState<string>('');
  // const [searchLandlordError, setSearchLandlordError] = useState<string>('');
  // const [landlordFound, setLandlordFound] = useState<any>(null);

  const navigate = useNavigate();

  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    countries: [],
    characteristics: [],
    amenities: [],
    propertyTypes: [],
    plans: [],
    currencies: [],
  });

  const { getCurrentCountry } = useUserCountry();
  const currentUserCountry = getCurrentCountry()?.code || '';

  const marketplaceTypesList = listToSelectOptionAdapter(
    [
      {
        label: 'Venta',
        value: LeadMarketplaceType.ON_SALE,
      },
      {
        label: 'Renta',
        value: LeadMarketplaceType.ON_RENT,
      },
    ],
    'value',
    'label',
  );
  const [marketplaceTypeSelected, setMarketplaceTypeSelected] =
    useState<Option>();

  // ---- prepare form and validations ---- //
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<CreatePropertyForm>({
    resolver: yupResolver(
      yup
        .object({
          type_id: yup
            .string()
            .nullable()
            .required('Tipo de propiedad requerida'),
          currency: yup.string().required('Debes seleccionar moneda'),
          marketplace_type: yup
            .string()
            .required('Tipo de marketplace es requerido'),
          price: yup
            .number()
            .min(0)
            // .required('Mensualidad es requerida')
            .typeError('Valor el requerida con un formato numérico'),
          landlord_id: yup
            .string()
            .required('Información de propietario es requerida'),
          min_price: yup
            .number()
            .max(
              yup.ref('price'),
              'Valor minima tiene que ser menor o igual al alquiler',
            ),
        })
        .required(),
    ),
  });

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  // ---- LOAD CATALOGS ---- //
  useEffect(() => {
    const callCatalogs = async () => {
      const characteristic = await callEndpoint(
        getCharacteristicsEndpoint(token),
      );
      if (characteristic.status !== 200) {
        setError(
          'Ocurrio un error al momento de cargar los catalogos de caracteristicas',
        );
        return;
      }
      const propertyTypes = await callEndpoint(
        getTypePropertiesEndpoint(token),
      );
      if (propertyTypes.status !== 200) {
        setError(
          'Ocurrio un error al momento de cargar los catalogos de tipos',
        );
        return;
      }
      const currencies = await callEndpoint(
        getCurrenciesPerCountry(currentUserCountry),
      );

      const characteristicsResponse = fetchResponseAdapter(characteristic.data);
      const propertyTypesResponse = fetchResponseAdapter(propertyTypes.data);
      const currenciesResponse = fetchResponseAdapter(currencies.data);
      setCatalogs({
        characteristics: characteristicsResponse.data,
        propertyTypes: propertyTypesResponse.data,
        currencies: plainListToSelectOptionAdapter(currenciesResponse.data),
      });
      setLoadingCurrency(false);
    };

    callCatalogs().catch((e) => console.error(e));
  }, [currentUserCountry]);

  const doAction = async (form: CreatePropertyForm) => {
    setError('');

    const apiData = createPropertyApiV2Adapter(
      form,
      catalogs.characteristics ?? [],
    );
    const { status, data } = await callEndpoint(
      createPropertyEndpoint(apiData, token),
    );
    if (status === 201) {
      const response = fetchResponseAdapter(data);
      const { id = null } = response.data;
      const path = id ? `/properties/admin/${id}` : '/properties/view';
      navigate(path);
    }
  };

  return {
    isLoading,
    control,
    register,
    setValue,
    handleSubmit,
    doAction,
    catalogs,
    errors,
    loadingCurrency,
    currentCurrency,
    marketplaceTypesList,
    setMarketplaceTypeSelected,
    marketplaceTypeSelected,
  };
};

export default useCreateProperty;
