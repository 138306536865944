import { configureStore } from "@reduxjs/toolkit"
import mapViewReducer from "./features/mapView"
import authReducer from "./features/auth"
import gridSearchBarReducer from "./features/gridSearchBar"

export interface AppStore {
    mapView: any,
    auth: any,
    gridSearchBar: any
}

export default configureStore<AppStore>({
    reducer: {
        mapView: mapViewReducer,
        auth: authReducer,
        gridSearchBar: gridSearchBarReducer
    }
})