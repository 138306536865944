import axios from 'axios';
import {
  UpdateGuestOfferRequest,
  UpdateGuestProfileRequest,
} from '../models/guestModel';

const loadAbort = () => {
  return new AbortController();
};

export const getGuestVisitsEndpoint = (query: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/guests/visits/${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getGuestOffersEndpoint = (query: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/guests/offers/${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getGuestOfferDetailEndpoint = (
  guestOfferId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/guests/offers/${guestOfferId}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getGuestVisitDetailEndpoint = (
  guestVisitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/guests/visits/${guestVisitId}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const cancelVisitEndpoint = (
  guestId: string,
  visitId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/ts/v1/admin/guests/${guestId}/visits/${visitId}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const cancelOfferEndpoint = (
  guestId: string,
  offerId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/ts/v1/admin/guests/${guestId}/offers/${offerId}/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const activateGuestEndpoint = (
  guestId: string,
  properId: string,
  token: string,
) => {
  const controller = loadAbort();
  let payload = {};
  if (properId !== '') {
    payload = {
      proper_id: properId,
    };
  }
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/guest/${guestId}/activate`,
        data: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateGuestVisitEndpoint = (
  visitId: string,
  updateGuestVisitRequest: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/guests/visits/${visitId}/`,
        data: JSON.stringify(updateGuestVisitRequest),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateGuestOfferEndpoint = (
  offerId: string,
  updateGuestOfferRequest: UpdateGuestOfferRequest,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/ts/v1/admin/guests/offers/${offerId}/`,
        data: JSON.stringify(updateGuestOfferRequest),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updateGuestProfileEndpoint = (
  guestId: string,
  updateGuestProfileRequest: UpdateGuestProfileRequest,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/guest/${guestId}/`,
        data: JSON.stringify(updateGuestProfileRequest),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAvailableTimeVisitsEndpoint = (
  propertyId: string,
  date: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/properties/${propertyId}/available-time-visits/${date}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getGuestPropertiesEndpoint = (
  query: string = '',
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/guest/property${query}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getGuestPropertyDetailEndpoint = (
  id: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/guest/property/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const activateGuestPropertyEndpoint = (
  guestId: string,
  guestPropertyId: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/user/v1/admin/guest/${guestId}/activate/${guestPropertyId}/landlord`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const cancelGuestPropertyEndpoint = (
  id: string = '',
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'delete',
        url: `${urlService}/rs/v1/admin/guest/property/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
