import axios from 'axios';
import { CreateDownPaymentInQuotasForm } from '../models/downPaymentModel';

const loadAbort = () => {
  return new AbortController();
};

export const getDownPaymentQuotaConfigEndpoint = (
  transactionPreSaleId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionPreSaleId}/retrieve-quotas-config`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const previewDownPaymentQuotasEndpoint = (
  transactionPreSaleId: string,
  transactionDetailId: string,
  payload: CreateDownPaymentInQuotasForm,
  token: string,
) => {
  const { quotas } = payload;
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionPreSaleId}/transaction-detail/${transactionDetailId}/preview-quotas`,
        params: {
          quotas,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createDownPaymentQuotasEndpoint = (
  transactionPreSaleId: string,
  transactionDetailId: string,
  form: CreateDownPaymentInQuotasForm,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/ts/v1/admin/transaction-pre-sales/${transactionPreSaleId}/transaction-detail/${transactionDetailId}/quotas`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
