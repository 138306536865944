import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { Transaction } from '../models/contracts';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import CreatePendingQuotasModal from './CreatePendingQuotasModal';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { createPendingQuotasEndpoint } from '../services/contractPaymentsService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

export type ContractDetailsProps = {
  transactionDetails: Transaction;
};

const ContractDetails: React.FC<ContractDetailsProps> = ({
  transactionDetails,
}) => {
  const contractStatusMapper = useCallback((status: string): string => {
    const contractStatus = {
      created: 'Creado',
      active: 'En curso',
      disabled: 'Desactivado',
      done: 'Finalizado',
    };

    return (
      contractStatus[status as keyof typeof contractStatus] ?? 'Desconocido'
    );
  }, []);

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const [showPendingQuotasModal, setShowPendingQuotasModal] =
    useState<boolean>(false);
  const toggleShowPendingQuotasModal = () =>
    setShowPendingQuotasModal((state) => !state);

  const createPendingQuotas = async (transactionId: string) => {
    const { status, data, statusText } = await callEndpoint(
      createPendingQuotasEndpoint(transactionId, token),
    );

    if (status === 201) {
      navigate(0);
    } else {
      console.log(statusText);
      setErrorAlert('Error no se pudo generar las cuotas pendientes');
    }
  };

  const fieldMapper = [
    {
      title: 'Contrato',
      value: `Información de contrato`,
      href: `/contract/${transactionDetails.contract_id}/detail`,
    },
    {
      title: 'Propietario',
      value: `${transactionDetails.landlord_fullname}`,
    },
    {
      title: 'Inquilino',
      value: `${transactionDetails.tenant_fullname}`,
    },
    {
      title: 'Código de propiedad',
      value: transactionDetails.property_code,
    },
    {
      title: 'Dirección',
      value: transactionDetails.property_address,
    },
    {
      title: 'Fecha de pago',
      value: transactionDetails.transaction_months,
    },
    {
      title: 'Fecha de inicio de contrato',
      value: transactionDetails.transaction_start,
    },
    {
      title: 'Fecha de vencimiento de contrato',
      value: transactionDetails.transaction_end,
    },
    {
      title: 'Pago Automático',
      value: transactionDetails.has_subscription_pay ? 'Si' : 'No',
    },
    {
      title: 'Cuota',
      value: transactionDetails.contract_price,
    },
    {
      title: 'Estado',
      value: contractStatusMapper(transactionDetails.transaction_status),
    },
  ];

  const footer = transactionDetails.has_pending_dues_to_create ? (
    <Button color={'primary'} onClick={() => toggleShowPendingQuotasModal()}>
      Crear cuotas pendientes
    </Button>
  ) : null;

  return (
    <>
      <AdminListGroup
        title="Detalles del contrato"
        items={fieldMapper}
        footer={footer}
      ></AdminListGroup>
      <CreatePendingQuotasModal
        transactionId={transactionDetails.id}
        isOpen={showPendingQuotasModal}
        onToggle={() => toggleShowPendingQuotasModal()}
        onConfirmation={createPendingQuotas}
        bodyText={'Se genenerarán las cuotas pendientes'}
        confirmationText={'Generar cuotas pendientes'}
        abortText={'No, seguir revisando'}
        titleText={'Generar cuotas pendientes'}
      />
    </>
  );
};

export default ContractDetails;
