import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  PropertyAdditionalInfoForm,
  PropertyUpdateDataApi,
  PublishCatalogs,
} from '../models/propertyModel';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  getPropertyToEditEndpoint,
  getTypePropertiesEndpoint,
  updatePropertyEndpoint,
} from '../services/propertyService';
import useAuth from '../../../hooks/useAuth';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getCurrenciesPerCountry } from '../../../services/publicService';
import useUserCountry from '../../../hooks/useUserCountry';
import { plainListToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { Option } from '../../../models/formModel';
import { useNavigate } from 'react-router-dom';
import useProperList from '../../../hooks/useProperList';

interface Props {
  id: string;
}

const useAdminAdditionalInfo = ({ id }: Props) => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [formUpdateData, setFormUpdateData] = useState<PropertyUpdateDataApi>();
  const [loadingCurrency, setLoadingCurrency] = useState<boolean>(true);
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountry = getCurrentCountry()?.code || '';
  const [currentCurrency, setCurrentCurrency] = useState<Option>({
    label: 'Selecciona una moneda',
    value: '',
  });
  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    countries: [],
    characteristics: [],
    amenities: [],
    propertyTypes: [],
    plans: [],
    currencies: [],
  });

  const { propersOptions, isLoadingPropers } = useProperList();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<PropertyAdditionalInfoForm>({
    resolver: yupResolver(
      yup
        .object({
          type_id: yup
            .string()
            .nullable()
            .required('Tipo de propiedad requerida'),
          name: yup.string().required('Titulo o nombre es requerido'),
          description: yup.string().required('Descripción es requerida'),
          property_number: yup
            .string()
            .required('Número de propiedad requerido'),
          extra_info: yup.string().default(''),
          currency: yup.string().required('Debes seleccionar moneda'),
          landlord_id: yup.string(),
          proper_id: yup.string().required('Proper es requerido'),
          has_propi_property_keys: yup.boolean(),
          location_property_keys: yup.string(),
        })
        .required(),
    ),
  });

  // Load property to update and catalogs
  useEffect(() => {
    const callCatalogs = async (updateData: PropertyUpdateDataApi) => {
      const propertyTypes = await callEndpoint(
        getTypePropertiesEndpoint(token),
      );
      if (propertyTypes.status !== 200) {
        alert('Ocurrio un error al momento de cargar los catalogos de tipos');
        return;
      }
      const currencies = await callEndpoint(
        getCurrenciesPerCountry(currentUserCountry),
      );
      const propertyTypesResponse = fetchResponseAdapter(propertyTypes.data);
      const currenciesResponse = fetchResponseAdapter(currencies.data);
      setCatalogs({
        propertyTypes: propertyTypesResponse.data,
        currencies: plainListToSelectOptionAdapter(currenciesResponse.data),
      });
      setCurrentCurrency({
        label: updateData.currency ? updateData.currency : '',
        value: updateData.currency ? updateData.currency : '',
      });
      setLoadingCurrency(false);
    };

    const loadExistingProperty = async () => {
      const { status, data } = await callEndpoint(
        getPropertyToEditEndpoint(id, token),
      );
      if (status === 200) {
        const { data: formData } = fetchResponseAdapter(data);
        setFormUpdateData(formData);
        return formData;
      }
    };

    loadExistingProperty()
      .then((updateData) => callCatalogs(updateData))
      .catch((e) => console.error(e));
  }, []);

  // PRE load update data to form
  useEffect(() => {
    if (formUpdateData) {
      console.log({ formUpdateData });
      reset({
        type_id: formUpdateData.type.id,
        name: formUpdateData.name,
        description: formUpdateData.description,
        property_number: formUpdateData.property_number,
        extra_info: formUpdateData.extra_info,
        has_propi_property_keys:
          formUpdateData.has_propi_property_keys || false,
        location_property_keys: formUpdateData.location_property_keys,
        currency: formUpdateData.currency,
        proper_id: formUpdateData.proper_id,
      });
    }
  }, [formUpdateData]);

  const doAction = async (form: PropertyAdditionalInfoForm) => {
    console.log({ form });
    const { status } = await callEndpoint(
      updatePropertyEndpoint(form, id, token),
    );
    if (status === 200) {
      navigate(`/properties/admin/${id}`);
    }
  };

  return {
    isLoading,
    control,
    register,
    setValue,
    errors,
    handleSubmit,
    formUpdateData,
    doAction,
    catalogs,
    loadingCurrency,
    currentCurrency,
    propersOptions,
    isLoadingPropers
  };
};

export default useAdminAdditionalInfo;
