import AdminPage from '../../components/admin/AdminPage';
import RegisteredTypeBadge from './components/RegisteredTypeBadge';
import CreditScoreBadge from './components/CreditScoreBadge';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { GuestInfoDetails, UpdateGuestProfileRequest } from './models/guestModel';
import {
  getGuestOfferDetailEndpoint,
  getGuestVisitDetailEndpoint,
  updateGuestProfileEndpoint,
} from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { Button, Col, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

export default function GuestProfileEdit() {
  const { entityType = "", entityId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const {
    handleSubmit, formState: { errors }, control
  } = useForm<UpdateGuestProfileRequest>({
    resolver: yupResolver(
      yup.object({
        email: yup.string()
          .email("Ingrese un formato válido para el correo.")
          .required("Correo electrónico es requerido."),
        document_number: yup.string()
          .required("Documento es requerido."),
      }).required()
    )
  });
  const [guestInfo, setGuestInfo] = useState<GuestInfoDetails>();
  const loadGuestOffer = useCallback(async (guestOfferId: string) => {
      const {
        status, data
      } = await callEndpoint(getGuestOfferDetailEndpoint(guestOfferId, token));

      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setGuestInfo(response.data.guestInfo);
      }
    }, [callEndpoint, token]);
  const loadGuestVisit = useCallback(async (guestVisitId: string) => {
    const {
      status, data
    } = await callEndpoint(getGuestVisitDetailEndpoint(guestVisitId, token));

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setGuestInfo(response.data.guestInfo)
    }
  }, [callEndpoint, token]);

  useEffect(() => {
    switch(entityType) {
      case "visit": {
        loadGuestVisit(entityId).catch((e) => setError(e.toString()));
        break;
      }
      case "offer": {
        loadGuestOffer(entityId).catch((e) => setError(e.toString()));
        break;
      }
    }
  }, [loadGuestOffer, loadGuestVisit, entityId, entityType]);

  const doUpdate = useCallback(async (form: UpdateGuestProfileRequest) => {
    if (!guestInfo)
      return;

    const {
      status,
      data,
    } = await callEndpoint(updateGuestProfileEndpoint(guestInfo.id, form, token));

    if (status === 200)
      return navigate(`/guest-${entityType}s/${entityId}/`);

    const response = fetchResponseAdapter(data);
    setError(response.message)
  }, [callEndpoint, guestInfo, navigate, token, entityType, entityId]);

  const pageName = "Editar perfil Guest";
  const pageParentName = "Guest";

  if (!guestInfo || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  const componentHeader = (
    <>
      <RegisteredTypeBadge guestInfo={guestInfo} />
      <CreditScoreBadge guestInfo={guestInfo} />
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      header={componentHeader}
    >
      <h3 className="mb-4">Editar perfil del guest</h3>
      <Form role="form" onSubmit={handleSubmit(doUpdate)}>
        <Col md="6">
          <Row>
            <Col md="12">
              {
                error && (
                  <UncontrolledAlert color="danger" fade={false}>
                    <span className="alert-inner--text">
                      <strong> Ops! </strong>{error}
                    </span>
                  </UncontrolledAlert>
                )
              }
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="email"
                >
                  Email
                </label>
                <Controller
                  name="email" control={control} defaultValue={guestInfo.email}
                  render={({ field }) => (
                    <Input
                      className={errors.email?.message && 'is-invalid'}
                      {...field}
                      id="first_name" />
                  )}
                />
                <h5 className="text-danger">{errors.email?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="document_number"
                >
                  Documento
                </label>
                <Controller
                  name="document_number" control={control} defaultValue={guestInfo.document_number}
                  render={({ field }) => (
                    <Input
                      className={errors.document_number?.message && 'is-invalid'}
                      {...field}
                      id="document_number" />
                  )}
                />
                <h5 className="text-danger">{errors.document_number?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Button color={'primary'} disabled={isLoading}>
          Actualizar
        </Button>
      </Form>
    </AdminPage>
  );
};