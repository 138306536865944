import React, { useCallback, useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import loading from '../../assets/gif/loading.gif';
import { FilePreviewTypes } from '../../constants/formConstants';

export type Props = {
  handleUpload: Function;
  description: string;
  maxFiles?: number;
  dropZoneText?: string;
  filesPreviewType?: FilePreviewTypes | undefined;
};
const AdminUploadFile: React.FC<Props> = ({
  handleUpload,
  maxFiles,
  description,
  dropZoneText = 'Formatos soportados: JPEG y PNG, máximo de 1MB por archivo.',
  filesPreviewType = FilePreviewTypes.LIST,
}) => {
  const [filesForPreview, setFilesForPreview] = useState<Array<any>>([]);
  const { isLoading } = useCallApiAndLoad();

  const onDrop = useCallback((acceptedFiles: any) => {
    const acceptedFilesForPreview = acceptedFiles.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    setFilesForPreview((currentFiles) => {
      if (maxFiles && maxFiles === 1) return [...acceptedFilesForPreview];
      else if (
        maxFiles &&
        currentFiles.length + acceptedFilesForPreview.length <= maxFiles
      )
        return [...currentFiles, ...acceptedFilesForPreview];
      else if (!maxFiles) return [...currentFiles, ...acceptedFilesForPreview];
      else return [...currentFiles];
    });
  }, []);

  let dropZoneOptions: any = {
    onDrop,
  };

  if (maxFiles) dropZoneOptions['maxFiles'] = maxFiles;

  const { getRootProps, getInputProps } = useDropzone(dropZoneOptions);

  const removeFile = useCallback((file: any) => {
    setFilesForPreview((currentFilesForPreview) => {
      const newFilesForPreview = [...currentFilesForPreview];
      newFilesForPreview.splice(newFilesForPreview.indexOf(file), 1);
      return newFilesForPreview;
    });
  }, []);

  const removeAll = useCallback(() => {
    setFilesForPreview([]);
  }, []);

  return (
    <Card>
      <CardHeader>
        <h4>{description}</h4>
      </CardHeader>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <ListGroupItem className="px-0">
            <Row>
              <Col md="12">
                <div
                  {...getRootProps({
                    className: 'px-4 py-4 text-center dropzone',
                  })}
                >
                  <input {...getInputProps()} />
                  <p>Arrastra tus archivos o Selecciona</p>
                  <span className="text-sm">{dropZoneText}</span>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
        {/* Mostrar preview de archivos en modo lista con eliminación manual */}
        {filesPreviewType === FilePreviewTypes.LIST &&
          filesForPreview &&
          filesForPreview.length > 0 &&
          filesForPreview.map((file) => (
            <ListGroup className="list my--3" flush key={file.preview}>
              <ListGroupItem className="px-0">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      className="rounded"
                      alt="..."
                      width={175}
                      height={100}
                      src={file.preview}
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                    />
                  </Col>
                  <div className="col ml--2"></div>
                  <Col className="col-auto">
                    <Button
                      onClick={(event) => {
                        event.preventDefault();
                        removeFile(file);
                      }}
                      color="danger"
                      size="sm"
                      type="button"
                    >
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          ))}
        {/* Mostrar preview de archivos en formato de contador, eliminación global */}
        {filesPreviewType === FilePreviewTypes.COUNT &&
          filesForPreview &&
          filesForPreview.length > 0 && (
            <Row className="align-items-center mt-2">
              <Col className="col-auto">
                <span>Archivos cargados: {filesForPreview.length}</span>
              </Col>
            </Row>
          )}
      </CardBody>
      <CardFooter>
        <Row>
          {filesForPreview.length > 0 && (
            <>
              <Col className="col-auto">
                <Button
                  color="danger"
                  size="md"
                  type="button"
                  onClick={removeAll}
                >
                  Eliminar todas
                </Button>
              </Col>
            </>
          )}
          <div className="col ml--2"></div>
          {isLoading && <img src={loading} alt="" width={45} />}
          <Col className="col-auto">
            <Button
              onClick={() => handleUpload(filesForPreview)}
              className=""
              color="success"
              size="md"
              type="button"
              disabled={isLoading}
            >
              <i className="fa fa-duotone fa-upload mr-1" /> Subir
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default AdminUploadFile;
