import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAdminEntity from '../../hooks/useAdminEntity';
import { InvestorProjectDetail } from './models/investors';
import AdminPage from '../../components/admin/AdminPage';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import { getInvestorProjectDetailEndpoint } from './services/investorService';
import useAuth from '../../hooks/useAuth';


const InvestorProjectModelList: React.FC = () => {
  const {id: projectId = ""} = useParams();
  const [errorAlert, setErrorAlert] = useState(null);
  const {isLoading} = useCallApiAndLoad();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getInvestorProject = useCallback(() => getInvestorProjectDetailEndpoint(projectId, token), []);
  const [investorProject] = useAdminEntity<InvestorProjectDetail>(getInvestorProject, setErrorAlert)

  const pageName = `Modelos - Proyecto #${investorProject?.code}`;
  const pageParentName = `Preventa`
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }
  if (!investorProject || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >

    </AdminPage>
  );
};

export default InvestorProjectModelList;