import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const getProjectListEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/list`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getUnitsTaken = (projectId: string | null, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/${projectId}/units-taken`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getUnitsAvailable = (projectId: string | null, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/projects/${projectId}/units-availables`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getFinanceByUnitEndpoint = (unitId: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/model-units/${unitId}/finances`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


// POligonos de torrres

export const polygonDetailTowerEndpoint = (id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const createPolygonTowerLevel = (
  idProject: any,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${idProject}`,
        data: form,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createImagePolygonTowerLevel = (
  idProject: any,
  form: any,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${idProject}/facade-background`,
        data: form,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

// PISOS

export const polygonDetailFloorEndpoint = (project_id: string, distribution_id: string, token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${project_id}/distribution/${distribution_id}/units`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createImageFloorEndpoint = (
  project_id: string,
  distribution_id: string,
  form: any,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'PUT',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${project_id}/distribution/${distribution_id}/facade-background`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const createLevelFloorEndpoint = (
  project_id: string,
  distribution_id: string,
  form: any,
  token: string
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/pre-sales/v1/admin/project-facade/${project_id}/distribution/${distribution_id}/units`,
        data: form,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};