import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { CustomerRegistrationForm } from '../models/customerModel';
import { useNavigate } from 'react-router-dom';
import useUserCountry from '../../../hooks/useUserCountry';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useProperList from '../../../hooks/useProperList';
import useAuth from '../../../hooks/useAuth';
import { createCustomerEndpoint } from '../services/customerService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

const useCustomer = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { getPhoneCountryConfiguration } = useUserCountry();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { propersOptions } = useProperList();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const phone_config = getPhoneCountryConfiguration();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<CustomerRegistrationForm>({
    resolver: yupResolver(
      yup
        .object({
          first_name: yup.string().required('Nombre es requerido'),
          last_name: yup.string().required('Apellido es requerido'),
          email: yup
            .string()
            .email('Ingrese un formato valido para correo')
            .required('Correo electrónico es requerido'),
          phone: yup.string().required('Teléfono es requerido'),
          proper_id: yup.string(),
        })
        .required(),
    ),
  });

  const doCreate = async (form: CustomerRegistrationForm) => {
    setError('');
    form.username = form.email;
    form.area_code = phone_config?.area_code;
    if (form.proper_id === undefined) delete form.proper_id;

    const { status, data } = await callEndpoint(
      createCustomerEndpoint(form, token),
    );
    if (status === 201) {
      return navigate('/tenant/view');
    }
    const response = fetchResponseAdapter(data);
    setError(response.message);
  };

  return {
    handleSubmit,
    doCreate,
    error,
    control,
    errors,
    propersOptions,
    setValue,
    isLoading,
    phone_config,
  };
};

export default useCustomer;
