import { createSlice } from "@reduxjs/toolkit";
import { GridSearchBarModel } from '../../models/gridSearchBarModel';
import { createSearchParams } from 'react-router-dom';

const initialState: GridSearchBarModel = {
  currentSearch: {
    control: null,
    value: null
  },
  params: {},
  query: ''
}

export const gridSearchBarSlice = createSlice({
  name: 'gridSearchBar',
  initialState,
  reducers: {
    setUniqueGridFiltersAction: (state, action) => {
      const { payload } = action
      const newParams = { ...payload }
      return {
        ...state,
        params: newParams,
        query: `?${createSearchParams(newParams)}`
      }
    },
    setGridFiltersAction: (state, action) => {
      const { payload } = action
      const newParams = { ...state.params, ...payload }
      return {
        ...state,
        params: newParams,
        query: `?${createSearchParams(newParams)}`
      }
    },
    clearGridFiltersAction: (state, action) => initialState,
    setCurrentSearchAction: (state, action) => {
      return {
        ...state,
        currentSearch: action.payload
      }
    },
  }
})

// provide actions
export const { 
  setGridFiltersAction, 
  clearGridFiltersAction,
  setUniqueGridFiltersAction,
  setCurrentSearchAction
} = gridSearchBarSlice.actions;
// provide reducer
export default gridSearchBarSlice.reducer