import { useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { SaleTransactionList } from './models/transactionSaleModel';
import useAdminPagination from '../../hooks/useAdminPagination';
import { getTransactionSalesListEndpoint } from './services/transactionSaleService';
import AdminPaginator from '../../components/admin/AdminPaginator';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

const TransactionSaleList: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState(null);

  const [transactions, setTransactions] = useState<SaleTransactionList[]>([]);

  const { paginator, loadItems } = useAdminPagination(
    getTransactionSalesListEndpoint,
    setTransactions,
    console.error,
  );

  const pageName = 'Lista de compras vivienda usada';
  const pageParentName = 'Pagos';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert ?? '',
  };

  const componentFooter = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={componentFooter}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Propiedad</th>
            <th scope="col">Comprador</th>
            <th scope="col">Correo</th>
            <th scope="col">Precio</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 &&
            transactions.map((transaction) => (
              <tr key={`${transaction.id}`}>
                <td>{transaction.propertyInfo.code}</td>
                <td>{transaction.tenantInfo.name}</td>
                <td>{transaction.tenantInfo.email}</td>
                <td>{transaction.price}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/transaction-sales/${transaction.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default TransactionSaleList;
