import { VisitInfo } from '../models/guestModel';
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { LeadMarketplaceType } from '../../../components/VisitMarketplaceTypeBadge';

interface Props {
  guestVisit: VisitInfo;
}

export default function GuestVisitDetailCard({ guestVisit }: Props) {
  const navigate = useNavigate();
  const toGuestVisitEdit = () =>
    navigate(`/guest-visits/${guestVisit.id}/edit`);

  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">Visita</h6>
          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">Tipo</h4>
              </div>
              <Col className="col-auto">
                <span className="mr-2">
                  {guestVisit.visit_type === 'presential'
                    ? 'Presencial'
                    : 'Virtual'}
                </span>
                <Button
                  color=""
                  size="sm"
                  type="button"
                  onClick={toGuestVisitEdit}
                >
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">Fecha y hora</h4>
              </div>
              <Col className="col-auto">
                <span className="mr-2">{`${guestVisit.visit_date} ${guestVisit.visit_time}`}</span>
                <Button
                  color=""
                  size="sm"
                  type="button"
                  onClick={toGuestVisitEdit}
                >
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">Negocio</h4>
              </div>
              <Col className="col-auto">
                <span className="mr-2">{`${
                  guestVisit.marketplace_type === LeadMarketplaceType.ON_RENT ? 'Renta' : 'Venta'
                }`}</span>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
}
