import AdminListGroup from '../../../components/admin/AdminListGroup';
import { TransactionSaleQuotaDetails } from '../models/transactionSaleModel';

interface Props {
  quotaDetails: TransactionSaleQuotaDetails;
}

export const TransactionSaleQuotaDetailCard = ({ quotaDetails }: Props) => {
  const stateMapper = (state: string) => {
    const states = {
      created: 'Sin pago',
      current: 'Cuota en curso',
      paid: 'Pagada',
      pending: 'Pendiente',
      disabled: 'Desactivada',
    };

    return states[state as keyof typeof states] ?? 'Desconocido';
  };

  const paymentMethodMapper = (paymentMethod: string) => {
    const paymentMethods = {
      card: 'Tarjeta',
      card_subscription: 'Pago suscrito',
      wire_transfer: 'Transferencia bancaria',
    };

    return paymentMethods[paymentMethod as keyof typeof paymentMethods];
  };

  const fieldMapper = [
    {
      title: 'Descripción',
      value: quotaDetails.description,
    },
    {
      title: 'Estado',
      value: stateMapper(quotaDetails.status),
    },
    {
      title: 'Valor',
      value: quotaDetails.price,
    },
    {
      title: 'Fecha vencimiento',
      value: quotaDetails.due_date || 'No disponible',
    },
    {
      title: 'Método de pago',
      value: paymentMethodMapper(quotaDetails.payment_method),
    },
  ];

  return <AdminListGroup title={'Detalle de la couta'} items={fieldMapper} />;
};

export default TransactionSaleQuotaDetailCard;
