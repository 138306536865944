import { DashboardMetric } from '../models/dashboardModels';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, NavLink, Progress, Row } from 'reactstrap';
import React from 'react';

export interface Props {
  metric: DashboardMetric;
}

const DashboardMetricCard: React.FC<Props> = ({metric}) => {
  const metricComplianceStatus = {
    STARTED: "started",
    ALMOST_COMPLETED: "almost_completed",
    COMPLETED: "completed",
  };
  const getMetricComplianceStatus = (percentage: number) => {
    if (percentage >= 1.0)
      return metricComplianceStatus.COMPLETED;

    if (percentage >= 0.9)
      return metricComplianceStatus.ALMOST_COMPLETED;

    return metricComplianceStatus.STARTED;

  }

  const colorComplianceMapper = new Map<string, string>([
    [metricComplianceStatus.STARTED, "default"],
    [metricComplianceStatus.ALMOST_COMPLETED, "primary"],
    [metricComplianceStatus.COMPLETED, "success"],
  ]);

  const metricCompliancePercentage = metric.total > 0 ? (metric.currentValue / metric.total) : 0.0;
  const complianceStatus = getMetricComplianceStatus(metricCompliancePercentage);
  const metricComplianceColor = colorComplianceMapper.get(complianceStatus);

  return (
    <Card className={`bg-gradient-${metricComplianceColor}`}>
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle className="text-uppercase text-muted mb-0 text-white">
              {metric.title}
            </CardTitle>
            <span className="h1 mb-0 text-white">
              {(metric.currentValue > 0)
                ? `${metric.currentValue}/${metric.total}`
                : metric.total }
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
              <i className={metric.icon} />
            </div>
          </Col>
        </Row>
        {(metric.currentValue > 0) && (
          <Progress
            max="100"
            value={Math.floor(metricCompliancePercentage * 100.0)}
            color={(metricComplianceColor === "default") ? "primary" : "default"}
            className="mt-2"
          />
        )}

      </CardBody>
        {(metric.externalResourceURI) && (
          <CardFooter className={`bg-gradient-${metricComplianceColor}`}>
            <a href={metric.externalResourceURI} className="text-white font-weight-bold">Ver detalle</a>
          </CardFooter>
        )}
    </Card>
  )
};

export default DashboardMetricCard;