import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useCallback, useEffect, useState } from 'react';
import { GuestOfferDetails, UpdateGuestOfferRequest } from './models/guestModel';
import { getGuestOfferDetailEndpoint, updateGuestOfferEndpoint } from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import LoadingCard from '../../components/LoadingCard';
import { Button, Col, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export default function GuestOfferEdit() {
  const { id: guestOfferId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const {
    handleSubmit, formState: { errors }, control
  } = useForm<UpdateGuestOfferRequest>({
    resolver: yupResolver(
      yup.object({
        price: yup.number()
          .positive("Ingrese un valor mayor que cero.")
          .required("El valor de la oferta es requerido."),
        period: yup.number()
          .integer("Ingrese un valor entero.")
          .positive("Ingrese un valor mayor que cero.")
          .required("El periodo de la oferta es requerido."),
        estimate_moving_at: yup.date()
          .min(new Date(), "La fecha debe ser mayor a hoy.")
      }).required()
    )
  });
  const [guestOffer, setGuestOffer] = useState<GuestOfferDetails>();
  const loadGuestOffer = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(getGuestOfferDetailEndpoint(guestOfferId, token));

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setGuestOffer(response.data);
    }
  }, [callEndpoint, guestOfferId, token]);
  useEffect(() => {
    loadGuestOffer().catch(e => setError(e.toString()));
  }, [loadGuestOffer]);
  const doUpdate = useCallback(async (form: UpdateGuestOfferRequest) => {
    if (!guestOffer?.offerInfo?.id)
      return

    const {
      status, data
    } = await callEndpoint(
      updateGuestOfferEndpoint(guestOffer.offerInfo.id, form, token)
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      navigate(`/guest-offers/${guestOffer.offerInfo.id}`)
    } else {
      setError(response.message);
    }
  }, [token, callEndpoint, guestOffer, navigate]);

  const formatMoney = (money: string) => parseFloat(
    money
      .replaceAll("$", "")
      .replaceAll(",", "")
  );

  const formatPeriod = (strPeriod: string) => parseInt(
    strPeriod
      .toUpperCase()
      .replaceAll(" MESES", "")
      .replaceAll(" MES", "")
  );

  // const formatDate = (strDate: string) => {
  //   parseInt()
  // };

  const pageName = "Editar oferta";
  const pageParentName = "Guests";

  if (!guestOffer || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <h3 className="mb-4">Editar oferta del guest</h3>
      <Form role="form" onSubmit={handleSubmit(doUpdate)}>
        <Col md="6">
          <Row>
            <Col md="12">
              {
                error && (
                  <UncontrolledAlert color="danger" fade={false}>
                    <span className="alert-inner--text">
                      <strong> Ops! </strong>{error}
                    </span>
                  </UncontrolledAlert>
                )
              }
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="price"
                >
                  Precio (Dólares)
                </label>
                <Controller
                  name="price"
                  control={control}
                  defaultValue={formatMoney(guestOffer?.offerInfo?.offer_price)}
                  render={({ field }) => (
                    <Input
                      className={errors.price?.message && 'is-invalid'}
                      {...field}
                      id="price"
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.price?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="period"
                >
                  Periodo (Meses)
                </label>
                <Controller
                  name="period"
                  control={control}
                  defaultValue={formatPeriod(guestOffer?.offerInfo?.period)}
                  render={({ field }) => (
                    <Input
                      className={errors.period?.message && 'is-invalid'}
                      {...field}
                      id="period"
                      type="number"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.period?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="estimate_moving_at"
                >
                  Fecha estimada de mudanza
                </label>
                <Controller
                  name="estimate_moving_at"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.estimate_moving_at?.message && 'is-invalid'}
                      {...field}
                    id="estimate_moving_at"
                      type="date"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.estimate_moving_at?.message}</h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Button color={'primary'} disabled={isLoading}>
          Actualizar
        </Button>
      </Form>

    </AdminPage>
  );
};