import React, { useState } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import { UpdateUnitsPresalesStateForm } from '../models/investors';


export interface Props {
  // entity: any;
  model_id: string;
  unit_id: string;
  model_name?: string;
  unit_name?: string;
  isOpen: boolean;
  marketplace_type?: 'rent' | 'sale';
  onToggle: () => void;
  // onConfirmation: (entity: any, reason: string, comments: string) => void;
  onConfirmation: (
    model_id: string,
    unit_id: string,
    form: UpdateUnitsPresalesStateForm,
  ) => void;
  warningText: string;
  confirmationText: string;
  abortText: string;
}
const TakeDownUnitModal: React.FC<Props> = ({
  // entity,
  model_id,
  unit_id,
  isOpen,
  marketplace_type = 'rent',
  model_name,
  unit_name,
  onToggle,
  onConfirmation,
  warningText,
  confirmationText,
  abortText,
}) => {
  const [takeDownReason, setTakeDownReason] = useState<string>('');
  const [takeDownAdditionalComments, setTakeDownAdditionalComments] =
    useState<string>('');
  const isValid = () =>
    takeDownReason !== '' && takeDownAdditionalComments !== '';
  const takeDownOptions = [
    {
      value: 'disabled',
      label: 'Deshabilitar',
    },
    {
      value: 'rented_external',
      label: 'Rentada fuera de la plataforma',
    },
  ];

  const takeDownSalesOptions = [
    {
      value: 'disabled',
      label: 'Deshabilitar',
    },
    {
      value: 'sold_external',
      label: 'Vendida fuera de la plataforma',
    },
  ];

  const displayOptions = () =>
    marketplace_type === 'sale' ? takeDownSalesOptions : takeDownOptions;

  const confirmationModal = (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Tu atención es requerida
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">Dar de baja a {unit_name} del modelo {model_name} </h4>
          <p>
            Completa la razón por la cual estás dando de baja está unidad
          </p>
          <Input
            type="select"
            onChange={(event) => setTakeDownReason(event.target.value)}
          >
            <option value="">Selecciona una razón</option>
            {displayOptions().map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          <p className="mt-3">Comentarios adicionales</p>
          <Input
            type="textarea"
            value={takeDownAdditionalComments}
            onChange={(event) =>
              setTakeDownAdditionalComments(event.target.value)
            }
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          disabled={!isValid()}
          onClick={() =>
            onConfirmation(model_id, unit_id, {
              status: takeDownReason,
              comment: takeDownAdditionalComments,
            })
          }
        >
          {confirmationText}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );

  return <>{confirmationModal}</>;
};

export default TakeDownUnitModal;
