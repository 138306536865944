import AdminListGroup from '../../../components/admin/AdminListGroup';
import { ProofIncomeClientInfo } from '../models/proofIncomeModel';
import { Badge, Button } from 'reactstrap';
interface Props {
  clientInfo: ProofIncomeClientInfo;
  toggleSendProofModal: () => void;
}

const AccountingProofIncomeClientCard = ({
  clientInfo,
  toggleSendProofModal,
}: Props) => {
  const fieldMapper = [
    {
      title: 'Nombre',
      value: `${clientInfo.name} ${clientInfo.lastname}`,
    },
    {
      title: 'Documento',
      value: clientInfo.document_number,
    },
    {
      title: 'Email',
      value: clientInfo.email ?? 'No ingresado',
    },
    {
      title: 'Último comprobante enviado',
      value:
        clientInfo.last_email_proof_log_at !== ''
          ? clientInfo.last_email_proof_log_at
          : 'Sin registro',
    },
  ];

  const footer = clientInfo.email ? (
    <Button color="primary" onClick={() => toggleSendProofModal()}>
      Enviar comprobante
    </Button>
  ) : (
    <Badge className="badge-lg" color="warning">
      Es necesario configurar el email para enviar el comprobante
    </Badge>
  );

  return (
    <AdminListGroup
      title={'Información cliente'}
      items={fieldMapper}
      footer={footer}
    />
  );
};

export default AccountingProofIncomeClientCard;
