import React, { useCallback } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, Table } from 'reactstrap';
import { ProperAssignedVisit } from '../models/dashboardModels';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { updateVisitStateEndpoint } from '../services/dashboardService';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { getVisitStatusAdapter } from '../../properties/adapters/visitsAdapter';
import { VISIT_STATUS } from '../../../constants/visitsConstants';
import { deleteVisitEndpoint } from '../../properties/services/propertyService';

export interface Props {
  visits: ProperAssignedVisit[];
  footer?: any;
}

const DashboardVisitsTable: React.FC<Props> = ({ visits, footer }) => {
  const { callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const navigate = useNavigate();

  const doVisitDone = useCallback(
    async (visitId: string) => {
      const { status } = await callEndpoint(
        updateVisitStateEndpoint(visitId, true, token),
      );
      if (status === 200) navigate(0);
    },
    [callEndpoint, token],
  );

  const doCancelVisit = useCallback(
    async (visitId: string) => {
      const { status } = await callEndpoint(
        deleteVisitEndpoint(visitId, token),
      );
      if (status === 200) navigate(0);
    },
    [callEndpoint, token],
  );

  return (
    <>
      <Card style={{ overflowY: 'scroll', maxHeight: '450px' }}>
        <CardBody>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Propiedad</th>
                <th scope="col">Código</th>
                <th scope="col">Fecha de visita</th>
                <th scope="col">Inquilino</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {visits.map((visit) => (
                <tr key={visit.id}>
                  <th scope="row">
                    <img
                      alt="..."
                      width={175}
                      height={100}
                      src={
                        !visit.propertyInfo.cover ||
                        visit.propertyInfo.cover.endsWith('null')
                          ? 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                          : visit.propertyInfo.cover
                      }
                    />
                  </th>
                  <td>{visit.propertyInfo.code}</td>
                  <td>{visit.visitInfo.visit_date}</td>
                  <td>{visit.visitInfo.tenant_name}</td>
                  <td>
                    {visit.visitInfo.is_visit_done ? (
                      <Badge className="badge-lg mr-2" color="success">
                        {getVisitStatusAdapter(VISIT_STATUS.DONE)}
                      </Badge>
                    ) : (
                      <>
                        <Button
                          onClick={() => doVisitDone(visit.visitInfo.id)}
                          className="btn-icon btn-success mr-0"
                          size="sm"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-check-bold"></i>
                          </span>
                        </Button>
                        <Button
                          onClick={() => doCancelVisit(visit.visitInfo.id)}
                          className="btn btn-icon btn-danger mr-0"
                          size="sm"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-fat-remove"></i>
                          </span>
                        </Button>
                        <Button
                          onClick={() =>
                            navigate(
                              `/properties/admin/${visit.propertyInfo.id}/admin-visit`,
                            )
                          }
                          className="btn btn-icon btn-default"
                          size="sm"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-calendar-grid-58"></i>
                          </span>
                        </Button>
                      </>
                    )}
                    {/* <label className="custom-toggle">
                    <input
                      type="checkbox"
                      defaultChecked={visit.visitInfo.is_visit_done}
                      onChange={(event) => toggleVisitDone(visit.visitInfo.id, event.target.checked)}
                    />
                    <span className="custom-toggle-slider rounded-circle" /></label> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {footer && <div>{footer}</div>}
    </>
  );
};

export default DashboardVisitsTable;
