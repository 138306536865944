import React, { useCallback, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  InvestorProjectDetail,
  InvestorProjectDeveloper,
  InvestorProjectForm,
} from '../models/investors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import useAdminEntity from '../../../hooks/useAdminEntity';
import {
  getDevelopersEndpoint,
  getNearbyPlacesEndpoint,
} from '../services/investorService';
import useAuth from '../../../hooks/useAuth';
import Select from 'react-select';
import {
  listToSelectOptionAdapter,
  plainListToSelectOptionAdapter,
} from '../../../adapters/listAdapter';
import { getCurrenciesPerCountry } from '../../../services/publicService';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { getAmenitiesEndpoint } from '../../properties/services/propertyService';
import { getCurrentProjectAmenities } from '../adapters/projectAdapter';
import { PROPERTY_TYPES_LIST } from '../../properties/constants/propertyConstants';
import { PROJECT_WORK_PROGRESS } from '../constants/projectConstants';
import AddressListPicker from '../../../components/address/AddressListPicker';
import useUserCountry from '../../../hooks/useUserCountry';
import { getAddressDetailById } from '../../../services/addressService';

export type Props = {
  investorProject?: InvestorProjectDetail;
  handleForm: (form: InvestorProjectForm, errorRepository: Function) => void;
};
const ManageInvestorProjectForm: React.FC<Props> = ({
  investorProject,
  handleForm,
}) => {
  // https://stackoverflow.com/questions/48991254/reactjs-input-defaultvalue-is-set-but-not-showing
  const { getAuthToken } = useAuth();
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode = getCurrentCountry()?.code || 'SV';
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState(null);

  const projectTypeOptions = listToSelectOptionAdapter(
    PROPERTY_TYPES_LIST,
    'value',
    'display_value',
  );

  const workProgressOprions = listToSelectOptionAdapter(
    PROJECT_WORK_PROGRESS,
    'value',
    'display_value',
  );

  const projectDistributionOptions = listToSelectOptionAdapter(
    [
      {
        value: 'na',
        display_value: 'NA',
      },
      {
        value: 'polygon',
        display_value: 'Poligono',
      },
      {
        value: 'stages',
        display_value: 'Etapas',
      },
    ],
    'value',
    'display_value',
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm<InvestorProjectForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required('El nombre del proyecto es requerido.'),
          description: yup
            .string()
            .required('La descripción del proyecto es requerida.'),
          address: yup
            .string()
            .required('La dirección del proyecto es requerida.'),
          developer_id: yup.string().required('Selecciona una desarrolladora.'),
          lat: yup.number().required('Ubica el proyecto en el mapa.'),
          lng: yup.number().required('Ubica el proyecto en el mapa.'),
          estimate_build_at: yup
            .string()
            .required('Selecciona una fecha estimada de entrega del proyecto.'),
          amenities: yup
            .array()
            .of(yup.string())
            .required('Selecciona al menos una amenidad.'),
          near_by_places: yup
            .array()
            .of(yup.string())
            .required(
              'Selecciona al menos un lugar de interés cercano al proyecto.',
            ),
          type: yup.string().required('Seleccione el tipo de proyecto.'),
          is_quarter_config: yup.boolean(),
          is_allowing_multiple_prebooked_config: yup.boolean(),
          has_config_by_quota: yup.boolean(),
          currency: yup.string(),
          work_progress: yup
            .string()
            .required('Seleccione el progreso del proyecto.'),
          address_id: yup
            .string()
            .required('Seleccione una dirección del proyecto en el mapa.'),
          is_exclusive: yup.boolean(),
          is_private: yup.boolean(),
          is_verified: yup.boolean(),
          // qualification_stars: yup
          //   .number()
          //   .max(5, 'Calificación máxima 5')
          //   .min(0, 'Calificación mínima 0'),
        })
        .required(),
    ),
  });

  const internalHandleForm = useCallback(
    (form: InvestorProjectForm) => handleForm(form, setError),
    [handleForm],
  );

  const estimated_built_at_split =
    investorProject?.estimate_built_at.split('/') || [];
  const estimated_built_at_formatted = `${estimated_built_at_split[2]}-${estimated_built_at_split[1]}-${estimated_built_at_split[0]}`;

  // ---- LOAD CATALOGS ---- //
  const getDevelopers = useCallback(
    () => getDevelopersEndpoint(token),
    [token],
  );
  const [developers, developersFn, loadingDevelopers] = useAdminEntity<
    InvestorProjectDeveloper[]
  >(getDevelopers, setError);
  const developerOptions = listToSelectOptionAdapter(
    (developers as InvestorProjectDeveloper[]) || [],
    'id',
    'name',
  );

  const getAmenities = useCallback(() => getAmenitiesEndpoint(token), []);
  const getCurrencies = useCallback(
    () => getCurrenciesPerCountry(currentUserCountryCode),
    [],
  );
  const [currencies, currenciesFn, loadingCurrencies] = useAdminEntity(
    getCurrencies,
    setError,
  );
  const currenciesOptions = plainListToSelectOptionAdapter(
    (currencies as Array<any>) || [],
  );

  const [amenities, amenitiesFn, loadingAmenities] = useAdminEntity(
    getAmenities,
    setError,
  );
  const amenityOptions = listToSelectOptionAdapter(
    (amenities as Array<any>) || [],
    'id',
    'description',
  );

  const getNearbyPlaces = useCallback(
    () => getNearbyPlacesEndpoint(token),
    [token],
  );
  const [nearbyPlaces, nearbyPlacesFn, loadingNearbyPlaces] = useAdminEntity(
    getNearbyPlaces,
    setError,
  );
  const placesOptions = listToSelectOptionAdapter(
    (nearbyPlaces as Array<any>) || [],
    'id',
    'name',
  );
  // const watchAddressId = watch('address_id');
  // const [currentAddress, setCurrentAddress] = useState<any>();

  // useEffect(() => {
  //   callEndpoint(getAddressDetailById(token, parseInt(watchAddressId))).then(
  //     ({ status, data }) => {
  //       if (status === 200) {
  //         const addressInfo = data.data;
  //         setCurrentAddress(addressInfo);
  //       }
  //     },
  //   );
  // }, [watchAddressId, callEndpoint, token]);

  // ---- LOAD FORM IF IT IS AN UPDATE ---- //
  useEffect(() => {
    if (investorProject) {
      reset({
        name: investorProject.name,
        description: investorProject.description,
        address: investorProject.address,
        type: investorProject.type,
        developer_id: investorProject.developer.id,
        estimate_build_at: estimated_built_at_formatted,
        amenities: investorProject.amenities || [],
        near_by_places: investorProject.near_by_places || [],
        lat: investorProject.lat,
        lng: investorProject.lng,
        is_quarter_config: `${investorProject.is_quarter_config}`,
        is_allowing_multiple_prebooked_config: `${investorProject.is_allowing_multiple_prebooked_config}`,
        has_config_by_quota: `${investorProject.has_config_by_quota}`,
        currency: investorProject.currency,
        address_id: investorProject.address_id,
        work_progress: investorProject.work_progress,
        is_exclusive: `${investorProject.is_exclusive}`,
        is_verified: `${investorProject.is_verified}`,
        // qualification_stars: investorProject.qualification_stars,
      });
      // set default amenities to state
    }
  }, []);

  return (
    <Form role="form" onSubmit={handleSubmit(internalHandleForm)}>
      {/*<pre>{JSON.stringify(errors)}</pre>*/}
      <Row>
        <Col md="6">
          <Row>
            <Col>
              {error && (
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--text">{error}</span>
                </UncontrolledAlert>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={errors.name?.message && 'is-invalid'}
                      {...field}
                      type="text"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.name?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="description">
                  Descripción
                </label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={errors.description?.message && 'is-invalid'}
                      {...field}
                      id="description"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.description?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="address">
                  Dirección
                </label>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Input
                      className={errors.address?.message && 'is-invalid'}
                      {...field}
                      id="address"
                    />
                  )}
                />
                <h5 className="text-danger">{errors.address?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="type">
                  Tipo de proyecto
                </label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      name="type"
                      placeholder="Seleccione el tipo de proyecto"
                      options={projectTypeOptions}
                      defaultValue={projectTypeOptions.find(
                        (e) => e.value === investorProject?.type,
                      )}
                      onChange={(optionSelected) =>
                        setValue('type', optionSelected?.value)
                      }
                      ref={field.ref}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.type?.message}</h5>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="type">
                  Tipo de distribución
                </label>
                <Controller
                  name="distribution"
                  control={control}
                  render={({ field }) => (
                    <Select
                      name="distribution"
                      placeholder="Seleccione el tipo de distribución"
                      options={projectDistributionOptions}
                      defaultValue={projectDistributionOptions.find(
                        (e) => e.value === investorProject?.distribution,
                      )}
                      onChange={(optionSelected) =>
                        setValue('distribution', optionSelected?.value)
                      }
                      ref={field.ref}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.distribution?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="developer_id">
                  Desarrolladora
                </label>
                {!loadingDevelopers && (
                  <Controller
                    name="developer_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder="Selecciona una desarrolladora"
                        options={developerOptions}
                        defaultValue={developerOptions.find(
                          (e) => e.value === investorProject?.developer.id,
                        )}
                        key={`${investorProject?.developer.id}`}
                        onChange={(optionSelected) =>
                          setValue('developer_id', optionSelected?.value)
                        }
                        ref={field.ref}
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">{errors.developer_id?.message}</h5>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="developer_id">
                  Avance de obra
                </label>
                <Controller
                  name="work_progress"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecciona un estado"
                      options={workProgressOprions}
                      defaultValue={workProgressOprions.find(
                        (e) => e.value === investorProject?.work_progress,
                      )}
                      key={`${investorProject?.work_progress}`}
                      onChange={(optionSelected) =>
                        setValue('work_progress', optionSelected?.value)
                      }
                      ref={field.ref}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.work_progress?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="currency">
                  Moneda
                </label>
                {!loadingCurrencies && (
                  <Controller
                    name="currency"
                    control={control}
                    defaultValue={''}
                    render={() => (
                      <Select
                        placeholder="Selecciona una Moneda"
                        options={currenciesOptions}
                        defaultValue={currenciesOptions.find(
                          (e) => e.value === investorProject?.currency,
                        )}
                        onChange={(optionSelected) =>
                          setValue('currency', optionSelected?.value)
                        }
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">{errors.currency?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="estimate_build_at"
                >
                  Fecha estimada de entrega
                </label>
                <Controller
                  name="estimate_build_at"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="estimate_build_at"
                      type="date"
                      className={
                        errors.estimate_build_at?.message && 'is-invalid'
                      }
                      defaultValue={estimated_built_at_formatted}
                      key={`${investorProject?.estimate_built_at}-key`}
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.estimate_build_at?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="qualification_stars"
                >
                  Calificación
                </label>
                {!loadingAmenities && (
                  <Controller
                    name="qualification_stars"
                    control={control}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.qualification_stars?.message && 'is-invalid'
                        }
                        {...field}
                        type="number"
                        step="any"
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">
                  {errors.qualification_stars?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row> */}

          <Row>
            <Col>
              <FormGroup>
                <Controller
                  name="is_quarter_config"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="is_quarter_config"
                      type="checkbox"
                      className={
                        errors.is_quarter_config?.message && 'is-invalid'
                      }
                      key={`${investorProject?.is_quarter_config}-is_quarter_config-key`}
                      style={{ marginLeft: 0 }}
                      defaultChecked={investorProject?.is_quarter_config}
                    />
                  )}
                />
                <label
                  className="form-control-label ml-3"
                  htmlFor="is_quarter_config"
                >
                  Mostrar solo Q estimado
                </label>
                <h5 className="text-danger">
                  {errors.is_quarter_config?.message}
                </h5>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Controller
                  name="is_allowing_multiple_prebooked_config"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="is_allowing_multiple_prebooked_config"
                      type="checkbox"
                      className={
                        errors.is_allowing_multiple_prebooked_config?.message &&
                        'is-invalid'
                      }
                      key={`${investorProject?.is_allowing_multiple_prebooked_config}-is_-key`}
                      style={{ marginLeft: 0 }}
                      defaultChecked={
                        investorProject?.is_allowing_multiple_prebooked_config
                      }
                    />
                  )}
                />
                <label className="form-control-label ml-3" htmlFor="is_">
                  Permite agendar multiple a las unidades
                </label>
                <h5 className="text-danger">
                  {errors.is_allowing_multiple_prebooked_config?.message}
                </h5>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Controller
                  name="has_config_by_quota"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="has_config_by_quota"
                      type="checkbox"
                      className={
                        errors.has_config_by_quota?.message && 'is-invalid'
                      }
                      key={`${investorProject?.has_config_by_quota}-is_-key`}
                      style={{ marginLeft: 0 }}
                      defaultChecked={investorProject?.has_config_by_quota}
                    />
                  )}
                />
                <label className="form-control-label ml-3" htmlFor="is_">
                  Configuración de precio por cuota
                </label>
                <h5 className="text-danger">
                  {errors.has_config_by_quota?.message}
                </h5>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Controller
                  name="is_exclusive"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="is_exclusive"
                      type="checkbox"
                      className={errors.is_exclusive?.message && 'is-invalid'}
                      key={`${investorProject?.is_exclusive}-is_-key`}
                      style={{ marginLeft: 0 }}
                      defaultChecked={investorProject?.is_exclusive}
                    />
                  )}
                />
                <label className="form-control-label ml-3" htmlFor="is_">
                  Proyecto exclusivo
                </label>
                <h5 className="text-danger">{errors.is_exclusive?.message}</h5>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Controller
                  name="is_verified"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="is_verified"
                      type="checkbox"
                      className={errors.is_verified?.message && 'is-invalid'}
                      key={`${investorProject?.is_verified}-is_-key`}
                      style={{ marginLeft: 0 }}
                      defaultChecked={investorProject?.is_verified}
                    />
                  )}
                />
                <label className="form-control-label ml-3" htmlFor="is_">
                  Proyecto verificado
                </label>
                <h5 className="text-danger">{errors.is_verified?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="amenities">
                  Amenidades
                </label>
                {!loadingAmenities && (
                  <Controller
                    name="amenities"
                    control={control}
                    render={() => (
                      <Select
                        placeholder="Selecciona una o más amenidades"
                        isMulti
                        options={amenityOptions}
                        defaultValue={getCurrentProjectAmenities(
                          amenityOptions,
                          investorProject?.amenities || [],
                        )}
                        onChange={(optionSelected) =>
                          setValue(
                            'amenities',
                            optionSelected.map((amenity) => amenity.value),
                          )
                        }
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">{errors.amenities?.message}</h5>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="near_by_places">
                  Lugares de interés
                </label>
                {!loadingNearbyPlaces && (
                  <Controller
                    name="near_by_places"
                    control={control}
                    defaultValue={[]}
                    render={() => (
                      <Select
                        placeholder="Selecciona una o más lugares de interés"
                        isMulti
                        options={placesOptions}
                        defaultValue={getCurrentProjectAmenities(
                          placesOptions,
                          investorProject?.near_by_places || [],
                        )}
                        onChange={(optionSelected) =>
                          setValue(
                            'near_by_places',
                            optionSelected.map((place) => place.value),
                          )
                        }
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">
                  {errors.near_by_places?.message}
                </h5>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <AddressListPicker
            defaultPolygonId={
              investorProject?.address_id
                ? parseInt(investorProject?.address_id)
                : 0
            }
            defaultCenter={{
              lat: investorProject?.lat ?? 0,
              lng: investorProject?.lng ?? 0,
            }}
            onAddressAssigned={(addressAssignedId, centerPoint) => {
              console.log(`
                onPolygonAssigned: ${addressAssignedId}, centerPoint: ${centerPoint}
              `);
              setValue('lat', centerPoint.lat);
              setValue('lng', centerPoint.lng);
              setValue('address_id', addressAssignedId);
            }}
          />
          <h5 className="text-danger">
            {errors.lat?.message ||
              errors.lng?.message ||
              errors.address_id?.message}
          </h5>
        </Col>
      </Row>
      <Button color="primary" disabled={isLoading}>
        {investorProject ? 'Actualizar' : 'Crear'}
      </Button>
    </Form>
  );
};

export default ManageInvestorProjectForm;
