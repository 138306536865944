import React from 'react';
import { InvestorProjectDetail } from '../models/investors';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { Badge } from 'reactstrap';

export type Props = {
  project: InvestorProjectDetail;
}

const InvestorProjectDetailCard: React.FC<Props> = ({ project }) => {
  const fieldMapper = [
    {
      title: "Visibilidad",
      value: project.is_private
        ? (<Badge className="badge-lg" color="danger">
          Privado
        </Badge>)
        : (<Badge className="badge-lg" color="success">
          Público
        </Badge>)
    },
    {
      title: "Código",
      value: project.code,
    },
    {
      title: "Nombre",
      value: project.name,
    },
    {
      title: "Desarrolladora",
      value: project.developer.name,
    },
    {
      title: "Descripción",
      value: project.description,
    },
    {
      title: "Dirección",
      value: project.address,
    },
    {
      title: "Fecha estimada de entrega",
      value: project.estimate_built_at,
    },
  ];

  return (
    <AdminListGroup title="Detalles del proyecto" items={fieldMapper} />
  );
};


export default InvestorProjectDetailCard;