import { Option } from "../../../models/formModel";

export const getCurrentProjectAmenities = (
  amenities: Option[],
  assignedAmenitiesIds: string[],
) => {
  const mapCurrentAmenities = assignedAmenitiesIds.map((item) => ({
    value: item
  }));
  return amenities.filter((element) =>
    mapCurrentAmenities?.some((item: any) => element.value === item.value),
  );
};

export const getCurrentProjectNearPlaces = (
  nearPlaces: Option[],
  assignedPlaces: string[]
) => {
  
}