import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import React, { useCallback, useEffect, useState } from 'react';
import { GuestOffer } from './models/guestModel';
import { createSearchParams } from 'react-router-dom';
import {
  cancelOfferEndpoint,
  getGuestOffersEndpoint,
} from './services/guestService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import CancelEntityModal from './components/CancelEntityModal';
import useModalState from '../../hooks/useModalState';
import AdminPaginator from '../../components/admin/AdminPaginator';
import VisitMarketplaceTypeBadge from '../../components/VisitMarketplaceTypeBadge';

const GuestOffers = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();

  const [errorAlert, setErrorAlert] = useState(null);

  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const [guestOffers, setGuestOffers] = useState<GuestOffer[]>([]);

  const loadGuestOffers = useCallback(
    async (page: number = 0, take: number = 0) => {
      let filterBuilder: any = {};
      if (page > 0) filterBuilder.page = page.toString();
      if (take > 0) filterBuilder.take = take.toString();
      const queryString = `?${createSearchParams(filterBuilder)}`;

      const { status, data } = await callEndpoint(
        getGuestOffersEndpoint(queryString, token),
      );

      const response = fetchResponseAdapter(data);

      if (status === 200) {
        setGuestOffers(response.data.items || []);
        const { page, pageCount } = response.data.meta;
        setPaginator({ page, pageCount });
      } else {
        setErrorAlert(response.message);
      }
    },
    [callEndpoint, token],
  );
  useEffect(() => {
    loadGuestOffers().catch((e) => setErrorAlert(e.toString()));
  }, [loadGuestOffers]);

  const [offerForCancel, setOfferForCancel] = useState({});
  const [showCancelOfferModal, toggleCancelOfferModal] = useModalState();
  const cancelOffer = useCallback(
    async (offerForCancel: GuestOffer) => {
      await callEndpoint(
        cancelOfferEndpoint(
          offerForCancel.guestInfo.id,
          offerForCancel.offerInfo.id,
          token,
        ),
      );
      toggleCancelOfferModal();
      await loadGuestOffers();
    },
    [callEndpoint, loadGuestOffers, toggleCancelOfferModal, token],
  );

  const pageName = 'Ofertas de Guests';
  const pageParentName = 'Guests';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const cancelOfferModal = (
    <CancelEntityModal
      entity={offerForCancel}
      isOpen={showCancelOfferModal}
      onToggle={toggleCancelOfferModal}
      onConfirmation={cancelOffer}
      warningText={'¿Estás seguro de anular la oferta?'}
      confirmationText={'Si, anular oferta'}
      abortText={'No, continuar revisando...'}
    />
  );

  const guestOfferActions = (offer: GuestOffer) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem href={`/guest-offers/${offer.offerInfo.id}`}>
          Ver oferta detallada
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setOfferForCancel(offer);
            toggleCancelOfferModal();
          }}
        >
          Anular oferta
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Email</th>
          <th scope="col">Fecha estimada de mudanza</th>
          <th scope="col">Precio oferta</th>
          <th scope="col">Tipo de lead</th>
          <th scope="col">Periodo oferta</th>
          <th scope="col">ID Propiedad</th>
          <th scope="col">Propiedad</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {guestOffers.length > 0 &&
          guestOffers.map((guestOffer) => (
            <tr
              key={`${guestOffer.guestInfo.id}-${guestOffer.propertyInfo.id}`}
            >
              <td>{guestOffer.guestInfo.fullname}</td>
              <td>{guestOffer.guestInfo.phone}</td>
              <td>{guestOffer.guestInfo.email}</td>
              <td>{guestOffer.offerInfo.estimate_moving_at}</td>
              <td>{guestOffer.offerInfo.offer_price}</td>
              <td>
                <VisitMarketplaceTypeBadge
                  marketplace_type={guestOffer.offerInfo.marketplace_type}
                />
              </td>
              <td>{guestOffer.offerInfo.period}</td>
              <td>{guestOffer.propertyInfo.code}</td>
              <td>
                <img
                  alt="..."
                  width={175}
                  height={100}
                  src={
                    guestOffer.propertyInfo.cover
                      ? guestOffer.propertyInfo.cover
                      : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                  }
                />
              </td>
              <td className="text-right">{guestOfferActions(guestOffer)}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadGuestOffers}
    />
  );

  if (isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentPagination}
      globalAlertOptions={globalAlertOptions}
    >
      {componentTable}
      {cancelOfferModal}
    </AdminPage>
  );
};

export default GuestOffers;
