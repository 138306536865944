import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { getOfferStatusAdapter } from '../../adapters/offerAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import Timeline from '../../components/Timeline';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { getOfferDetailEndpoint } from '../../services/publicService';
import DetailLabelText from '../properties/components/DetailLabelText';
import { offerLogTimelineAdapter } from './adapters/offerAdapter';
import { CreateContractExpress, OfferDetailData } from './models/offerModel';
import AcceptOfferModal from './components/AcceptOfferModal';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import {
  acceptOfferEndpoint,
  createContractExpressEndpoint,
} from './services/offerService';
import CreateContractExpressModal from './components/CreateExpressContractModal';
import VisitMarketplaceTypeBadge, {
  LeadMarketplaceType,
} from '../../components/VisitMarketplaceTypeBadge';
import CreateTransactionSaleModal from './components/CreateTransactionSaleModal';
import { CreateTransactionSale } from './models/transactionSaleModel';
import { createTransactionSaleEndpoint } from './services/transactionSaleService';
import BuroOfferDetailCard from './components/BuroOfferDetailCard';
import FormModal from '../../components/modals/FormModal';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import useOfferCompliance from './hooks/useOfferCompliance';
import BasicModal from '../../components/modals/BasicModal';

const OfferDetail = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken, isPermissionAvailable } = useAuth();
  const token = getAuthToken();
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
  const [showCreateContractExpressModal, setShowCreateContractExpressModal] =
    useState<boolean>(false);
  const [showCreateTransactionSaleModal, setShowCreateTransactionSaleModal] =
    useState<boolean>(false);
  const [showFormOfferDeniedModal, setShowFormOfferDeniedModal] =
    useState<boolean>(false);
  const [
    showFormOfferAprovalComplianceModal,
    setShowFormOfferApprovalComplianceModal,
  ] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);
  const pageName = 'Detalle de Oferta';
  const pageParentName = 'Ofertas';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const getOfferDetail = useCallback(
    () => getOfferDetailEndpoint(id, token),
    [id, token],
  );
  const [offer] = useAdminEntity<OfferDetailData>(
    getOfferDetail,
    setErrorAlert,
  );

  const {
    apiErrors,
    control,
    errors,
    optionOfferBuroCompliance,
    handleSubmit,
    resetValues,
    setValue,
    doDeniedCompliance,
    doApproveCompliance,
  } = useOfferCompliance({
    offerId: id,
  });

  const toogleModal = () => setShowAcceptModal((state) => !state);
  const toggleCreateContractExpressModal = () =>
    setShowCreateContractExpressModal((state) => !state);
  const toggleCreateTransactionSaleModal = () =>
    setShowCreateTransactionSaleModal((state) => !state);
  const toogleFormOfferDeniedModal = () =>
    setShowFormOfferDeniedModal((state) => !state);
  const toogleFormOfferApproveComplianceModal = () =>
    setShowFormOfferApprovalComplianceModal((state) => !state);

  const acceptOffer = async (offerId: string) => {
    const { status } = await callEndpoint(acceptOfferEndpoint(offerId, token));
    if (status === 200) {
      navigate(0);
    } else {
      setErrorAlert('Error al mostrar formulario');
    }
  };

  const createContractExpress = async (
    offerId: string,
    hasRentalManagement: boolean = false,
  ) => {
    const { status, data } = await callEndpoint(
      createContractExpressEndpoint(offerId, hasRentalManagement, token),
    );
    if (status === 201) {
      const response: CreateContractExpress = fetchResponseAdapter(data).data;
      navigate(`/contracts/${response.transaction_id}/payment-status`);
    } else {
      setErrorAlert('Error no se pudo generar el contrato en su forma express');
    }
  };

  const createTransactionSale = async (payload: CreateTransactionSale) => {
    console.log('🚀 ~ createTransactionSale ~ payload:', payload);
    const { status, data } = await callEndpoint(
      createTransactionSaleEndpoint(payload, token),
    );
    if (status === 201) {
      const response = fetchResponseAdapter(data).data;
      navigate(`/transaction-sales/${response.id}`);
    } else {
      setErrorAlert('Error no se pudo generar el mandato de pago para venta');
    }
  };

  if (!offer || isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  const pageFooter = (
    <>
      {offer.contract_id && (
        <Button
          color="primary"
          onClick={() => navigate(`/contract/${offer.contract_id}/detail`)}
        >
          Ver contrato
        </Button>
      )}
      {offer.transaction_sale_id && (
        <Button
          color="primary"
          onClick={() =>
            navigate(`/transaction-sales/${offer.transaction_sale_id}`)
          }
        >
          Mandato de pago generado
        </Button>
      )}
    </>
  );

  const onSaleOfferFooter = offer.status === 'accepted' &&
    !offer.transaction_sale_id &&
    !offer.contract_id && (
      <Button
        color="success"
        disabled={isLoading}
        onClick={() => toggleCreateTransactionSaleModal()}
      >
        Crear mandato de pago para venta
      </Button>
    );

  const onRentOfferFooter = offer.status === 'accepted' &&
    !offer.contract_id && (
      <>
        <Button
          onClick={() => navigate(`/offer/${id}/contract/create`)}
          color="primary"
          disabled={isLoading}
        >
          Crear contrato
        </Button>
        <Button
          color="success"
          disabled={isLoading}
          onClick={() => toggleCreateContractExpressModal()}
        >
          Crear contrato express
        </Button>
      </>
    );

  const formModal = (
    <>
      <Form>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="reason">
                Selecciona motivo de denegar oferta
              </label>
              <Controller
                name="reason"
                control={control}
                key={'reason-key'}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder={'Selecciona motivo de denegar oferta'}
                    options={optionOfferBuroCompliance}
                    onChange={(selected: any) => {
                      setValue('reason', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
              <h5 className="text-danger">{errors.reason?.message}</h5>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="form-control-label" htmlFor="explain_reason">
              Explica motivo
            </label>
            <Controller
              name="comment"
              control={control}
              key={`comment-key`}
              render={({ field }) => (
                <Input
                  className={errors.comment?.message && 'is-invalid'}
                  {...field}
                  type="textarea"
                  placeholder="Explica motivo de denegar oferta"
                />
              )}
            />
            <h5 className="text-danger">{errors.comment?.message}</h5>
          </Col>
        </Row>
      </Form>
    </>
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={pageFooter}
    >
      <Row>
        {/* Profile card section */}
        <Col className="order-xl-2 pt-10" xl="4">
          <Card className="card-profile">
            <CardHeader>
              <h5 className="h3 mb-0">Usuarios</h5>
            </CardHeader>
            <CardBody>
              <ListGroup className="list my--3" flush>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <a
                        className="avatar rounded-circle"
                        href={`/landlord/${offer.landlord_id}/profile`}
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={
                            offer.landlordInfo.avatar
                              ? offer.landlordInfo.avatar
                              : DEFAULT_AVATAR
                          }
                        />
                      </a>
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a
                          href={`/landlord/${offer.landlord_id}/profile`}
                          target="_blank"
                        >
                          {offer.landlordInfo.name}
                        </a>
                      </h4>
                      <small>Propietario</small>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <a
                        className="avatar rounded-circle"
                        href={`/tenant/${offer.tenant_id}/profile`}
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={
                            offer.tenantInfo.avatar
                              ? offer.tenantInfo.avatar
                              : DEFAULT_AVATAR
                          }
                        />
                      </a>
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a
                          href={`/tenant/${offer.tenant_id}/profile`}
                          target="_blank"
                        >
                          {offer.tenantInfo.name}
                        </a>
                      </h4>
                      <small>Inquilino</small>
                    </div>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <a
                        className="avatar rounded-circle"
                        href={`/tenant/${''}/profile`}
                        target="_blank"
                      >
                        <img
                          alt="..."
                          src={
                            'https://storage.googleapis.com/assets-us-east4-propilatam-dev/avatars/default-avatar.png'
                          }
                        />
                      </a>
                    </Col>
                    <div className="col ml--2">
                      <h4 className="mb-0">
                        <a href={`/tenant/${''}/profile`} target="_blank">
                          Proper...
                        </a>
                      </h4>
                      <small>Proper</small>
                    </div>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
            <hr className="my-4" />
            {offer.score_info.id &&
              BuroOfferDetailCard({
                score: offer.score_info.score,
                internalInfo: offer.score_info.internal_info,
              })}
          </Card>
        </Col>
        {/* Aditional info */}
        <Col className="order-xl-1" xl="8">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Información adicional</h3>
                </Col>
                <Col className="text-right" xs="6">
                  {offer.status === 'on_wait' &&
                    !isPermissionAvailable('SHOW_APPROVE_OFFER') && (
                      <Badge className="badge-lg" color="warning">
                        <strong>Oferta en revisión de cumplimiento</strong>
                      </Badge>
                    )}
                  <VisitMarketplaceTypeBadge
                    customClassname="badge-lg"
                    marketplace_type={offer.marketplace_type}
                  />
                  <Badge className="badge-lg" color="default">
                    Oferta {getOfferStatusAdapter(offer.status)}
                  </Badge>
                  {offer.contract_id && (
                    <Badge className="badge-lg" color="success">
                      Contrato creado
                    </Badge>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="">
                <DetailLabelText
                  label="Precio de oferta"
                  text={offer.offer_price}
                />
                <DetailLabelText
                  label="Periodo"
                  text={offer.period_formatted}
                />
                <DetailLabelText
                  label="Fecha de mudanza"
                  text={offer.estimate_moving_at_formatted}
                />
              </div>
              <hr className="my-4" />
              <Timeline data={offerLogTimelineAdapter(offer.offer_log)} />
            </CardBody>
            <CardFooter>
              {offer.status === 'on_wait' &&
                offer.must_apply_compliance &&
                !offer.is_review_by_compliance &&
                isPermissionAvailable('SHOW_APPROVE_OFFER') && (
                  <>
                    <Button
                      onClick={() => toogleFormOfferApproveComplianceModal()}
                      color="success"
                      disabled={isLoading}
                    >
                      Aceptar oferta por cumpliento
                    </Button>
                    <Button
                      onClick={() => toogleFormOfferDeniedModal()}
                      color="danger"
                      disabled={isLoading}
                    >
                      Denegar oferta por cumpliento
                    </Button>
                  </>
                )}
              {offer.status === 'on_wait' &&
                (!offer.must_apply_compliance ||
                  offer.is_review_by_compliance) && (
                  <Button
                    onClick={() => toogleModal()}
                    color="success"
                    disabled={isLoading}
                  >
                    Aceptar oferta
                  </Button>
                )}
              {offer.marketplace_type === LeadMarketplaceType.ON_RENT
                ? onRentOfferFooter
                : onSaleOfferFooter}
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <AcceptOfferModal
        offerId={id}
        isOpen={showAcceptModal}
        onToggle={() => toogleModal()}
        onConfirmation={() => acceptOffer(id)}
        bodyText={
          'Aceptando la oferta podrás generar su contrato y mandato de pago al inquilino, las otras ofertas en espera pueden verse afectadas '
        }
        confirmationText={'Aceptar oferta'}
        abortText={'No, seguir revisando'}
        titleText={'Aceptar oferta'}
      />
      <CreateContractExpressModal
        offerId={id}
        isOpen={showCreateContractExpressModal}
        onToggle={() => toggleCreateContractExpressModal()}
        onConfirmation={createContractExpress}
        onConfirmationWithoutRentalManagement={createContractExpress}
        bodyText={`Al generar el contrato de forma express, se generará un contrato SIN RENTAL MANAGEMENT , se generará exclusivamente 1 cuota en forma de DEPÓSITO (${offer.offer_price}), posteriormente se pueden generar las cuotas restantes (${offer.period})`}
        confirmationText={'Con rental management'}
        confirmationWithoutRMText={'Sin rental management'}
        abortText={'No, seguir revisando'}
        titleText={'Generar contrato express'}
      />
      <CreateTransactionSaleModal
        abortText={'No, seguir revisando'}
        bodyText={'Crear mandato de pago para venta'}
        confirmationText={'Generar'}
        isOpen={showCreateTransactionSaleModal}
        onConfirmation={createTransactionSale}
        onToggle={() => toggleCreateTransactionSaleModal()}
        titleText={'Generar mandato de pago para venta'}
        payload={{
          offer_id: offer.id,
          property_id: offer.property_id,
          tenant_id: offer.tenant_id,
        }}
      />
      <FormModal
        isOpen={showFormOfferDeniedModal}
        onToggle={() => toogleFormOfferDeniedModal()}
        key="modal_form"
        children={formModal}
        headerText="Denegar oferta"
        onConfirmation={handleSubmit(doDeniedCompliance)}
        onReset={resetValues}
        confirmationText="Denegar"
      />
      <BasicModal
        abortText="Seguir revisando"
        bodyText="Se aprobará la oferta por cumplimiento"
        confirmationText="Deseas aprobar la oferta de cumplimiento"
        isOpen={showFormOfferAprovalComplianceModal}
        onToggle={toogleFormOfferApproveComplianceModal}
        onConfirmation={() => doApproveCompliance(id)}
        titleText="Aprobación por reglas de cumplimiento"
      />
    </AdminPage>
  );
};

export default OfferDetail;
