import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import useProperList from '../../../hooks/useProperList';
import { useState } from 'react';
import Select from 'react-select';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { UpdateContractForm } from '../models/contractModel';
import { updateContractAdmin } from '../services/contractService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

export interface IProps {
  contract_id: string;
  proper_id: string | undefined;
}

const UpdateProperToContrect: React.FC<IProps> = ({
  contract_id,
  proper_id,
}) => {
  const { propersOptions } = useProperList();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [properId, setProperId] = useState<string>('');
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const handleUpdateProper = async () => {
    const payload: UpdateContractForm = {
      proper_id: properId,
    };
    const { status } = await callEndpoint(
      updateContractAdmin(payload, contract_id, token),
    );
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setError('Lo sentimos, por el momento no es posible completar la acción');
    }
  };

  return (
    <Card>
      <CardHeader>
        {error && <Alert className="alert-danger">{error}</Alert>}
        Seleccionar proper
      </CardHeader>
      <CardBody>
        <Select
          key={`proper-${propersOptions.length}`}
          placeholder={'Selecciona un proper'}
          options={propersOptions}
          onChange={(selected) => {
            if (selected?.value) {
              setProperId(selected.value);
            }
          }}
          defaultValue={propersOptions.find((po) => po.value === proper_id)}
        />
      </CardBody>
      <CardFooter>
        <Row>
          <Col className="col-auto">
            <Button
              disabled={isLoading}
              className=""
              color="success"
              size="md"
              type="button"
              onClick={handleUpdateProper}
            >
              Actualizar proper
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default UpdateProperToContrect;
