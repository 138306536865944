import React, { useCallback, useEffect, useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  PropertyToAmenities,
  UpdatePropertyAmenitiesApiPayload,
  UpdatePropertyAmenitiesInfoForm,
} from '../models/propertyModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getAmenitiesEndpoint, getCharacteristicsEndpoint, updatePropertyEndpoint } from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { buildCharacteristics, getCurrentAmenities } from '../adapters/propertyAdapter';

const useAdminAmenities = (property_id: string, amenities_info: PropertyToAmenities[]) => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();
  const [characteristicsCatalog, setCharacteristicsCatalog] = useState<any[]>([]);
  const [amenitiesCatalog, setAmenitiesCatalog] = useState<any[]>([]);
  const [currentAmenities, setCurrentAmenities] = useState<any[]>([]);

  const loadCatalogs = useCallback(async () => {
    const characteristicResponse = await callEndpoint(
      getCharacteristicsEndpoint(token),
    );
    const amenitiesResponse = await callEndpoint(
      getAmenitiesEndpoint(token)
    );

    const characteristics = fetchResponseAdapter(
      characteristicResponse.data
    );
    const amenities = fetchResponseAdapter(
      amenitiesResponse.data
    );
    setCharacteristicsCatalog(
      characteristics.data
    );
    setAmenitiesCatalog(
      listToSelectOptionAdapter(
        amenities.data,
        'id',
        'description',
      )
    );

    setCurrentAmenities(
      getCurrentAmenities(
        listToSelectOptionAdapter(
          amenities.data,
          'id',
          'description',
        ),
        amenities_info,
      ),
    );

  }, [amenities_info, callEndpoint, token]);

  const updatePropertyAmenities = useCallback(async (formInfo: UpdatePropertyAmenitiesInfoForm) => {

    // Transform form info into payload
    const payload: UpdatePropertyAmenitiesApiPayload = {
      has_white_goods: formInfo.has_white_goods,
      is_furnished: formInfo.is_furnished,
      pets_allowed: formInfo.pets_allowed,
      characteristics_info: buildCharacteristics(
        characteristicsCatalog,
        formInfo.c_rooms,
        formInfo.c_bathrooms,
        formInfo.c_half_bathrooms,
        formInfo.c_parkings,
        formInfo.c_square_mts,
      ),
      amenities_info: formInfo.amenities_info,
    };

    const { status } = await callEndpoint(
      updatePropertyEndpoint(payload, property_id, token),
    );
    if (status === 200) {
      navigate(`/properties/admin/${property_id}`);
    }

  }, [callEndpoint, navigate, characteristicsCatalog, property_id, token]);

  useEffect(() => {
    loadCatalogs().catch(e => console.error(e));
  }, []);



  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<UpdatePropertyAmenitiesInfoForm>({
    resolver: yupResolver(
      yup
        .object({
          has_white_goods: yup.boolean(),
          is_furnished: yup.boolean(),
          pets_allowed: yup.boolean(),
        })
        .required(),
    ),
  });

  return {
    // Form
    control,
    register,
    errors,
    reset,
    handleSubmit,
    setValue,

    // Request
    isLoading,

    // Catalogs
    amenitiesCatalog,
    currentAmenities,
    characteristicsCatalog,

    // Update funcs
    updatePropertyAmenities,
  };
};

export default useAdminAmenities;
