import { TRANSACTION_DETAIL_STATUS, PAYMENT_METHODS } from '../constants/transactionConstants';

export const getTransactionDetailStatusAdapter = (status: string) => {
    return TRANSACTION_DETAIL_STATUS.hasOwnProperty(status)
        ? TRANSACTION_DETAIL_STATUS[status] : 'Desconocido'
}

export const getPaymentMethodStringAdapter = (method: string) => {
    return PAYMENT_METHODS.hasOwnProperty(method)
        ? PAYMENT_METHODS[method] : 'Desconocido'
}