import { useCallback, useEffect, useState } from 'react';
import { fetchResponseAdapter } from '../adapters/fetchAdapter';
import useCallApiAndLoad from './useCallApiAndLoad';
import { Paginator } from '../models/formModel';
import useAuth from './useAuth';

function useAdminEntity<T,>(
  endpoint: Function,
  errorRepository: Function,
  postActions?: (item: T, pagination?: Paginator) => void,
): [T, Function, boolean]
{
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [item, setItem] = useState<T>();
  const loadItem = useCallback(async () => {
    const {
      status, data
    } = await callEndpoint(endpoint());

    if (status === 200) {
      const response = fetchResponseAdapter(data);
      let actualResponse;
      let paginator;

      if (response.data.items && response.data.items.length >= 0) {
        actualResponse = response.data.items;
        paginator = response.data.meta;
      } else {
        actualResponse = response.data;
      }

      setItem(actualResponse as T);

      if (postActions)
        postActions(actualResponse as T, paginator as Paginator);
    }
  }, [callEndpoint, endpoint, postActions]);

  useEffect(() => {
    loadItem().catch(e => errorRepository(e.toString()));
  }, [loadItem, errorRepository]);

  return [
    item as T,
    loadItem as Function,
    isLoading as boolean
  ];
}



export default useAdminEntity;