import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};
export const getAddressConfigurationEndpoint = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/address/v1/admin/polygons/config`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAddressListsByLevelParentEndpoint = (
  token: string,
  level: number,
  parentId: number | undefined = undefined,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/address/v1/admin/polygons/resume`,
        params: {
          level: level,
          parent: parentId,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAddressDetailById = (token: string, addressId: number) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/address/v1/admin/polygons/${addressId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getAddressPreviousAssignedEndpoint = (token: string, addressId: number) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/address/v1/admin/polygons/contained/${addressId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};
