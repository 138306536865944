import { Option } from '../../../models/formModel';

export const PROPERTY_STATUS: any = {
  created: 'Creada',
  take_photos: 'Toma de fotografías',
  photos_already_taken: 'Fotos tomadas',
  pending_property_docs: 'Pendiente de documentos',
  waiting_for_approval: 'Pendiente de aprobación',
  property_denied: 'Denegada',
  property_approved: 'Aprobada',
  published: 'Publicada',
  disabled: 'Inhabilitada',
  taken: 'Alquilada',
  rented_external: 'Rentada fuera',
};

export enum PROPERTY_SALE_STATUS_VALUES {
  CREATED = 'created',
  TAKE_PHOTOS = 'take_photos',
  PUBLISHED = 'published',
  DISABLED = 'disabled',
  RESERVED = 'reserved',
  ON_TITLE_DEED = 'on_title_deed',
  SOLD = 'sold',
  SOLD_EXTERNAL = 'sold_external',
  ON_TRANSFER_OWNERSHIP = 'transfer_ownership',
}

export enum PROPERTY_STATUS_VALUES {
  CREATED = 'created',
  TAKE_PHOTOS = 'take_photos',
  PUBLISHED = 'published',
  DISABLED = 'disabled',
  RENTED_EXTERNAL = 'rented_external',
  TAKEN = 'taken',
}

export enum PROPERTY_TYPES_OPTIONS {
  APTO = 'apto',
  HOUSE = 'house',
  PLOT = 'plot',
}

export enum POLYGONS_TYPES_OPTIONS {
  STATE = 'state',
  DISTRICT = 'district',
  CITY = 'city',
  NEIGHBORHOOD = 'nb',
  TOWER = 'tower',
}

export const PROPERTY_TYPES_LIST = [
  {
    value: PROPERTY_TYPES_OPTIONS.APTO,
    display_value: 'Apartamentos',
  },
  {
    value: PROPERTY_TYPES_OPTIONS.HOUSE,
    display_value: 'Casas',
  },
  {
    value: PROPERTY_TYPES_OPTIONS.PLOT,
    display_value: 'Lote',
  },
];

export const POLYGONS_TYPES_LIST = [
  {
    value: POLYGONS_TYPES_OPTIONS.STATE,
    display_value: 'Estado',
  },
  {
    value: POLYGONS_TYPES_OPTIONS.DISTRICT,
    display_value: 'Distrito',
  },
  {
    value: POLYGONS_TYPES_OPTIONS.CITY,
    display_value: 'Ciudad',
  },
  {
    value: POLYGONS_TYPES_OPTIONS.NEIGHBORHOOD,
    display_value: 'Vecindario/Residencial',
  },
  {
    value: POLYGONS_TYPES_OPTIONS.TOWER,
    display_value: 'Torre',
  },
];

export const PROPERTY_SALE_STATUS: any = {
  created: 'Creada',
  take_photos: 'Toma de fotografías',
  published: 'Publicada',
  disabled: 'Inhabilitada',
  reserved: 'Reservada',
  on_title_deed: 'En escrituración',
  sold: 'Vendida',
  sold_external: 'Vendida fuera',
};

export const PROPERTY_MONTHS_LIST: Option[] = [
  {
    value: '6',
    label: '6 meses',
  },
  {
    value: '12',
    label: '12 meses',
  },
  {
    value: '24',
    label: '24 meses',
  },
  {
    value: '36',
    label: '+36 meses',
  },
];
