import React from 'react';
import { QuotaTransferDetails } from '../../models/paymentsModel';
import AdminListGroup from '../admin/AdminListGroup';

export interface Props {
  transactionDetail: QuotaTransferDetails;
}
const TransferDetailsCard: React.FC<Props> = ({transactionDetail}) => {
  const fieldMapper = [
    {
      "title": "Nombre del depositante",
      "value": transactionDetail.name,
    },
    {
      "title": "Documento de identificación",
      "value": transactionDetail.document,
    },
    {
      "title": "Referencia",
      "value": transactionDetail.identifier,
    },
    {
      "title": "Imagen del voucher",
      "value": transactionDetail.voucher_url
        ? <a target="_blank" href={transactionDetail.voucher_url}>Ver voucher</a> 
        : 'No disponible',
    },
  ]

  return (<AdminListGroup title={"Detalle de la transferencia"} items={fieldMapper}></AdminListGroup>);
};

export default TransferDetailsCard