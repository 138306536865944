import { Card, CardBody, ListGroup, Table } from 'reactstrap';
import { generateRandomString } from '../adapters/randomAdapter';

interface Props {
  title: string;
  cardHeader: string[];
  rows: RowItem[];
  striped?: boolean;
  hover?: boolean;
}

interface RowItem {
  specialRow?: boolean;
  coloredRow?: string;
  rows: string[];
}

const SimpleCardTable = ({
  cardHeader,
  rows,
  title,
  hover = false,
  striped = false,
}: Props) => {
  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">{title}</h6>
        </ListGroup>

        <div>
          <Table
            className="align-items-center table-flush hei"
            striped={striped}
            hover={hover}
          >
            <thead className="thead-light">
              <tr>
                {cardHeader.map((header) => (
                  <th key={header} scope="col">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((item) => (
                <tr
                  key={generateRandomString(6)}
                  className={item.specialRow ? item.coloredRow : ''}
                >
                  {item.rows.map((element) => (
                    <td key={element}>{element}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default SimpleCardTable;
