import React, { useCallback, useState } from 'react';
import { InvestorProjectModelDetail } from '../models/investors';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import MarketplaceVisibleModal from './MarketplaceVisibleModal';
import { isModelVisibleInMarketplace } from '../helpers/investorHelpers';
import useModalState from '../../../hooks/useModalState';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { updateInvestorProjectModelStatusEndpoint } from '../services/investorService';
import useAuth from '../../../hooks/useAuth';

export type Props = {
  model: InvestorProjectModelDetail;
  projectId: string;
  showButtons?: boolean;
};
const InvestorProjectModelSummaryCard: React.FC<Props> = ({ model, projectId, showButtons = false }) => {
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [showMarketplaceVisibleModal, toggleMarketplaceVisibleModal] = useModalState();
  const [errorAlert, setErrorAlert] = useState(null);
  const { callEndpoint } = useCallApiAndLoad();
  const isModelVisible = isModelVisibleInMarketplace(model.status)
  const changeMarketplaceVisible = async (modelId: string, modelStatus: string, projectId: string = '') => {
    const form = {
      status: isModelVisibleInMarketplace(modelStatus)
        ? 'disabled'
        : 'published'
    }
    const { status, data } = await callEndpoint(
      updateInvestorProjectModelStatusEndpoint(projectId, modelId, form, token)
    );
    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleMarketplaceVisibleModal();
    }
  }
  return (
    <Card>
      <CardBody>
        <Row className="align-items-center">
          <Col className="col-auto" >
            <img
              className='rounded'
              alt="..."
              width={175}
              height={100}
              src={
                model.cover && !model.cover.includes("null") && !model.cover.includes("undefined")
                  ? model.cover
                  : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
              }

            />
          </Col>
          <div className="col ml--2">
            <h4 className="mb-0">
              {model.name}
            </h4>
            <p className="text-sm text-muted mb-0">Amenidades: </p>
            <ul>
              {(model.amenities && model.amenities.length > 0) && model.amenities.map(amenity => (
                <li key={amenity}>{amenity}</li>
              ))}
            </ul>

          </div>
        </Row>
      </CardBody>
      {showButtons && (
        <CardFooter>
          <Button 
            color="info" size="sm" className='m-2'
            onClick={() => navigate(`/investors/projects/${projectId}/models/${model.id}`)}>
            <i className="fa fa-duotone fa-building mr-2" /> Ver detalle
          </Button>
          <Button
            color={isModelVisible ? 'warning' : 'success'} size="sm"
            onClick={() => toggleMarketplaceVisibleModal()}>
            {
              isModelVisible
                ? 'Ocultar de marketplace'
                : 'Publicar en marketplace'
            }
          </Button>
        </CardFooter>
      )}
      <MarketplaceVisibleModal
        parentEntityId={projectId}
        entityId={model.id}
        entityStatus={model.status}
        entityType='model'
        isOpen={showMarketplaceVisibleModal}
        onToggle={toggleMarketplaceVisibleModal}
        onConfirmation={changeMarketplaceVisible}
        bodyText={
          isModelVisible
            ? 'Con esta acción ocultarás el modelo y unidades de la marketplace'
            : 'Con esta acción publicarás el modelo y unidades disponibles en la marketplace'
        }
        confirmationText={"Entiendo, continuar"}
        abortText={"Cancelar"}
      />
    </Card>
  );
};

export default InvestorProjectModelSummaryCard;