import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  NavLink,
  Row,
  Table,
} from 'reactstrap';
import {
  FilePreviewTypes,
  checkOptions,
} from '../../../../constants/formConstants';
import usePropertySalesInfo from '../../hooks/usePropertySalesInfo';
import { Controller } from 'react-hook-form';
import { PropertySalesInfoModel } from '../../models/propertyModel';
import AdminUploadFile from '../../../../components/admin/AdminUploadFile';

interface Props {
  propertyId: string;
  propertySalesInfo?: PropertySalesInfoModel;
  setErrorAlert: Function;
  baseSalePrice: number;
}

const PropertySalesInfo = ({
  propertyId,
  setErrorAlert,
  baseSalePrice,
}: Props) => {
  const {
    // form
    control,
    register,
    errors,
    handleSubmit,
    handlePropertySalesAction,
    handleUploadExtractedLetter,
    handleUploadScriptures,
    handleUploadPurchaseAndSaleDoc,
    // loading
    propertySalesInfo,
    isLoading,
  } = usePropertySalesInfo({ propertyId, setErrorAlert, baseSalePrice });

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">
                Información de propiedad en venta
              </h6>
            </CardHeader>
            {/* {propertySalesInfo && ( */}
            <CardBody>
              <Form
                role="form"
                onSubmit={handleSubmit(handlePropertySalesAction)}
              >
                <CardBody>
                  {/* mortage */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="mortgage"
                        >
                          Hipoteca
                        </label>
                      </Col>
                      <Col md={6}>
                        {checkOptions.map((item: any) => (
                          <div
                            key={`mortgage-${item.name}`}
                            className="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              {...register('mortgage')}
                              className="custom-control-input"
                              type="radio"
                              value={item.value}
                              defaultChecked={
                                item.value === propertySalesInfo?.mortgage
                              }
                              id={`mortgage-${item.name}`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`mortgage-${item.name}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* free_of_lien */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="free_of_lien"
                        >
                          Libre de gravamen
                        </label>
                      </Col>
                      <Col md={6}>
                        {checkOptions.map((item: any) => (
                          <div
                            key={`free_of_lien-${item.name}`}
                            className="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              {...register('free_of_lien')}
                              className="custom-control-input"
                              type="radio"
                              value={item.value}
                              defaultChecked={
                                item.value === propertySalesInfo?.free_of_lien
                              }
                              id={`free_of_lien-${item.name}`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`free_of_lien-${item.name}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* has_extracted_letter */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="has_extracted_letter"
                        >
                          Tiene carta extractada
                        </label>
                      </Col>
                      <Col md={6}>
                        {checkOptions.map((item: any) => (
                          <div
                            key={`has_extracted_letter-${item.name}`}
                            className="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              {...register('has_extracted_letter')}
                              className="custom-control-input"
                              type="radio"
                              value={item.value}
                              defaultChecked={
                                item.value ===
                                propertySalesInfo?.has_extracted_letter
                              }
                              id={`has_extracted_letter-${item.name}`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`has_extracted_letter-${item.name}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* has_scriptures */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="has_scriptures"
                        >
                          Tiene escrituras
                        </label>
                      </Col>
                      <Col md={6}>
                        {checkOptions.map((item: any) => (
                          <div
                            key={`has_scriptures-${item.name}`}
                            className="custom-control custom-radio custom-control-inline"
                          >
                            <input
                              {...register('has_scriptures')}
                              className="custom-control-input"
                              type="radio"
                              value={item.value}
                              defaultChecked={
                                item.value === propertySalesInfo?.has_scriptures
                              }
                              id={`has_scriptures-${item.name}`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`has_scriptures-${item.name}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* return_of_investment */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="has_scriptures"
                        >
                          ROI (Retorno de inversión)
                        </label>
                      </Col>
                      <Col md={6}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              %
                            </span>
                            <Controller
                              name="return_of_investment"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  className={
                                    errors.return_of_investment?.message &&
                                    'is-invalid'
                                  }
                                  {...field}
                                  id="return_of_investment"
                                  type="number"
                                  min={0}
                                  step={0.1}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <h5 className="text-danger">
                          {errors.return_of_investment?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* internal_rate_return */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="has_scriptures"
                        >
                          IRR (Tasa interna de retorno)
                        </label>
                      </Col>
                      <Col md={6}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              %
                            </span>
                            <Controller
                              name="internal_rate_return"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  className={
                                    errors.internal_rate_return?.message &&
                                    'is-invalid'
                                  }
                                  {...field}
                                  id="internal_rate_return"
                                  type="number"
                                  min={0}
                                  step={0.1}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <h5 className="text-danger">
                          {errors.internal_rate_return?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* earnings_percentage */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="has_scriptures"
                        >
                          Porcentaje de ganacias
                        </label>
                      </Col>
                      <Col md={6}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              %
                            </span>
                            <Controller
                              name="earnings_percentage"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  className={
                                    errors.earnings_percentage?.message &&
                                    'is-invalid'
                                  }
                                  {...field}
                                  id="earnings_percentage"
                                  type="number"
                                  min={0}
                                  step={0.1}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <h5 className="text-danger">
                          {errors.earnings_percentage?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* reserve_price */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="reserve_price"
                        >
                          Precio de reserva
                        </label>
                      </Col>
                      <Col md={6}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <Controller
                              name="reserve_price"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  className={
                                    errors.reserve_price?.message &&
                                    'is-invalid'
                                  }
                                  {...field}
                                  id="reserve_price"
                                  type="number"
                                  min={0}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <h5 className="text-danger">
                          {errors.reserve_price?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* down_payment_price */}
                  <FormGroup>
                    <Row>
                      <Col md={6}>
                        <label
                          className="form-control-label"
                          htmlFor="down_payment_price"
                        >
                          Precio de prima
                        </label>
                      </Col>
                      <Col md={6}>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              $
                            </span>
                            <Controller
                              name="down_payment_price"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  className={
                                    errors.down_payment_price?.message &&
                                    'is-invalid'
                                  }
                                  {...field}
                                  id="down_payment_price"
                                  type="number"
                                  min={0}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <h5 className="text-danger">
                          {errors.down_payment_price?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button color={'primary'} disabled={isLoading}>
                    {propertySalesInfo?.id ? 'Actualizar' : 'Crear'}
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
            {/* )} */}
          </Card>
        </Col>
        <Col md="12">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">Detalle financiero</h6>
            </CardHeader>
            <CardBody>
              {propertySalesInfo?.finances &&
                propertySalesInfo?.finances?.length > 0 && (
                  <Table
                    className="align-items-center table-flush hei"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Concepto</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {propertySalesInfo.finances.map((finance) => (
                        <tr key={finance.text}>
                          <td>{finance.text}</td>
                          <td>{finance.value}</td>
                          <td>{finance.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h6 className="heading-small text-muted">Subida de archivos</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <div className="col ml--2">
                    {propertySalesInfo?.extracted_letter_url && (
                      <NavLink
                        href={propertySalesInfo?.extracted_letter_url}
                        target="_blank"
                      >
                        <i className={'fa fa-duotone fa-file-contract'} /> Ver
                        carta extractada existente
                      </NavLink>
                    )}
                  </div>
                  {propertySalesInfo?.has_extracted_letter && (
                    <AdminUploadFile
                      handleUpload={handleUploadExtractedLetter}
                      description={'Subir Carta Extractada'}
                      maxFiles={1}
                      dropZoneText="Formatos soportados: PDF máximo de 10MB por archivo."
                      filesPreviewType={FilePreviewTypes.COUNT}
                    />
                  )}
                </Col>
                <Col md="6">
                  <div className="col ml--2">
                    {propertySalesInfo?.scriptures_url && (
                      <NavLink
                        href={propertySalesInfo?.scriptures_url}
                        target="_blank"
                      >
                        <i className={'fa fa-duotone fa-file-contract'} /> Ver
                        escrituras existentes
                      </NavLink>
                    )}
                  </div>
                  {propertySalesInfo?.has_scriptures && (
                    <AdminUploadFile
                      handleUpload={handleUploadScriptures}
                      description={'Subir Escrituras'}
                      maxFiles={1}
                      dropZoneText="Formatos soportados: PDF máximo de 10MB por archivo."
                      filesPreviewType={FilePreviewTypes.COUNT}
                    />
                  )}
                </Col>
                <Col md="6">
                  <div className="col ml--2">
                    {propertySalesInfo?.purchase_and_sale_doc_url && (
                      <NavLink
                        href={propertySalesInfo?.purchase_and_sale_doc_url}
                        target="_blank"
                      >
                        <i className={'fa fa-duotone fa-file-contract'} /> Ver
                        acuerdo de compra venta existente
                      </NavLink>
                    )}
                  </div>
                  <AdminUploadFile
                    handleUpload={handleUploadPurchaseAndSaleDoc}
                    description={'Subir acuerdo compra venta'}
                    maxFiles={1}
                    dropZoneText="Formatos soportados: PDF máximo de 10MB por archivo."
                    filesPreviewType={FilePreviewTypes.COUNT}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PropertySalesInfo;
