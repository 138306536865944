import { Modal } from 'reactstrap';
import SimpleCardTable from '../SimpleCardTable';

interface Props {
  key?: string;
  isOpen: boolean;
  onToggle: () => void;
  title: string;
  cardHeader: string[];
  rows: RowItem[];
  striped?: boolean;
  hover?: boolean;
}

interface RowItem {
  specialRow?: boolean;
  coloredRow?: string;
  rows: string[];
}

const TableModal = ({
  cardHeader,
  isOpen,
  onToggle,
  rows,
  title,
  key,
  striped,
  hover,
}: Props) => {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <SimpleCardTable
        cardHeader={cardHeader}
        rows={rows}
        title={title}
        key={key}
        hover={hover}
        striped={striped}
      />
    </Modal>
  );
};

export default TableModal;
