import { SV } from '../../../constants/countryConstants';

/**
 * DO NOT USE THIS ON OTHER MODULES,
 * THIS IS JUST FOR POLYGONS USE
 * Improve this moving this settings with currency lib or backend return this formatted
 */
const SYMBOLS_BY_COUNTRY: any = {
  SV: '$',
  GT: 'Q',
}

export const formatMoney = (price: number, countryCode: string = SV) => {
  const symbol = SYMBOLS_BY_COUNTRY[countryCode] || ''
  const format = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const money = format.format(price);
  return `${symbol}${money}`;
};
