import React from 'react';
import AdminListGroup from '../../../components/admin/AdminListGroup';
import { InvestorProjectModelDetail } from '../models/investors';

export type Props = {
  model: InvestorProjectModelDetail;
  projectId: string;
};
const InvestorProjectModelActions: React.FC<Props> = ({model, projectId}) => {
  const fieldMapper = [
    {
      title: <><i className="ni ni-ruler-pencil mr-2" /> Editar modelo</>,
      value: "",
      href: `/investors/projects/${projectId}/models/${model.id}/edit`,
    },
    {
      title: <><i className="fa fa-solid fa-plus mr-2" /> Agregar nueva unidad al modelo</>,
      value: "",
      href: `/investors/models/${model.id}/model-units/create`,
    },
    {
      title: <><i className="fa fa-duotone fa-plus mr-2" /> Agregar unidades con CSV</>,
      value: "",
      href: `/investors/models/${model.id}/create-units-in-csv`,
    },
    {
      title: <><i className="fa fa-duotone fa-building mr-2" /> Administrar unidades del modelo</>,
      value: "",
      href: `/investors/models/${model.id}/model-units`,
    },
    {
      title: <><i className="fa fa-duotone fa-image mr-2" /> Agregar imágenes al modelo</>,
      value: "",
      href: `/investors/projects/${projectId}/models/${model.id}/upload-images`,
    },
  ]
  return (
    <AdminListGroup title={"Administrar proyecto"} items={fieldMapper} />
  );
};

export default InvestorProjectModelActions;