import { createSlice } from "@reduxjs/toolkit";
import { MapView } from '../../models/mapViewModel';

const initialState: MapView = {
    location: {
        lat: null,
        lng: null
    }
}

export const mapViewSlice = createSlice({
    name: 'mapView',
    initialState,
    reducers: {
        setLocationAction: (state, action) => {
            console.log(action);
            return {
                ...action.payload
            }
        },
    }
})

// provide actions
export const { setLocationAction } = mapViewSlice.actions;
// provide reducer
export default mapViewSlice.reducer