import { useCallback, useEffect, useState } from 'react';
import {
  AddressConfiguration,
  AddressLevelListsForView,
  OptionListSelected,
  PickedAddressApi,
} from '../models/addressModel';
import { listToSelectOptionAdapter } from '../adapters/listAdapter';
import { Option } from '../models/formModel';
import { buildMapAddressPolygonAdapter } from '../pages/admin_polygons/adapters/polygonsAdapter';
import {
  ExistingAssignedPolygon,
  PolygonsForMapBuilding,
} from '../pages/admin_polygons/models/polygonsModel';
import { Coordinate } from '../models/mapViewModel';
import {
  getAddressConfigurationEndpoint,
  getAddressDetailById,
  getAddressListsByLevelParentEndpoint,
  getAddressPreviousAssignedEndpoint,
} from '../services/addressService';
import useCallApiAndLoad from './useCallApiAndLoad';
import useAuth from './useAuth';

export interface Props {
  defaultCenter?: Coordinate;
  defaultPolygonId?: number;
}

const useAddressLisPicker = ({ defaultCenter, defaultPolygonId }: Props) => {
  const { callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const center: Coordinate =
    defaultCenter && defaultCenter.lat && defaultCenter.lng
      ? defaultCenter
      : {
          lat: 13.701402266923608,
          lng: -89.22444462777489,
        };
  // Map states
  const [mapCenter, setMapCenter] = useState<Coordinate>(center);
  const [mapZoom, setMapZoom] = useState<number>(15);
  const onLoadMap = useCallback((map: any) => {
    map.setZoom(10);
  }, []);

  // Address configuration given by backend by country
  const [addressConfiguration, setAddressConfiguration] =
    useState<AddressConfiguration | null>(null);

  // Address lists given by configurations.max_level or level_configurations length
  // This will create many select options configurated by levels and lists dynamic
  const [addressLevelListsForView, setAddressLevelListsForView] = useState<
    AddressLevelListsForView[]
  >([]);

  // state to manipulate the current option selected of many levels configurated
  // by addressConfiguration
  const [optionSelected, setOptionSelected] = useState<OptionListSelected>();

  // state to manage assignable polygons and show into map
  const [assignablePolygon, setAssignablePolygon] =
    useState<PolygonsForMapBuilding>();

  // state to manage previous assigned polygon to fill lists, map and draw polygon
  const [existingAssignedPolygon, setExistingAssignedPolygon] =
    useState<ExistingAssignedPolygon>();

  // state to store picked address to send to api and assign to project or property
  const [pickedAddressApi, setPickedAddressApi] = useState<PickedAddressApi>();

  const loadExistingAssignedPolygon = async () => {
    if (!defaultPolygonId) {
      console.log('loadExistingAssignedPolygon not found id to search');
      return null;
    }
    const { status, data } = await callEndpoint(
      getAddressPreviousAssignedEndpoint(token, defaultPolygonId),
    );
    if (status === 200) {
      const responseData = data.data;
      const adaptedPolygon = buildMapAddressPolygonAdapter(
        responseData,
        defaultCenter,
      );
      setExistingAssignedPolygon(responseData);
      setAssignablePolygon(adaptedPolygon);
    } else {
      console.error('Error obteniendo las configuraciones');
      setAddressConfiguration(null);
      return null;
    }
  };

  const loadAddressConfiguration = async () => {
    const { status, data } = await callEndpoint(
      getAddressConfigurationEndpoint(token),
    );

    if (status === 200) {
      setAddressConfiguration(data.data as AddressConfiguration);
      return data.data as AddressConfiguration;
    } else {
      console.error('Error obteniendo las configuraciones');
      setAddressConfiguration(null);
      return null;
    }
  };

  const loadNextLevelAddress = async (
    searchLevel: number,
    optionSelected: Option,
    assigneble: boolean = false,
  ) => {
    const previousLevel = searchLevel - 1 === 0 ? 1 : searchLevel - 1;
    const parentId = optionSelected.value;
    let list: Option[] = [];

    const { status, data } = await callEndpoint(
      getAddressListsByLevelParentEndpoint(
        token,
        searchLevel,
        parseInt(parentId),
      ),
    );

    if (status !== 200) {
      return;
    }

    list = listToSelectOptionAdapter(data.data, 'id', 'name');

    // if we change a value from previous lists e.g. level 1 and level 2 and 3 are populated,
    // clean lvl 2 and 3  levels to restart the filters
    const clearAndDisableInputsValues = addressLevelListsForView.map((item) => {
      if (previousLevel < item.level && item.list.length > 0) {
        item.list = [];
        item.selected = null;
      }
      return item;
    });
    // update state of addressLevelListsForView
    const updateAddressLevelWithNextValues = clearAndDisableInputsValues.map(
      (item) => {
        // assign new list to next level
        if (searchLevel === item.level) {
          item.list = list;
        }
        // set selected to previous level
        if (previousLevel === item.level) {
          item.selected = optionSelected;
        }
        return item;
      },
    );
    setAddressLevelListsForView(updateAddressLevelWithNextValues);
    // if polygon picked is assignable show detail on map
    if (assigneble) {
      const { status, data } = await callEndpoint(
        getAddressDetailById(token, parseInt(optionSelected.value)),
      );

      if (status !== 200) {
        return;
      }

      const adaptedPolygon = buildMapAddressPolygonAdapter(data.data);
      setAssignablePolygon(adaptedPolygon);
      setMapCenter(adaptedPolygon.center);
      setMapZoom(adaptedPolygon.zoom);
    } else {
      setAssignablePolygon(undefined);
    }
  };

  const loadInitLevelAddress = async () => {
    const { status, data } = await callEndpoint(
      getAddressListsByLevelParentEndpoint(token, 1),
    );

    if (status !== 200) {
      return;
    }

    return listToSelectOptionAdapter(data.data, 'id', 'name');
  };

  const handlePickAddress = () => {
    const pickedAddress = {
      polygon_selected: optionSelected?.selected_option?.value ?? '',
      polygon_selected_name: optionSelected?.selected_option?.label ?? '',
      center: mapCenter,
    };
    setPickedAddressApi(pickedAddress);
    // if new location is selected set existing polygon to null to hide card
    setExistingAssignedPolygon(undefined);
    return pickedAddress;
  };

  // Init use Effect
  useEffect(() => {
    Promise.all([
      loadAddressConfiguration(),
      loadInitLevelAddress(),
      loadExistingAssignedPolygon(),
    ])
      .then((results) => {
        const AddressConfiguration = results[0];
        const listOptionsLvl1 = results[1];
        const defaultPolygonInfo = results[2];
        if (!AddressConfiguration || !listOptionsLvl1) {
          console.error('Cannot retrieve admin configs');
          return;
        }
        const { max_level, level_configuration } = AddressConfiguration;
        const levelList: AddressLevelListsForView[] = [];
        for (let i = 0; i < max_level; i++) {
          const currentLevel = i + 1;
          const levelConfig = level_configuration.find(
            (l) => l.level === currentLevel,
          );
          levelList.push({
            level: currentLevel,
            level_config: levelConfig,
            selected: null,
            list: i + 1 === 1 ? listOptionsLvl1 : [],
          });
        }
        setAddressLevelListsForView(levelList);
      })
      .catch(console.error);
  }, []);

  // use effect to catch option selected on any levels form
  useEffect(() => {
    if (
      optionSelected &&
      optionSelected.search_level &&
      optionSelected.selected_option
    ) {
      loadNextLevelAddress(
        optionSelected.search_level,
        optionSelected.selected_option,
        optionSelected.assignable,
      ).catch(console.error);
    }
  }, [optionSelected]);

  return {
    addressConfiguration,
    loadNextLevelAddress,
    addressLevelListsForView,
    setOptionSelected,
    assignablePolygon,
    mapCenter,
    mapZoom,
    setMapCenter,
    onLoadMap,
    pickedAddressApi,
    handlePickAddress,
    existingAssignedPolygon,
  };
};

export default useAddressLisPicker;
