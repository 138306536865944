import React from 'react';
import { Badge } from 'reactstrap';

export enum LeadMarketplaceType {
  ON_RENT = 'on_rent',
  ON_SALE = 'on_sale',
}

interface Props {
  marketplace_type: string;
  customClassname?: string;
}

const VisitMarketplaceTypeBadge = ({
  marketplace_type,
  customClassname = '',
}: Props) => {
  switch (marketplace_type) {
    case LeadMarketplaceType.ON_RENT:
      return (
        <Badge color="primary" className={customClassname}>
          Alquiler
        </Badge>
      );
    case LeadMarketplaceType.ON_SALE:
      return (
        <Badge color="success" className={customClassname}>
          Venta
        </Badge>
      );
    default:
      return <></>;
  }
};

export default VisitMarketplaceTypeBadge;
