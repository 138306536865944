
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import TimelineHeader from '../headers/TimelineHeader';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

interface Props {
  name: string;
  parentName: string;
  header?: any;
  footer?: any;
  children?: any;
  parentUri?: string;
  globalAlertOptions?: {
    success: boolean;
    warning: boolean;
    onConfirm: () => void;
    title: string;
    text?: string;
  }
}

const AdminPage = (props: Props) => {
  const navigate = useNavigate();
  const onBackInHistory = useCallback(() => {
    navigate(-1)
  }, [navigate]);
  const componentHeader = (
    <>
      <TimelineHeader name={props.name} parentName={props.parentName} parentUri={props.parentUri} onHistoryBack={onBackInHistory} />
    </>
  );

  const alertDefaultProperties = {
    success: false,
    warning: false,
    onConfirm: () => {},
    title: "Error",
    text: "",
  }

  const globalAlertProps = props.globalAlertOptions ?? alertDefaultProperties

  const componentErrorAlert = (
    <SweetAlert {...globalAlertProps}>{globalAlertProps?.text || ""}</SweetAlert>
  );

  const componentCard = (
    <Card>
      <CardHeader className="border-0">
        {props.header}
      </CardHeader>
      <CardBody>
        {props.globalAlertOptions?.text && componentErrorAlert}
        {props.children}
      </CardBody>
      <CardFooter className="py-4">
        {props.footer}
      </CardFooter>
    </Card>
  );



  return (
    <>
      {componentHeader}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            {componentCard}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AdminPage;