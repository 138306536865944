import { GT_FLAG, SV, SV_FLAG } from '../constants/countryConstants';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { REALMS } from '../constants/profileConstants';
import { getPhoneConfig } from '../helpers/countryHelpers';
import { PhoneCountryConfig } from '../models/countryModel';

export interface CountryStorage {
  code: string;
  flag: string;
}

export const useUserCountry = () => {
  const navigate = useNavigate();
  const { getUserCountries } = useAuth();

  const getPhoneCountryConfiguration = (): PhoneCountryConfig | null => {
    const country = getCurrentCountry();
    if (!country) {
      return null;
    }
    const { code } = country;
    return getPhoneConfig(code) ?? null;
  };

  const pickFlag = (countryCode: string) => {
    return countryCode === SV ? SV_FLAG : GT_FLAG;
  };

  const setCountry = (countryCode: string) => {
    const storage: CountryStorage = {
      code: countryCode,
      flag: pickFlag(countryCode),
    };
    const countryJson = JSON.stringify(storage);
    localStorage.setItem('c', countryJson);
    navigate(0);
  };

  const getCurrentCountry = (): CountryStorage | null => {
    const currentCountry = localStorage.getItem('c') || null;
    if (!currentCountry) {
      return null;
    }
    const { code: countryCode } = JSON.parse(currentCountry);
    return {
      code: countryCode,
      flag: pickFlag(countryCode),
    };
  };

  const getCountries = (): CountryStorage[] => {
    const countries = getUserCountries();
    const mapCountries: CountryStorage[] = countries.map(
      (countryCode: string) => ({
        code: countryCode,
        flag: pickFlag(countryCode),
      }),
    );
    return mapCountries;
  };

  /**
   * Function to build url and service extra path
   * @param userRealm - This is used if realm is proper we add a /p on path for authorization
   * @returns
   */
  const getServiceUrlCountry = (
    userRealm: string = REALMS.INTERNAL,
  ): string => {
    const currentCountry = getCurrentCountry();
    const domainSv = process.env.REACT_APP_API_URL || '';
    const domainGt = process.env.REACT_APP_API_URL_GT || '';
    if (!currentCountry) {
      console.error('CurrenctCountry not defined');
      return '';
    }
    let domain = currentCountry.code === SV ? domainSv : domainGt;
    return userRealm === REALMS.PROPER ? `${domain}/p` : domain;
  };

  return {
    setCountry,
    getCurrentCountry,
    getCountries,
    getServiceUrlCountry,
    getPhoneCountryConfiguration
  };
};

export default useUserCountry;
