import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import { getTypePropertiesEndpoint } from '../properties/services/propertyService';
import useAdminEntity from '../../hooks/useAdminEntity';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import { getResidentialsEndpoint } from './services/residentialService';
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';

const ResidentialList: React.FC = () => {

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar)

  const getResidentials = useCallback(
    () => getResidentialsEndpoint(gridSearchBar.query, token),
    [gridSearchBar.query, token]
  );
  const [residentials] = useAdminEntity<any[]>(getResidentials, console.error);

  const getPropertyTypes = useCallback(() => getTypePropertiesEndpoint(), []);
  const [propertyTypes] = useAdminEntity<any[]>(getPropertyTypes, console.error);

  const { isLoading } = useCallApiAndLoad();

  const propertyFilters = [
    {
      label: 'Tipo de propiedad',
      value: 'propertyTypeId',
      apiParamName: 'type_id',
      inputType: 'select',
      inputTypeValues: propertyTypes?.map((propertyType: any) => ({
        label: propertyType.type,
        value: propertyType.id,
      })) || [],
      isUnique: false,
      placeholder: 'Seleccione...'
    },
  ]
  const componentHeader = (
    <GridSearchBar
      filtersList={propertyFilters}
    />
  )

  const actions = (residential: any) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={e => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem href={`/residentials/${residential.id}/edit`}>
          Editar residencial
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const pageName = "Residencias"
  const pageParentName = "Propiedades"

  if (isLoading || !propertyTypes)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    )

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      header={componentHeader}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">Descripción</th>
          <th scope="col">Calle</th>
          <th scope="col" />
        </tr>
        </thead>
        <tbody>
        {(residentials && residentials.length > 0) && (
          residentials.map((res, key) => (
            <tr key={key}>
              <td>{res.name}</td>
              <td>{res.description}</td>
              <td>{res.street}</td>
              <td>
                {actions(res)}
              </td>
            </tr>
          ))
        )}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default ResidentialList;