import axios from 'axios';
import {
  CreatePropertySalesInfoForm,
  UpdatePropertySalesPricesForm,
  UpdatePropertySalesStateForm,
} from '../models/propertyModel';

const loadAbort = () => {
  return new AbortController();
};

export const createPropertySalesInfoEndpoint = (
  form: CreatePropertySalesInfoForm,
  property_id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/rs/v1/admin/properties/${property_id}/sales`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertySalesInfoEndpoint = (
  form: CreatePropertySalesInfoForm,
  property_id: string,
  property_sales_id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${property_id}/sales/${property_sales_id}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertySalesInfoEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/sales`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropertyPricesStatusSalesInfoEndpoint = (
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/sales-prices`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadPropertySalesFileEndpoint = (
  form: any,
  property_id: string,
  property_sales_id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${property_id}/sales/${property_sales_id}/files`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertySalesPricesAvailabilityEndpoint = (
  form: UpdatePropertySalesPricesForm | UpdatePropertySalesStateForm,
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const updatePropertySalesAvailabilityEndpoint = (
  form: UpdatePropertySalesStateForm,
  propertyId: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${propertyId}/sales/change-status`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const uploadPropertySalesPurchaseAndSaleDocEndpoint = (
  form: any,
  property_id: string,
  property_sales_id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${property_id}/${property_sales_id}/purchase-and-sale-docs`,
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const markPropertyAsSoldEndpoint = (
  property_id: string,
  token: string,
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'put',
        url: `${urlService}/rs/v1/admin/properties/${property_id}/update-to-sold`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};