import { AuthUser } from '../models/authUserModel';
import { AppStore } from '../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { loginAction, logoutAction } from '../redux/features/auth';
import { useCallback } from 'react';
import useCrypt from './useCrypt';
import { AuthTypes } from '../types/auth.type';

const oldVersion = '1.0.1';

const useAuth = () => {
  const user = useSelector((store: AppStore) => store.auth);
  const dispatch = useDispatch();
  const { encrypt, decrypt } = useCrypt();

  const getAuth = useCallback(() => {
    const auth = localStorage.getItem('auth') || '{}';
    const authParsed = JSON.parse(auth);
    const version = authParsed.version ?? null;
    if (!version || version === oldVersion) {
      return null;
    }
    const decryptedUser = decrypt(authParsed.key);
    return decryptedUser;
  }, []);

  const setAuth = useCallback(
    (user: AuthUser) => {
      dispatch(loginAction(user)); // set in redux login response
      const authEncrypted = encrypt(user); // encrypt login response
      // save in storage encrypted user login response and a date
      localStorage.setItem(
        'auth',
        JSON.stringify({
          key: authEncrypted,
          version: '1.0.2',
          date: new Date().toDateString(),
        }),
      );
    },
    [dispatch],
  );

  const unsetAuth = useCallback(() => {
    localStorage.clear();
    dispatch(logoutAction({}));
  }, [dispatch]);

  const isLoggedIn = useCallback(() => {
    return user && user.token && user.realm;
  }, [user]);

  const getAuthToken = useCallback(() => {
    return isLoggedIn() ? user.token : '';
  }, [isLoggedIn, user.token]);

  const getUserRealm = useCallback(() => {
    return isLoggedIn() ? (user.realm as string) : '';
  }, [isLoggedIn, user]);

  const getUserCountries = useCallback(() => {
    return isLoggedIn() && user.assigned_countries
      ? user.assigned_countries
      : [];
  }, [isLoggedIn, user]);

  const getUserRoles = useCallback(() => {
    return isLoggedIn() && user.assigned_roles ? user.assigned_roles : [];
  }, [isLoggedIn, user]);

  const getUserPermissions = useCallback((): string[] => {
    return isLoggedIn() && user.assigned_permissions
      ? user.assigned_permissions
      : [];
  }, [isLoggedIn, user]);

  const isPermissionAvailable = useCallback(
    (checkPermission: AuthTypes): boolean => {
      const permissions = getUserPermissions();
      return (
        permissions.includes(checkPermission) || permissions.includes('ADMIN')
      );
    },
    [getUserPermissions],
  );

  return {
    getAuth,
    setAuth,
    unsetAuth,
    getAuthToken,
    isLoggedIn,
    getUserRealm,
    getUserCountries,
    getUserRoles,
    getUserPermissions,
    isPermissionAvailable,
  };
};

export default useAuth;
