import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import TransferDetailsCard from '../../components/payments/TransferDetailsCard';
import useAdminEntity from '../../hooks/useAdminEntity';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  approveWireTransferEndpoint,
  rejectWireTransferEndpoint,
} from '../contract_payments/services/contractPaymentsService';
import { InvestorQuotaDetailCard } from './components/InvestorQuotaDetailCard';
import {
  InvestQuotaDetails,
  InvestQuotaTransferDetails,
  QuotaPreviewCheckout,
} from './models/investors';
import {
  attachVoucherForPaidTransaction,
  getInvestorQuotaDetailEndpoint,
  getQuotaPreviewCheckoutEndpoint,
  markTransactionForPaid,
  uploadInvestorProjectResourcesEndpoint,
} from './services/investorService';
import ExternalQuotaPaymentForm from './components/ExternalQuotaPaymentForm';
import useUserCountry from '../../hooks/useUserCountry';
import CopyToClipboard from 'react-copy-to-clipboard';
import useModalState from '../../hooks/useModalState';
import { anulatePaymentEndpoint } from '../../services/paymentService';
import BasicModal from '../../components/modals/BasicModal';
import CheckoutQuotaPreview from '../../components/payments/CheckoutQuotaPreview';

interface rowItem {
  specialRow?: boolean;
  coloredRow?: '' | 'table-info' | 'table-danger';
  rows: string[];
}

const InvestorQuotaDetail = () => {
  const navigate = useNavigate();
  const { id: transactionId = '', transactionDetailId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = 'Detalle de cuota';
  const pageParentName = 'Detalle de Alquiler';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const { getCurrentCountry } = useUserCountry();
  const currenUserCountryCode = getCurrentCountry()?.code.toLowerCase() || '';
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const urlCheckout = `${process.env.REACT_APP_PROPI_WEB}/app/${currenUserCountryCode}/l/ld/payment-detail/${transactionId}/${transactionDetailId}`;
  const [showAnulatePayment, toggleAnulatePaymentModal] = useModalState();

  const [rowItem, setRowItem] = useState<rowItem[]>([]);

  const getTransactionDetail = useCallback(
    () =>
      getInvestorQuotaDetailEndpoint(transactionId, transactionDetailId, token),
    [transactionId, token, transactionDetailId],
  );
  const [quotaDetails] = useAdminEntity<InvestQuotaDetails>(
    getTransactionDetail,
    setErrorAlert,
  );

  const loadQuotaPreviewCheckout = useCallback(async () => {
    const { status, data } = await callEndpoint(
      getQuotaPreviewCheckoutEndpoint(
        transactionId,
        transactionDetailId,
        token,
      ),
    );

    const response = fetchResponseAdapter(data);

    if (status === 200) {
      const result = [];

      const items: rowItem[] = [];
      const cardSection: QuotaPreviewCheckout = response.data[0] ?? null;
      const wireSection: QuotaPreviewCheckout = response.data[1] ?? null;

      if (cardSection) {
        items.push({
          coloredRow: 'table-info',
          specialRow: true,
          rows: ['Tarjeta', ''],
        });
        cardSection.detail.forEach((item) => {
          items.push({
            coloredRow: item.is_total ? 'table-danger' : '',
            specialRow: item.is_total,
            rows: [item.label, item.value],
          });
        });
      }

      if (wireSection) {
        items.push({
          coloredRow: 'table-info',
          specialRow: true,
          rows: ['Transferencia', ''],
        });
        result.push(['Transferencia', '']);
        wireSection.detail.forEach((item) => {
          items.push({
            coloredRow: item.is_total ? 'table-danger' : '',
            specialRow: item.is_total,
            rows: [item.label, item.value],
          });
        });
      }

      setRowItem(items);
    }
  }, [callEndpoint, token, transactionId, transactionDetailId]);

  const acceptWireTransfer = useCallback(
    async (transfer: InvestQuotaTransferDetails) => {
      const { status, data } = await callEndpoint(
        approveWireTransferEndpoint(transfer.id, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 200) {
        navigate(0);
      } else {
        setErrorAlert(response.message);
      }
    },
    [callEndpoint, navigate, token],
  );

  const rejectWireTransfer = useCallback(
    async (transfer: InvestQuotaTransferDetails) => {
      const { status, data } = await callEndpoint(
        rejectWireTransferEndpoint(transfer.id, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 200) {
        navigate(0);
      } else {
        setErrorAlert(response.message);
      }
    },
    [callEndpoint, navigate, token],
  );

  const attachPaidTransactionInfo = useCallback(
    async (form: FormData, errorRepository: Function) => {
      const { status: attachVoucherStatus } = await callEndpoint(
        attachVoucherForPaidTransaction(transactionDetailId, form, token),
      );
      if (attachVoucherStatus === 201) {
        const { status: markAsPaidStatus } = await callEndpoint(
          markTransactionForPaid(transactionDetailId, token),
        );
        if (markAsPaidStatus === 200) navigate(0);
        else errorRepository('No se pudo marcar la transacción como pagada.');
      } else {
        errorRepository('No se pudo marcar la transacción como pagada.');
      }
    },
    [callEndpoint, navigate, token, transactionDetailId],
  );

  const anulatePayment = useCallback(async (transactionDetailId: string) => {
    const { status, data } = await callEndpoint(
      anulatePaymentEndpoint(transactionDetailId, token),
    );

    if (status === 200) {
      navigate(0);
    } else {
      const response = fetchResponseAdapter(data);
      setErrorAlert(response.message);
    }
    toggleAnulatePaymentModal();
  }, []);

  useEffect(() => {
    loadQuotaPreviewCheckout().catch(console.error);
  }, [loadQuotaPreviewCheckout]);

  if (!quotaDetails || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  const needApproval =
    quotaDetails.status === 'review' &&
    quotaDetails.payment_method === 'wire_transfer';

  const canCreateProofIncome = quotaDetails.status === 'paid';

  const isCurrentQuota = quotaDetails.status === 'current';

  const isAvailableAnulation =
    quotaDetails.allow_anulation &&
    quotaDetails.payment_method === 'card' &&
    quotaDetails.status === 'paid' &&
    quotaDetails.payment_info;

  const getFooter = (
    needApproval: boolean,
    canCreateProofIncome: boolean,
    isCurrentQuota: boolean,
  ) => {
    if (needApproval) {
      return (
        <>
          <Button
            color="primary"
            onClick={() => {
              if (
                quotaDetails &&
                quotaDetails.transfer_detail &&
                quotaDetails.transfer_detail.length > 0
              )
                acceptWireTransfer(quotaDetails.transfer_detail[0]).catch((e) =>
                  setErrorAlert(e.toString),
                );
            }}
          >
            Aceptar transferencia
          </Button>
          <Button
            color="warning"
            onClick={() => {
              if (
                quotaDetails &&
                quotaDetails.transfer_detail &&
                quotaDetails.transfer_detail.length > 0
              )
                rejectWireTransfer(quotaDetails.transfer_detail[0]).catch((e) =>
                  setErrorAlert(e.toString),
                );
            }}
          >
            Rechazar transferencia
          </Button>
        </>
      );
    }
    if (canCreateProofIncome) {
      return (
        <>
          <Button
            color="default"
            onClick={() =>
              navigate(
                `/accounting/proof-income/pre-sales/${transactionDetailId}/create`,
              )
            }
          >
            Generar comprobante de ingreso
          </Button>
          {isAvailableAnulation && (
            <Button color="warning" onClick={() => toggleAnulatePaymentModal()}>
              Anular pago
            </Button>
          )}
        </>
      );
    }

    if (isCurrentQuota) {
      return (
        <>
          <CopyToClipboard
            text={urlCheckout}
            onCopy={() => setCheckoutUrl(urlCheckout)}
          >
            <Button color="primary" id="copy-button">
              <i className="ni ni-single-copy-04" />
              <span>Copiar link de pago</span>
            </Button>
          </CopyToClipboard>
          <UncontrolledTooltip delay={0} target="copy-button">
            {checkoutUrl === urlCheckout ? 'Copiado' : 'Hacer clic para copiar'}
          </UncontrolledTooltip>
          <CheckoutQuotaPreview
            cardHeader={['Detalle', 'Valor']}
            rows={rowItem}
            title={'Preview de cuota'}
          />
        </>
      );
    }
  };

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={getFooter(needApproval, canCreateProofIncome, isCurrentQuota)}
    >
      <Row>
        <Col>
          <InvestorQuotaDetailCard quotaDetails={quotaDetails} />
        </Col>

        {quotaDetails &&
          !['paid', 'disabled', 'review'].includes(quotaDetails.status) && (
            <Col>
              <Card>
                <CardBody>
                  <ExternalQuotaPaymentForm
                    handleForm={attachPaidTransactionInfo}
                  />
                </CardBody>
              </Card>
            </Col>
          )}

        {needApproval &&
          quotaDetails &&
          quotaDetails.transfer_detail &&
          quotaDetails.transfer_detail.length > 0 && (
            <Col>
              <TransferDetailsCard
                transactionDetail={quotaDetails.transfer_detail[0]}
              />
            </Col>
          )}
      </Row>
      <BasicModal
        abortText={'No, seguir revisando...'}
        bodyText={'Estas seguro que deseas anular el pago?'}
        confirmationText={'Anular pago'}
        titleText={'Anulación de pago'}
        isOpen={showAnulatePayment}
        onConfirmation={anulatePayment}
        onToggle={toggleAnulatePaymentModal}
        payload={transactionDetailId}
      />
    </AdminPage>
  );
};

export default InvestorQuotaDetail;
