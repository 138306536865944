import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  PropertySalesProps,
  UpdatePropertySalesPricesForm,
  UpdatePropertySalesStateForm,
} from '../models/propertyModel';
import {
  markPropertyAsSoldEndpoint,
  updatePropertySalesAvailabilityEndpoint,
  updatePropertySalesPricesAvailabilityEndpoint,
} from '../services/propertySalesService';

const usePropertySalesPrices = (props: PropertySalesProps) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<UpdatePropertySalesPricesForm>({
    resolver: yupResolver(
      yup
        .object({
          sale_price: yup
            .number()
            .min(0)
            .required('Precio de venta es requerido')
            .typeError('Precio de venta es requerido en formato numérico'),
          sale_min_price: yup
            .number()
            .max(
              yup.ref('sale_price'),
              'Precio de venta mínimo tiene que ser menor o igual al precio de venta',
            )
            .required('Precio mínimo de venta es requerido'),
        })
        .required(),
    ),
  });

  const updateMarketplaceStatus = async (
    property_id: string,
    form: UpdatePropertySalesStateForm,
  ) => {
    const { status } = await callEndpoint(
      updatePropertySalesAvailabilityEndpoint(form, property_id, token),
    );
    if (status !== 200) {
      alert('Error');
      return;
    }
    navigate(0);
  };

  const updateMarketplacePrices = async (
    form: UpdatePropertySalesPricesForm,
  ) => {
    const { status } = await callEndpoint(
      updatePropertySalesPricesAvailabilityEndpoint(
        form,
        props.property_id,
        token,
      ),
    );
    if (status !== 200) {
      alert('Error');
      return;
    }
    navigate(0);
  };

  const markPropertyAsSold = async (
    propertyId: string,
  ) => {
    //
    const { status } = await callEndpoint(
      markPropertyAsSoldEndpoint(
        propertyId,
        token,
      ),
    );
    if (status !== 200) {
      alert('Error');
      return;
    }
    navigate(0);
  };

  useEffect(() => {
    reset({
      sale_price: props.property_sale_price_value,
      sale_min_price: props.property_sale_min_price_value,
    });
  }, []);

  return {
    control,
    register,
    errors,
    handleSubmit,
    isLoading,
    updateMarketplaceStatus,
    updateMarketplacePrices,
    markPropertyAsSold,
  };
};

export default usePropertySalesPrices;
