import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//  components
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import TimelineHeader from '../../../components/headers/TimelineHeader';
import ProfileCard from '../../../components/ProfileCard';
// form
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';
import useUserCountry from '../../../hooks/useUserCountry';
// models and services
import { Option } from '../../../models/formModel';
import { CreateVisitForm } from '../../properties/models/propertyModel';
import {
  CreateVisitInvestor,
  ProjectVisitList,
  UpdateVisitInvestor,
} from '../models/visitsInvestorModel';
import {
  createProjectVisitsEndpoint,
  deleteProjectVisitsEndpoint,
  getAvailableTimeProjectVisitsEndpoint,
  getProjectVisitsEndpoint,
  updateProjectVisitsEndpoint,
} from '../services/visitsInvestorService';
import { getLandlordByMailEndpoint } from '../../../services/landlordService';
// calendar libs and dependencies
import { Calendar, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
// helpers and utils
import { getDateVars } from '../../../helpers/dateHelpers';
import { VISIT_STATUS } from '../../../constants/visitsConstants';
import {
  formatStandarCalendarDate,
  getHoursMinutesFromMinutes,
  getStringDate,
} from '../../../adapters/dateAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import {
  buildCalendarVisitInvestorData,
  buildCalendarVisitInvestorEvent,
  getVisitStatusAdapter,
} from '../adapters/visitsInvestorAdapter';

let calendar: any;

const InvestorProjectVisits = () => {
  const navigate = useNavigate();
  const { id: projectId = '' } = useParams();
  const { callEndpoint } = useCallApiAndLoad();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  // tenant search states
  const [landlordEmail, setLandlordEmail] = useState<string>('');
  const [landlordFound, setLandlordFound] = useState<any>(null);
  const [formError, setFormError] = useState<string>('');
  // modals
  const [modalAdd, setModalAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalDoneCancelled, setModalDoneCancelled] = useState(false);
  // date states
  const [startDate, setStartDate] = useState('');
  const [oldStartDate, setOldStartDate] = useState('');
  const [creationStartDate, setCreationStartDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<Option[]>([]);
  // event states
  const [event, setEvent] = useState<any>(null);
  const [oldEvent, setOldEvent] = useState<any>(null);
  const [changeEvent, setChangeEvent] = useState<any>(null);

  const [calendarView, setCalendarView] = useState<string>('dayGridMonth');

  const calendarRef = useRef<any>();

  const [showChangeProperForm, toggleChangeProperForm] =
    useState<boolean>(false);
  const [updateProperId, setUpdateProperId] = useState<string>('');

  const { getCurrentCountry } = useUserCountry();
  const currenUserCountryCode = getCurrentCountry()?.code || '';

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    unregister,
  } = useForm<CreateVisitInvestor>({
    resolver: yupResolver(
      yup.object({
        start_time: yup.number().required('Hora de visita es requerida'),
      }),
    ),
  });

  useEffect(() => {
    createCalendar();
  }, []);

  const createCalendar = () => {
    if (!calendarRef || !calendarRef.current) {
      return;
    }
    calendar = new Calendar(calendarRef.current, {
      showNonCurrentDates: false,
      plugins: [interaction, dayGridPlugin],
      initialView: calendarView,
      views: {
        dayGridWeek: {
          duration: { days: 3 },
        },
      },
      buttonText: {
        today: 'Hoy',
      },
      selectable: true,
      editable: true,
      events: function (info, successCallback, failureCallback) {
        const {
          day: startDay,
          month: startMonth,
          year: startYear,
        } = getDateVars(info.start);
        const {
          day: endDay,
          month: endMonth,
          year: endYear,
        } = getDateVars(info.end);
        // this is triggered when calendar needs new data set
        // https://fullcalendar.io/docs/events-function
        callEndpoint(
          getProjectVisitsEndpoint(
            projectId,
            `${startYear}-${startMonth}-${startDay}`,
            `${endYear}-${endMonth}-${endDay}`,
            token,
          ),
        )
          .then((response) => {
            const { status, data } = response;
            if (status !== 200) {
              failureCallback(new Error('Error al cargar información'));
            } else {
              const responseAdapted = fetchResponseAdapter(data);
              successCallback(
                buildCalendarVisitInvestorData(responseAdapted.data),
              );
            }
          })
          .catch((e) => {
            failureCallback(e);
          });
      },
      // Add new event
      select: (info: any) => {
        setCreationStartDate(info.start);
        setModalAdd(true);
        setIsEdit(false);
        setStartDate(info.startStr);
        loadHours(info.startStr);
        setLandlordFound(null);
        // for create edit vars should be reset
        setEvent(null);
        setOldEvent(null);
        setOldStartDate('');
        setChangeEvent(null);
        // clear form properties
        unregister('start_time');
        // clear generic modal error
        setFormError('');
      },
      // View calendar event
      eventClick: ({ event }: EventClickArg) => {
        setEvent(event);
        if (
          event.extendedProps.status === VISIT_STATUS.DELETED ||
          event.extendedProps.status === VISIT_STATUS.DONE
        ) {
          setModalDoneCancelled(true);
        } else {
          setModalView(true);
        }
      },
      // Edit calendar event action
      eventChange: (eventChange: EventChangeArg) => {
        const { event, oldEvent } = eventChange;
        if (
          event.extendedProps.status === VISIT_STATUS.DELETED ||
          event.extendedProps.status === VISIT_STATUS.DONE
        ) {
          // events from change status does not need to show modal reschedule
          // eventChange.revert();
          return;
        }
        setModalAdd(true);
        setIsEdit(true);
        setStartDate(event.startStr);
        loadHours(getStringDate(event.start));
        // assign edit vars
        setLandlordFound({
          avatar: event.extendedProps.landlordInfo.avatar,
          name: event.extendedProps.landlordInfo.name,
          phone: '',
        });
        // for edit we should set event data
        setEvent(event);
        setOldEvent(oldEvent);
        setOldStartDate(oldEvent.startStr);
        setChangeEvent(eventChange);
        // clear form properties
        unregister('start_time');
        // clear generic modal error
        setFormError('');
      },
    });
    calendar.render();
  };

  // utils
  const closeAddEditModal = () => {
    setModalAdd(false);
    changeEvent !== null && changeEvent.revert();
  };

  const changeCalendarView = (view: string) => {
    calendar.changeView(view);
    setCalendarView(view);
  };

  // Load functions
  const loadHours = async (startDate: string) => {
    if (!startDate) {
      setHours([]);
      return;
    }
    const { status, data } = await callEndpoint(
      getAvailableTimeProjectVisitsEndpoint(projectId, startDate, token),
    );
    if (status === 200) {
      const hoursResponse = fetchResponseAdapter(data);
      setHours(
        listToSelectOptionAdapter(
          hoursResponse.data,
          'minutes',
          'time',
          true,
          'is_blocked',
        ),
      );
    }
  };

  // Handlers
  const handleSearchLandlord = async () => {
    if (!landlordEmail) {
      setFormError('Debes ingresar un correo valido');
      setLandlordFound(null);
      return;
    }
    setFormError('');
    const { status, data } = await callEndpoint(
      getLandlordByMailEndpoint(landlordEmail.trim().toLowerCase(), token),
    );
    if (status === 200) {
      const landlordResponse = fetchResponseAdapter(data);
      setLandlordFound(landlordResponse.data);
      return;
    }
    setFormError('Inquilino no encontrado');
    setLandlordFound(null);
  };

  // Actions
  const doCreateVisit = async (form: CreateVisitInvestor) => {
    setFormError('');
    if (!landlordFound || !landlordFound.landlord_id) {
      setFormError('Información de inquilino es requerida');
      return;
    }
    if (!startDate) {
      setFormError('No fue posible seleccionar el día para la visita');
      return;
    }
    const formApi: CreateVisitInvestor = {
      project_id: projectId,
      landlord_id: landlordFound.landlord_id,
      date: startDate,
      start_time: form.start_time,
      visit_type: 'presential',
    };
    const { status, data } = await callEndpoint(
      createProjectVisitsEndpoint(formApi, token),
    );
    if (status === 201) {
      // add visit manually to calendar
      const { hours, minutes } = getHoursMinutesFromMinutes(form.start_time);
      const [yearString, monthString, dayString] = startDate.split('-');
      const dateTimeVars: any = {
        day: parseInt(dayString),
        month: parseInt(monthString),
        year: parseInt(yearString),
        hours,
        minutes,
      };
      const response = fetchResponseAdapter(data);
      const visit: ProjectVisitList = {
        id: response.data.id,
        project_id: projectId,
        status: VISIT_STATUS.SCHEDULED,
        visitDateTime: dateTimeVars,
        landlordInfo: {
          id: landlordFound.tenant_id,
          name: landlordFound.name,
          avatar: landlordFound.avatar,
        },
      };
      calendar.addEvent(buildCalendarVisitInvestorEvent(visit, dateTimeVars));
      setModalAdd(false);
      return;
    }
    setFormError(`(${status}) - No fue posible agendar la visita`);
  };

  const doRescheduleVisit = async (form: CreateVisitInvestor) => {
    setFormError('');
    const { id: visitId, start } = event;
    if (!visitId || !start) {
      setFormError(
        'No fue posible seleccionar el día para la reangendar visita',
      );
    }
    const formApi: UpdateVisitInvestor = {
      date: getStringDate(start),
      start_time: form.start_time,
      project_id: projectId,
      visit_type: 'presential',
    };
    const { status } = await callEndpoint(
      updateProjectVisitsEndpoint(visitId, formApi, token),
    );
    if (status === 200) {
      setModalAdd(false);
      return;
    }
    setFormError(`(${status}) - No fue posible reagendar la visita`);
  };

  const deleteVisit = async (event: any) => {
    if (!event) return;
    const { status } = await callEndpoint(
      deleteProjectVisitsEndpoint(event.id, token),
    );
    if (status === 200) {
      setModalView(false);
      // set event status cancelled and paint it as red card
      event.setExtendedProp('status', VISIT_STATUS.DELETED);
      event.setProp('classNames', 'bg-red text-white');
    }
  };

  const completeVisit = async (event: any) => {
    setModalView(false);
    event.setExtendedProp('status', VISIT_STATUS.DONE);
    event.setProp('classNames', 'bg-green text-white');
    // if (!event) return;
    // const { status } = await callEndpoint(
    //   deleteProjectVisitsEndpoint(event.id, token),
    // );
    // if (status === 200) {
    //   setModalView(false);
    //   // set event status cancelled and paint it as red card
    //   event.setExtendedProp('status', VISIT_STATUS.DONE);
    //   event.setProp('classNames', 'bg-red text-white');
    // }
  };

  return (
    <>
      <TimelineHeader name="Administrar Asesorías" parentName="Proyectos" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                      {event?.currentDate}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-lg-0 text-lg-right" lg="6">
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="month"
                      onClick={() => changeCalendarView('dayGridMonth')}
                      size="sm"
                    >
                      Vista de mes
                    </Button>
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="basicWeek"
                      onClick={() => changeCalendarView('dayGridWeek')}
                      size="sm"
                    >
                      Vista 3 días
                    </Button>
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="basicDay"
                      onClick={() => changeCalendarView('dayGridDay')}
                      size="sm"
                    >
                      Vista día
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  className="calendar"
                  data-toggle="calendar"
                  id="calendar"
                  ref={calendarRef}
                />
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal add */}
      <Modal
        isOpen={modalAdd}
        toggle={() => closeAddEditModal()}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-header">
          <Row>
            <Col md="12">
              <Alert color="default">
                {isEdit
                  ? `Reagendar asesoría al ${formatStandarCalendarDate(
                      event?.start,
                      false,
                    )}`
                  : `Agendar asesoría el ${formatStandarCalendarDate(
                      creationStartDate || '',
                      false,
                    )}`}
              </Alert>
            </Col>
          </Row>
        </div>
        <form
          onSubmit={
            !isEdit
              ? handleSubmit(doCreateVisit)
              : handleSubmit(doRescheduleVisit)
          }
        >
          <div className="modal-body">
            {!isEdit && (
              <FormGroup>
                <label className="form-control-label" htmlFor="plan_id">
                  Asignar Inversionista
                </label>
                <InputGroup className="mb-3">
                  <Input
                    onChange={({ target }) =>
                      setLandlordEmail(target.value || '')
                    }
                    placeholder="Correo de inversionista"
                    type="text"
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={handleSearchLandlord}
                      color="primary"
                      outline
                    >
                      Buscar
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            )}
            {landlordFound && landlordFound !== null && (
              <Row>
                <Col md="12">
                  <h5>
                    {isEdit
                      ? 'Inversionista asignado'
                      : 'Inversionista encontrado:'}
                  </h5>
                  <ProfileCard
                    avatar={landlordFound.avatar}
                    name={landlordFound?.name}
                    phone={landlordFound?.phone}
                    showAvatar={true}
                  />
                </Col>
              </Row>
            )}
            {formError && (
              <Row>
                <Col md="12">
                  <Alert color="danger">{formError}</Alert>
                </Col>
              </Row>
            )}
            <FormGroup>
              <label className="form-control-label" htmlFor="start_time">
                Selecciona una hora
              </label>
              <Controller
                name="start_time"
                control={control}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder="Selecciona una hora"
                    options={hours}
                    isOptionDisabled={(option: any) => option.disabled}
                    onChange={(selected: any) => {
                      setValue('start_time', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
            </FormGroup>
            {errors.start_time?.message && (
              <Alert color="danger">{errors.start_time?.message}</Alert>
            )}
            <Row>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  onClick={() => closeAddEditModal()}
                  color="secondary"
                  size="sm"
                >
                  Cerrar
                </Button>
              </Col>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  size="sm"
                  className="new-event--add"
                  color={isEdit ? 'default' : 'primary'}
                  type="submit"
                >
                  {isEdit ? 'Reagendar asesoría' : 'Crear asesoría'}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Modal>

      {/* Modal view visit */}
      <Modal
        isOpen={modalView}
        toggle={() => setModalView(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          <Form className="edit-event--form">
            <FormGroup>
              <Alert color="default">
                {`Visita: ${formatStandarCalendarDate(event?.start)} `}
              </Alert>
              <label className="form-control-label">Inversionista:</label>
              <ProfileCard
                avatar={event?.extendedProps.landlordInfo.avatar || ''}
                name={event?.extendedProps.landlordInfo.name || ''}
                phone={''}
                extraInfo={`${event?.title}`}
                showAvatar={true}
              />
            </FormGroup>
            <Row>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  onClick={() => setModalView(false)}
                  color="secondary"
                  size="sm"
                >
                  Cerrar
                </Button>
              </Col>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  onClick={() =>
                    navigate(
                      `/landlord/${event?.extendedProps.landlordInfo.id}/profile`,
                    )
                  }
                  color="default"
                  size="sm"
                >
                  Ver perfil de inversionista
                </Button>
              </Col>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  onClick={() => deleteVisit(event)}
                  color="danger"
                  size="sm"
                >
                  Eliminar asesoría
                </Button>
              </Col>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  onClick={() => completeVisit(event)}
                  color="success"
                  size="sm"
                >
                  Completar asesoría
                </Button>
              </Col>
            </Row>
            <input className="edit-event--id" type="hidden" />
          </Form>
        </div>
      </Modal>

      {/* Modal view done or cancelled visit */}
      <Modal
        isOpen={modalDoneCancelled}
        toggle={() => setModalDoneCancelled(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          <Form className="edit-event--form">
            <FormGroup>
              <Badge
                className="badge-lg"
                color={
                  event?.extendedProps.status === VISIT_STATUS.DONE
                    ? 'success'
                    : 'danger'
                }
              >
                {getVisitStatusAdapter(event?.extendedProps.status)}
              </Badge>
              <Alert className="mt-2" color="default">
                {`Fecha de visita: ${formatStandarCalendarDate(event?.start)} `}
              </Alert>
              <label className="form-control-label">Inquilino:</label>
              <ProfileCard
                avatar={event?.extendedProps.landlordInfo.avatar || ''}
                name={event?.extendedProps.landlordInfo.name || ''}
                phone={''}
                extraInfo={`${event?.title}`}
                showAvatar={true}
              />
            </FormGroup>
            <Row>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  size="sm"
                  onClick={() => setModalDoneCancelled(false)}
                  color="secondary"
                >
                  Cerrar
                </Button>
              </Col>
              <Col lg="12" className="pb-2">
                <Button
                  block
                  size="sm"
                  onClick={() =>
                    navigate(
                      `/landlord/${event?.extendedProps.landlordInfo.id}/profile`,
                    )
                  }
                  color="default"
                >
                  Ver perfil de inversionista
                </Button>
              </Col>
            </Row>
            <input className="edit-event--id" type="hidden" />
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default InvestorProjectVisits;
