import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { Option } from '../../../models/formModel';
import {
  createWithdrawalRentEndpoint,
  getWithdrawalReasonRentEndpoint,
} from '../services/withdrawalRentService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { useForm } from 'react-hook-form';
import { WithdrawalRentForm } from '../models/withdrawalModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

interface Props {
  contractId: string;
  resumeContract: string;
}

const useContractWithdrawal = ({ contractId, resumeContract }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  //form
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    resetField,
  } = useForm<WithdrawalRentForm>({
    resolver: yupResolver(
      yup
        .object({
          contract_id: yup.string().default(contractId),
          type: yup.string().default('regular'),
          reason: yup.string().required('Requerido'),
          explain_reason: yup.string().required('Requerido'),
          generated_at: yup.string().required('Requerido'),
          initial_resume: yup.string().default(resumeContract),
        })
        .required(),
    ),
  });

  const [withdrawalRentReasonOption, setWithdrawalRentReasonOption] = useState<
    Option[]
  >([]);

  const [apiErrors, setApiErrors] = useState('');

  //fill reason

  useEffect(() => {
    loadWithdrawalReasons().then().catch(console.error);
  }, []);

  const loadWithdrawalReasons = async () => {
    const { status, data } = await callEndpoint(
      getWithdrawalReasonRentEndpoint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setWithdrawalRentReasonOption(
        listToSelectOptionAdapter(response.data, 'key', 'value', false),
      );
    }
  };

  //create action
  const doCreateWithdrawalRent = async (form: WithdrawalRentForm) => {
    const payload = { ...form };
    payload.initial_resume = resumeContract;
    setApiErrors('');
    const { status, data } = await callEndpoint(
      createWithdrawalRentEndpoint(form, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      //reload
      navigate(0);
    } else {
      setApiErrors(response.message);
    }
  };

  //reset
  const resetValues = () => {
    setApiErrors('');
    setWithdrawalRentReasonOption([]);
    resetField('explain_reason');
    resetField('generated_at');
    resetField('initial_resume');
    resetField('reason');
  };

  return {
    apiErrors,
    withdrawalRentReasonOption,
    isLoading,
    errors,
    control,
    register,
    setValue,
    resetField,
    handleSubmit,
    doCreateWithdrawalRent,
    resetValues,
  };
};

export default useContractWithdrawal;
