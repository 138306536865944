import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import TimelineHeader from '../../components/headers/TimelineHeader';
import LoadingCard from '../../components/LoadingCard';
import { DEFAULT_AVATAR } from '../../constants/profileConstants';
import { dateFormatted, getAge } from '../../helpers/dateHelpers';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { ConfirmAlert } from '../../models/alertModel';
import DetailLabelText from '../properties/components/DetailLabelText';
import { mapGender, mapPetSize } from './helpers/tenantHelpers';
import { TenantDetailData } from './models/tenantModel';
import {
  getTenantEndpoint,
  calculateTenantScoreEndpoint,
} from './services/tenantService';

const TenantDetail = () => {
  const getScorePropsAlert = {
    warning: true,
    title: 'Solicitar score crediticio',
    text: 'Si el inquilino no tiene un score sera solicitado a equifax, Verificar que el inquilino tenga su documento único de identidad ingresado',
    showCancel: true,
    confirmBtnBsStyle: 'success',
    confirmBtnText: 'Solicitar',
    cancelBtnBsStyle: 'danger',
    cancelBtnText: 'Cancelar',
    onConfirm: () => calculateScore(),
    onCancel: () => setSweetAlert(false),
  };
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [tenantData, setTenantData] = useState<TenantDetailData>();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [sweetAlert, setSweetAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] =
    useState<ConfirmAlert>(getScorePropsAlert);
  const navigate = useNavigate();

  const loadTenant = async () => {
    const { status, data } = await callEndpoint(getTenantEndpoint(id, token));
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setTenantData(response.data);
    }
  };

  // TODO remove unused endpoint
  const calculateScore = async () => {
    setSweetAlert(false);
    const { status, data } = await callEndpoint(
      calculateTenantScoreEndpoint(id, token),
    );
    if (status === 201) {
      loadTenant().then().catch();
    } else {
      const response = fetchResponseAdapter(data);
      setAlertProps({
        warning: true,
        title: 'Lo sentimos',
        text: `Ocurrió un error al momento de solicitar el score, código interno: (${response.code}, ${response.message})`,
        showCancel: false,
        confirmBtnBsStyle: 'error',
        confirmBtnText: 'Entiendo',
        cancelBtnBsStyle: 'danger',
        cancelBtnText: 'Cancelar',
        onConfirm: () => {
          setSweetAlert(false);
          setAlertProps(getScorePropsAlert);
        },
        onCancel: () => {
          setSweetAlert(false);
          setAlertProps(getScorePropsAlert);
        },
      });
      setSweetAlert(true);
    }
  };

  useEffect(() => {
    loadTenant().catch((e) => console.error(e));
  }, []);

  const goToDocuments = (profileId: string) => {
    navigate(`/landlord/${profileId}/documents`, { replace: true });
  };

  if (!tenantData || isLoading) {
    return <LoadingCard name="" parent_name="" />;
  }

  return (
    <>
      {sweetAlert && (
        <SweetAlert {...alertProps}>{alertProps.text || ''}</SweetAlert>
      )}
      <TimelineHeader
        name="Detalle de inquilino"
        parentName="Listado de inquilinos"
      />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center mb-4">
              <Col className="col-12">
                {/* <h3 className="mb-0">Detalle de inquilino</h3> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {/* Profile card section */}
              <Col className="order-xl-2 pt-10" xl="4">
                <Card className="card-profile">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <div className="card-profile-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={
                              tenantData?.tenant.profile.avatar
                                ? tenantData?.tenant.profile.avatar
                                : DEFAULT_AVATAR
                            }
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between"></div>
                  </CardHeader>
                  <CardBody className="pt-0">
                    <Row>
                      <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                          <div>
                            {/* <Badge className="badge-lg" color="success">
                              Activo
                            </Badge> */}
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div className="text-center">
                      <h5 className="h3">
                        {tenantData.tenant.profile.first_name +
                          ' ' +
                          tenantData.tenant.profile.last_name +
                          '' +
                          (tenantData.tenant.profile.birth_date
                            ? `, ${getAge(
                                tenantData.tenant.profile.birth_date,
                              )}`
                            : '')}
                      </h5>
                      {}
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        Teléfono: {tenantData.tenant.profile.phone}
                      </div>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        Correo: {tenantData.tenant.profile.email}
                      </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        {tenantData.tenant.profession || ''}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* Aditional info */}
              <Col className="order-xl-1" xl="8">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h3 className="mb-0">Información adicional</h3>
                      </Col>
                      <Col className="text-right" xs="6">
                        {tenantData.tenant.profile.enabled ? (
                          <Badge className="badge-lg" color="success">
                            Perfil Activo
                          </Badge>
                        ) : (
                          <Badge className="badge-lg" color="danger">
                            Perfil Inactivo
                          </Badge>
                        )}
                        {tenantData.tenant.profile.is_account_verified ? (
                          <Badge className="badge-lg" color="success">
                            Correo verificado
                          </Badge>
                        ) : (
                          <Badge className="badge-lg" color="danger">
                            Correo no verificado
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <DetailLabelText
                      label="Acerca de"
                      text={tenantData.tenant.summary}
                    />
                    <DetailLabelText
                      label="Salario"
                      text={`${
                        tenantData.tenant.net_income
                          ? tenantData.tenant.net_income
                          : '0.00'
                      }`}
                    />
                    <DetailLabelText
                      label="Compañía"
                      text={tenantData.tenant.company_name}
                    />
                    <div className="">
                      <DetailLabelText
                        label="Pasatiempos"
                        text={tenantData.tenant.hobbies || 'No disponible'}
                      />
                      <DetailLabelText
                        label="Fecha de nacimiento"
                        text={
                          tenantData.tenant.profile.birth_date
                            ? dateFormatted(
                                tenantData.tenant.profile.birth_date.toString(),
                              )
                            : 'No disponible'
                        }
                      />
                      <DetailLabelText
                        label="Sexo"
                        text={mapGender(tenantData.tenant.profile.gender || '')}
                      />
                      <DetailLabelText
                        label="Cantidad de personas"
                        text={
                          tenantData.tenant.roommates_qty
                            ? tenantData.tenant.roommates_qty.toString()
                            : ''
                        }
                      />
                      <DetailLabelText
                        label="Red social"
                        text={tenantData.tenant.social_media || 'No disponible'}
                      />
                      <DetailLabelText
                        label="Tamaño de mascota"
                        text={mapPetSize(tenantData.tenant.pet_size || '')}
                      />
                      <DetailLabelText
                        label="Fecha de creación"
                        text={tenantData.created_at_local}
                      />
                      <DetailLabelText
                        label="Última actualización"
                        text={tenantData.updated_at_local}
                      />
                    </div>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h6 className="heading-small text-muted">Documentos</h6>
                      <Button
                        color="primary"
                        onClick={() =>
                          goToDocuments(tenantData?.tenant?.profile?.id)
                        }
                      >
                        Administrar documentos
                      </Button>
                    </div>
                  
                    <div className="">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Tipo Documento</th>
                            <th scope="col">Número</th>
                            <th scope="col">Creado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tenantData.documents &&
                            tenantData.documents.length > 0 &&
                            tenantData.documents.map((el) => (
                              <tr key={el.id}>
                                <td>{el.document.document}</td>
                                <td>{el.document_number}</td>
                                <td>{el.created_at_local}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <hr className="my-4" />

                    <Row>
                      <Col xs="6">
                        <h6 className="heading-small text-muted mb-4">
                          Información crediticia
                        </h6>
                      </Col>
                      <Col className="text-right" xs="6">
                        <Button
                          color="secondary"
                          outline
                          data-placement="top"
                          id="tooltip611234743"
                          size="sm"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-chat-round" />
                          </span>
                        </Button>
                        <Button
                          color="default"
                          outline
                          size="sm"
                          type="button"
                          onClick={() => setSweetAlert(true)}
                        >
                          Consultar score
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="tooltip611234743"
                        >
                          {tenantData.score_execution_info &&
                          tenantData.score_execution_info
                            .prequalification_status &&
                          tenantData.score_execution_info
                            .prequalification_message
                            ? `(${tenantData.score_execution_info.prequalification_status}) ${tenantData.score_execution_info.prequalification_message}`
                            : 'Pendiente consulta de precalificación'}
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <div className="">
                      <DetailLabelText
                        label="Score"
                        text={
                          tenantData.score
                            ? tenantData.score.toString()
                            : 'No disponible'
                        }
                      />
                    </div>
                    <hr className="my-4" />
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={() => navigate(`/tenant/${id}/edit`)}
                      color="primary"
                    >
                      Editar
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default TenantDetail;
