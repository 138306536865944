import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  PropertyRentProps,
  PropertyUpdateDataApi,
  PublishCatalogs,
  UpdatePropertyRentInfoForm,
  UpdatePropertyRentStateForm,
} from '../models/propertyModel';
import {
  getPlansEndpoint,
  getPropertyToEditEndpoint,
  takeDownPropertyEndpoint,
  updatePropertyEndpoint,
  updatePropertyStatusEndpoint,
} from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { PROPERTY_STATUS_VALUES } from '../constants/propertyConstants';
import { Option } from '../../../models/formModel';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { getOptionsBySelectedMonths } from '../adapters/propertyAdapter';

const usePropertyRentPrices = (props: PropertyRentProps) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [formUpdateData, setFormUpdateData] = useState<PropertyUpdateDataApi>();
  const [loadingPlan, setLoadingPlan] = useState<boolean>(true);
  const [currentPlan, setCurrentPlan] = useState<Option>({
    label: 'Selecciona...',
    value: '',
  });
  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    plans: [],
  });

  const [loadingMonths, setLoadingMonths] = useState<boolean>(true);
  const [currentMonths, setCurrentMonths] = useState<any[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<UpdatePropertyRentInfoForm>({
    resolver: yupResolver(
      yup
        .object({
          price: yup
            .number()
            .min(0)
            .required('Precio de renta es requerido')
            .typeError('Precio de renta es requerido en formato numérico'),
          min_price: yup
            .number()
            .max(
              yup.ref('price'),
              'Precio de renta mínimo tiene que ser menor o igual al precio de renta',
            )
            .required('Precio mínimo de renta es requerido'),
          months: yup
            .array()
            .min(1, 'Debes seleccionar almenos una opción')
            .required('Debes seleccionar almenos una opción'),
          available_at: yup.string().required('Fecha es requerida'),
          plan_id: yup.string().required('Plan es requerida'),
        })
        .required(),
    ),
  });

  // Load info
  useEffect(() => {
    const loadExistingProperty = async () => {
      const { status, data } = await callEndpoint(
        getPropertyToEditEndpoint(props.property_id, token),
      );
      if (status === 200) {
        const { data: formData } = fetchResponseAdapter(data);
        setFormUpdateData(formData);
        return formData;
      }
      return null;
    };

    const callCatalogs = async (response: PropertyUpdateDataApi) => {
      const plans = await callEndpoint(getPlansEndpoint(token));
      if (plans.status !== 200) {
        // props.setErrorAlert('')
        return;
      }
      const plansResponse = fetchResponseAdapter(plans.data);
      setCatalogs({
        plans: listToSelectOptionAdapter(plansResponse.data, 'id', 'name'),
      });
      setCurrentPlan({
        label: response.plan ? response.plan.name : '',
        value: response.plan ? response.plan.id : '',
      });
      setCurrentMonths(getOptionsBySelectedMonths(response?.months || []));

      setLoadingMonths(false);
      setLoadingPlan(false);
    };

    loadExistingProperty()
      .then((response) => callCatalogs(response))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (formUpdateData) {
      reset({
        plan_id: formUpdateData.plan?.id ?? '',
        price: formUpdateData.price ?? null,
        min_price: formUpdateData.min_price ?? null,
        available_at: formUpdateData.available_at ?? null,
        months: formUpdateData.months,
      });
    }
  }, [formUpdateData]);

  const updateMarketplaceRentPrices = async (
    form: UpdatePropertyRentInfoForm,
  ) => {
    // make sure that months are number on update already saved months
    const numberMonths = form.months.map((month) =>
      typeof month === 'string' ? parseInt(month) : month,
    );
    form.months = numberMonths;
    // map
    const { status } = await callEndpoint(
      updatePropertyEndpoint(form, props.property_id, token),
    );
    if (status === 200) {
      navigate(`/properties/admin/${props.property_id}`);
    }
  };

  const updateMarketplaceRentStatus = async (
    property_id: string,
    form: UpdatePropertyRentStateForm,
  ) => {
    console.log(property_id);
    console.log(form);
    let requestStatus = null;
    if (form.status === PROPERTY_STATUS_VALUES.PUBLISHED) {
      const { status } = await callEndpoint(
        updatePropertyStatusEndpoint(props.property_id, form, token),
      );
      requestStatus = status;
    } else {
      const { status } = await callEndpoint(
        takeDownPropertyEndpoint(props.property_id, form, token),
      );
      requestStatus = status;
    }
    if (requestStatus !== 200) {
      alert('Error');
      return;
    }
    navigate(0);
  };

  return {
    control,
    register,
    errors,
    setValue,
    handleSubmit,
    isLoading,
    loadingPlan,
    currentPlan,
    loadingMonths,
    currentMonths,
    catalogs,
    updateMarketplaceRentPrices,
    updateMarketplaceRentStatus,
  };
};

export default usePropertyRentPrices;
