import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import useAdminEntity from '../../hooks/useAdminEntity';
import { useParams } from 'react-router-dom';
import { getInvestorProjectDetailEndpoint, getProjectModelsEndpoint } from './services/investorService';
import useAuth from '../../hooks/useAuth';
import { InvestorProjectDetail, InvestorProjectModelDetail } from './models/investors';
import InvestorProjectSummaryCard from './components/InvestorProjectSummaryCard';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import { Col, Row } from 'reactstrap';
import InvestorProjectDetailCard from './components/InvestorProjectDetailCard';
import InvestorProjectActions from './components/InvestorProjectActions';
import InvestorProjectModelSummaryCard from './components/InvestorProjectModelSummaryCard';

const InvestorProjectDetails: React.FC = () => {
  const {id: investorProjectId = ""} = useParams();
  const { isLoading } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getProjectDetails = useCallback(
    () => getInvestorProjectDetailEndpoint(investorProjectId, token),
    []
  );
  const [project] = useAdminEntity<InvestorProjectDetail>(getProjectDetails, setErrorAlert);

  const getProjectModels = useCallback(() => getProjectModelsEndpoint(investorProjectId, token), []);
  const [projectModels] = useAdminEntity<InvestorProjectModelDetail[]>(getProjectModels, setErrorAlert);

  const pageName = "Proyecto de preventa";
  const pageParentName = "Proyectos de preventa";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!project || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}>
      <Row>
        <Col>
          <InvestorProjectSummaryCard project={project} />
        </Col>
      </Row>
      <Row>
        {projectModels && projectModels.length > 0 && projectModels.map(model => (
          <Col key={model.id}>
            <InvestorProjectModelSummaryCard projectId={investorProjectId} model={model} showButtons />
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <InvestorProjectDetailCard project={project} />
        </Col>
        <Col>
          <InvestorProjectActions project={project} />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectDetails;