import React, { useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
} from 'reactstrap';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import AdminPage from '../../../components/admin/AdminPage';
import LoadingCard from '../../../components/LoadingCard';
import useAdminEntity from '../../../hooks/useAdminEntity';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useModalState from '../../../hooks/useModalState';
import {
  InvestorProjectDetail,
  UpdateProjectPrivacy,
  GenerateCodes,
  ProjectAccessCodes,
} from '../models/investors';
import {
  getInvestorProjectDetailEndpoint,
  updateProjectAccessEndpoint,
  generateAccessCodesEndpoint,
  getAccessCodesEndpoint,
} from '../services/investorService';
import PrivateAccessConfirmationModal from './PrivateAccessConfirmationModal';
import GenerateAccessCodesModal from './GenerateAccessCodesModal';

const InvestorProjectAccess = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { id: investorProjectId = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState<string>('');
  const navigate = useNavigate();
  const pageName = 'Proyecto de preventa';
  const pageParentName = 'Proyectos de preventa';
  const maxCodes = 150;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };
  const getProjectDetails = useCallback(
    () => getInvestorProjectDetailEndpoint(investorProjectId, token),
    [],
  );
  const [project] = useAdminEntity<InvestorProjectDetail>(
    getProjectDetails,
    setErrorAlert,
  );

  const getProjectAccessCodes = useCallback(
    () => getAccessCodesEndpoint(investorProjectId, token),
    [],
  );
  const [accessCodes] = useAdminEntity<ProjectAccessCodes[]>(
    getProjectAccessCodes,
    setErrorAlert,
  );

  const [showProjectAccessModal, toogleShowProjectAccessModal] =
    useModalState();
  const [showGenerateCodesModal, toogleShowGenerateCodesModal] =
    useModalState();
  const [codes, setCodes] = useState<number>(0);

  const generateCodes = async (projectId: string, codes: number = 0) => {
    if (codes === 0) {
      setErrorAlert(`Debes ingresar una cantidad de códigos válida`);
    } else if (codes >= maxCodes) {
      setErrorAlert(
        `Cantidad máxima de códigos a generar por ejecución son: ${maxCodes} (cantidad digitada ${codes})`,
      );
    } else {
      const form: GenerateCodes = {
        amount: codes,
        project_id: projectId,
      };
      const { status, data } = await callEndpoint(
        generateAccessCodesEndpoint(token, form),
      );
      if (status === 201) {
        return navigate(0); // reload
      } else {
        const response = fetchResponseAdapter(data);
        toogleShowGenerateCodesModal();
        setErrorAlert(`(${response.code}) ${response.message}`);
      }
    }
  };

  const updateProjectAccess = async () => {
    const form: UpdateProjectPrivacy = {
      is_private: !project.is_private,
    };
    const { status, data } = await callEndpoint(
      updateProjectAccessEndpoint(investorProjectId, token, form),
    );
    if (status === 200) {
      return navigate(0); // reload
    } else {
      const response = fetchResponseAdapter(data);
      toogleShowProjectAccessModal();
      setErrorAlert(`(${response.code}) ${response.message}`);
    }
  };

  if (!project || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col md="6">
          <Card>
            <CardBody>
              <ListGroup className="list my--3" flush>
                <h6 className="heading-small text-muted mt-4 mb-4">
                  Administrar privacidad del proyecto
                </h6>
                <ListGroupItem>
                  <Row className="align-items-center">
                    <div className="col ml--2">
                      <h4 className="mb-0">Visibilidad</h4>
                    </div>
                    <Col className="col-auto">
                      <span className="mr-2">
                        {project.is_private ? (
                          <Badge className="badge-lg" color="danger">
                            Privado
                          </Badge>
                        ) : (
                          <Badge className="badge-lg" color="success">
                            Público
                          </Badge>
                        )}
                      </span>
                      <Button
                        className="mt-1"
                        onClick={() => toogleShowProjectAccessModal()}
                        color={project.is_private ? 'success' : 'default'}
                        size="sm"
                        type="button"
                      >
                        {project.is_private ? 'Hacer público' : 'Hacer privado'}
                      </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <CardBody>
              <ListGroup className="list my--3" flush>
                <h6 className="heading-small text-muted mt-4 mb-4">
                  Códigos de acceso
                </h6>
                <Row className="align-items-center">
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="plan_id">
                        Generar códigos
                      </label>
                      <InputGroup className="mb-3">
                        <Input
                          onChange={(e) => {
                            if (e.target.value) {
                              setCodes(+e.target.value);
                            }
                          }}
                          placeholder="Digite la cantidad de códigos que requiera generar"
                          type="number"
                          min={0}
                          max={maxCodes}
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            onClick={() => toogleShowGenerateCodesModal()}
                            color="primary"
                            outline
                          >
                            {' '}
                            Generar
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                      <p className="text-sm">
                        150 códigos máximo por ejecución
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </ListGroup>
              <div
                style={{
                  height: '300px',
                  overflow: 'scroll',
                }}
              >
                <Table
                  className="align-items-center table-flush hei"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Código</th>
                      <th scope="col">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessCodes &&
                      accessCodes.length > 0 &&
                      accessCodes.map((code) => (
                        <tr key={`${code.id}`}>
                          <td>{code.code}</td>
                          <td>
                            {code.active ? (
                              <Badge className="badge-lg" color="success">
                                Activo
                              </Badge>
                            ) : (
                              <Badge className="badge-lg" color="danger">
                                Inactivo
                              </Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <GenerateAccessCodesModal
        projectId={project.id}
        codes={codes}
        isOpen={showGenerateCodesModal}
        onToggle={toogleShowGenerateCodesModal}
        onConfirmation={generateCodes}
        bodyText={`Estas a punto de generar ${codes} código(s) de acceso`}
        confirmationText={'Entiendo, continuar'}
        abortText={'Cancelar'}
      />
      <PrivateAccessConfirmationModal
        projectId={project.id}
        isOpen={showProjectAccessModal}
        onToggle={toogleShowProjectAccessModal}
        onConfirmation={updateProjectAccess}
        bodyText={
          project.is_private
            ? 'Al hacer público el proyecto no tendrá restricción de acceso ni pedirá contraseña al usuario o guest que quiera acceder'
            : 'Al hacer privado el proyecto tendrá restricción de acceso y solicitará un código los cuales puedes generar en esta sección'
        }
        confirmationText={'Entiendo, continuar'}
        abortText={'Cancelar'}
      />
    </AdminPage>
  );
};

export default InvestorProjectAccess;
