import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from 'reactstrap';
import useContractWithdrawal from './hooks/useContractWithdrawal';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

interface Props {
  headerText: string;
  isOpen: boolean;
  onToggle: () => void;
  contractId: string;
  resumeContract: string;
}

export default function WithdrawalContractModal({
  contractId,
  headerText,
  isOpen,
  onToggle,
  resumeContract,
}: Props) {
  const {
    apiErrors,
    control,
    errors,
    isLoading,
    withdrawalRentReasonOption,
    doCreateWithdrawalRent,
    handleSubmit,
    resetValues,
    setValue,
  } = useContractWithdrawal({ contractId, resumeContract });

  const onReset = () => {
    resetValues();
    onToggle();
  };

  return (
    <Modal
      size="md"
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onReset()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          {headerText}
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onReset()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {apiErrors && (
          <Alert color="danger">
            <strong>Error!</strong> {apiErrors}
          </Alert>
        )}
        <Form role="form">
          <Col md={12}>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="reason">
                    Selecciona motivo de desestimiento
                  </label>
                  <Controller
                    name="reason"
                    control={control}
                    key={'reason-key'}
                    render={({ field: { ref } }) => (
                      <Select
                        placeholder={'Selecciona motivo de desestimiento'}
                        options={withdrawalRentReasonOption}
                        onChange={(selected: any) => {
                          setValue('reason', selected.value);
                        }}
                        ref={ref}
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.reason?.message}</h5>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="form-control-label" htmlFor="explain_reason">
                  Explica motivo de desestimiento
                </label>
                <Controller
                  name="explain_reason"
                  control={control}
                  //defaultValue={proofResume?.explain_reason ?? ''}
                  key={`explain_reason-key`}
                  render={({ field }) => (
                    <Input
                      className={errors.explain_reason?.message && 'is-invalid'}
                      {...field}
                      type="textarea"
                      placeholder="Explica la razón del desestimiento"
                    />
                  )}
                />
                <h5 className="text-danger">
                  {errors.explain_reason?.message}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="generated_at">
                    Generado en
                  </label>
                  <Controller
                    name="generated_at"
                    control={control}
                    //defaultValue={proofResume?.generated_at ?? ''}
                    defaultValue=""
                    key={`generated_at-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.generated_at?.message && 'is-invalid'}
                        {...field}
                        type="date"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.generated_at?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          form="downPaymentQuota"
          color={'success'}
          onClick={handleSubmit(doCreateWithdrawalRent)}
          disabled={isLoading}
        >
          {headerText}
        </Button>
      </div>
    </Modal>
  );
}
