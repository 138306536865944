import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  ProofOutcomeForm,
  ProofOutcomeSummary,
} from '../models/proofIncomeModel';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';

interface Props {
  proofResume?: ProofOutcomeSummary;
  handleForm: (form: ProofOutcomeForm) => void;
  outcomeProofPayload: any;
  isUpdate?: boolean;
}

const AccountingProofOutcomeForm: React.FC<Props> = ({
  handleForm,
  proofResume,
  outcomeProofPayload,
  isUpdate = false,
}) => {
  const { isLoading } = useCallApiAndLoad();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ProofOutcomeForm>({
    resolver: yupResolver(
      yup
        .object({
          proof_incomes: yup.array(),
          amount: yup.number().required('Requerido'),
          outcome_product: yup.string(),
          owner_id: yup.string(),
          owner_name: yup.string().required('Requerido'),
          owner_lastname: yup.string(),
          owner_document: yup.string().required('Requerido'),
          owner_bank: yup.string().required('Requerido'),
          owner_account_number: yup.string().required('Requerido'),
          concept_outcome: yup.string().required('Requerido'),
          ref_outcome: yup.string().required('Requerido'),
          payment_outcome: yup.string().required('Requerido'),
          additional_comments: yup.string(),
          generated_at: yup.string(),
          owner_email: yup
            .string()
            .nullable()
            .transform((value) => (value === '' ? null : value)),
        })
        .required(),
    ),
  });

  useEffect(() => {
    if (proofResume) {
      reset({
        // Set by default
        proof_incomes: outcomeProofPayload.selectedProofIncomes,
        owner_id: outcomeProofPayload.incomeOwnerId,
        outcome_product: outcomeProofPayload.incomeProduct,
        // Fill out by user
        amount: +proofResume?.amount_aux,
        owner_name: proofResume.owner_name,
        owner_lastname: proofResume.owner_lastname,
        owner_document: proofResume.owner_document,
        owner_bank: proofResume.owner_bank,
        owner_account_number: proofResume.owner_account_number,
        concept_outcome: proofResume.concept_outcome,
        ref_outcome: proofResume.ref_outcome,
        payment_outcome: proofResume.payment_outcome,
        generated_at: proofResume.generated_at,
        additional_comments: proofResume.additional_comments,
        owner_email: proofResume.owner_email,
      });
    }
  }, [proofResume]);

  return (
    <>
      <Form role="form" onSubmit={handleSubmit(handleForm)}>
        <Row>
          <Col md="6">
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="amount">
                    Monto
                  </label>
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={
                      proofResume?.amount && !isNaN(+proofResume?.amount)
                        ? +proofResume?.amount
                        : 0
                    }
                    key={`amount-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.amount?.message && 'is-invalid'}
                        {...field}
                        type="number"
                        step="any"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.amount?.message}</h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="owner_name">
                    Nombre
                  </label>
                  <Controller
                    name="owner_name"
                    control={control}
                    defaultValue={proofResume?.owner_name ?? ''}
                    key={`owner_name-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.owner_name?.message && 'is-invalid'}
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.owner_name?.message}</h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="owner_lastname"
                  >
                    Apellido
                  </label>
                  <Controller
                    name="owner_lastname"
                    control={control}
                    defaultValue={proofResume?.owner_lastname ?? ''}
                    key={`owner_lastname-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.owner_lastname?.message && 'is-invalid'
                        }
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.owner_lastname?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="owner_email">
                    Email
                  </label>
                  <Controller
                    name="owner_email"
                    control={control}
                    defaultValue={proofResume?.owner_email ?? ''}
                    key={`owner_email-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.owner_email?.message && 'is-invalid'}
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.owner_email?.message}</h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="owner_document"
                  >
                    Documento de identificación
                  </label>
                  <Controller
                    name="owner_document"
                    control={control}
                    defaultValue={proofResume?.owner_document ?? ''}
                    key={`owner_document-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.owner_document?.message && 'is-invalid'
                        }
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.owner_document?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="owner_bank">
                    Banco
                  </label>
                  <Controller
                    name="owner_bank"
                    control={control}
                    defaultValue={proofResume?.owner_bank ?? ''}
                    key={`owner_bank-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.owner_bank?.message && 'is-invalid'}
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.owner_bank?.message}</h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="owner_account_number"
                  >
                    Número de cuenta
                  </label>
                  <Controller
                    name="owner_account_number"
                    control={control}
                    defaultValue={proofResume?.owner_account_number ?? ''}
                    key={`owner_account_number-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.owner_account_number?.message && 'is-invalid'
                        }
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.owner_account_number?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="concept_outcome"
                  >
                    Concepto
                  </label>
                  <Controller
                    name="concept_outcome"
                    control={control}
                    defaultValue={proofResume?.concept_outcome ?? ''}
                    key={`concept_outcome-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.concept_outcome?.message && 'is-invalid'
                        }
                        {...field}
                        type="textarea"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.concept_outcome?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="ref_outcome">
                    Referencia
                  </label>
                  <Controller
                    name="ref_outcome"
                    control={control}
                    defaultValue={proofResume?.ref_outcome ?? ''}
                    key={`ref_outcome-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.ref_outcome?.message && 'is-invalid'}
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">{errors.ref_outcome?.message}</h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="payment_outcome"
                  >
                    Tipo de pago
                  </label>
                  <Controller
                    name="payment_outcome"
                    control={control}
                    defaultValue={proofResume?.payment_outcome ?? ''}
                    key={`payment_outcome-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.payment_outcome?.message && 'is-invalid'
                        }
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.payment_outcome?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="additional_comments"
                  >
                    Comentarios adicionales
                  </label>
                  <Controller
                    name="additional_comments"
                    control={control}
                    defaultValue={proofResume?.additional_comments ?? ''}
                    key={`additional_comments-key`}
                    render={({ field }) => (
                      <Input
                        className={
                          errors.additional_comments?.message && 'is-invalid'
                        }
                        {...field}
                        type="text"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.additional_comments?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="generated_at">
                    Generado en
                  </label>
                  <Controller
                    name="generated_at"
                    control={control}
                    defaultValue={proofResume?.generated_at ?? ''}
                    key={`generated_at-key`}
                    render={({ field }) => (
                      <Input
                        className={errors.generated_at?.message && 'is-invalid'}
                        {...field}
                        type="date"
                      />
                    )}
                  />
                  <h5 className="text-danger">
                    {errors.generated_at?.message}
                  </h5>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Button color="primary" disabled={isLoading}>
          {isUpdate ? 'Actualizar' : 'Crear'}
        </Button>
      </Form>
    </>
  );
};

export default AccountingProofOutcomeForm;
