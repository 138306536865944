import React, { useEffect, useState } from 'react'
import { createSearchParams } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage'
import AdminPaginator from '../../components/admin/AdminPaginator';
import LoadingCard from '../../components/LoadingCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { GuestVisitInvestor } from './models/guestInvestorModel';
import { getGuestVisitsInvestorEndpoint } from './services/guestInvestorService';

const InvestGuestVisits = () => {
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = "Asesorias de inversionistas";
  const pageParentName = "Inversiones";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const [guestVisitInvestor, setGuestVisitInvestor] = useState<GuestVisitInvestor[]>([])

  const loadGuestVisitsInvestors = async (page: number = 0, take: number = 0) => {
    let filterBuilder: any = {};
    if (page > 0) {
      filterBuilder.page = page.toString();
    }
    if (take > 0) {
      filterBuilder.take = take.toString();
    }
    const queryString = `?${createSearchParams(filterBuilder)}`;
    const {
      status, data
    } = await callEndpoint(getGuestVisitsInvestorEndpoint(queryString, token));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestVisitInvestor(response.data.items || []);
      const { page, pageCount } = response.data.meta;
      setPaginator({ page, pageCount });
    } else {
      setErrorAlert(response.message);
    }
  };

  useEffect(() => {
    loadGuestVisitsInvestors()
      .catch(e => console.error(e))
  }, [])

  if (isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={
        <AdminPaginator
          page={paginator.page}
          totalPages={paginator.pageCount}
          onPageChange={loadGuestVisitsInvestors} />
      }
      globalAlertOptions={globalAlertOptions}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Email</th>
            <th scope="col">Tipo de asesoria</th>
            <th scope="col">Fecha de asesoria</th>
            <th scope="col">ID Proyecto</th>
            <th scope="col">Proyecto</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {
            guestVisitInvestor.length > 0 && guestVisitInvestor.map((guestVisit) => (
              <tr key={`${guestVisit.guestInfo.id}-${guestVisit.projectInfo.id}`}>
                <td>{guestVisit.guestInfo.fullname}</td>
                <td>{guestVisit.guestInfo.phone}</td>
                <td>{guestVisit.guestInfo.email}</td>
                <td>{guestVisit.visitInfo.visit_type === "presential" ? "Presencial" : "Virtual"}</td>
                <td>{`${guestVisit.visitInfo.visit_date} ${guestVisit.visitInfo.visit_time}`}</td>
                <td>{guestVisit.projectInfo.code}</td>
                <td>
                  <img
                    alt="..."
                    width={175}
                    height={100}
                    src={
                      guestVisit.projectInfo.cover
                        ? guestVisit.projectInfo.cover
                        : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                    }
                  />
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/investors/guest/visits/${guestVisit.visitInfo.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </AdminPage>
  )
}

export default InvestGuestVisits
