import React, { useState } from 'react';
import { PropertyRentProps } from '../../models/propertyModel';
import useModalState from '../../../../hooks/useModalState';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { getPropertyStatus } from '../../adapters/propertyAdapter';
import { Controller } from 'react-hook-form';
import {
  PROPERTY_MONTHS_LIST,
  PROPERTY_STATUS_VALUES,
} from '../../constants/propertyConstants';
import TakeDownPropertyModal from '../TakeDownPropertyModal';
import ActivatePropertyModal from '../ActivatePropertyModal';
import usePropertyRentPrices from '../../hooks/usePropertyRentPrices';
import Select from 'react-select';

export const PropertyRentPrices = (props: PropertyRentProps) => {
  const [takeDownPropertyModal, toggleTakeDownPropertyModal] = useModalState();
  const [activatePropertyModal, toggleActivatePropertyModal] = useModalState();
  const {
    // form
    handleSubmit,
    control,
    register,
    errors,
    setValue,
    // loading
    isLoading,
    loadingPlan,
    currentPlan,
    loadingMonths,
    currentMonths,
    // catalogs
    catalogs,
    // actions
    updateMarketplaceRentPrices,
    updateMarketplaceRentStatus,
  } = usePropertyRentPrices({
    ...props
  });

  return (
    <Card>
      <CardHeader>
        <h6 className="heading-small text-muted">
          Configurar precios y visibilidad
        </h6>
      </CardHeader>

      <Form role="form" onSubmit={handleSubmit(updateMarketplaceRentPrices)}>
        <CardBody>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="has_scriptures">
                  Estado en renta
                </label>
              </Col>
              <Col md={6}>
                <Badge color="default">
                  {getPropertyStatus(props.property_rent_state)}
                </Badge>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="price">
                  Precio de renta
                </label>
              </Col>
              <Col md={6}>
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.price?.message && 'is-invalid'}
                      {...field}
                      id="price"
                      type="number"
                      min={0}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.price?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="min_price">
                  Precio de mínimo de renta
                </label>
              </Col>
              <Col md={6}>
                <Controller
                  name="min_price"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.min_price?.message && 'is-invalid'}
                      {...field}
                      id="min_price"
                      type="number"
                      min={0}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.min_price?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
          {/* available at */}
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="available_at">
                  ¿Desde cuando puede ser alquilado?
                </label>
              </Col>
              <Col md={6}>
                <Controller
                  name="available_at"
                  control={control}
                  render={({ field }) => (
                    <Input
                      className={errors.available_at?.message && 'is-invalid'}
                      {...field}
                      id="available_at"
                      type="date"
                      min={0}
                    />
                  )}
                />
                <h5 className="text-danger">{errors.available_at?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
          {/* Plan */}
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="plan_id">
                  Plan
                </label>
              </Col>
              <Col md={6}>
                {!loadingPlan && (
                  <Controller
                    name="plan_id"
                    control={control}
                    defaultValue={currentPlan.value || ''}
                    render={({ field: { ref } }) => (
                      <Select
                        placeholder="Selecciona un plan"
                        options={catalogs.plans}
                        defaultValue={currentPlan}
                        onChange={(selected: any) => {
                          const { value = '' } = selected;
                          setValue('plan_id', value);
                        }}
                        ref={ref}
                      />
                    )}
                  />
                )}

                <h5 className="text-danger">{errors.available_at?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
          {/* months */}
          <FormGroup>
            <Row>
              <Col md={6}>
                <label className="form-control-label" htmlFor="months">
                  Meses para contratos
                </label>
              </Col>
              <Col md={6}>
                {!loadingMonths && (
                  <Controller
                    name="months"
                    control={control}
                    render={({ field: { ref } }) => (
                      <Select
                        name="months"
                        isMulti
                        onChange={(selected: any) => {
                          const ids = [];
                          for (const element of selected) {
                            ids.push(parseInt(element.value));
                          }
                          setValue('months', ids);
                        }}
                        options={PROPERTY_MONTHS_LIST}
                        defaultValue={currentMonths}
                        placeholder="Seleccionar"
                        ref={ref}
                      />
                    )}
                  />
                )}
                <h5 className="text-danger">{errors.months?.message}</h5>
              </Col>
            </Row>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button color={'primary'} disabled={isLoading}>
            Actualizar información de renta
          </Button>
          {props.property_rent_state === PROPERTY_STATUS_VALUES.PUBLISHED ? (
            <Button onClick={toggleTakeDownPropertyModal} color={'danger'}>
              Ocultar en marketplace
            </Button>
          ) : (
            <Button onClick={toggleActivatePropertyModal} color={'success'}>
              Publicar en marketplace
            </Button>
          )}
        </CardFooter>
      </Form>
      <TakeDownPropertyModal
        marketplace_type="rent"
        property_id={props.property_id}
        isOpen={takeDownPropertyModal}
        onToggle={toggleTakeDownPropertyModal}
        onConfirmation={updateMarketplaceRentStatus}
        warningText={
          '¿Estás seguro de quitar la propiedad del marketplace de rentas?'
        }
        confirmationText={'Si, quitar del marketplace rentas'}
        abortText={'No, continuar revisando...'}
      />
      <ActivatePropertyModal
        marketplace_type="rent"
        property_id={props.property_id}
        isOpen={activatePropertyModal}
        onToggle={toggleActivatePropertyModal}
        onConfirmation={updateMarketplaceRentStatus}
        warningText={
          'Esta acción hará que tu publicación sea accedida desde la marketplace propiweb, asi como recibir visitas y/o ofertas'
        }
        confirmationText={'Si, activar del marketplace rentas'}
        abortText={'No, continuar revisando...'}
      />
    </Card>
  );
};
