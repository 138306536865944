import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { useForm } from 'react-hook-form';
import { OfferBuroComplianceForm } from '../models/offerModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { Option } from '../../../models/formModel';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import {
  acceptdOfferByComplianceEndpoint,
  actionsCompliance,
  deniedOfferByComplianceEndpoint,
  loadReasonComplianceEndponint,
} from '../services/offerService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

interface Props {
  offerId: string;
}

const useOfferCompliance = ({ offerId }: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  //form
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    resetField,
  } = useForm<OfferBuroComplianceForm>({
    resolver: yupResolver(
      yup
        .object({
          offer_id: yup.string().default(offerId),
          reason: yup.string().required('Requerido'),
          comment: yup.string().required('Requerido'),
          is_approved: yup.boolean().default(false),
        })
        .required(),
    ),
  });

  const [optionOfferBuroCompliance, setOptionOfferBuroCompliance] = useState<
    Option[]
  >([]);

  const [apiErrors, setApiErrors] = useState('');

  useEffect(() => {
    //load data
    loadOptionReasonComplianceOffers().then().catch(console.error);
  }, []);

  const loadOptionReasonComplianceOffers = async () => {
    const { status, data } = await callEndpoint(
      loadReasonComplianceEndponint(token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setOptionOfferBuroCompliance(
        listToSelectOptionAdapter(response.data, 'key', 'value', false),
      );
    }
  };

  const doDeniedCompliance = async (form: OfferBuroComplianceForm) => {
    const reasonValue = optionOfferBuroCompliance.find(
      (o) => o.value === form.reason,
    );
    const payload: actionsCompliance = {
      is_approved_compliance: false,
      comment: form.comment,
      reason: reasonValue?.label,
    };

    setApiErrors('');
    const { status, data } = await callEndpoint(
      deniedOfferByComplianceEndpoint(offerId, payload, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      //reload
      navigate(0);
    } else {
      setApiErrors(response.message);
    }
  };

  const doApproveCompliance = async (offerId: string) => {
    const payload: actionsCompliance = {
      is_approved_compliance: true,
    };
    setApiErrors('');
    const { status, data } = await callEndpoint(
      acceptdOfferByComplianceEndpoint(offerId, payload, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      //reload
      navigate(0);
    } else {
      setApiErrors(response.message);
    }
  };

  //reset
  const resetValues = () => {
    setApiErrors('');
    resetField('reason');
    resetField('comment');
  };

  return {
    apiErrors,
    optionOfferBuroCompliance,
    isLoading,
    errors,
    control,
    register,
    setValue,
    resetField,
    handleSubmit,
    resetValues,
    doDeniedCompliance,
    doApproveCompliance,
  };
};

export default useOfferCompliance;
