import AdminPage from '../../components/admin/AdminPage';
import AdminUploadResource from '../../components/admin/AdminUploadResource';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
  deleteGalleryProjectEndpoint,
  deleteItemFromProjectGalleryEndpoint,
  getInvestorProjectDetailEndpoint,
  sortGalleryProjectEndpoint,
  syncProjectGalleryEndpoint,
  updateInvestorProjectEndpoint,
  uploadInvestorProjectGalleryResourcesEndpoint,
  uploadInvestorProjectResourcesEndpoint,
} from './services/investorService';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  InvestorProjectDetail,
  InvestorProjectVideoLinksFormPayload,
} from './models/investors';
import AdminResourceGallery from '../../components/admin/AdminResourceGallery';
import InvestorProjectVideoLinksForm from './components/InvestorProjectVideoLinksForm';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { wait } from '../../helpers/commonHelpers';

const InvestorProjectUploadResources: React.FC = () => {
  const { id: investorProjectId = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getProjectDetails = useCallback(
    () => getInvestorProjectDetailEndpoint(investorProjectId, token),
    [investorProjectId, token],
  );
  const [project] = useAdminEntity<InvestorProjectDetail>(
    getProjectDetails,
    setErrorAlert,
  );

  const uploadInvestorProjectCover = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;

      const formData = new FormData();
      files.forEach((file) => formData.append('cover', file));
      const { status } = await callEndpoint(
        uploadInvestorProjectResourcesEndpoint(
          investorProjectId,
          formData,
          token,
        ),
      );
      if (status === 200) navigate(0);
    },
    [investorProjectId, token, callEndpoint, navigate],
  );

  const uploadInvestorProjectGallery = useCallback(
    async (files: Array<any>) => {
      if (files.length < 0) return;

      //make n calls
      await Promise.allSettled(
        files.map((f) => {
          const formData = new FormData();
          formData.append('gallery', f);

          return callEndpoint(
            uploadInvestorProjectGalleryResourcesEndpoint(
              investorProjectId,
              formData,
              token,
            ),
          );
        }),
      );

      wait(2000)
        .then(async (r) => {
          //sync
          await syncProjectGallery();
        })
        .catch();
    },
    [investorProjectId, token, callEndpoint, navigate],
  );

  const syncProjectGallery = useCallback(async () => {
    await callEndpoint(syncProjectGalleryEndpoint(investorProjectId, token));
    // reload
    navigate(0);
  }, [investorProjectId, callEndpoint, navigate, token]);

  const deleteImageFromGallery = useCallback(
    async (galleryItemPath: string) => {
      const { status } = await callEndpoint(
        deleteItemFromProjectGalleryEndpoint(
          investorProjectId,
          galleryItemPath,
          token,
        ),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, callEndpoint, navigate, token],
  );

  const deleteAllImagesFromGallery = useCallback(
    async (gallery: string[]) => {
      const { status } = await callEndpoint(
        deleteGalleryProjectEndpoint(investorProjectId, token),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, callEndpoint, navigate, token],
  );

  const sortGallery = useCallback(
    async (gallery: string[]) => {
      const { status } = await callEndpoint(
        sortGalleryProjectEndpoint(investorProjectId, gallery, token),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setErrorAlert(
          'Lo sentimos, por el momento no es posible completar la acción',
        );
      }
    },
    [investorProjectId, callEndpoint, navigate, token],
  );

  const updateResourceLinks = useCallback(
    async (
      form: InvestorProjectVideoLinksFormPayload,
      errorRepository: Function,
    ) => {
      const { status, data } = await callEndpoint(
        updateInvestorProjectEndpoint(investorProjectId, form, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 200) navigate(0);
      else errorRepository(response.message);
    },
    [callEndpoint, investorProjectId, token, navigate],
  );

  const pageName = `Recursos de proyecto`;
  const pageParentName = `Preventa`;
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  if (!project || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  const coverGallery = [project.cover].filter((img) => img !== null);
  const projectGallery = [...project.gallery].filter((img) => img !== null);

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <InvestorProjectVideoLinksForm
                project={project}
                handleForm={updateResourceLinks}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadInvestorProjectCover}
            description={'Subir cover'}
            maxFiles={1}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={coverGallery}
            description={'Cover actual'}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <AdminUploadResource
            handleUpload={uploadInvestorProjectGallery}
            description={'Subir galería'}
          />
        </Col>
        <Col>
          <AdminResourceGallery
            gallery={projectGallery}
            description={'Galería actual'}
            onDelete={deleteImageFromGallery}
            onDeleteAll={deleteAllImagesFromGallery}
            onDrag={sortGallery}
            onSync={syncProjectGallery}
          />
        </Col>
      </Row>
    </AdminPage>
  );
};

export default InvestorProjectUploadResources;
