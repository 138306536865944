import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useAuth from '../../hooks/useAuth';
import {
  getProofIncomeDetailEndpoint,
  sendProofIncomeViaEmail,
} from './services/accountingService';
import useAdminEntity from '../../hooks/useAdminEntity';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import AccountingProofIncomeCard from './components/AccountingProofIncomeCard';
import { ProofIncomeDetail } from './models/proofIncomeModel';
import LoadingCard from '../../components/LoadingCard';
import AccountingProofIncomeClientCard from './components/AccountingProofIncomeClientCard';
import useModalState from '../../hooks/useModalState';
import AccountingSendProofEmailModal from './components/AccountingSendProofEmailModal';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const AccountingProofIncomeDetail = () => {
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(null);
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const pageName = 'Detalle comprobante de ingreso';
  const pageParentName = 'Contabilidad';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const { proofIncomeId = '' } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getProofIncomeDetail = useCallback(
    () => getProofIncomeDetailEndpoint(proofIncomeId, token),
    [proofIncomeId, token],
  );
  const [proofIncomeDetail] = useAdminEntity<ProofIncomeDetail>(
    getProofIncomeDetail,
    setErrorAlert,
  );

  const [showSendProofModal, toggleSendProofModal] = useModalState();
  const sendProofEmail = useCallback(
    async (id: string) => {
      const { status, data } = await callEndpoint(
        sendProofIncomeViaEmail(id, token),
      );
      if (status === 201) {
        toggleSendProofModal();
      } else {
        const response = fetchResponseAdapter(data);
        setErrorAlert(response.message);
        toggleSendProofModal();
      }
    },
    [callEndpoint, navigate, toggleSendProofModal, token],
  );

  if (!proofIncomeDetail || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  const sendProofModal = (
    <AccountingSendProofEmailModal
      proofId={proofIncomeId}
      isOpen={showSendProofModal}
      onToggle={toggleSendProofModal}
      onConfirmation={sendProofEmail}
      bodyText={`Enviaras el siguiente comprobante a ${proofIncomeDetail.clientInfo.email}`}
      confirmationText={'Entiendo, continuar'}
      abortText={'No, seguir revisando...'}
    />
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col>
          <AccountingProofIncomeCard
            proofIncome={proofIncomeDetail.proofIncome}
            proofOutcome={proofIncomeDetail.proofOutcome}
          />
        </Col>
        <Col>
          <AccountingProofIncomeClientCard
            clientInfo={proofIncomeDetail.clientInfo}
            toggleSendProofModal={toggleSendProofModal}
          />
        </Col>
      </Row>
      {sendProofModal}
    </AdminPage>
  );
};

export default AccountingProofIncomeDetail;
