import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageInvestorProjectForm from './components/ManageInvestorProjectForm';
import { InvestorProjectDetail, InvestorProjectForm } from './models/investors';
import { getInvestorProjectDetailEndpoint, updateInvestorProjectEndpoint } from './services/investorService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAdminEntity from '../../hooks/useAdminEntity';
import LoadingCard from '../../components/LoadingCard';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const InvestorProjectEdit: React.FC = () => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const {id: investorProjectId = ""} = useParams();
  const [errorAlert, setErrorAlert] = useState(null);

  const getInvestorProject = useCallback(() =>
    getInvestorProjectDetailEndpoint(investorProjectId, token),
    []);
  const [investorProject] = useAdminEntity<InvestorProjectDetail>(getInvestorProject, setErrorAlert);

  const editInvestorProject = useCallback(async (form: InvestorProjectForm, errorRepository: Function) => {
    const {
      status, data
    } = await callEndpoint(updateInvestorProjectEndpoint(investorProjectId, form, token));
    const response = fetchResponseAdapter(data);
    if (status === 200)
      navigate("/investors/projects")
    else
      errorRepository(response.message);
  }, []);

  const pageName = "Editar proyecto preventa";
  const pageParentName = "Preventa";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  if (!investorProject || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <ManageInvestorProjectForm investorProject={investorProject} handleForm={editInvestorProject} />
    </AdminPage>
  );
};

export default InvestorProjectEdit;