import CryptoJS from 'crypto-js';
import { AuthUser } from '../models/authUserModel';

const hash = process.env.REACT_APP_HASH || '';

const useCrypt = () => {
  const encrypt = (value: string | Object | Object[]) => {
    return CryptoJS.AES.encrypt(JSON.stringify(value), hash).toString();
  };

  const decrypt = (value: string): AuthUser | null => {
    try {
      const bytes = CryptoJS.AES.decrypt(value, hash);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
    //   console.error('Error on getting user auth information, log in again');
      return null;
    }
  };

  return {
    encrypt,
    decrypt,
  };
};

export default useCrypt;
