import React, { useCallback, useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDocumentsCatalog,
  getProfileDocuments,
  sendDocumentsProfile,
} from './services/documentService';
import useAuth from '../../hooks/useAuth';
import useUserCountry from '../../hooks/useUserCountry';
import useAdminEntity from '../../hooks/useAdminEntity';
import { Catalog, CatalogDocument } from './models/DocumentData';
import LoadingCard from '../../components/LoadingCard';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { IconEye, IconTrash, IconUpload } from '@tabler/icons-react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';

interface UploadedFile {
  file: File | null;
  id: string;
  text: string;
  isUploaded: boolean;
  document: string;
  code: string;
  input_mask: string;
  exists: boolean;
  error: string;
}

const DocumentsUser = () => {
  const { id: profileId = '' } = useParams();
  const [errorAlert, setErrorAlert] = useState(null);
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode = getCurrentCountry()?.code;
  const { callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const pageName = 'Administrar documentos';
  const pageParentName = 'Detalle de usuario';

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getDocumentCatalog = useCallback(
    () => getDocumentsCatalog(currentUserCountryCode as string),
    [],
  );

  const getDocumentByProfile = useCallback(
    () => getProfileDocuments(profileId, token),
    [],
  );
  const [catalogDocument] = useAdminEntity<CatalogDocument>(
    getDocumentCatalog,
    setErrorAlert,
  );
  const [existsDocuments] = useAdminEntity<any>(
    getDocumentByProfile,
    setErrorAlert,
  );

  const handleTextChange = (text: string, id: string) => {
    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].text = text;
      setUploadedFiles(newFiles);
    }
  };

  const removeFile = (id: string) => {
    const newFiles = uploadedFiles.filter((file) => file.id !== id);
    setUploadedFiles(newFiles);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    event.stopPropagation();
    const newFiles = [...uploadedFiles];
    const file = event.target.files?.[0] || null;
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].file = file;
      setUploadedFiles(newFiles);
    }
  };

  useEffect(() => {
    if (catalogDocument?.documents?.length > 0) {
      const initialUploadedFiles: any = catalogDocument?.documents?.map(
        (document: Catalog) => ({
          file: null,
          id: document.id,
          text: '',
          isUploaded: false,
          document: document?.document,
          code: document?.code,
          input_mask: document?.input_mask,
          error: ''
        }),
      );
      setUploadedFiles(initialUploadedFiles);
      
    }
  }, [catalogDocument?.documents]);

  useEffect(() => {
    const combinedDocuments = catalogDocument?.documents?.map(
      (document: any) => {
        const existsDocument = existsDocuments?.find(
          (existsDocument: any) => existsDocument.code === document.code,
        );

        if (existsDocument) {
          return { ...document, exists: true };
        } else {
          return { ...document, exists: false };
        }
      },
    );
    setUploadedFiles(combinedDocuments);
  }, [catalogDocument?.documents, existsDocuments]);

  const formDataToJson = (formData: any) => {
    return Object.fromEntries(formData.entries());
  };

  const submitFile = async (id: string, code: string) => {
    const uploadedFile = uploadedFiles.find((file) => file.id === id);
    if (!uploadedFile) return;
  
    const { file, text } = uploadedFile;
  
    let error = "";
  
    if (!file && !existsDocuments.some((doc: any) => doc.code === code)) {
      error = "Debes seleccionar un archivo.";
    }
  
    // Verificar si el documento ya existe antes de agregar document_input al formulario
    if (!existsDocuments.some((doc: any) => doc.code === code)) {
      if (!text) {
        error = "Debes ingresar un texto para el documento.";
      }
    }
  
    // Actualizar el estado de error solo para este archivo
    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].error = error;
      setUploadedFiles(newFiles);
    }
  
    if (error) {
      return;
    }
  
    const formData = new FormData();
    formData.append("document_type", code);
    formData.append("profile_id", profileId);
  
    // Si el documento no existe o si se ha modificado el texto, agregar document_input al formulario
    if (!existsDocuments.some((doc: any) => doc.code === code) && text ) {
      formData.append("document_input", text);
    }
    else if (text) {
        formData.append("document_input", text);
    }
  
    // Si el archivo está adjunto, agregarlo al formulario
    if (file) {
      formData.append("document", file);
    }
  
    try {
      // Llamada a la función para crear o actualizar el documento
      await createDocument(formData);
  
      // Actualizar el estado para marcar el archivo como enviado
      const newFiles = [...uploadedFiles];
      const index = newFiles.findIndex((file) => file.id === id);
      if (index !== -1) {
        newFiles[index].isUploaded = true;
        setUploadedFiles(newFiles);
      }
  
      // Limpiar el error
      setError("");
    } catch (error) {
      // Manejar cualquier error que pueda ocurrir al enviar el documento
      setError("Error al enviar el documento. Por favor, inténtalo de nuevo.");
    }
  };
  
  
  
  
  


  const createDocument = async (payload: any) => {  
    const { status, data } = await callEndpoint(
        sendDocumentsProfile(
        token,
        payload
      )
    );
    if (status === 201) {
      setTimeout(() => {
        return navigate(0)
      },2000)
      
    } else {
      document.body.style.overflowY = "scroll";
    }

  };


  if (!catalogDocument)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage name={pageName} parentName={pageParentName}>
      <h1 className=" mb-3">Documentación de usuarios</h1>
      <Form>
        <Row xs="3" className="">
          {uploadedFiles?.map(
            ({ file, id, text, isUploaded, document, code, input_mask, exists, error }) => (
              <Col key={id} className="p-4">
                <h3>{document}</h3>
                <FormGroup>
                  <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    accept=".pdf,image/*"
                    onChange={(e) => handleFileChange(e, id)}
                  />
                </FormGroup>
                {exists ? (
                  <FormGroup>
                    <p className="font-weight-bold mb-0">Numero de documento</p>
                    {existsDocuments?.map((doc: any) => {
                      if (doc?.code === code) {
                        const documentNumber = doc?.document_number || '';

                        return (
                          <InputMask
                            key={doc.id}
                            className="w-100 focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                            mask={input_mask}
                            alwaysShowMask={false}
                            onChange={(e) =>
                              handleTextChange(e.target.value, id)
                            }
                            defaultValue={documentNumber}
                          />
                        );
                      }
                    })}
                  </FormGroup>
                ) : (
                  <div className="mb-4">
                    <p className="mb-0 font-weight-bold">Numero de documento</p>
               
                    <InputMask
                      className="w-100 focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      mask={input_mask}
                      alwaysShowMask={false}
                      onChange={(e) => handleTextChange(e.target.value, id)}
                    />
                  </div>
                )}

                {file && (
                  <>
                    <p className="font-weight-bold text-[#212121] mb-3">
                      Documentos por subir
                    </p>
                    <div className="d-flex justify-content-between items-center mt-2">
                      <p className="text-[#757575] text-sm font-medium">
                        {file.name} -
                      </p>
                      <button style={{ background: 'none', border: 'none' }} onClick={() => removeFile(id)}>
                        <IconTrash color="#F44336" />
                      </button>
                    </div>
                  </>
                )}

                {exists && (
                  <>
                    <p className="font-weight-bold text-[#212121] mb-2">
                      Documentos subidos
                    </p>
                    {existsDocuments.map((doc: any) => {
                      if (doc.code === code) {
                        return (
                          <a
                            href={doc?.document_path}
                            target="_blank"
                            key={doc.id}
                            className="text-sm text-[#757575] flex justify-start items-center gap-2"
                            rel="noreferrer"
                          >
                           <IconEye color="#757575" /> {doc.document}
                          </a>
                        );
                      }
                    })}
                  </>
                )}

                <div>
                  <Button className='w-100 mt-3' onClick={() => submitFile(id, code)} color="primary">
                  <IconUpload color="#fff" />
                  {/* {isUploaded ? "Enviado" : "Subir documento"} */}
                  {
                    exists ? 'Actualizar documento' : 'Subir documento'
                  }
                  </Button>
                </div>
                {error && <p className='mt-2' style={{ color: '#F44336' }}>{error}</p>}
              </Col>
            ),
          )}
        </Row>
      </Form>
    </AdminPage>
  );
};

export default DocumentsUser;
