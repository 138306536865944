import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { DashboardMetric, ProperAssignedVisit, ProperMetrics } from '../models/dashboardModels';
import DashboardMetricCard from './DashboardMetricCard';
import DashboardVisitsTable from './DashboardVisitsTable';
import useAdminEntity from '../../../hooks/useAdminEntity';
import {
  getDashboardMetricsEndpoint,
  getDashboardOffersEndpoint,
  getDashboardVisitsEndpoint,
} from '../services/dashboardService';
import useAuth from '../../../hooks/useAuth';
import DashboardOffersCard from './DashboardOffersCard';
import useAdminPagination from '../../../hooks/useAdminPagination';
import AdminPaginator from '../../../components/admin/AdminPaginator';


const ProperDashboard: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getDashboardMetrics = useCallback(() => getDashboardMetricsEndpoint(token), [token]);
  const [dashboardMetrics] = useAdminEntity<ProperMetrics>(getDashboardMetrics, console.error);

  const [dashboardVisits, setDashboardVisits] = useState<ProperAssignedVisit[]>([]);
  const { paginator, loadItems: dashboardVisitLoadItems } = useAdminPagination(
    getDashboardVisitsEndpoint,
    setDashboardVisits,
    console.error,
  )

  const getDashboardOffers = useCallback(() => getDashboardOffersEndpoint(token), [token]);
  const [dashboardOffers] = useAdminEntity<any[]>(getDashboardOffers, console.error);

  const properAvailableMetricsMapper = new Map<string, any>([
    [
      "visits",
      {
        title: "Visitas",
        icon: "fa fa-person-booth",
      }
    ],
    [
      "offers",
      {
        title: "Ofertas",
        icon: "fa fa-money-check-alt",
      }
    ],
    [
      "contracts",
      {
        title: "Contratos",
        icon: "fa fa-file-signature",
        externalResourceURI: "/contract/view"
      }
    ],
    [
      "guest_visits",
      {
        title: "Visitas de guests",
        icon: "fa fa-user-friends",
        externalResourceURI: "/guest/visits"
      }
    ],
  ]);

  let properMetrics: DashboardMetric[] = []

  if (dashboardMetrics)
    properMetrics = Object.entries(dashboardMetrics).map(([key, value]) => (
      {
        title: properAvailableMetricsMapper.get(key) ? properAvailableMetricsMapper.get(key).title : "Métrica no conocida",
        currentValue: value.done || 0,
        total: value.total || 0,
        icon: properAvailableMetricsMapper.get(key) ? properAvailableMetricsMapper.get(key).icon : "",
        externalResourceURI: properAvailableMetricsMapper.get(key) ? properAvailableMetricsMapper.get(key).externalResourceURI : "",
      }
    ));

  return (
    <Container className="mt-6" fluid>
      <Row>
        {properMetrics.map((metric, metricIndex) => (
          <Col key={metricIndex} className="card-deck">
            <DashboardMetricCard metric={metric} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col lg={6}>
          <h1 className="my-3">Visitas</h1>
          {(dashboardVisits && dashboardVisits.length > 0) && (
            <DashboardVisitsTable
              visits={dashboardVisits}
              footer={
                <AdminPaginator
                  page={paginator.page}
                  totalPages={paginator.pageCount}
                  onPageChange={dashboardVisitLoadItems}
                />
              }
            />
          )}
        </Col>
        <Col lg={6}>
          <h1 className="my-3">Ofertas</h1>
          {(dashboardOffers && dashboardOffers.length > 0) && (
            <DashboardOffersCard offers={dashboardOffers} />
          )}
        </Col>
      </Row>
    </Container>
  )
};

export default ProperDashboard

