import { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import WithdrawalFormPreSale from './components/WithdrawalFormPreSale';
import { WithdrawalPreSaleForm } from './models/withdrawalModel';
import { useNavigate } from 'react-router-dom';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { createWithdrawalReasonPreSaleEndpoint } from './services/withdrawalPreSaleService';
import useAuth from '../../hooks/useAuth';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const WithdrawalCreatePreSale = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const pageName = 'Desistimiento  pre-venta';
  const pageParentName = 'Desistimiento';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const handleCreateWithdrawal = useCallback(
    async (form: WithdrawalPreSaleForm) => {
      const { status, data } = await callEndpoint(
        createWithdrawalReasonPreSaleEndpoint(form, token),
      );
      const response = fetchResponseAdapter(data);
      if (status === 201) {
        navigate(`/withdrawal/`);
      } else if (status === 409) {
        setErrorAlert('Desestimiento generado previamente');
      } else {
        setErrorAlert('No fue posible generar el desestimiento');
      }
    },
    [],
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <WithdrawalFormPreSale handleForm={handleCreateWithdrawal} />
    </AdminPage>
  );
};

export default WithdrawalCreatePreSale;
