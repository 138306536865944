import React, { useEffect, useRef, useState } from 'react';
import {
    CreateImageTower,
  CreateTowerLayoutLevel,
  FacadePolygon,
  TowerLayoutDetail,
} from '../models/towerLayoutModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { createImagePolygonTowerLevel, createPolygonTowerLevel, polygonDetailTowerEndpoint } from '../../withdrawal/services/projectService';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { PROJECT_DISTRIBUTION_LIST } from '../constants/towerLayoutConstants';
import { Option } from '../../../models/formModel';

const useCreateImageTower = () => {
  const distributionStatusOptions = listToSelectOptionAdapter(
    PROJECT_DISTRIBUTION_LIST,
    'value',
    'display_value',
  );
  const { id = '' } = useParams();
  const [towerInfo, setTowerInfo] = useState<TowerLayoutDetail>();
  const [points, setPoints] = useState<FacadePolygon[]>([]);
  const [selectPolygonStatus, setSelectPolygonStatus] = useState<any>()
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Nuevo estado
  const canvasRef = useRef(null);
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<CreateImageTower>({
    resolver: yupResolver(
      yup
        .object({
          
        })
        .required(),
    ),
  });


  const addCurrentImage = async (form: any) => {
    console.log({form})
    const { status, data } = await callEndpoint(createImagePolygonTowerLevel(id, form, token));
     if (status === 200) {
    alert('Imagen asignada con exito')
    navigate(0)
     }
    
  }

  return {
    // Form
    setIsEditing,
    isEditing,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
   
    isLoading,
    towerInfo,
    canvasRef,

    points,

    selectPolygonStatus,

    addCurrentImage
  };
};

export default useCreateImageTower;
