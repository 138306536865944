import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import {
  getProofOutcomeDetailEndpoint,
  updateProofOutcomeEndpoint,
} from './services/accountingService';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  ProofOutcomeForm,
  ProofOutcomeListItem,
  ProofOutcomePayload,
  ProofOutcomeSummary,
  UpdateProofOutcomeForm,
} from './models/proofIncomeModel';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import LoadingCard from '../../components/LoadingCard';
import AccountingProofOutcomeForm from './components/AccountingProofOutcomeForm';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

const AccountingProofOutcomeUpdate: React.FC = () => {
  const { id = '' } = useParams();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const pageName = 'Editar comprobante de egresos';
  const pageParentName = 'Comprobante de egreso';
  const parentUri = `/accounting/proof-outcome/${id}`;
  const [errorAlert, setErrorAlert] = useState<any>(null);

  const getProofIncomeDetail = useCallback(
    () => getProofOutcomeDetailEndpoint(id, token),
    [id, token],
  );
  const [proofOutcomeDetail] = useAdminEntity<ProofOutcomeListItem>(
    getProofIncomeDetail,
    setErrorAlert,
  );

  let proofOutcomeSummary: ProofOutcomeSummary = {
    amount: '0',
    amount_aux: '0',
    concept_outcome: '',
    generated_at: '',
    owner_account_number: '',
    owner_bank: '',
    owner_document: '',
    owner_lastname: '',
    owner_name: '',
    payment_outcome: '',
    ref_outcome: '',
    additional_comments: '',
    owner_email: '',
    owner_id: '',
  };

  let outcomeProofPayload: ProofOutcomePayload = {
    outcome_product: '',
    owner_id: '',
    selectedProofIncomes: [],
  };

  const handleUpdateProofOutcome = useCallback(
    async (form: ProofOutcomeForm) => {
      const { proofOutcome } = proofOutcomeDetail;

      const payload: UpdateProofOutcomeForm = {
        ...form,
      };
      delete payload.owner_id;
      delete payload.outcome_product;
      delete payload.proof_incomes;

      const { status, data } = await callEndpoint(
        updateProofOutcomeEndpoint(proofOutcome.id, payload, token),
      );

      const response = fetchResponseAdapter(data);
      if (status === 200) {
        navigate(`/accounting/proof-outcome/${response.data.id}`);
      } else {
        setErrorAlert('No fue posible actualizar el comprobante de egreso');
      }
    },
    [callEndpoint, navigate, token, proofOutcomeDetail],
  );

  if (!proofOutcomeDetail || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  if (proofOutcomeDetail !== undefined) {
    const generated_at = proofOutcomeDetail.proofOutcome.generated_at
      .trim()
      .split('/');
    const formatted_date = `${generated_at[2]}-${generated_at[1]}-${generated_at[0]}`;
    proofOutcomeSummary = {
      amount: proofOutcomeDetail.proofOutcome?.amount
        ? proofOutcomeDetail.proofOutcome.amount
        : '0',
      amount_aux: proofOutcomeDetail.proofOutcome?.amount_aux
        ? proofOutcomeDetail.proofOutcome.amount_aux.toString()
        : '0',
      concept_outcome: proofOutcomeDetail.proofOutcome.concept_outcome,
      generated_at: formatted_date,
      owner_account_number: proofOutcomeDetail.ownerInfo.account_number,
      owner_bank: proofOutcomeDetail.ownerInfo.bank,
      owner_document: proofOutcomeDetail.ownerInfo.document_number,
      owner_lastname: proofOutcomeDetail.ownerInfo.lastname,
      owner_name: proofOutcomeDetail.ownerInfo.name,
      payment_outcome: proofOutcomeDetail.proofOutcome.payment_outcome,
      ref_outcome: proofOutcomeDetail.proofOutcome.ref_outcome,
      additional_comments: proofOutcomeDetail.proofOutcome.additional_comments,
      owner_email: proofOutcomeDetail.ownerInfo.email,
      owner_id: proofOutcomeDetail.ownerInfo.id,
    };

    outcomeProofPayload = {
      outcome_product: proofOutcomeDetail.proofOutcome.outcome_product,
      owner_id: proofOutcomeDetail.ownerInfo.id,
      selectedProofIncomes: proofOutcomeDetail.proofIncomes.map(
        (incomes) => incomes.id,
      ),
    };
  }

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };
  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      parentUri={parentUri}
      globalAlertOptions={globalAlertOptions}
    >
      <h2>Editar comprobante [{proofOutcomeDetail.proofOutcome.code}]</h2>
      <AccountingProofOutcomeForm
        handleForm={handleUpdateProofOutcome}
        outcomeProofPayload={outcomeProofPayload}
        proofResume={proofOutcomeSummary}
        isUpdate={true}
      />
    </AdminPage>
  );
};

export default AccountingProofOutcomeUpdate;
