import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  UncontrolledTooltip,
  Container,
  Row,
  CardHeader,
  Card,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import { createSearchParams } from "react-router-dom";

import TimelineHeader from "../../components/headers/TimelineHeader";
import { Paginator } from "../../models/formModel";
import { PublishPropertiesList } from "./models/propertyModel";
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { getPhotoSessionsPropertiesEndpoint } from "./services/propertyService";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import ProfileAvatarGroup from "../../components/ProfileAvatarGroup";
import useAuth from "../../hooks/useAuth";

const PublishProperties = () => {

  const { callEndpoint } = useCallApiAndLoad()
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [properties, setProperties] = useState<PublishPropertiesList[]>([])
  const [paginator, setPaginator] = useState<Paginator>({
    page: 0,
    take: 0,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false
  })

  useEffect(() => {
    loadProperties()
      .catch(e => console.error(e))
  }, [])

  const loadProperties = async (page: number = 0, take: number = 0) => {
    let query: string = ''
    let filters: any = {
      status: 'created'
    }
    if (page > 0 && take > 0) {
      filters.page = page.toString()
      filters.take = take.toString()
    }
    query = `?${createSearchParams(filters)}`
    const {
      status, data
    } = await callEndpoint(getPhotoSessionsPropertiesEndpoint(query, token))
    if (status === 200) {
      const response = fetchResponseAdapter(data)
      setProperties(response.data.items || [])
      setPaginator(response.data.meta)
    }
  }

  return (
    <>
      <TimelineHeader name="Lista de propiedades creadas" parentName="Sesiones agendadas" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0"></h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Propiedad</th>
                    <th scope="col">ID</th>
                    <th scope="col">Dirección</th>
                    <th scope="col">Propietario</th>
                    <th scope="col">Fecha sesión fotografías</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    properties.length > 0 && properties.map((property) => (
                      <tr key={property.id}>
                        <th scope="row">
                          <img
                            alt="..."
                            width={175}
                            height={100}
                            src={
                              property.cover
                                ? property.cover
                                : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                            }

                          />
                        </th>
                        <td>{property.code}</td>
                        <td>{property.address}</td>
                        <td>
                          <ProfileAvatarGroup
                            code={property.code}
                            name={
                              property.landlordInfo && property.landlordInfo.name
                                ? property.landlordInfo.name
                                : ''
                            }
                            avatar={
                              property.landlordInfo && property.landlordInfo.avatar
                                ? property.landlordInfo.avatar
                                : null
                            }
                          />
                        </td>
                        <td>{ property?.requestPhotographyInfo.dateTime || 'No agendada' }</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href={`/properties/publish/${property.id}`}
                              >
                                Ver publicación
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={!paginator.hasPreviousPage ? 'disabled' : ''}>
                      <PaginationLink
                        onClick={(e) => loadProperties(paginator.page - 1, paginator.take)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Anterior</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink>
                        {paginator.page}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={!paginator.hasNextPage ? 'disabled' : ''}>
                      <PaginationLink
                        onClick={(e) => loadProperties(paginator.page + 1, paginator.take)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Siguiente</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>

          </div>
        </Row>

      </Container>
    </>
  )
}

export default PublishProperties