import React, { useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import useAdminRent from './hooks/useAdminRent';
import LoadingCard from '../../components/LoadingCard';
import PropertyAmenities from './components/Rent/PropertyAmenities';
import { buildFormCharacteristics } from './adapters/propertyAdapter';

const AdminPropertyAmenities = () => {
  const { id: propertyId = '' } = useParams();
  const [errorAlert, setErrorAlert] = useState<string>('');
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(''),
    title: 'Error',
    text: errorAlert || '',
  };

  const pageName = 'Administrar características de la propiedad';
  const pageParentName = 'Propiedades';

  const { formUpdateData } = useAdminRent({ propertyId });

  if (!formUpdateData) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  const formC = buildFormCharacteristics(
    formUpdateData.propertyToCharacteristics,
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      <Row>
        <Col md="8">
          <PropertyAmenities
            property_id={propertyId}
            has_white_goods={formUpdateData.has_white_goods}
            is_furnished={formUpdateData.is_furnished}
            pets_allowed={formUpdateData.pets_allowed}
            amenities_info={formUpdateData.propertyToAmenities}
            bathrooms={formC.bathrooms}
            parkings={formC.parking}
            rooms={formC.rooms}
            half_bathrooms={formC.half_bathrooms}
            square_mts={formC.mts}
            />
        </Col>
      </Row>


    </AdminPage>
  );
};

export default AdminPropertyAmenities;
