import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import AdminPage from '../../components/admin/AdminPage';
import AdminPaginator from '../../components/admin/AdminPaginator';
import useAdminPagination from '../../hooks/useAdminPagination';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { PreSalesTransactionList } from './models/investors';
import { getInvestorPaymentsListEndpoint } from './services/investorService';
import { getLandlordByNameEndpoint } from '../accounting/services/accountingService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import GridSearchBar from '../../components/searchBar/GridSearchBar';

const InvestorPaymentList: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);

  const [transactions, setTransactions] = useState<PreSalesTransactionList[]>(
    [],
  );

  const { paginator, loadItems } = useAdminPagination(
    getInvestorPaymentsListEndpoint,
    setTransactions,
    console.error,
  );

  const pageName = 'Lista de compras preventas';
  const pageParentName = 'Pagos';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const investorFilters = [
    {
      label: 'Unidad',
      value: 'code',
      apiParamName: 'unit_name',
      inputType: 'text',
      isUnique: true,
      placeholder: 'Ingresa unidad',
    },
    {
      label: 'Nombre inversionista',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del inversionista',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const componentHeader = <GridSearchBar filtersList={investorFilters} />;

  return (
    <AdminPage
      header={componentHeader}
      name={pageName}
      parentName={pageParentName}
      footer={
        <AdminPaginator
          page={paginator.page}
          totalPages={paginator.pageCount}
          onPageChange={loadItems}
        />
      }
      globalAlertOptions={globalAlertOptions}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">COD proyeto</th>
            <th scope="col">Proyecto</th>
            <th scope="col">Modelo</th>
            <th scope="col">Unidad</th>
            <th scope="col">Precio</th>
            <th scope="col">Inversionista</th>
            <th scope="col">Correo</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 &&
            transactions.map((transaction) => (
              <tr key={`${transaction.id}`}>
                <td>{transaction.code}</td>
                <td>{transaction.name}</td>
                <td>{transaction.model_name}</td>
                <td>{transaction.model_unit}</td>
                <td>{transaction.unit_price}</td>
                <td>{transaction.investor_name}</td>
                <td>{transaction.investor_email}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/investors/payments/${transaction.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </AdminPage>
  );
};

export default InvestorPaymentList;
