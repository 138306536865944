import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ContractPaymentsPreviewCard from './ContractPaymentsPreviewCard';
import { ContractQuotaPreview } from '../models/contractModel';
import { getContractQuotasPreview } from '../services/contractService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import useAuth from '../../../hooks/useAuth';

export interface IProps {
  onAction: () => void;
  onError: (errorText: string) => void;
  contractId: string;
}

const GenerateContractPaymentMandateCard: React.FC<IProps> = ({onAction, onError, contractId}) => {
  const { callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const [contractQuotasPreview, setContractQuotasPreview] = useState<ContractQuotaPreview[]>([]);
  const [showQuotasPreview, setShowQuotasPreview] = useState<boolean>(false);

  useEffect(() => {
    const generateQuotaPreview = async () => {

      const { status, data } = await callEndpoint(
        getContractQuotasPreview(contractId, token)
      )

      const response = fetchResponseAdapter(data);
      if (status === 200) {
        setContractQuotasPreview(response.data);
      } else {
        onError(`(${response.code}) ${response.message}`);
      }
    };

    generateQuotaPreview().catch(e => onError(e.toString()));

  }, [showQuotasPreview]);

  const toggleQuotasPreview = () => setShowQuotasPreview(showPreview => !showPreview);

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">Generar mandato de pago y cuotas</h3>
            <p>Esta acción generará el mandato de pago y el detalle de las cuotas de alquilar señaladas en el precio de oferta por el periodo de meses contrato.</p>
            <p>*Esta acción habilitará en el perfil del tenant la opción de mis alquileres, poder ver este contrato y poder realizar pagos en todos los métodos autorizados*</p>
          </Col>
          {showQuotasPreview && (
            <Col>
              <ContractPaymentsPreviewCard quotas={contractQuotasPreview} />
            </Col>
          )}
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="justify-content-md-center">
          <Col md="3">
            {!showQuotasPreview && (
              <Button
                block
                onClick={toggleQuotasPreview}
                color="warning" size="md" type="button">
                Generar
              </Button>
            )}
            {showQuotasPreview && (
              <Button
                block
                onClick={onAction}
                color="primary" size="md" type="button">
                Publicar mandato de pago
              </Button>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default GenerateContractPaymentMandateCard;