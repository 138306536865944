import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { NavLink } from 'reactstrap';
import React from 'react';
import { ProofOutcomeListItemProofOutcome } from '../models/proofIncomeModel';

export interface Props {
  proofOutcome: ProofOutcomeListItemProofOutcome;
}
const AccountProofOutcomeCard: React.FC<Props> = ({ proofOutcome }) => {
  const fieldMapper: DisplayItem[] = [
    {
      title: (
        <>
          <i className="ni ni-ruler-pencil mr-2" /> Editar comprobante
        </>
      ),
      value: '',
      href: `/accounting/proof-outcome/${proofOutcome.id}/edit`,
    },
    {
      title: 'Código',
      value: proofOutcome.code,
    },
    {
      title: 'Monto',
      value: proofOutcome.amount,
    },
    {
      title: 'Referencia',
      value: proofOutcome.ref_outcome,
    },
    {
      title: 'Concepto',
      value: proofOutcome.concept_outcome,
    },
    {
      title: 'Generado el',
      value: proofOutcome.generated_at,
    },
    {
      title: 'Tipo de pago',
      value: proofOutcome.payment_outcome,
    },
  ];

  if (proofOutcome.url_file) {
    fieldMapper.push({
      title: '',
      value: (
        <NavLink href={proofOutcome.url_file || ''} target="_blank">
          <i className={'fa fa-duotone fa-file-contract'} /> Ver comprobante en
          PDF
        </NavLink>
      ),
    });
  }

  return (
    <AdminListGroup
      title={'Información comprobante de egreso'}
      items={fieldMapper}
    />
  );
};

export default AccountProofOutcomeCard;
