import React, { useEffect, useState } from 'react'
import { createSearchParams } from 'react-router-dom';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage'
import AdminPaginator from '../../components/admin/AdminPaginator';
import LoadingCard from '../../components/LoadingCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { GuestPreBookingInvestor } from './models/guestInvestorModel';
import { getGuestPreBookingInvestorEndpoint } from './services/guestInvestorService';

const InvestGuestPreBooking = () => {
  const [errorAlert, setErrorAlert] = useState(null);
  const pageName = "Pre reservas de Invitados";
  const pageParentName = "Inversiones";
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const [guestPreBooking, setGuestPreBooking] = useState<GuestPreBookingInvestor[]>([])

  const loadGuestPreBookingsInvestors = async (page: number = 0, take: number = 0) => {
    let filterBuilder: any = {};
    if (page > 0) {
      filterBuilder.page = page.toString();
    }
    if (take > 0) {
      filterBuilder.take = take.toString();
    }
    const queryString = `?${createSearchParams(filterBuilder)}`;
    const {
      status, data
    } = await callEndpoint(getGuestPreBookingInvestorEndpoint(queryString, token));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestPreBooking(response.data.items || []);
      const { page, pageCount } = response.data.meta;
      setPaginator({ page, pageCount });
    } else {
      setErrorAlert(response.message);
    }
  };

  useEffect(() => {
    loadGuestPreBookingsInvestors()
      .catch(e => console.error(e))
  }, [])

  if (isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={
        <AdminPaginator
          page={paginator.page}
          totalPages={paginator.pageCount}
          onPageChange={loadGuestPreBookingsInvestors} />
      }
      globalAlertOptions={globalAlertOptions}
    >
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Estado</th>
            <th scope="col">Nombre</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Email</th>
            <th scope="col">ID Proyecto</th>
            <th scope="col">Proyecto</th>
            <th scope="col">Modelo</th>
            <th scope="col">Unidad</th>
            <th scope="col">Precio base</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {
            guestPreBooking.length > 0 && guestPreBooking.map((guestPreBooking) => (
              <tr key={`${guestPreBooking.guestInfo.id}-${guestPreBooking.projectInfo.id}`}>
                <td>
                  <Badge color={guestPreBooking.status === "created" ? "warning" : "primary"}>
                    {guestPreBooking.status === "created" ? "Nueva" : "Procesada"}
                  </Badge>
                </td>
                <td>{guestPreBooking.guestInfo.fullname}</td>
                <td>{guestPreBooking.guestInfo.phone}</td>
                <td>{guestPreBooking.guestInfo.email}</td>
                <td>{guestPreBooking.projectInfo.code}</td>
                <td>
                  <img
                    alt="..."
                    width={175}
                    height={100}
                    src={
                      guestPreBooking.projectInfo.cover
                        ? guestPreBooking.projectInfo.cover
                        : 'https://storage.googleapis.com/assets-us-east4-propilatam-dev/properties/default/default_1.jpg'
                    }
                  />
                </td>
                <td>{ guestPreBooking.modelInfo.name }</td>
                <td>{ guestPreBooking.unitInfo.name }</td>
                <td>{ guestPreBooking.unitInfo.base_price }</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        href={`/investors/guest/pre-booking/${guestPreBooking.id}`}
                      >
                        Ver detalle
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </AdminPage>
  )
}

export default InvestGuestPreBooking
