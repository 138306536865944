import { Button, Modal } from 'reactstrap';

interface Props {
  proofId: string;
  isOpen: boolean;
  onToggle: () => void;
  onConfirmation: (id: string) => void;
  bodyText: string;
  confirmationText: string;
  abortText: string;
}

export default function AccountingSendProofEmailModal({
  proofId,
  isOpen,
  onToggle,
  onConfirmation,
  bodyText,
  confirmationText,
  abortText,
}: Props) {
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Confirmación de acción
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{bodyText}</p>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          onClick={() => onConfirmation(proofId)}
        >
          {confirmationText}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );
}
