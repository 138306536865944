import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { ChildrenQuotaDetail } from '../models/contracts';

export interface Props {
  childrenQuotaDetails: ChildrenQuotaDetail[];
}

const ChildrenQuotaDetailCard: React.FC<Props> = ({ childrenQuotaDetails }) => {
  const itemList: DisplayItem[] = [];

  if (childrenQuotaDetails.length > 0) {
    childrenQuotaDetails.forEach((children) => {
      itemList.push({
        title: `${children.description}`,
        value: `${children.price}`,
        key: children.id,
      });
    });
  }

  return (
    <AdminListGroup title={'Detalle de cargos adicionales'} items={itemList} />
  );
};

export default ChildrenQuotaDetailCard;
