import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';
import PropertyDetailCard from '../../components/PropertyDetailCard';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import useModalState from '../../hooks/useModalState';
import ActivateEntityInvestorModal from '../../components/landlordInvestors/ActivateEntityInvestorModal';
import InvestorProfileCard from '../../components/landlordInvestors/InvestorProfileCard';
import { GuestPreBookingInvestor } from './models/guestInvestorModel';
import InvestorPreBookingDetailCard from './components/InvestorPreBookingDetailCard';
import {
  activateGuestInvestorEndpoint, disableAlreadyActivatedPrebookingEndpoint, disableEndpoint,
  getGuestPreBookingInvestorDetailEndpoint,
} from './services/guestInvestorService';
import CancelEntityInvestorModal from '../../components/landlordInvestors/CancelEntityInvestorModal';

const InvestGuestPreBookingDetail = () => {
  const pageName = "Detalle de Pre reserva";
  const pageParentName = "Pre reservas de invitados";
  const { id: guestPreBookingId = "" } = useParams();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [guestPreBooking, setGuestPreBooking] = useState<GuestPreBookingInvestor>()
  const [errorAlert, setErrorAlert] = useState(null);
  const [showDisablePreBookingModal, toggleDisablePreBookingModal] = useModalState();
  const [showActivateGuestModal, toggleActivateGuestModal] = useModalState();

  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: "Error",
    text: errorAlert || "",
  }

  const activateGuest = async (guestId: string) => {
    const { status, data } = await callEndpoint(
      activateGuestInvestorEndpoint(guestId, token)
    );
    if (status === 200) {
      toggleActivateGuestModal();
      navigate('/investors/guest/pre-booking');
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleActivateGuestModal();
    }
  }

  const disablePreBooking = useCallback(async (preBookingId: string) => {
    const { status, data } = await callEndpoint(
      disableEndpoint(preBookingId, token)
    );
    if (status === 200) {
      toggleDisablePreBookingModal();
      navigate('/investors/guest/pre-booking');
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleDisablePreBookingModal();
    }
  }, [callEndpoint, navigate, toggleDisablePreBookingModal, token]);

  const disableAcceptedPreBooking = useCallback(async (preBookingId: string) => {
    const { status, data } = await callEndpoint(
      disableAlreadyActivatedPrebookingEndpoint(preBookingId, token)
    );
    if (status === 200) {
      toggleDisablePreBookingModal();
      navigate('/investors/guest/pre-booking');
    } else {
      const response = fetchResponseAdapter(data)
      setErrorAlert(response.message);
      toggleDisablePreBookingModal();
    }
  }, [callEndpoint, navigate, toggleDisablePreBookingModal, token]);

  const loadGuestPrebooking = async () => {
    const { status, data } = await callEndpoint(
      getGuestPreBookingInvestorDetailEndpoint(guestPreBookingId, token)
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setGuestPreBooking(response.data);
    } else {
      setErrorAlert(response.message);
    }
  };
  useEffect(() => {
    loadGuestPrebooking()
      .catch(e => setErrorAlert(e.toString()));
  }, []);

  if (!guestPreBooking || isLoading)
    return (
      <LoadingCard name={pageName} parent_name={pageParentName} />
    );

  const processedPrebooking = guestPreBooking.status.toLowerCase() === "processed";

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
      footer={<>
        <Button color="warning" onClick={() => toggleDisablePreBookingModal()}>
          Anular pre reserva
        </Button>
        {(!processedPrebooking) && (
          <Button color="primary" onClick={() => toggleActivateGuestModal()}>
            Aceptar Guest
          </Button>
        )}
      </>}
    >
      <Row>
        {/* Profile section */}
        {(!processedPrebooking) && (
          <Col className="order-xl-2 pt-10" xl="4">
            <InvestorProfileCard guestInfo={guestPreBooking.guestInfo} />
          </Col>
        )}
        <Col>
          {/* Property info */}
          <PropertyDetailCard
            cover={guestPreBooking.projectInfo.cover || ''}
            address={guestPreBooking.projectInfo.address}
            code={guestPreBooking.projectInfo.code}
            price={''}
          />
          {/* Visit detail info */}
          <InvestorPreBookingDetailCard
            projectInfo={guestPreBooking.projectInfo}
            modelInfo={guestPreBooking.modelInfo}
            unitInfo={guestPreBooking.unitInfo} />
        </Col>
      </Row>
      <ActivateEntityInvestorModal
        guestId={guestPreBooking.guestInfo.id}
        isOpen={showActivateGuestModal}
        onToggle={toggleActivateGuestModal}
        onConfirmation={activateGuest}
        // bodyText={"Al activar el guest aceptas la pre reserva del mismo. Además vuelves al guest un Inversonista."}
        bodyText={
          guestPreBooking.guestInfo.is_registered
            ? 'Al aceptar asignaras el lead al inversionista/propietario encontrado.'
            : "Al activar el guest aceptas todas las pre reservas del mismo. Además vuelves al guest un Inversonista."
        }
        confirmationText={"Entiendo, continuar"}
        abortText={"No, seguir revisando..."}
      />
      <CancelEntityInvestorModal
        guestId={guestPreBooking.id}
        isOpen={showDisablePreBookingModal}
        onToggle={toggleDisablePreBookingModal}
        onConfirmation={processedPrebooking ? disableAcceptedPreBooking : disablePreBooking}
        bodyText={"Al anular la pre reserva se vuelve a colocar la unidad como disponible en el marketplace."}
        confirmationText={"Entiendo, anular"}
        abortText={"No, seguir revisando..."}
        titleText={"Anular pre reserva"}
      />
    </AdminPage>
  )
}

export default InvestGuestPreBookingDetail