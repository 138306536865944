import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { Option } from '../../../models/formModel';
import {
  createDownPaymentQuotasEndpoint,
  getDownPaymentQuotaConfigEndpoint,
  previewDownPaymentQuotasEndpoint,
} from '../services/downPaymentService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { plainListToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { useForm } from 'react-hook-form';
import {
  CreateDownPaymentInQuotasForm,
  PreviewDownPaymentQuotas,
} from '../models/downPaymentModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

interface Props {
  transactionPreSaleId: string;
  transactionDetailId: string;
}

const useDownPaymentQuotas = ({
  transactionDetailId,
  transactionPreSaleId,
}: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [availableQuotas, setAvailableQuotas] = useState<Option[]>([]);
  const [downPaymentPreviewDues, setDownPaymentPreviewDue] = useState<
    PreviewDownPaymentQuotas[]
  >([]);

  const [apiErrors, setApiErrors] = useState('');

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    resetField,
  } = useForm<CreateDownPaymentInQuotasForm>({
    resolver: yupResolver(
      yup.object({
        quotas: yup
          .number()
          .required('Seleccione el valor de cuotas a aplicar'),
      }),
    ),
  });

  useEffect(() => {
    loadConfigDownPayment().catch((e) => console.error(e));
  }, []);

  //fill select
  const loadConfigDownPayment = async () => {
    if (transactionPreSaleId !== '') {
      const { status, data } = await callEndpoint(
        getDownPaymentQuotaConfigEndpoint(transactionPreSaleId, token),
      );
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setAvailableQuotas(
          plainListToSelectOptionAdapter(response.data.quotas_available),
        );
      }
    }
  };

  //preview
  const doPreviewDownPaymentQuotas = async (
    form: CreateDownPaymentInQuotasForm,
  ) => {
    setApiErrors('');
    const { status, data } = await callEndpoint(
      previewDownPaymentQuotasEndpoint(
        transactionPreSaleId,
        transactionDetailId,
        form,
        token,
      ),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setDownPaymentPreviewDue(response.data);
    } else {
      setApiErrors(response.message);
    }
  };

  //create
  const doCreateDownPaymentQuotas = async (
    form: CreateDownPaymentInQuotasForm,
  ) => {
    const { status, data } = await callEndpoint(
      createDownPaymentQuotasEndpoint(
        transactionPreSaleId,
        transactionDetailId,
        form,
        token,
      ),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      //reload
      navigate(0);
    } else {
      setApiErrors(response.message);
    }
  };

  //reset
  const resetValues = () => {
    setApiErrors('');
    setDownPaymentPreviewDue([]);
    resetField('quotas');
  };

  return {
    availableQuotas,
    downPaymentPreviewDues,
    isLoading,
    errors,
    apiErrors,
    control,
    register,
    setValue,
    resetField,
    handleSubmit,
    doPreviewDownPaymentQuotas,
    doCreateDownPaymentQuotas,
    resetValues,
  };
};

export default useDownPaymentQuotas;
