import React from 'react';
import { ProperAssignedOffer } from '../models/dashboardModels';
import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardText,
  Col,
  ListGroup,
  ListGroupItem,
  NavLink,
  Row,
} from 'reactstrap';
import ProfileAvatarGroup from '../../../components/ProfileAvatarGroup';
import { useNavigate } from 'react-router-dom';
import VisitMarketplaceTypeBadge, {
  LeadMarketplaceType,
} from '../../../components/VisitMarketplaceTypeBadge';

export interface Props {
  offers: ProperAssignedOffer[];
}

const DashboardOffersCard: React.FC<Props> = ({ offers }) => {
  const navigate = useNavigate();

  const allOffersComponents = offers.map((offer) => (
    <Card className={`p-1`} key={offer.id}>
      <CardBody
        className="blockquote mb-0"
        onClick={() => navigate(`/offer/${offer.offerInfo.id}/detail`)}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
      >
        <ListGroup className="list my--4" flush>
          <ListGroupItem className={`px-0`}>
            <Row className="align-items-center">
              <ProfileAvatarGroup
                code={offer.tenantInfo.id}
                name={offer.tenantInfo.name}
                avatar={offer.tenantInfo.avatar}
                avatar_size="avatar-xl"
              />
              <Col className={`col-auto`}>
                <Row>
                  <Col>
                    <p className="mb-0">
                      {offer.tenantInfo.name}
                      {`   `}
                      <a
                        href={`/tenant/${offer.tenantInfo.id}/profile`}
                        target="_blank"
                        className="fas fa-eye"
                      />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Badge color="success">{offer.offerInfo.amount}</Badge>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mb-0">
                      <span className="font-weight-bold">Código Prop.</span>
                      &nbsp;{offer.propertyInfo.code}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className="mb-0">
                      <span className="font-weight-bold">Periodo:</span>
                      &nbsp;{offer.offerInfo.period}
                    </p>
                    <VisitMarketplaceTypeBadge
                      marketplace_type={offer.offerInfo.marketplace_type}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <CardFooter>
        <NavLink href={`/offer/${offer.id}/detail`} color="primary">
          Ver detalle
        </NavLink>
      </CardFooter>
    </Card>
  ));

  return (
    // overflow-y: scroll; height:400px;
    <Card style={{ overflowY: 'scroll', maxHeight: '500px' }}>
      <CardBody>
        <div className="card-columns">{allOffersComponents}</div>
      </CardBody>
    </Card>
  );
};

export default DashboardOffersCard;
