import AdminPage from '../../components/admin/AdminPage';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import React, { useCallback, useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { TransactionList } from './models/contracts';
import { contractPaymentsListEndpoint } from './services/contractPaymentsService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import AdminPaginator from '../../components/admin/AdminPaginator';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import {
  getLandlordByNameEndpoint,
  getTenantByNameEndpoint,
} from '../accounting/services/accountingService';
import useAdminPagination from '../../hooks/useAdminPagination';

const ContractPaymentsList: React.FC = () => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const [contractPayments, setContractPayments] = useState<TransactionList[]>(
    [],
  );

  const { paginator, loadItems } = useAdminPagination(
    contractPaymentsListEndpoint,
    setContractPayments,
    console.error,
  );

  const contractStatusMapper = useCallback((status: string): string => {
    const contractStatus = {
      created: 'Creada',
      active: 'En curso',
      disabled: 'Desactivada',
      done: 'Finalizada',
    };

    return (
      contractStatus[status as keyof typeof contractStatus] ?? 'Desconocido'
    );
  }, []);

  const pageName = 'Lista de alquileres';
  const pageParentName = 'Pagos de alquileres';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  const contractFilters = [
    {
      label: 'Código de propiedad',
      value: 'code',
      apiParamName: 'property_code',
      inputType: 'number',
      isUnique: true,
      placeholder: 'Ingresa el código de propiedad',
    },
    {
      label: 'Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone}</small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: 'Nombre Inquilino',
      value: 'tenant_name',
      apiParamName: 'tenant_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del inquilino',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getTenantByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((tenant: any) => ({
            value: tenant.tenant_id,
            label: (
              <>
                <span>{tenant.name}</span>
                <small className="text-gray mx-2">
                  {tenant.phone} {tenant.email}
                </small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const contractPaymentActions = (contractPayment: any) => (
    <UncontrolledDropdown>
      <DropdownToggle
        className="btn-icon-only text-light"
        href="#"
        role="button"
        size="sm"
        color=""
        onClick={(e) => e.preventDefault()}
      >
        <i className="fas fa-ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem href={`/contracts/${contractPayment.id}/payment-status`}>
          Ver detalles de pago
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Código de propiedad</th>
          <th scope="col">Propietario</th>
          <th scope="col">Inquilino</th>
          <th scope="col">Dirección</th>
          <th scope="col">Mensualidad</th>
          <th scope="col">Estado</th>
          <th scope="col">Vencimiento</th>
          <th scope="col">Pago Automático</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {contractPayments.length > 0 &&
          contractPayments.map((contractPayment: TransactionList) => (
            <tr key={`${contractPayment.id}`}>
              <td>{contractPayment.property_code}</td>
              <td>{contractPayment.landlord_fullname}</td>
              <td>{contractPayment.tenant_fullname}</td>
              <td>{contractPayment.property_address}</td>
              <td>{contractPayment.contract_price}</td>
              <td>
                {contractStatusMapper(contractPayment.transaction_status)}
              </td>
              <td>{contractPayment.contract_due_date}</td>
              <td>{contractPayment.has_subscription_pay ? 'Si' : 'No'}</td>
              <td className="text-right">
                {contractPaymentActions(contractPayment)}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  const componentHeader = <GridSearchBar filtersList={contractFilters} />;

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      footer={componentPagination}
      globalAlertOptions={globalAlertOptions}
      header={componentHeader}
    >
      {componentTable}
    </AdminPage>
  );
};

export default ContractPaymentsList;
