export interface BussinessProduct {
  color: 'primary' | 'warning' | 'success';
  product: 'rent' | 'pre_sale' | 'sale' | '';
  product_text: string;
}

export const bussinessProducts: BussinessProduct[] = [
  {
    color: 'primary',
    product: 'rent',
    product_text: 'Renta',
  },
  {
    color: 'warning',
    product: 'pre_sale',
    product_text: 'PreVenta',
  },
  {
    color: 'success',
    product: 'sale',
    product_text: 'Vivienda usada',
  },
  {
    color: 'success',
    product: '',
    product_text: '',
  },
];
