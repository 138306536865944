import useCallApiAndLoad from './useCallApiAndLoad';
import { useCallback, useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { fetchResponseAdapter } from '../adapters/fetchAdapter';
import useAuth from './useAuth';
import { useSelector } from 'react-redux';
import { AppStore } from '../redux/store';

const useAdminPagination = (
  endpoint: Function,
  elementRepository: Function,
  errorRepository: Function,
) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();
  const [paginator, setPaginator] = useState({
    page: 1,
    pageCount: 0,
  });
  const gridSearchBar = useSelector((store: AppStore) => store.gridSearchBar)

  const loadItems = useCallback(async (page: number = 0, take: number = 0) => {
    let filterBuilder: any = {};
    if (page > 0)
      filterBuilder.page = page.toString();
    if (take > 0)
      filterBuilder.take = take.toString();
    const queryString = `?${createSearchParams(filterBuilder)}&${gridSearchBar.query.replace("?", "")}`;
    const {
      status, data
    } = await callEndpoint(endpoint(queryString, token));
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      elementRepository(response.data.items || []);
      const { page, pageCount } = response.data.meta;
      setPaginator({page, pageCount});
    } else {
      errorRepository(response.message);
    }
  }, [gridSearchBar.query]);

  useEffect(() => {
    loadItems().catch(e => errorRepository(e.toString()));
  }, [loadItems]);

  return {
    paginator,
    loadItems,
  }
}

export default useAdminPagination