import React, { useEffect, useRef, useState } from 'react';
import {
  CreateTowerLayoutLevel,
  FacadePolygon,
  TowerLayoutDetail,
} from '../models/towerLayoutModel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { createPolygonTowerLevel, polygonDetailTowerEndpoint } from '../../withdrawal/services/projectService';
import useAuth from '../../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { PROJECT_DISTRIBUTION_LIST } from '../constants/towerLayoutConstants';
import { Option } from '../../../models/formModel';
import { getImageSize, ImageSize } from '../../../helpers/imageDimentionsHelpers';

const useProjectTowerLayout = () => {
  const distributionStatusOptions = listToSelectOptionAdapter(
    PROJECT_DISTRIBUTION_LIST,
    'value',
    'display_value',
  );
  const { id = '' } = useParams();
  const [towerInfo, setTowerInfo] = useState<TowerLayoutDetail>();
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [points, setPoints] = useState<FacadePolygon[]>([]);
  const [selectPolygonStatus, setSelectPolygonStatus] = useState<any>()
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Nuevo estado
  const canvasRef = useRef(null);
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<CreateTowerLayoutLevel>({
    resolver: yupResolver(
      yup
        .object({
          status: yup.string().required('El estado del proyecto es requerido.'),
          level: yup
            .number()
            .integer('El valor no puede contener decimales y debe ser positivo')
            .required('Nivel es requerido'),
          facade_background: yup.string().notRequired(),
        })
        .required(),
    ),
  });

  const loadPolygonTowerData = async (
    id: string
  ) => {
    const { status, data: responseData } = await callEndpoint(
      polygonDetailTowerEndpoint(id, token),
    );
    if (status === 200) {
      setTowerInfo(responseData?.data)
    }
    
  }

  const fetchImageSize = async (image: string) => {
    try {
      if (image) {
        const size = await getImageSize(image);
        console.log('medidas', size);
        setImageSize(size);
        return size;
      }
    } catch (error) {
      console.error('Error al cargar la imagen:', error);
    }
  };

  

  // 2-  Load canvas and catch updates when we are drawing
  useEffect(() => {
    if (!towerInfo || !towerInfo.facade_background) {
      return;
    }
    fetchImageSize(towerInfo.facade_background).then((size) => {
      const canvas: any = canvasRef.current;
    if (canvas && size) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, size.width, size.height);

      const img = new Image();
      img.src = towerInfo.facade_background;
      img.onload = () => {
        ctx.globalAlpha = 0.5;
        ctx.drawImage(img, 0, 0, size.width, size.height);
        ctx.globalAlpha = 1.0;

        if (towerInfo.distribution.length) {
          towerInfo.distribution.forEach((d: any, index) => {
            console.log({d})
            if (d.status === "available") {
              ctx.strokeStyle = '#2FB672';
              ctx.fillStyle = 'rgba(47, 182, 114, 0.2)';
            } else {
              ctx.strokeStyle = 'red';
              ctx.fillStyle = 'rgba(229, 0, 0, 0.2)';
            }
            
            
            const { polygon } = d;
   
            ctx.beginPath();
            ctx.moveTo(polygon[0].x, polygon[0].y);
            polygon.slice(1).forEach((point: any) => ctx.lineTo(point.x, point.y));
            ctx.closePath();
            ctx.stroke();
            ctx.fill();
            d.index = index; // Asigna el índice sin modificar el objeto original
          });
        }

        if (points.length > 1 && isDrawing) {
          ctx.strokeStyle = 'green';
          ctx.beginPath();
          ctx.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            ctx.lineTo(points[i].x, points[i].y);
          }
          ctx.lineTo(points[0].x, points[0].y);
          ctx.lineWidth = 3;
          ctx.stroke();
          ctx.fillStyle = 'rgba(229, 255, 204, 0.2)';
          ctx.fill();
        }
      };
    }
    })



  }, [points, isDrawing, towerInfo]);

  /* -- Handlers -- */

  // This put a pin of a shape that is being drawn
  const handleClick = (event: any) => {
    const canvas: any = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const ctx = canvas.getContext('2d'); // Aquí definimos el contexto

      if (isEditing && towerInfo) {
        towerInfo.distribution.forEach((d, index) => {
          const path = new Path2D();
          path.moveTo(d.polygon[0].x, d.polygon[0].y);
          d.polygon.slice(1).forEach((point) => path.lineTo(point.x, point.y));
          path.closePath();
          
          if (ctx.isPointInPath(path, x, y)) {
            console.log('Polígono seleccionado:', d, 'Índice:', index); // Información del polígono y su índice

            setValue('level', d.level);
            const selectedStatus = distributionStatusOptions.find((option) => option.value === d.status)
            if (selectedStatus) {
              setSelectPolygonStatus(d)
            }
            
            setValue('status', d.status);
            setValue('polygon', d.polygon)

          }
        });
      } else if (isDrawing) {
        setPoints([...points, { x, y }]);
      } else {
        setPoints([{ x, y }]);
        setIsDrawing(true);
      }
    }
  };

  const resetForm = () => {
    // TODO clear canvas and clear form
    setPoints([]) // This clear the canvas
  };

  const sendLevelToCreate = async (form: CreateTowerLayoutLevel) => {
    if (points.length <= 2) {
      // TODO define a SweetAlert error
      alert('Polígono no fue encontrado, debes dibujar uno');
      return;
    }
    // close polygon before create
    const closedPolygon = [...points, points[0]];
    setPoints(closedPolygon); // Close the polygon
    setIsDrawing(false);
    form.polygon = closedPolygon;

    const { status, data } = await callEndpoint(createPolygonTowerLevel(id, form, token));
    if (status === 200) {
      alert('El poligono fue creado con exito')
    }
    navigate(0)
    console.log({ form });
  };

  const sendLevelToUpdate = async (form: CreateTowerLayoutLevel) => {
    
    // close polygon before create
    setIsDrawing(false);
    const { status, data } = await callEndpoint(createPolygonTowerLevel(id, form, token));
    if (status === 200) {
      alert('El poligono fue actualizado con exito')
    }
    navigate(0)
    console.log({ form });
  };

  const addCurrentImage = async (form: any) => {
    console.log({form})
    // const { status, data } = await callEndpoint(createPolygonTowerLevel(id, form, token));
    // if (status === 200) {
    //   alert('El poligono fue actualizado con exito')
    //   navigate(0)
    // }
    
  }

  return {
    // Form
    setIsEditing,
    isEditing,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    sendLevelToCreate,
    resetForm,
    isLoading,
    towerInfo,
    canvasRef,
    handleClick,
    points,
    loadPolygonTowerData,
    selectPolygonStatus,
    sendLevelToUpdate,
    addCurrentImage,
    imageSize
  };
};

export default useProjectTowerLayout;
