const PHOTO_STATUS: any = {
    scheduled: 'Programada',
    rescheduled: 'Reprogramada',
    taking_photos: 'Tomando fotografías',
    done: 'Finalizada',
  }

export const getPhotoSessionStatus = (status: string) => {
    if(!status) return 'No agendada'
    return PHOTO_STATUS[status] ? PHOTO_STATUS[status] : ''

}