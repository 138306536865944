import React from 'react'
import { UncontrolledTooltip } from 'reactstrap';
import { DEFAULT_AVATAR } from '../constants/profileConstants';

interface Props {
  code: string | number;
  name: string;
  avatar: string | null;
  avatar_size?: string;
}

const defaultAvatar = DEFAULT_AVATAR

export const ProfileAvatarGroup = ({ code = '', avatar = null, name = '',
  avatar_size = 'avatar-sm' }: Props) => {
  return (
    <span className='mr-2' >
      <div className="avatar-group">
        <a
          className={`avatar ${avatar_size} rounded-circle`}
          href="#"
          id={`tooltip742438047-${code}`}
          onClick={e => e.preventDefault()}
        >
          <img alt="..." src={avatar || defaultAvatar} />
        </a>
        <UncontrolledTooltip
          delay={0}
          target={`tooltip742438047-${code}`}
        >
          {name}
        </UncontrolledTooltip>
      </div>
    </span>
  )
}

export default ProfileAvatarGroup
