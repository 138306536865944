import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  UncontrolledCarousel,
} from 'reactstrap';
import TimelineHeader from '../../components/headers/TimelineHeader';
import UploadCover from './components/UploadCover';
import UploadGallery from './components/UploadGallery';
import './sass/style.scss';
import { Form, useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import {
  deleteGalleryPhotoEndpoint,
  getPhotosEndpoint,
  getPropertyToEditEndpoint,
  uploadLinksPropertiesEndpoint,
} from './services/propertyService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { UploadLinksForm } from './models/propertyModel';
import LoadingCard from '../../components/LoadingCard';
import { removeEmptyFields } from '../../helpers/dataHelpers';
import logoYt from '../../assets/img/brand/icons8-youtube-48.png';
import logoMt from '../../assets/img/brand/icons8-matterport-50.png';
import useAuth from '../../hooks/useAuth';

const PropertyPhotos = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [cover, setCover] = useState<string | null>(null);
  const [gallery, setGallery] = useState<string[]>([]);
  const [link360, setLink360] = useState<string | null>(null);
  const [linkVideo, setLinkVideo] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>('');
  const [linksError, setLinkErrors] = useState<string>('');
  const { getAuthToken } = useAuth()
  const token = getAuthToken()

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UploadLinksForm>({
    resolver: yupResolver(
      yup
        .object({
          link_tour360: yup.string(),
          link_video: yup.string(),
        })
        .required(),
    ),
  });

  useEffect(() => {
    const loadPropertyPhotos = async () => {
      const { status, data } = await callEndpoint(getPhotosEndpoint(id, token));
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setCover(response.data.cover);
        setGallery(response.data.gallery);
      }
      const { status: statusProperty, data: dataProperty } = await callEndpoint(
        getPropertyToEditEndpoint(id, token),
      );
      if (statusProperty === 200) {
        const responseProperty = fetchResponseAdapter(dataProperty);
        setLink360(responseProperty.data.link_tour360 || null);
        setLinkVideo(responseProperty.data.link_video || null);
      }
    };
    loadPropertyPhotos().catch((e) => console.error(e));
  }, []);

  const toggleModal = (image: string) => {
    setCurrentImage(image);
    setShowModal((state) => !state);
  };

  const deleteImage = async (imagePath: string) => {
    if (!imagePath) {
      return;
    }
    const { status } = await callEndpoint(
      deleteGalleryPhotoEndpoint(id, imagePath, token),
    );
    if (status === 200) {
      const newGallery = gallery.filter((image) => image !== imagePath);
      setGallery(newGallery);
    }
  };

  const doSaveLinksProperties = async (form: UploadLinksForm) => {
    removeEmptyFields(form);
    if (Object.keys(form).length === 0) {
      setLinkErrors('Ingresa un campo a actualizar');
    } else {
      const { status } = await callEndpoint(
        uploadLinksPropertiesEndpoint(form, id, token),
      );
      if (status === 200) {
        navigate(0); //make a reload
      } else {
        setLinkErrors('Error al actualizar los links');
      }
    }
  };

  return (
    <>
      <TimelineHeader parentName="Propiedades" name={`Administrar propiedad`} />
      <Container className="mt--6" fluid>
        {/* Cover card */}
        <Card>
          <CardHeader className="py-0"></CardHeader>
          <CardBody>
            <Row className="pt-4">
              <Col md="8">
                <UploadCover property_id={id} />
              </Col>
              <Col md="4">
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <h4>Cover actual</h4>
                      </CardHeader>
                      <CardBody>
                        {cover && (
                          <ListGroup className="list my--3" flush>
                            <ListGroupItem className="px-0">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    onClick={() => toggleModal(cover || '')}
                                    className="rounded"
                                    alt="..."
                                    width={175}
                                    height={100}
                                    src={cover}
                                  />
                                </Col>
                                <div className="col ml--2"></div>
                                <Col className="col-auto">
                                  <Button
                                    className="btn-icon"
                                    onClick={() => toggleModal(cover || '')}
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="ni ni-image" />
                                    </span>
                                    <span className="btn-inner--text">Ver</span>
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  {/* tour 360 */}
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="4">
                            <h3 className="mb-0">Links</h3>
                          </Col>
                          <Col className="text-right" xs="8">
                            {link360 && (
                              <a href={link360} target="_blank">
                                {' '}
                                <img
                                  height={46}
                                  width={46}
                                  src={logoMt}
                                  title="Matterport"
                                  alt="Matterport"
                                />
                              </a>
                            )}
                            {linkVideo && (
                              <a href={linkVideo} target="_blank">
                                {' '}
                                <img
                                  height={46}
                                  width={46}
                                  src={logoYt}
                                  title="Youtube"
                                  alt="Youtube"
                                />
                              </a>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <form onSubmit={handleSubmit(doSaveLinksProperties)}>
                        <CardBody>
                          <Col md="12">
                            <FormGroup>
                              <Controller
                                name="link_tour360"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    id="link_tour360"
                                    placeholder="Ingrese el link tour 360"
                                  />
                                )}
                              />
                              <br />
                              <Controller
                                name="link_video"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    id="link_video"
                                    placeholder="Ingrese el link del video"
                                  />
                                )}
                              />
                            </FormGroup>
                            {errors.link_tour360?.message && (
                              <Alert color="danger">
                                {errors.link_tour360?.message}
                              </Alert>
                            )}
                            {linksError && (
                              <Alert color="danger">{linksError}</Alert>
                            )}
                          </Col>
                        </CardBody>
                        <CardFooter>
                          <Row>
                            <div className="col ml--2"></div>
                            <Col className="col-auto">
                              <Button
                                className=""
                                color="success"
                                size="md"
                                type="submit"
                              >
                                Subir links
                              </Button>
                            </Col>
                          </Row>
                        </CardFooter>
                      </form>
                    </Card>
                  </Col>
                  {/*  */}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* gallery card */}
        <Card>
          <CardHeader className="py-0"></CardHeader>
          <CardBody>
            <Row className="pt-4">
              <Col md="8">
                <UploadGallery property_id={id} />
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card>
                  <CardHeader>
                    <h4>Galeria actual</h4>
                  </CardHeader>
                  <CardBody>
                    {gallery &&
                      gallery.length > 0 &&
                      gallery.map((image) => (
                        <ListGroup className="list my--3" flush>
                          <ListGroupItem className="px-0">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  onClick={() => toggleModal(image || '')}
                                  className="rounded"
                                  alt="..."
                                  width={175}
                                  height={100}
                                  src={image || ''}
                                />
                              </Col>
                              <div className="col ml--2"></div>
                              <Col className="col-auto">
                                <Button
                                  className="btn-icon"
                                  onClick={() => toggleModal(image || '')}
                                  color="secondary"
                                  size="sm"
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="ni ni-image" />
                                  </span>
                                  <span className="btn-inner--text">Ver</span>
                                </Button>
                                <Button
                                  className="btn-icon"
                                  onClick={() => deleteImage(image || '')}
                                  color="danger"
                                  size="sm"
                                  type="button"
                                >
                                  <span className="btn-inner--icon">
                                    <i className="ni ni-fat-delete" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Eliminar
                                  </span>
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Modal
          size="lg"
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => toggleModal('')}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal('')}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <UncontrolledCarousel
              controls={false}
              indicators={false}
              items={[
                {
                  src: currentImage,
                  altText: '',
                  caption: '',
                  header: '',
                },
              ]}
            />
          </div>
          <div className="modal-footer"></div>
        </Modal>
      </Container>
    </>
  );
};

export default PropertyPhotos;
