import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  CreatePropertySalesInfoForm,
  PropertySalesInfoModel,
} from '../models/propertyModel';
import { useNavigate } from 'react-router-dom';
import {
  createPropertySalesInfoEndpoint,
  getPropertySalesInfoEndpoint,
  updatePropertySalesInfoEndpoint,
  uploadPropertySalesFileEndpoint,
  uploadPropertySalesPurchaseAndSaleDocEndpoint,
} from '../services/propertySalesService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';

interface Props {
  propertyId: string;
  setErrorAlert: Function;
  baseSalePrice: number;
}

enum SaleFilesType {
  EXTRACTED_LETTER = 'extracted_letter',
  SCRIPTURES = 'scriptures',
}

const usePropertySalesInfo = ({
  propertyId,
  setErrorAlert,
  baseSalePrice,
}: Props) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();

  const [downPayment, setDownPayment] = useState(0);

  const [propertySalesInfo, setPropertySalesInfo] =
    useState<PropertySalesInfoModel>();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
    watch,
  } = useForm<CreatePropertySalesInfoForm>({
    resolver: yupResolver(
      yup
        .object({
          mortgage: yup.boolean(),
          free_of_lien: yup.boolean(),
          has_extracted_letter: yup.boolean(),
          has_scriptures: yup.boolean(),
          return_of_investment: yup
            .number()
            .positive('Retorno de inversión debe ser positivo')
            .min(0)
            .max(100)
            .required('Retorno de inversión es requerido'),
          internal_rate_return: yup
            .number()
            .positive('Tasa interna de retorno debe ser positivo')
            .min(0)
            .max(100)
            .required('Tasa interna de retorno es requerido'),
          earnings_percentage: yup
            .number()
            .positive('Porcentaje de ganancia debe ser positivo')
            .min(0)
            .max(100)
            .required('Porcentaje de ganancia es requerido'),
          reserve_price: yup
            .number()
            .positive('El precio de reserva debe ser positivo')
            .min(0)
            .required('El precio de reserva es requerido'),
          down_payment_price: yup
            .number()
            .positive('El precio de primar debe ser positivo')
            .min(0)
            .required('El precio de primar es requerido'),
        })
        .required(),
    ),
  });

  const loadPropertySalesInfo = async (
    propertyId: string,
  ): Promise<PropertySalesInfoModel | null> => {
    const { status, data } = await callEndpoint(
      getPropertySalesInfoEndpoint(propertyId, token),
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setPropertySalesInfo(response.data);
      return response.data;
    } else {
      return null;
    }
  };

  const handlePropertySalesAction = async (
    form: CreatePropertySalesInfoForm,
  ) => {
    const isUpdate = !!propertySalesInfo?.id;
    if (isUpdate) {
      return updateSalesInfo(form);
    } else {
      return createSalesInfo(form);
    }
  };

  const updateSalesInfo = async (form: CreatePropertySalesInfoForm) => {
    const { status, data } = await callEndpoint(
      updatePropertySalesInfoEndpoint(
        form,
        propertyId,
        propertySalesInfo?.id || '',
        token,
      ),
    );
    const response = fetchResponseAdapter(data);
    // update validation
    if (status !== 200) {
      setErrorAlert(
        `Error, no fue posible actualizar información, código error: ${response.code}`,
      );
      return;
    }
    navigate(0);
  };

  const createSalesInfo = async (form: CreatePropertySalesInfoForm) => {
    const { status, data } = await callEndpoint(
      createPropertySalesInfoEndpoint(form, propertyId, token),
    );
    const response = fetchResponseAdapter(data);
    // update validation
    if (status !== 201) {
      setErrorAlert(
        `Error, no fue posible crear registro, código error: ${response.code}`,
      );
      return;
    }
    navigate(0);
  };

  const handleUploadExtractedLetter = async (files: Array<any>) => {
    if (!files.length) {
      setErrorAlert('Error, debes de adjuntar un archivo para subir');
      return;
    }
    const formData = new FormData();
    formData.append('document', files[0]);
    formData.append('type', SaleFilesType.EXTRACTED_LETTER);
    const { status } = await callEndpoint(
      uploadPropertySalesFileEndpoint(
        formData,
        propertyId,
        propertySalesInfo?.id || '',
        token,
      ),
    );
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  };

  const handleUploadScriptures = async (files: Array<any>) => {
    if (!files.length) {
      setErrorAlert('Error, debes de adjuntar un archivo para subir');
      return;
    }
    const formData = new FormData();
    formData.append('document', files[0]);
    formData.append('type', SaleFilesType.SCRIPTURES);
    const { status } = await callEndpoint(
      uploadPropertySalesFileEndpoint(
        formData,
        propertyId,
        propertySalesInfo?.id || '',
        token,
      ),
    );
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  };

  const handleUploadPurchaseAndSaleDoc = async (files: Array<any>) => {
    if (!files.length) {
      setErrorAlert('Error, debes de adjuntar un archivo para subir');
      return;
    }
    const formData = new FormData();
    formData.append('purchase_and_sale_doc', files[0]);
    const { status } = await callEndpoint(
      uploadPropertySalesPurchaseAndSaleDocEndpoint(
        formData,
        propertyId,
        propertySalesInfo?.id || '',
        token,
      ),
    );
    if (status === 200) {
      navigate(0); //make a reload
    } else {
      setErrorAlert(
        'Lo sentimos, por el momento no es posible completar la acción',
      );
    }
  };

  useEffect(() => {
    loadPropertySalesInfo(propertyId)
      .then((response) => {
        if (response) {
          console.log({ response });
          //calculate
          let down_payment_price = response.down_payment_price;
          let reserve_price = response.reserve_price;

          if (!down_payment_price) {
            //calculate
            down_payment_price = baseSalePrice * 0.1;
          }

          setDownPayment(down_payment_price);

          reset({
            mortgage: response.mortgage || false,
            free_of_lien: response.free_of_lien || false,
            has_extracted_letter: response.has_extracted_letter || false,
            has_scriptures: response.has_scriptures || false,
            return_of_investment: response.return_of_investment,
            internal_rate_return: response.internal_rate_return,
            earnings_percentage: response.earnings_percentage,
            reserve_price: reserve_price,
            down_payment_price: down_payment_price,
          });
        }
      })
      .catch((e) => console.error(e));
  }, [baseSalePrice]);

  const reservePriceWatched = watch('reserve_price');

  useEffect(() => {
    setValue('down_payment_price', downPayment - reservePriceWatched);
  }, [reservePriceWatched]);

  return {
    control,
    register,
    errors,
    handleSubmit,
    handlePropertySalesAction,
    handleUploadExtractedLetter,
    handleUploadScriptures,
    handleUploadPurchaseAndSaleDoc,
    propertySalesInfo,
    isLoading,
  };
};

export default usePropertySalesInfo;
