import { Card, CardBody, Table } from 'reactstrap';
import { ContractQuotaPreview } from '../models/contractModel';

export interface IProps {
  quotas: ContractQuotaPreview[];
}

const ContractPaymentsPreviewCard: React.FC<IProps> = ({quotas}) => {
  return (
    <Card>
      <CardBody>
        <h3>Vista previa del mandato de pago</h3>
        <Table className="mt-3 table-striped" responsive>
          <thead>
            <th>Cuota</th>
            <th>Fecha de pago</th>
            <th>Valor</th>
          </thead>
          <tbody>
          {quotas.map(quota => (
            <tr>
              <td>{`${quota.label} ${quota.due}`}</td>
              <td>{quota.due_date}</td>
              <td>{quota.value}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default ContractPaymentsPreviewCard;