import React from 'react';
import { useState } from 'react';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table, Badge,
} from 'reactstrap';
import ProfileAvatarGroup from '../../components/ProfileAvatarGroup';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { Landlord } from './models/landlordModel';
import { getLandlordsEndpoint } from './services/landlordService';
import { ErrorAlert, SuccessAlert } from '../../models/alertModel';
import { requestChangePasswordEndpoint } from '../../services/publicService';
import AdminPaginator from '../../components/admin/AdminPaginator';
import AdminPage from '../../components/admin/AdminPage';
import useAdminPagination from '../../hooks/useAdminPagination';
import { getLandlordByEmailEndpoint, getLandlordByNameEndpoint } from '../accounting/services/accountingService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import GridSearchBar from '../../components/searchBar/GridSearchBar';

const Landlords = () => {
  const [sweetAlert, setSweetAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<SuccessAlert | ErrorAlert>({
    success: true,
    title: '',
    text: '',
    onConfirm: () => setSweetAlert(false),
    onCancel: () => setSweetAlert(false),
  });
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const [landlord, setLandlord] = useState<Landlord[]>([]);

  const [errorAlert, setErrorAlert] = useState(null);

  const { paginator, loadItems } = useAdminPagination(
    getLandlordsEndpoint,
    setLandlord,
    setErrorAlert,
  );

  const sendChangePassword = async (email: string) => {
    const { status } = await callEndpoint(requestChangePasswordEndpoint(email));
    if (status === 200) {
      setAlertProps((state) => ({
        ...state,
        success: true,
        danger: false,
        title: 'OK',
        text: 'Correo enviado satisfactoriamente',
      }));
    } else {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Lo sentimos ocurrió un error al querer mandar correo de cambio de contraseña',
      }));
    }
    setSweetAlert(true);
  };

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  const getLandlordTypePill = (isInvestor: boolean) => {
    if (isInvestor)
      return (
        <Badge color="primary" pill>Inversionista</Badge>
      )
    else
      return (
        <Badge color="success" pill>Propietario</Badge>
      )
  }

  const propertyFilters = [
    {
      label: 'Nombre propietario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getLandlordByNameEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone} {landlord.email}</small>
              </>
            ),
          }))
        } else {
          return []
        }
      },
    },
    {
      label: 'Email propietario',
      value: 'landlord_email',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el email del propietario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm)
          return []

        const {
          status, data
        } = await callEndpoint(getLandlordByEmailEndpoint(searchTerm, token));

        if (status === 200) {
          const response = fetchResponseAdapter(data)
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">{landlord.phone} {landlord.email}</small>
              </>
            ),
          }))
        } else {
          return []
        }
      },
    },
  ]

  const componentHeader = (
    <GridSearchBar
      filtersList={propertyFilters}
    />
  )

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Avatar</th>
          <th scope="col">Nombre</th>
          <th scope="col">Apellido</th>
          <th scope="col">Correo</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Fecha de registro</th>
          <th scope="col">Tipo</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {landlord.length > 0 &&
          landlord.map((landlord, key) => (
            <tr key={landlord.id}>
              <td>
                <ProfileAvatarGroup
                  code={key}
                  name={
                    landlord.profile.first_name +
                    ' ' +
                    landlord.profile.last_name
                  }
                  avatar={
                    landlord.profile.avatar ? landlord.profile.avatar : ''
                  }
                />
              </td>
              <td>{landlord.profile.first_name}</td>
              <td>{landlord.profile.last_name}</td>
              <td>{landlord.profile.email}</td>
              <td>{landlord.profile.phone}</td>
              <td>{landlord.created_at_local}</td>
              <td>{getLandlordTypePill(landlord.is_investor)}</td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem href={`/landlord/${landlord.id}/profile`}>
                      Ver perfil
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        sendChangePassword(landlord.profile.email);
                      }}
                    >
                      Envíar cambio de contraseña
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  return (
    <AdminPage
      name="Lista de propietarios"
      parentName="Propietarios"
      footer={componentPagination}
      header={componentHeader}
    >
      {componentTable}
    </AdminPage>
  );
};

export default Landlords;
