import { OfferInfo } from '../models/guestModel';
import { Button, Card, CardBody, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

interface Props {
  guestOffer: OfferInfo;
}

export default function GuestOfferDetailCard({guestOffer}: Props) {
  const navigate = useNavigate();
  const toGuestOfferEdit = () => navigate(`/guest-offers/${guestOffer.id}/edit`)
  return (
    <Card>
      <CardBody>
        <ListGroup className="list my--3" flush>
          <h6 className="heading-small text-muted mt-4 mb-4">
            Oferta
          </h6>
          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Fecha estimada de mudanza
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{guestOffer.estimate_moving_at}</span>
                <Button color="" size="sm" type="button" onClick={toGuestOfferEdit}>
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Precio
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{guestOffer.offer_price}</span>
                <Button color="" size="sm" type="button" onClick={toGuestOfferEdit}>
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              </Col>
            </Row>
          </ListGroupItem>

          <ListGroupItem>
            <Row className="align-items-center">
              <div className="col ml--2">
                <h4 className="mb-0">
                  Período
                </h4>
              </div>
              <Col className="col-auto">
                <span className='mr-2' >{guestOffer.period}</span>
                <Button color="" size="sm" type="button" onClick={toGuestOfferEdit}>
                  <i className="ni ni-bold-right pt-1" />
                </Button>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};