import React, { useCallback } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import ManageInvestorProjectModelForm from './components/ManageInvestorProjectModelForm';
import { InvestorProjectModelForm } from './models/investors';
import { useNavigate, useParams } from 'react-router-dom';
import { createInvestorProjectModelEndpoint } from './services/investorService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useAuth from '../../hooks/useAuth';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';

const InvestorProjectModelCreate: React.FC = () => {
  const {id: investorProjectId = ""} = useParams();
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const createProjectModel = useCallback(async (form: InvestorProjectModelForm, errorRepository: Function) => {
    const {
      status, data
    } = await callEndpoint(createInvestorProjectModelEndpoint(investorProjectId, form, token));
    const response = fetchResponseAdapter(data);
    if (status === 201)
      navigate(`/investors/projects/${investorProjectId}`);
    else
      errorRepository(response.message);
  }, []);

  const pageName = `Crear modelo`;
  const pageParentName = `Preventa`;
  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
    >
      <ManageInvestorProjectModelForm handleForm={createProjectModel} />
    </AdminPage>
  );
};

export default InvestorProjectModelCreate;