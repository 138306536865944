import React, { useEffect, useState } from 'react';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import {
  IPropertyViewCounter,
  lastCounterDetail,
} from '../models/propertyModel';
import useAuth from '../../../hooks/useAuth';
import { getAdminPropertyViewCounterEndpoint } from '../services/propertyService';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getLastPropertyViewsCounterAdapter } from '../adapters/propertyAdapter';

const usePropertyCounterViews = (id: string) => {
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [lastCounter, setLastCounter] = useState<lastCounterDetail>({
    text: '',
    counter: 0,
  });
  const [propertyCounterView, setPropertyCounterView] =
    useState<IPropertyViewCounter[]>();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  useEffect(() => {
    const loadPropertiesCounter = async () => {
      const { status, data } = await callEndpoint(
        getAdminPropertyViewCounterEndpoint(id, token),
      );

      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setPropertyCounterView(response.data);
        setLastCounter(getLastPropertyViewsCounterAdapter(response.data));
      }
    };
    loadPropertiesCounter().catch((e) => console.error(e));
  }, []);

  return {
    isLoading,
    propertyCounterView,
    lastCounter
  };
};

export default usePropertyCounterViews;
