import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Calendar, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import TimelineHeader from '../../components/headers/TimelineHeader';
import {
  getAvailableTimeVisitsEndpoint,
  getTenantByMailEndpoint,
  getPropertyVisitsEndpoint,
  createPropertyVisitsEndpoint,
  updatePropertyVisitsEndpoint,
  deleteVisitEndpoint,
  editProperVisitVisitEndpoint,
} from './services/propertyService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import {
  buildCalendarVisitData,
  buildCalendarVisitEvent,
  getVisitStatusAdapter,
} from './adapters/visitsAdapter';
import { Option } from '../../models/formModel';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import {
  CreateVisitForm,
  CreateVisitFormApi,
  PropertyVisitList,
  RescheduleFormApi,
} from './models/propertyModel';
import ProfileCard from '../../components/ProfileCard';
import {
  formatStandarCalendarDate,
  getHoursMinutesFromMinutes,
  getStringDate,
} from '../../adapters/dateAdapter';
import { getPropersSelectList } from '../../services/publicService';
import { getDateVars } from '../../helpers/dateHelpers';
import useAuth from '../../hooks/useAuth';
import { VISIT_STATUS } from '../../constants/visitsConstants';
import useUserCountry from '../../hooks/useUserCountry';
import VisitMarketplaceTypeBadge from '../../components/VisitMarketplaceTypeBadge';
import CopyToClipboard from 'react-copy-to-clipboard';

let calendar: any;

const AdminVisit = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { callEndpoint } = useCallApiAndLoad();

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  // propers list
  const [propers, setPropers] = useState<Option[]>([]);
  // tenant search states
  const [tenantEmail, setTenantEmail] = useState<string>('');
  const [tenantFound, setTenantFound] = useState<any>(null);
  const [formError, setFormError] = useState<string>('');
  // modals
  const [modalAdd, setModalAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalDoneCancelled, setModalDoneCancelled] = useState(false);
  // date states
  const [startDate, setStartDate] = useState('');
  const [oldStartDate, setOldStartDate] = useState('');
  const [creationStartDate, setCreationStartDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<Option[]>([]);
  //visit type
  const marketplaceTypesAux = [
    {
      id: 'on_rent',
      value: 'Renta',
    },
    {
      id: 'on_sale',
      value: 'Venta',
    },
  ];
  const [marketplaceTypes, setMarketplaceTypes] = useState<Option[]>([]);
  // event states
  const [event, setEvent] = useState<any>(null);
  const [oldEvent, setOldEvent] = useState<any>(null);
  const [changeEvent, setChangeEvent] = useState<any>(null);

  const [calendarView, setCalendarView] = useState<string>('dayGridMonth');

  const calendarRef = useRef<any>();

  const [showChangeProperForm, toggleChangeProperForm] =
    useState<boolean>(false);
  const [updateProperId, setUpdateProperId] = useState<string>('');

  const { getCurrentCountry } = useUserCountry();
  const currenUserCountryCode = getCurrentCountry()?.code.toLowerCase() || '';
  const urlVisitsTenant = `${process.env.REACT_APP_PROPI_WEB}/app/${currenUserCountryCode}/l/tn/visits`;
  const urlVisitsLandlord = `${process.env.REACT_APP_PROPI_WEB}/app/${currenUserCountryCode}/l/admin-property/visits/${id}`;
  const [clipboardUrls, setClipboardUrls] = useState<{
    landlord: string;
    tenant: string;
  }>({
    landlord: '',
    tenant: '',
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    unregister,
  } = useForm<CreateVisitForm>({
    resolver: yupResolver(
      yup.object({
        proper_id: yup.string().required('Proper es requerido'),
        start_time: yup.number().required('Hora de visita es requerida'),
        marketplace_type: yup.string().required('Selecciona el tipo de visita'),
      }),
    ),
  });

  useEffect(() => {
    createCalendar();
  }, []);

  const createCalendar = () => {
    if (!calendarRef || !calendarRef.current) {
      return;
    }
    calendar = new Calendar(calendarRef.current, {
      showNonCurrentDates: false,
      plugins: [interaction, dayGridPlugin],
      initialView: calendarView,
      views: {
        dayGridWeek: {
          duration: { days: 3 },
        },
      },
      buttonText: {
        today: 'Hoy',
      },
      selectable: true,
      editable: true,
      events: function (info, successCallback, failureCallback) {
        const {
          day: startDay,
          month: startMonth,
          year: startYear,
        } = getDateVars(info.start);
        const {
          day: endDay,
          month: endMonth,
          year: endYear,
        } = getDateVars(info.end);
        // this is triggered when calendar needs new data set
        // https://fullcalendar.io/docs/events-function
        callEndpoint(
          getPropertyVisitsEndpoint(
            id,
            `${startYear}-${startMonth}-${startDay}`,
            `${endYear}-${endMonth}-${endDay}`,
            token,
          ),
        )
          .then((response) => {
            const { status, data } = response;
            if (status !== 200) {
              failureCallback(new Error('Error al cargar información'));
            } else {
              const responseAdapted = fetchResponseAdapter(data);
              successCallback(buildCalendarVisitData(responseAdapted.data));
            }
          })
          .catch((e) => {
            failureCallback(e);
          });
      },
      // Add new event
      select: (info: any) => {
        setCreationStartDate(info.start);
        setModalAdd(true);
        setIsEdit(false);
        setStartDate(info.startStr);
        loadHours(info.startStr);
        setTenantFound(null);
        // for create edit vars should be reset
        setEvent(null);
        setOldEvent(null);
        setOldStartDate('');
        setChangeEvent(null);
        // clear form properties
        unregister('start_time');
        unregister('proper_id');
        unregister('marketplace_type');
        // clear generic modal error
        setFormError('');
      },
      // View calendar event
      eventClick: ({ event }: EventClickArg) => {
        setEvent(event);
        if (
          event.extendedProps.status === VISIT_STATUS.CANCELLED ||
          event.extendedProps.status === VISIT_STATUS.DONE
        ) {
          setModalDoneCancelled(true);
        } else {
          setModalView(true);
        }
      },
      // Edit calendar event action
      eventChange: (eventChange: EventChangeArg) => {
        const { event, oldEvent } = eventChange;
        console.log({ eventUpdated: event.extendedProps });
        if (
          event.extendedProps.status === VISIT_STATUS.CANCELLED ||
          event.extendedProps.status === VISIT_STATUS.DONE
        ) {
          // events from change status does not need to show modal reschedule
          // eventChange.revert()
          return;
        }
        setModalAdd(true);
        setIsEdit(true);
        setStartDate(event.startStr);
        loadHours(getStringDate(event.start));
        // assign edit vars
        setTenantFound({
          avatar: event.extendedProps.tenantInfo.avatar,
          name: event.extendedProps.tenantInfo.name,
          phone: '',
        });
        // for edit we should set event data
        setEvent(event);
        setOldEvent(oldEvent);
        setOldStartDate(oldEvent.startStr);
        setChangeEvent(eventChange);
        // clear form properties
        unregister('start_time');
        unregister('proper_id');
        unregister('marketplace_type');
        // clear generic modal error
        setFormError('');
      },
    });
    calendar.render();
  };

  // utils
  const closeAddEditModal = () => {
    setModalAdd(false);
    changeEvent !== null && changeEvent.revert();
  };

  const changeCalendarView = (view: string) => {
    calendar.changeView(view);
    setCalendarView(view);
  };

  // Load functions
  const loadPropers = useCallback(async () => {
    const { status, data } = await callEndpoint(
      getPropersSelectList(currenUserCountryCode, 'all', token),
    );
    if (status === 200) {
      const propersResponse = fetchResponseAdapter(data);
      setPropers(
        listToSelectOptionAdapter(propersResponse.data, 'id', 'name', false),
      );
    }
  }, [currenUserCountryCode, callEndpoint]);

  const loadMarketplaceTypes = () => {
    setMarketplaceTypes(
      listToSelectOptionAdapter(marketplaceTypesAux, 'id', 'value', false),
    );
  };

  useEffect(() => {
    loadPropers().catch((e) => console.error(e));
    loadMarketplaceTypes();
  }, [loadPropers]);

  const loadHours = async (startDate: string) => {
    if (!startDate) {
      setHours([]);
      return;
    }
    const { status, data } = await callEndpoint(
      getAvailableTimeVisitsEndpoint(id, startDate, token),
    );
    if (status === 200) {
      const hoursResponse = fetchResponseAdapter(data);
      setHours(
        listToSelectOptionAdapter(
          hoursResponse.data,
          'minutes',
          'time',
          true,
          'is_blocked',
        ),
      );
    }
  };

  // Handlers
  const handleSearchTenant = async () => {
    if (!tenantEmail) {
      setFormError('Debes ingresar un correo valido');
      setTenantFound(null);
      return;
    }
    setFormError('');
    const { status, data } = await callEndpoint(
      getTenantByMailEndpoint(tenantEmail.trim().toLowerCase(), token),
    );
    if (status === 200) {
      const landlordResponse = fetchResponseAdapter(data);
      setTenantFound(landlordResponse.data);
      return;
    }
    setFormError('Inquilino no encontrado');
    setTenantFound(null);
  };

  // Actions
  const doCreateVisit = async (form: CreateVisitForm) => {
    setFormError('');
    if (!tenantFound || !tenantFound.tenant_id) {
      setFormError('Información de inquilino es requerida');
      return;
    }
    if (!startDate) {
      setFormError('No fue posible seleccionar el día para la visita');
      return;
    }
    const formApi: CreateVisitFormApi = {
      property_id: id,
      tenant_id: tenantFound.tenant_id,
      date: startDate,
      start_time: form.start_time,
      proper_id: form.proper_id,
      marketplace_type: form.marketplace_type,
    };

    const { status, data } = await callEndpoint(
      createPropertyVisitsEndpoint(formApi, token),
    );
    if (status === 201) {
      // add visit manually to calendar
      const { hours, minutes } = getHoursMinutesFromMinutes(form.start_time);
      const [yearString, monthString, dayString] = startDate.split('-');
      const dateTimeVars: any = {
        day: parseInt(dayString),
        month: parseInt(monthString),
        year: parseInt(yearString),
        hours,
        minutes,
      };
      const response = fetchResponseAdapter(data);
      const visit: PropertyVisitList = {
        id: response.data.id,
        property_id: id,
        status: VISIT_STATUS.SCHEDULED,
        visitDateTime: dateTimeVars,
        tenantInfo: {
          id: tenantFound.tenant_id,
          name: tenantFound.name,
          avatar: tenantFound.avatar,
        },
        proper_id: form.proper_id,
        marketplace_type: response.data.marketplace_type,
      };
      calendar.addEvent(buildCalendarVisitEvent(visit, dateTimeVars));
      setModalAdd(false);
      return;
    }
    setFormError(`(${status}) - No fue posible agendar la visita`);
  };

  const doRescheduleVisit = async (form: CreateVisitForm) => {
    setFormError('');
    const { id, start } = event;
    if (!id || !start) {
      setFormError(
        'No fue posible seleccionar el día para la reangendar visita',
      );
    }
    const formApi: RescheduleFormApi = {
      date: getStringDate(start),
      start_time: form.start_time,
      proper_id: form.proper_id,
    };
    const { status } = await callEndpoint(
      updatePropertyVisitsEndpoint(formApi, id, token),
    );
    if (status === 200) {
      setModalAdd(false);
      return;
    }
    setFormError(`(${status}) - No fue posible reagendar la visita`);
  };

  const findProper = (properId: string): string => {
    const proper = propers.find((proper) => proper.value === properId);
    return proper ? proper.label : '';
  };

  const deleteVisit = async (event: any) => {
    if (!event) return;
    const { status } = await callEndpoint(deleteVisitEndpoint(event.id, token));
    if (status === 200) {
      setModalView(false);
      // set event status cancelled and paint it as red card
      event.setExtendedProp('status', VISIT_STATUS.CANCELLED);
      event.setProp('classNames', 'bg-red text-white');
    }
  };

  const updateProperVisit = async (event: any) => {
    if (!event) return;
    const { status } = await callEndpoint(
      editProperVisitVisitEndpoint(updateProperId, event.id, token),
    );

    if (status === 200) {
      setModalView(false);
      navigate(0);
    }
  };

  return (
    <>
      <TimelineHeader name="Administrar visitas" parentName="Propiedades" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                      {event?.currentDate}
                    </h6>
                  </Col>
                  <Col className="mt-3 mt-lg-0 text-lg-right" lg="6">
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="month"
                      onClick={() => changeCalendarView('dayGridMonth')}
                      size="sm"
                    >
                      Vista de mes
                    </Button>
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="basicWeek"
                      onClick={() => changeCalendarView('dayGridWeek')}
                      size="sm"
                    >
                      Vista 3 días
                    </Button>
                    <Button
                      className="btn-neutral"
                      color="default"
                      data-calendar-view="basicDay"
                      onClick={() => changeCalendarView('dayGridDay')}
                      size="sm"
                    >
                      Vista día
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div
                  className="calendar"
                  data-toggle="calendar"
                  id="calendar"
                  ref={calendarRef}
                />
              </CardBody>
              <CardFooter>
                <CopyToClipboard
                  text={urlVisitsTenant}
                  onCopy={() =>
                    setClipboardUrls((state) => ({
                      landlord: '',
                      tenant: urlVisitsTenant,
                    }))
                  }
                >
                  <Button color="primary" id="copy-button-tenant">
                    <i className="ni ni-single-copy-04" />
                    <span>Copiar link visitas inquilino</span>
                  </Button>
                </CopyToClipboard>
                <UncontrolledTooltip
                  key={'ttip-tenant'}
                  delay={0}
                  target="copy-button-tenant"
                >
                  {clipboardUrls.tenant === urlVisitsTenant
                    ? 'Copiado'
                    : 'Hacer clic para copiar'}
                </UncontrolledTooltip>
                <CopyToClipboard
                  text={urlVisitsLandlord}
                  onCopy={() =>
                    setClipboardUrls((state) => ({
                      landlord: urlVisitsLandlord,
                      tenant: '',
                    }))
                  }
                >
                  <Button color="default" id="copy-button-landlord">
                    <i className="ni ni-single-copy-04" />
                    <span>Copiar link visitas propietario</span>
                  </Button>
                </CopyToClipboard>
                <UncontrolledTooltip
                  key={'ttip-landlord'}
                  delay={0}
                  target="copy-button-landlord"
                >
                  {clipboardUrls.landlord === urlVisitsLandlord
                    ? 'Copiado'
                    : 'Hacer clic para copiar'}
                </UncontrolledTooltip>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {/* Modal add visit */}
      <Modal
        isOpen={modalAdd}
        toggle={() => closeAddEditModal()}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-header">
          <Row>
            <Col md="12">
              <Alert color="default">
                {isEdit
                  ? `Reagendar visita a propidad al ${formatStandarCalendarDate(
                      event?.start,
                      false,
                    )}`
                  : `Agendar visita a propidad el ${formatStandarCalendarDate(
                      creationStartDate || '',
                      false,
                    )}`}
              </Alert>
            </Col>
          </Row>
        </div>
        <form
          onSubmit={
            !isEdit
              ? handleSubmit(doCreateVisit)
              : handleSubmit(doRescheduleVisit)
          }
        >
          <div className="modal-body">
            {!isEdit && (
              <FormGroup>
                <label className="form-control-label" htmlFor="plan_id">
                  Asignar inquilino
                </label>
                <InputGroup className="mb-3">
                  <Input
                    onChange={({ target }) =>
                      setTenantEmail(target.value || '')
                    }
                    placeholder="Correo de inquilino"
                    type="text"
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={handleSearchTenant}
                      color="primary"
                      outline
                    >
                      Buscar
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            )}
            {tenantFound && tenantFound !== null && (
              <Row>
                <Col md="12">
                  <h5>
                    {isEdit ? 'Inquilino asignado' : 'Inquilino encontrado:'}
                  </h5>
                  <ProfileCard
                    avatar={tenantFound.avatar}
                    name={tenantFound?.name}
                    phone={tenantFound?.phone}
                    showAvatar={true}
                  />
                </Col>
              </Row>
            )}
            {formError && (
              <Row>
                <Col md="12">
                  <Alert color="danger">{formError}</Alert>
                </Col>
              </Row>
            )}
            <FormGroup>
              <label className="form-control-label" htmlFor="start_time">
                Selecciona una hora
              </label>
              <Controller
                name="start_time"
                control={control}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder="Selecciona una hora"
                    options={hours}
                    isOptionDisabled={(option: any) => option.disabled}
                    onChange={(selected: any) => {
                      setValue('start_time', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
            </FormGroup>
            {errors.start_time?.message && (
              <Alert color="danger">{errors.start_time?.message}</Alert>
            )}
            <FormGroup>
              <label className="form-control-label" htmlFor="marketplace_type">
                Selecciona el tipo de visita
              </label>
              <Controller
                name="marketplace_type"
                control={control}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder="Selecciona tipo de visita"
                    options={marketplaceTypes}
                    onChange={(selected: any) => {
                      setValue('marketplace_type', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
            </FormGroup>
            {errors.marketplace_type?.message && (
              <Alert color="danger">{errors.marketplace_type?.message}</Alert>
            )}
            <FormGroup>
              <label className="form-control-label" htmlFor="proper_id">
                Selecciona un proper
              </label>
              <Controller
                name="proper_id"
                control={control}
                render={({ field: { ref } }) => (
                  <Select
                    placeholder="Selecciona un proper"
                    options={propers}
                    onChange={(selected: any) => {
                      setValue('proper_id', selected.value);
                    }}
                    ref={ref}
                  />
                )}
              />
            </FormGroup>
            {errors.proper_id?.message && (
              <Alert color="danger">{errors.proper_id?.message}</Alert>
            )}
          </div>
          <div className="modal-footer">
            <Button onClick={() => closeAddEditModal()} color="secondary">
              Cancelar
            </Button>
            <Button
              className="new-event--add"
              color={isEdit ? 'default' : 'primary'}
              type="submit"
            >
              {isEdit ? 'Reagendar visita' : 'Crear visita'}
            </Button>
          </div>
        </form>
      </Modal>

      {/* Modal view visit */}
      <Modal
        isOpen={modalView}
        toggle={() => setModalView(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          <Form className="edit-event--form">
            <FormGroup>
              <VisitMarketplaceTypeBadge
                marketplace_type={event?.extendedProps.marketplace_type || ''}
                customClassname="badge-lg"
              />
              <Alert color="default" className="mt-1">
                {`Visita: ${formatStandarCalendarDate(event?.start)} `}
              </Alert>
              <label className="form-control-label">Inquilino:</label>
              <ProfileCard
                avatar={event?.extendedProps.tenantInfo.avatar || ''}
                name={event?.extendedProps.tenantInfo.name || ''}
                phone={''}
                extraInfo={`${event?.title}`}
                showAvatar={true}
              />
              <Alert color="primary">
                <span className="alert-inner--text">
                  {event?.extendedProps.proper_id && (
                    <>
                      <strong>Proper asignado: </strong>
                      {findProper(event?.extendedProps.proper_id)}{' '}
                      <span
                        className="alert-inner--icon"
                        onClick={() =>
                          toggleChangeProperForm(!showChangeProperForm)
                        }
                      >
                        <i className="fa fa-pencil" />
                      </span>
                    </>
                  )}
                </span>
              </Alert>

              {showChangeProperForm && (
                <>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="select"
                        placeholder="Selecciona un proper"
                        onChange={(event) => {
                          setUpdateProperId(event.target.value);
                        }}
                      >
                        {propers.map((proper) => (
                          <option key={proper.value} value={proper.value}>
                            {proper.label}
                          </option>
                        ))}
                      </Input>
                      <InputGroupAddon addonType="append">
                        <Button
                          color="primary"
                          onClick={() => updateProperVisit(event)}
                        >
                          Cambiar
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </>
              )}

              <Button onClick={() => setModalView(false)} color="secondary">
                Cerrar
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/tenant/${event?.extendedProps.tenantInfo.id}/profile`,
                  )
                }
                color="default"
              >
                Ver perfil de inquilino
              </Button>
              <Button onClick={() => deleteVisit(event)} color="danger">
                Eliminar visita
              </Button>
            </FormGroup>
            <input className="edit-event--id" type="hidden" />
          </Form>
        </div>
      </Modal>

      {/* Modal view done or cancelled visit */}
      <Modal
        isOpen={modalDoneCancelled}
        toggle={() => setModalDoneCancelled(false)}
        className="modal-dialog-centered modal-secondary"
      >
        <div className="modal-body">
          <Form className="edit-event--form">
            <FormGroup>
              <Badge
                className="badge-lg"
                color={
                  event?.extendedProps.status === VISIT_STATUS.DONE
                    ? 'success'
                    : 'danger'
                }
              >
                {getVisitStatusAdapter(event?.extendedProps.status)}
              </Badge>
              <VisitMarketplaceTypeBadge
                marketplace_type={event?.extendedProps.marketplace_type || ''}
                customClassname="badge-lg"
              />
              <Alert className="mt-2" color="default">
                {`Fecha de visita: ${formatStandarCalendarDate(event?.start)} `}
              </Alert>
              <label className="form-control-label">Inquilino:</label>
              <ProfileCard
                avatar={event?.extendedProps.tenantInfo.avatar || ''}
                name={event?.extendedProps.tenantInfo.name || ''}
                phone={''}
                extraInfo={`${event?.title}`}
                showAvatar={true}
              />
              <Alert color="primary">
                <span className="alert-inner--text">
                  {event?.extendedProps.proper_id && (
                    <>
                      <strong>Proper asignado: </strong>
                      {findProper(event?.extendedProps.proper_id)}{' '}
                    </>
                  )}
                </span>
              </Alert>
              <Button
                onClick={() => setModalDoneCancelled(false)}
                color="secondary"
              >
                Cerrar
              </Button>
              <Button
                onClick={() =>
                  navigate(
                    `/tenant/${event?.extendedProps.tenantInfo.id}/profile`,
                  )
                }
                color="default"
              >
                Ver perfil de inquilino
              </Button>
            </FormGroup>
            <input className="edit-event--id" type="hidden" />
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AdminVisit;
