import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import useAdminAdditionalInfo from './hooks/useAdminAdditionalInfo';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { checkOptions } from '../../constants/formConstants';
import LandlordSearchInput from '../../components/landlordInvestors/LandlordSearchInput';
import AdminPage from '../../components/admin/AdminPage';
import LoadingCard from '../../components/LoadingCard';

export const AdminPropertyAdditionalInfo = () => {
  const { id = '' } = useParams();
  const {
    isLoading,
    control,
    register,
    setValue,
    errors,
    handleSubmit,
    formUpdateData,
    doAction,
    catalogs,
    loadingCurrency,
    currentCurrency,
    propersOptions,
    isLoadingPropers,
  } = useAdminAdditionalInfo({ id });

  if (!formUpdateData) {
    return (
      <LoadingCard name={'Actualizar propiedad'} parent_name={'Propiedades'} />
    );
  }

  return (
    <AdminPage
      name={`Actualizar propiedad #${formUpdateData?.code}`}
      parentName={'Propiedades'}
      header={<h3 className="mb-0">{'Actualizar información adicional'}</h3>}
    >
      <Row>
        <div className="col">
          <Form role="form" onSubmit={handleSubmit(doAction)}>
            <Row>
              <Col md="6">
                <Row>
                  {/* type */}
                  <Col md="12">
                    <FormGroup>
                      {catalogs.propertyTypes &&
                        catalogs.propertyTypes.map((item) => (
                          <div 
                          key={`type-property-${item.id}`}
                          className="custom-control custom-radio custom-control-inline">
                            <input
                              {...register('type_id')}
                              className="custom-control-input"
                              type="radio"
                              value={item.id}
                              id={item.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                              {item.type}
                            </label>
                          </div>
                        ))}
                      <h5 className="text-danger">{errors.type_id?.message}</h5>
                    </FormGroup>
                  </Col>
                  {/* Currency */}
                  <Col md={12}>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="currency">
                        Moneda
                      </label>
                      {!loadingCurrency && (
                        <Controller
                          name="currency"
                          control={control}
                          defaultValue={currentCurrency.value || ''}
                          render={({ field: { ref } }) => (
                            <Select
                              placeholder="Selecciona una moneda"
                              options={catalogs.currencies}
                              defaultValue={currentCurrency}
                              onChange={(selected: any) => {
                                const { value = '' } = selected;
                                setValue('currency', value);
                              }}
                              ref={ref}
                            />
                          )}
                        />
                      )}
                      <h5 className="text-danger">
                        {errors.currency?.message}
                      </h5>
                    </FormGroup>
                  </Col>
                  {/* name */}
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Titulo o nombre de propiedad
                      </label>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <Input
                            className={errors.name?.message && 'is-invalid'}
                            {...field}
                            id="name"
                          />
                        )}
                      />
                      <h5 className="text-danger">{errors.name?.message}</h5>
                    </FormGroup>
                  </Col>
                  {/* description */}
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="description"
                      >
                        Descripción
                      </label>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <Input
                            className={
                              errors.description?.message && 'is-invalid'
                            }
                            {...field}
                            id="description"
                          />
                        )}
                      />
                      <h5 className="text-danger">
                        {errors.description?.message}
                      </h5>
                    </FormGroup>
                  </Col>
                  {/* property_number + extra_info */}
                  <Col md="12">
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="property_number"
                          >
                            Número de casa o apartamento
                          </label>
                          <Controller
                            name="property_number"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.property_number?.message &&
                                  'is-invalid'
                                }
                                {...field}
                                id="property_number"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.property_number?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="extra_info"
                          >
                            Más información
                          </label>
                          <Controller
                            name="extra_info"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                className={
                                  errors.extra_info?.message && 'is-invalid'
                                }
                                {...field}
                                id="extra_info"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.extra_info?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  {/* property keys */}
                  <Col md="12">
                    <p>Llaves de la propiedad</p>
                    <FormGroup>
                      <Row>
                        <Col md={6}>
                          <label
                            className="form-control-label"
                            htmlFor="has_keys"
                          >
                            Propi tiene las llaves
                          </label>
                        </Col>
                        <Col md={6}>
                          {checkOptions &&
                            checkOptions.map((item: any) => (
                              <div 
                              key={`div_propi_property_keys-${item.name}`}
                              className="custom-control custom-radio custom-control-inline">
                                <input
                                  {...register('has_propi_property_keys')}
                                  className="custom-control-input"
                                  type="radio"
                                  value={item.value}
                                  defaultChecked={
                                    item.value ===
                                    (formUpdateData?.has_propi_property_keys ||
                                      false)
                                  }
                                  id={`has_propi_property_keys-${item.name}`}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`has_propi_property_keys-${item.name}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            ))}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Instrucciones para obtener las llaves
                      </label>
                      <Controller
                        name="location_property_keys"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <Input
                            className={
                              errors.location_property_keys?.message &&
                              'is-invalid'
                            }
                            {...field}
                            id="location_property_keys"
                          />
                        )}
                      />
                      <h5 className="text-danger">
                        {errors.location_property_keys?.message}
                      </h5>
                    </FormGroup>
                  </Col>
                  {/* Proper */}
                  {!isLoadingPropers && (
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="proper_id"
                        >
                          Proper asignado
                        </label>
                        <Controller
                          key={'proper_id'}
                          control={control}
                          name="proper_id"
                          render={({ field }) => (
                            <>
                              <Select
                                key={`proper-`}
                                placeholder={'Selecciona un proper'}
                                options={propersOptions}
                                defaultValue={propersOptions.find(
                                  (po) =>
                                    po.value === formUpdateData?.proper_id,
                                )}
                                onChange={(selected) => {
                                  if (selected) {
                                    setValue('proper_id', selected.value);
                                  }
                                }}
                                ref={field.ref}
                              />
                            </>
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.proper_id?.message}
                        </h5>
                      </FormGroup>
                    </Col>
                  )}
                  {/* Landlord */}
                  <LandlordSearchInput
                    header={'Actualizar propietario'}
                    onLandlordfound={(landlordId, landlord) => {
                      console.log(`
                            landlordId: ${landlordId}, landlord: ${JSON.stringify(
                        landlord,
                      )}
                          `);
                      setValue('landlord_id', landlordId);
                    }}
                  />
                  <Col md="12">
                    {formUpdateData?.landlord_info.name && (
                      <h5>
                        {' '}
                        Landlord previamente asignado:{' '}
                        {formUpdateData.landlord_info.name}{' '}
                      </h5>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md="12" className='mt-3'>
                <Button color={'warning'} disabled={isLoading}>
                  {'Actualizar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </AdminPage>
  );
};
