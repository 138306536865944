import { Button, Modal } from 'reactstrap';

interface Props {
  entity: any;
  isOpen: boolean;
  onToggle: () => void;
  onConfirmation: (entity: any) => void;
  warningText: string;
  confirmationText: string;
  abortText: string;
}

export default function CancelEntityModal(
  {entity, isOpen, onToggle, onConfirmation, warningText, confirmationText, abortText}: Props ) {
  const confirmationModal = (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Tu atención es requerida
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">Anular</h4>
          <p>
            {warningText}
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          onClick={() => onConfirmation(entity)}
        >
          {confirmationText}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );

  return (
    <>
      {confirmationModal}
    </>
  );

};
