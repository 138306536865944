export const ADMIN_ROLES = {
  PROPER_RENT: 'PROPER_RENT',
  SDR_RENT: 'SDR_RENT',
  CONTENT_RENT: 'CONTENT_RENT',
  SUPPLY_RENT: 'SUPPLY_RENT',
  MANAGER_RENT: 'MANAGER_RENT',
  PROPER_SALES: 'PROPER_SALES',
  SDR_SALES: 'SDR_SALES',
  SUPPLY_SALES: 'SUPPLY_SALES',
  MANAGER_SALES: 'MANAGER_SALES',
  GENERAL_MANAGER: 'GENERAL_MANAGER',
  GENERAL_IT: 'GENERAL_IT',
  GENERAL_MARKETING: 'GENERAL_MARKETING',
  GENERAL_FINANCE: 'GENERAL_FINANCE',
};
