import React, { useCallback, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import {
  InvestorProjectDetail,
  ModelUnitForm,
  StatusComponentMapperProps,
  UpdateUnitsPresalesStateForm,
} from './models/investors';
import {
  getInvestorProjectDetailEndpoint,
  getUnitsForModelEndpoint,
  takeDownUnitsEndpoint,
  updateModelUnitEndpoint,
} from './services/investorService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {
  Badge,
  Button,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledAlert,
} from 'reactstrap';
import InvestorProjectUnitCard from './components/InvestorProjectDetailUnitCard';
import LoadingCard from '../../components/LoadingCard';
import useAdminEntity from '../../hooks/useAdminEntity';

import * as yup from 'yup';
import useModalState from '../../hooks/useModalState';
import TakeDownPropertyModal from '../properties/components/TakeDownPropertyModal';
import TakeDownUnitModal from './components/TakeDownUnitModal';

const itemsPerPage = 15;

const InvestorProjectUnits: React.FC = () => {
  const navigate = useNavigate();
  const { callEndpoint } = useCallApiAndLoad();
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { id: investorProjectId = '' } = useParams();
  const { isLoading } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [successMessage, setSuccesMessage] = useState<string | null>(null);

  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [editedData, setEditedData] = useState<any | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [currentTakeDownProperty, setCurrentTakeDownProperty] = useState<any>();
  const [takeDownPropertyModal, toggleTakeDownPropertyModal] = useModalState();

  const takeDownProperty = useCallback(
    async (model_id: any, unit_id: any, form: UpdateUnitsPresalesStateForm) => {
      const {
        status, // data
      } = await callEndpoint(
        takeDownUnitsEndpoint(
          model_id,
          unit_id,
          { status: form.status, comment: form.comment },
          token,
        ),
      );
      if (status === 200) {
        // const response = fetchResponseAdapter(data)
        
        toggleTakeDownPropertyModal();
        navigate(0)
      }
    },
    [callEndpoint, navigate, toggleTakeDownPropertyModal, token],
  );

  //DELETE

  const takeDownPropertyModalComponent = (
    <TakeDownUnitModal
      // entity={currentTakeDownProperty}
      unit_id={currentTakeDownProperty?.id || ''}
      model_id={currentTakeDownProperty?.model_id || ''}
      marketplace_type="sale"
      model_name={currentTakeDownProperty?.model_name}
      unit_name={currentTakeDownProperty?.name}
      isOpen={takeDownPropertyModal}
      onToggle={toggleTakeDownPropertyModal}
      onConfirmation={takeDownProperty}
      warningText={'¿Estás seguro de dar de baja está unidad ?'}
      confirmationText={'Si, dar de baja'}
      abortText={'No, continuar revisando...'}
    />
  );

  // PAGINATION
  const getPageRange = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return { startIndex, endIndex };
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < Math.ceil(modelUnits.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  // PAGE HEADER
  const pageName = 'Administrar unidades';
  const pageParentName = 'Preventa';
  const globalAlertOptions = {
    success: false,
    warning: true,
    onConfirm: () => setErrorAlert(null),
    title: 'Error',
    text: errorAlert || '',
  };

  // EDIT LOGIC DATA

  const displayPropertiesSpanish = [
    'ID',
    'Modelo',
    'Estado',
    'Unidad',
    'Precio',
    'Área',
    'Varas cuadradas',
    'Baños',
    'Cuartos',
    'Parqueos',
    'Piso',
    'Distribución',
  ];

  const displayProperties = [
    'id',
    'model_name',
    'status',
    'name',
    'base_price',
    'area',
    'square_rods',
    'bathrooms',
    'bedrooms',
    'parking',
    'level',
    'distribution',
  ];

  // Definir las propiedades que se pueden editar
  const editableProperties = [
    'name',
    'base_price',
    'area',
    'square_rods',
    'bathrooms',
    'bedrooms',
    'parking',
    'level',
    'distribution',
  ];

  const getUnitsForModal = useCallback(
    () => getUnitsForModelEndpoint(investorProjectId, token),
    [investorProjectId],
  );
  const [modelUnits, setModelUnits] = useAdminEntity<any[]>(
    getUnitsForModal,
    setErrorAlert,
  );

  const startEditing = (rowIndex: number, rowData: any) => {
    setEditingRow(rowIndex);
    setEditedData({
      ...rowData,
      modelo: undefined,
    });
  };

  const cancelEditing = () => {
    setEditingRow(null);
    setEditedData(null);
  };

  const saveChanges = async () => {
    try {
      // Validar cada campo antes de enviar la solicitud
      for (const key of editableProperties) {
        const error = await validateField(key, editedData?.[key]);
        if (error) {
          // Mostrar alerta con el mensaje de error y detener el proceso
          setAlertMessage(`Error en el campo ${key}: ${error}`);
          setTimeout(() => setAlertMessage(null), 3000);
          return;
        }
      }

      // Si pasa la validación, proceder con el guardado
      const data: ModelUnitForm = {
        name: editedData?.name,
        base_price: Number(editedData?.base_price),
        area: Number(editedData?.area),
        square_rods: Number(editedData?.square_rods),
        bathrooms: Number(editedData?.bathrooms),
        bedrooms: Number(editedData?.bedrooms),
        parking: Number(editedData?.parking),
        level: Number(editedData?.level),
        distribution: editedData?.distribution,
      };

      // Enviar la solicitud para guardar los cambios

      handleModeulUnitUpdate(data, editedData?.id);
      cancelEditing();
    } catch (error) {
      // Manejar cualquier error inesperado
      console.error('Error al intentar guardar los cambios:', error);
    }
  };

  const handleModeulUnitUpdate = async (
    form: ModelUnitForm,
    modelId: string,
  ) => {
    const { status, data } = await callEndpoint(
      updateModelUnitEndpoint(form, investorProjectId, modelId, token),
    );
    const response = fetchResponseAdapter(data);
    if (status === 200) {
      setModelUnits((prevModelUnits: any) => {
        const updatedModelUnits = [...prevModelUnits];
        const updatedModelIndex = updatedModelUnits.findIndex(
          (item) => item.id === modelId,
        );

        if (updatedModelIndex !== -1) {
          // Actualizar el elemento específico
          updatedModelUnits[updatedModelIndex] = {
            ...updatedModelUnits[updatedModelIndex],
            ...form,
          };
        }

        return updatedModelUnits;
      });

      setSuccesMessage('Unidad actualiza con exito');
      setTimeout(() => setSuccesMessage(null), 3000);
    } else setErrorAlert(response.message);
  };

  const getProjectDetails = useCallback(
    () => getInvestorProjectDetailEndpoint(investorProjectId, token),
    [],
  );

  const [project] = useAdminEntity<InvestorProjectDetail>(
    getProjectDetails,
    setErrorAlert,
  );

  if (!project || isLoading)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  if (!modelUnits || isLoading) {
    return <LoadingCard name={pageName} parent_name={pageParentName} />;
  }

  // FORM LOGIC VALIDATIONS

  const validationSchema = yup.object({
    name: yup.string().required('nombre de la unidad es requerido.'),
    base_price: yup
      .number()
      .positive('El precio debe ser mayor que cero.')
      .required('El precio de la unidad es requerido.'),
    area: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    square_rods: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    bathrooms: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    bedrooms: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    parking: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    level: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    distribution: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),
  });

  const validateField = async (field: string, value: any) => {
    try {
      await validationSchema.validateAt(field, { [field]: value });
    } catch (error: any) {
      return error.message;
    }
    return undefined;
  };

  // Status units

  const StatusComponentMapper: React.FC<StatusComponentMapperProps> = ({ status }) => {
    switch (status) {
      case 'available':
        return <Badge color="success">Disponible</Badge>;
      case 'pre_booked':
        return <Badge color="info">Pre-reservada</Badge>;
        case 'assigned':
        return <Badge color="info">Asignada</Badge>;
        case 'taken':
        return<Badge color="info">Reservada</Badge>;
        case 'disabled':
        return <Badge color="danger">Desactivada</Badge>;
        case 'sold_external':
        return <Badge color="warning">Vendida fuera</Badge>;
        case 'created':
        return <Badge color="dark" className='text-white'>Creada</Badge>;
      // Agrega más casos según sea necesario para otros estados
      default:
        return null; // Puedes retornar null o algún componente por defecto
    }
  };

  return (
    <AdminPage
      name={pageName}
      parentName={pageParentName}
      globalAlertOptions={globalAlertOptions}
    >
      {alertMessage && (
        <UncontrolledAlert color="danger" fade={true}>
          {alertMessage}
        </UncontrolledAlert>
      )}
      {successMessage && (
        <UncontrolledAlert color="success" fade={true}>
          {successMessage}
        </UncontrolledAlert>
      )}
      <Row>
        <Col>
          <InvestorProjectUnitCard project={project} />
        </Col>
      </Row>

      <Table responsive>
        <thead>
          <tr>
            <th
              style={{
                position: 'sticky',
                left: 0,
                zIndex: 1,
                backgroundColor: '#fff',
              }}
            >
              Acciones
            </th>
            {displayPropertiesSpanish.map((property, index) => (
              <th style={{ width: 'auto' }} key={index}>
                {property}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {modelUnits
            ?.slice(getPageRange().startIndex, getPageRange().endIndex)
            .map((rowData, index) => (
              <tr key={index}>
                <td
                  style={{
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                  }}
                >
                  {editingRow === index ? (
                    <>
                      <Button color="success" size="sm" onClick={saveChanges}>
                        Guardar
                      </Button>
                      <Button color="danger" size="sm" onClick={cancelEditing}>
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => startEditing(index, rowData)}
                      >
                        Editar
                      </Button>
                      {(rowData?.status === 'created' || rowData?.status === 'available' ) && (
                        <Button
                          color="warning"
                          size="sm"
                          onClick={() => {
                            setCurrentTakeDownProperty(rowData);
                            toggleTakeDownPropertyModal();
                          }}
                        >
                          Dar de baja
                        </Button>
                      )}
                    </>
                  )}
                </td>
                {displayProperties.map((key) => (
                  <React.Fragment key={key}>
                    {editingRow === index &&
                    editableProperties.includes(key) ? (
                      <td>
                        <input
                          style={{ width: '100px' }}
                          className="border border-light-subtle rounded-2 text-center"
                          type={
                            key === 'name' || key === 'distribution'
                              ? 'text'
                              : 'number'
                          }
                          value={editedData?.[key] || ''}
                          onChange={(e) =>
                            setEditedData({
                              ...editedData,
                              [key]: e.target.value,
                            })
                          }
                        />
                      </td>
                    ) : (
                      <>
                      {key === 'id' ? (
                          <td
                            style={{
                              maxWidth: '100px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {rowData[key]}
                          </td>
                        ) : (
                          <>
                            {key === 'base_price' ? (
                              <td>{`${rowData['base_price_currency']}`}</td>
                            ) : key === 'area' ? (
                              <td>{`${rowData[key]} m²`}</td>
                            ) : key === 'status' ? (
                              <td>
                                <StatusComponentMapper status={String(rowData['status'])} />
                              </td>
                            ) : (
                              <td>{rowData[key]}</td>
                            )}
                          </>
                        )}
                      </>
                        
                      
                    )}
                  </React.Fragment>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>

      <Row className="d-flex justify-content-center mt-3">
        <div className="w-auto">
          <Pagination>
            <PaginationItem>
              <PaginationLink onClick={goToPreviousPage}>
                <i className="fas fa-angle-left" />
              </PaginationLink>
            </PaginationItem>
            {Array.from({
              length: Math.ceil(modelUnits.length / itemsPerPage),
            }).map((_, index) => (
              <PaginationItem
                key={index + 1}
                active={currentPage === index + 1}
              >
                <PaginationLink onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationLink onClick={goToNextPage}>
                <i className="fas fa-angle-right" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </Row>

      {takeDownPropertyModalComponent}
    </AdminPage>
  );
};

export default InvestorProjectUnits;
