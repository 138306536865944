import React from 'react';
import {
  ProofIncomeObject,
  ProofOutcomeObject,
} from '../models/proofIncomeModel';
import AdminListGroup, {
  DisplayItem,
} from '../../../components/admin/AdminListGroup';
import { NavLink } from 'reactstrap';

interface Props {
  proofIncome: ProofIncomeObject;
  proofOutcome: ProofOutcomeObject;
}

const AccountingProofIncomeCard = ({ proofIncome, proofOutcome }: Props) => {
  const fieldMapper: DisplayItem[] = [
    {
      title: 'Código',
      value: proofIncome.code,
    },
    {
      title: 'Concepto',
      value: proofIncome.concept_income,
    },
    {
      title: 'Monto',
      value: proofIncome.amount,
    },
    {
      title: 'Forma de pago recibido',
      value: proofIncome.payment_income,
    },
    {
      title: 'Referencia',
      value: proofIncome.ref_income,
    },
    {
      title: 'Fecha generado',
      value: proofIncome.generated_at,
    },
    {
      title: 'Fecha última modificación',
      value: proofIncome.updated_at,
    },
  ];

  if (!proofOutcome.id) {
    fieldMapper.unshift({
      title: (
        <>
          <i className="ni ni-ruler-pencil mr-2" /> Editar comprobante
        </>
      ),
      value: '',
      href: `/accounting/proof-income/${proofIncome.id}/edit`,
    });
  }

  if (proofOutcome.id) {
    fieldMapper.push({
      title: 'Comprobante de egreso relacionado',
      value: proofOutcome.code,
      href: `/accounting/proof-outcome/${proofOutcome.id}`,
    });
  }

  if (proofIncome.url_file) {
    fieldMapper.push({
      title: '',
      value: (
        <NavLink href={proofIncome.url_file || ''} target="_blank">
          <i className={'fa fa-duotone fa-file-contract'} /> Ver comprobante en
          PDF
        </NavLink>
      ),
    });
  }

  return (
    <AdminListGroup
      title={'Información comprobante de ingreso'}
      items={fieldMapper}
    />
  );
};

export default AccountingProofIncomeCard;
