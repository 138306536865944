import React, { useCallback, useEffect, useRef, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CreatePolygonApi,
  CreateUpdatePolygonForm,
  ModifyPolygonApi,
  PolygonsApi,
  PolygonsForMapBuilding,
  UpdatePolygon,
} from '../models/polygonsModel';
import { useJsApiLoader } from '@react-google-maps/api';
import useUserCountry from '../../../hooks/useUserCountry';
import {
  createPolygonEndpoint,
  polygonDetailEndpoint,
  polygonListEndpoint,
  polygonParentListEndpoint,
  updatePolygonEndpoint,
} from '../services/polygonService';
import { formatMoney } from '../utils/moneyUtils';
import { ErrorAlert, SuccessAlert } from '../../../models/alertModel';
import { Coordinate } from '../../../models/mapViewModel';
import { buildMapPolygonsFromGeoJsonAdapter } from '../adapters/polygonsAdapter';
import { listToSelectOptionAdapter } from '../../../adapters/listAdapter';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { Option } from '../../../models/formModel';

const svCenter = { lat: 13.67555049401088, lng: -89.23800587655418 };

const gtCenter = {
  lat: 14.602933,
  lng: -90.5107544,
};

const defaultZoom = 10;

const usePolygons = () => {
  const [sweetAlert, setSweetAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<SuccessAlert | ErrorAlert>({
    success: true,
    title: '',
    text: '',
    onConfirm: () => setSweetAlert(false),
    onCancel: () => setSweetAlert(false),
  });
  const { getAuthToken } = useAuth();
  const navigate = useNavigate();
  const token = getAuthToken();
  const { isLoading, callEndpoint } = useCallApiAndLoad();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB0c1nq-isvWJWWam5CICmPt0oYgY1A_eg',
  });
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode = getCurrentCountry()?.code || 'SV';
  const [polygonMarkerCoords, setPolygonMarkerCoords] = useState<Coordinate[]>(
    [],
  );
  const [builtPolygon, setBuiltPolygon] = useState<Coordinate[]>([]);
  const [mapState, setMapState] = useState<any>(null);
  const [polygonErrorMsg, setPolygonErrorMsg] = useState<string>('');

  const [defaultMapCenter, setDefaultMapCenter] = useState<Coordinate>(
    currentUserCountryCode === 'SV' ? svCenter : gtCenter,
  );

  // on change polygons by level states
  const [pickedLevel, setPickedLevel] = useState<number>(1);
  const [polygonsByLevel, setPolygonsByLevel] = useState<
    PolygonsForMapBuilding[]
  >([]);

  // polygons info to  assign parent
  const [loadingParentPolygons, setLoadingParentPolygons] =
    useState<boolean>(false);
  const [polygonsParent, setPolygonsParent] = useState<Option[]>([]);

  // update polygon refs and states
  const updatePolygonRef = useRef<any>(null);
  const initialUpdatePolygonState: UpdatePolygon = {
    polygon_id: '',
    previous_polygon: [],
    polygon_modified: [],
    polygon_info: undefined,
  };
  const [updatePolygon, setUpdatePolygon] = useState<UpdatePolygon>(
    initialUpdatePolygonState,
  );

  const clearMap = () => {
    setPolygonMarkerCoords([]);
    setBuiltPolygon([]);
  };

  // Polygon handlers to create and update polygon
  const handleBuildPolygon = () => {
    if (!polygonMarkerCoords.length) {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Debes seleccionar puntos para formar un polígono',
      }));
      setSweetAlert(true);
      return;
    }
    // Set first point to last one to close the polygon
    const polygonToBuild = [...polygonMarkerCoords, polygonMarkerCoords[0]];
    setPolygonMarkerCoords([]);
    setBuiltPolygon(polygonToBuild);
  };

  /**
   * This function catch polygon update event rezise, drag etc.
   * @param newPaths
   */
  const handleUpdatePolygon = (newPaths: any) => {
    // Update state with new polygon coordinates
    if (updatePolygonRef && updatePolygonRef.current) {
      const newPaths = updatePolygonRef.current.state.polygon
        .getPath()
        .getArray()
        .map((latLng: any) => ({ lat: latLng.lat(), lng: latLng.lng() }));
      setUpdatePolygon((state) => ({
        ...state,
        polygon_modified: newPaths,
      }));
    }
  };

  // Reset polygons update actions
  const handleDiscardPolygonChanges = () => {
    setUpdatePolygon(initialUpdatePolygonState);
    reset({
      square_identifier: '',
      area: 0,
      square_rods: 0,
      min_price_value: 0,
      mid_price_value: 0,
      high_price_value: 0,
      level: pickedLevel
    });
    setPolygonsByLevel([]);
    loadPolygonsByLevel().catch((e) => console.error(e));
  };

  // this is used to put markers in map to build a new polygon
  const handleMapClick = (e: any) => {
    const newCoords = [
      ...polygonMarkerCoords,
      { lat: e.latLng.lat(), lng: e.latLng.lng() },
    ];
    setPolygonMarkerCoords(newCoords);
  };

  const onLoad = useCallback((map: any) => {
    map.setZoom(defaultZoom);
    setMapState(map);
  }, []);

  // Form schema
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    register,
    reset,
  } = useForm<CreateUpdatePolygonForm>({
    resolver: yupResolver(
      yup
        .object({
          square_identifier: yup
            .string()
            .required('Nombre identificador es requerido'),
          level: yup.number().required('Nivel de polígono es requerido'),
          area: yup
            .number()
            .required('Area de construcción de polígono es requerido'),
          square_rods: yup
            .number()
            .required('Area de terreno de polígono es requerido'),
          min_price_value: yup
            .number()
            .required('Precio mínimo de polígono es requerido'),
          mid_price_value: yup
            .number()
            .required('Precio medio de polígono es requerido'),
          high_price_value: yup
            .number()
            .required('Precio máximo de polígono es requerido'),
          property_type: yup
            .string()
            .required('Tipo de propiedad es requerida'),
          parent_id: yup.number().nullable().default(null),
        })
        .required(),
    ),
  });

  const doFormAction = async (form: CreateUpdatePolygonForm) => {
    return updatePolygon.polygon_id && updatePolygon.polygon_info
      ? modifyPolygon(form)
      : createPolygon(form);
  };

  // CREATE POLYGON
  const createPolygon = async (form: CreateUpdatePolygonForm) => {
    if (!builtPolygon.length) {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Error, Polígono no ha sido encontrado para procesar',
      }));
      setSweetAlert(true);
      return;
    }
    const {
      square_identifier,
      area,
      square_rods,
      min_price_value,
      mid_price_value,
      high_price_value,
      property_type,
      polygon_type,
      level,
      zoom,
      parent_id,
    } = form;
    const formApi: CreatePolygonApi = {
      display_info: {
        square_identifier,
        center: JSON.parse(form.center),
        full_plus_code: '',
        area,
        zoom,
        floors: 0,
        square_rods,
        street: '',
        city: '',
        min_price: formatMoney(min_price_value, currentUserCountryCode),
        min_price_value,
        mid_price: formatMoney(mid_price_value, currentUserCountryCode),
        mid_price_value,
        high_price: formatMoney(high_price_value, currentUserCountryCode),
        high_price_value,
        property_type,
        parent: 0,
        polygon_type,
      },
      level,
      name: square_identifier,
      geo_points: builtPolygon,
      parent_id,
    };
    const { status } = await callEndpoint(
      createPolygonEndpoint(formApi, token),
    );
    if (status === 201) {
      setAlertProps((state) => ({
        ...state,
        onConfirm: () => navigate(0),
        success: true,
        danger: false,
        title: 'OK',
        text: 'Polígono creado satisfactoriamente',
      }));
    } else {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Lo sentimos, no fue posible procesar el polígono en el servicio',
      }));
    }
    setSweetAlert(true);
  };

  // UPDATE POLYGON
  const modifyPolygon = async (form: CreateUpdatePolygonForm) => {
    if (!updatePolygon.polygon_id && !updatePolygon.polygon_modified.length) {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Lo sentimos, no fue posible actualizar el polígono, no fue encontrado modificaciones realizadas',
      }));
      setSweetAlert(true);
      return;
    }
    const {
      square_identifier,
      area,
      square_rods,
      min_price_value,
      mid_price_value,
      high_price_value,
      property_type,
      level,
      zoom,
      parent_id,
      polygon_type,
    } = form;
    const updateform: ModifyPolygonApi = {
      display_info: {
        square_identifier,
        center: JSON.parse(form.center),
        full_plus_code: '',
        area,
        zoom,
        floors: 0,
        square_rods,
        street: '',
        city: '',
        min_price: formatMoney(min_price_value, currentUserCountryCode),
        min_price_value,
        mid_price: formatMoney(mid_price_value, currentUserCountryCode),
        mid_price_value,
        high_price: formatMoney(high_price_value, currentUserCountryCode),
        high_price_value,
        property_type,
        parent: 0,
        polygon_type,
      },
      level,
      name: square_identifier,
      parent_id,
      geo_points:
        updatePolygon.polygon_modified.length > 0
          ? updatePolygon.polygon_modified
          : updatePolygon.previous_polygon,
    };
    const { status } = await callEndpoint(
      updatePolygonEndpoint(updatePolygon.polygon_id, updateform, token),
    );
    if (status === 200) {
      // setUpdatePolygon(initialUpdatePolygonState);
      handleDiscardPolygonChanges();
      setAlertProps((state) => ({
        ...state,
        success: true,
        danger: false,
        title: 'OK',
        text: 'Polígono actualizado satisfactoriamente',
      }));
    } else {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Lo sentimos, no fue posible modificar el polígono en el servicio',
      }));
    }
    setSweetAlert(true);
  };

  // GET POLYGONS BY LEVEL
  const loadPolygonsByLevel = async () => {
    const { status, data: responseData } = await callEndpoint(
      polygonListEndpoint(pickedLevel, token),
    );
    if (status === 200) {
      const polygonsApi: PolygonsApi[] = responseData.data;
      const polygonsAdapted = buildMapPolygonsFromGeoJsonAdapter(polygonsApi);
      setPolygonsByLevel(polygonsAdapted);
    }
    // call polygons from lower level to assign parent
    if (pickedLevel > 1) {
      setLoadingParentPolygons(true);
      const { status: parentStatus, data: responseParentData } =
        await callEndpoint(polygonParentListEndpoint(pickedLevel - 1, token));
      if (parentStatus !== 200) {
        setAlertProps((state) => ({
          ...state,
          success: false,
          danger: true,
          title: 'Error',
          text: 'Polígonos padre no pudieron ser cargados',
        }));
        setSweetAlert(true);
        setLoadingParentPolygons(false);
        return;
      }
      const response = fetchResponseAdapter(responseParentData);
      const parentListOptions = listToSelectOptionAdapter(
        response.data,
        'id',
        'name',
      );
      setPolygonsParent(parentListOptions);
      setLoadingParentPolygons(false);
    }
  };

  // Get polygon by id for update
  const loadUpdatePolygonData = async (
    polygonId: number,
    polygon: Coordinate[],
  ) => {
    // call api to get data for update form
    const { status, data: responseData } = await callEndpoint(
      polygonDetailEndpoint(polygonId, token),
    );
    if (status === 200 && responseData.data) {
      const polygonFound: PolygonsApi = responseData.data;
      reset({
        square_identifier: polygonFound.display_info.square_identifier,
        level: polygonFound.level,
        area: polygonFound.display_info.area,
        square_rods: polygonFound.display_info.square_rods,
        min_price_value: polygonFound.display_info.min_price_value,
        mid_price_value: polygonFound.display_info.mid_price_value,
        high_price_value: polygonFound.display_info.high_price_value,
        property_type: polygonFound.display_info.property_type,
        parent_id: polygonFound.parent_id,
      });
      setUpdatePolygon((state) => ({
        ...state,
        polygon_id: polygonId,
        previous_polygon: polygon,
        polygon_info: polygonFound,
      }));
      return;
    }
    setAlertProps((state) => ({
      ...state,
      success: false,
      danger: true,
      title: 'Error',
      text: 'Detalle de polígono no pudo ser encontrado',
    }));
    setSweetAlert(true);
  };

  // function

  // define defaults map data
  useEffect(() => {
    setValue('zoom', defaultZoom);
    setValue('level', 1);
    setValue('center', JSON.stringify(defaultMapCenter));
  }, []);

  // load polygons by parent
  useEffect(() => {
    loadPolygonsByLevel().catch((e) => console.error(e));
  }, [pickedLevel]);

  return {
    // level for preview polygons
    pickedLevel,
    setPickedLevel,
    polygonsByLevel,
    // Form
    control,
    register,
    errors,
    handleSubmit,
    isLoading,
    doFormAction,
    setValue,
    // Map
    isLoaded,
    onLoad,
    mapState,
    handleMapClick,
    polygonMarkerCoords,
    builtPolygon,
    handleBuildPolygon,
    handleDiscardPolygonChanges,
    defaultMapCenter,
    defaultZoom,
    clearMap,
    polygonErrorMsg,
    // Sweet alert messages
    sweetAlert,
    alertProps,
    // update polygon refs and state
    updatePolygonRef,
    updatePolygon,
    loadUpdatePolygonData,
    handleUpdatePolygon,
    // parent polygons to assign
    polygonsParent,
    loadingParentPolygons,
  };
};

export default usePolygons;
